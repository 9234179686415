import { useAppSelector } from '@/hooks/store'
import { useMapSpotName } from '@/hooks/useMapSpotName'
import { mapSelectors } from '@/store/mapSlice'
import { getFormattedReservationKey } from '@/utils/getFormattedReservationKey'
import { priceFormatter } from '@/utils/price'
import { SkeletonText } from '../SkeletonText'

interface Props {
  reservationKey: string
  value: number
  isQuotePending: boolean
}

export const QuoteReservation = ({
  reservationKey,
  value,
  isQuotePending,
}: Props) => {
  const { spotType, name } = getFormattedReservationKey(reservationKey)
  const mapElements = useAppSelector(mapSelectors.mapData)?.elements
  const spot = mapElements?.find(
    (element) => element.subType === spotType && element.name === name
  )
  const { getMapSpotName } = useMapSpotName()
  const title = getMapSpotName(spot)
  return (
    <div
      className="flex items-end justify-between gap-1 text-sm text-secondary"
      key={reservationKey}
    >
      <span className="break-word">{title}</span>
      <SkeletonText
        className="w-12"
        isPending={isQuotePending}
        text={priceFormatter.format(value)}
      />
    </div>
  )
}
