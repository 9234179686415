export function getLicenseFromUrl() {
  const url = new URL(window.location.href)
  const pathSegments = url.pathname.split('/').filter(Boolean)

  const [firstPath] = pathSegments

  if (firstPath === 'settings') {
    return ''
  }

  const license = pathSegments[0]
  return license
}
