import { DownloadIcon, HomeIcon } from 'lucide-react'
import { ComponentPropsWithoutRef } from 'react'
import { Cup } from '@/assets/icons/Cup.tsx'

import { ExternalLink } from '@/assets/icons/ExternalLink.tsx'
import { Approve } from './Approve'
import { ApproveTick } from './ApproveTick.tsx'
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'
import { AvailableSpot } from './AvailableSpot'
import { BasketBall } from './BasketBall.tsx'
import { BeachHut } from './BeachHut.tsx'
import { Boat } from './Boat.tsx'
import { Business } from './Business.tsx'
import { Cabin } from './Cabin.tsx'
import { Calendar } from './Calendar.tsx'
import { Cart } from './Cart.tsx'
import { Checkmark } from './Checkmark.tsx'
import { ChevronUp } from './ChevronUp.tsx'
import { Close } from './Close.tsx'
import { Communication } from './Communication.tsx'
import { DeckChair } from './DeckChair.tsx'
import { DiagonalResize } from './DiagonalResize.tsx'
import { Edit } from './Edit.tsx'
import { ExclamationMark } from './ExclamationMark.tsx'
import { Gazebo } from './Gazebo.tsx'
import { Info } from './Info.tsx'
import { KioskSpot } from './KioskSpot.tsx'
import { KioskTicket } from './KioskTicket.tsx'
import { Logo } from './Logo.tsx'
import { LogoFr } from './LogoFr.tsx'
import { Minus } from './Minus.tsx'
import { Move } from './Move.tsx'
import { NotAvailableSpot } from './NotAvailableSpot.tsx'
import { Parking } from './Parking.tsx'
import { Plus } from './Plus.tsx'
import { PoweredBy } from './PoweredBy.tsx'
import { Profile } from './Profile.tsx'
import { Sunbed } from './Sunbed.tsx'
import { Sunbeds } from './Sunbeds.tsx'
import { SunUmbrella } from './SunUmbrella.tsx'
import { Trash } from './Trash.tsx'
import { TriangleExclamationMark } from './TriangleExclamationMark.tsx'
import { Umbrella } from './Umbrella.tsx'
import { Weather } from './Weather.tsx'

export const icons = {
  Approve,
  ApproveTick,
  ArrowLeft,
  ArrowRight,
  AvailableSpot,
  BasketBall,
  BeachHut,
  Boat,
  Business,
  Cabin,
  Calendar,
  Cart,
  Checkmark,
  ChevronUp,
  Close,
  Communication,
  Cup,
  DeckChair,
  DiagonalResize,
  DownloadIcon,
  Edit,
  ExclamationMark,
  ExternalLink,
  Gazebo,
  HomeIcon,
  Info,
  KioskSpot,
  KioskTicket,
  Logo,
  LogoFr,
  Minus,
  Move,
  NotAvailableSpot,
  Parking,
  Plus,
  PoweredBy,
  Profile,
  SunUmbrella,
  Sunbed,
  Sunbeds,
  Trash,
  TriangleExclamationMark,
  Umbrella,
  Weather,
}

export type AppIcon = keyof typeof icons

interface IconProps extends ComponentPropsWithoutRef<'svg'> {
  name: AppIcon
}

export const Icon = ({ name, ...otherProps }: IconProps) => {
  const IconElement = icons[name]
  return <IconElement aria-hidden="true" {...otherProps} />
}

export const isAppIcon = (name: unknown): name is AppIcon => {
  if (typeof name !== 'string') return false
  return Object.keys(icons).includes(name)
}
