import phoneMetadata from 'libphonenumber-js/metadata.min.json'
import { CommandFilter } from '@/components/Combobox'

import { useLocalizedCountries } from '@/hooks/useLocalizedCountries.ts'

export const usePhoneNationFilter: () => CommandFilter = () => {
  const countryCallingCodes = phoneMetadata.country_calling_codes
  const countries = useLocalizedCountries()

  return (phonePrefix, search) => {
    const currentValue = phonePrefix.replace('+', '')

    return Number(
      !!countryCallingCodes[currentValue].find((country) => {
        return `${countries[country]} (${phonePrefix})`
          ?.toLowerCase()
          .includes(search.toLowerCase().trim())
      })
    )
  }
}
