import { FC, SVGProps } from 'react'

import { AppIcon, Icon, isAppIcon } from '../../assets/icons'

interface Props {
  isDisabled?: boolean
  onClick: () => void
  icon: AppIcon | FC<SVGProps<SVGSVGElement>>
  ariaLabel: string
}

export const IconButton = (props: Props) => {
  const { icon: PropsIcon } = props

  return (
    <button
      aria-label={props.ariaLabel}
      className="flex h-10 w-10 items-center justify-center rounded border border-blue-500 p-0 text-blue-500 hover:bg-blue-100 hover:text-blue-500 disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-300"
      disabled={props.isDisabled}
      onClick={props.onClick}
      type="button"
    >
      {isAppIcon(PropsIcon) ? (
        <Icon name={PropsIcon} />
      ) : (
        <PropsIcon aria-hidden="true" className="h-5 w-5" />
      )}
    </button>
  )
}
