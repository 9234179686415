import { QuoteReservation } from '@/models/quote.ts'

interface GetReservationsObjectProps {
  reservations: QuoteReservation[]
  isQuoteForTickets?: boolean
}

// if the reservation is for a specific spot
// the object needs to have a key following this pattern: “_” + spotType + “_” + spotName
// if the reservation is for a generic ticket, an index number will suffice
export const getReservationsObjectFrom = (
  props: GetReservationsObjectProps
) => {
  return props.reservations.reduce(
    (acc, reservation, index) => {
      const dynamicKey = props.isQuoteForTickets
        ? index
        : `_${reservation.spotType}_${reservation.spotName}`

      return {
        ...acc,
        [dynamicKey]: reservation,
      }
    },
    {} as Record<string, object>
  )
}
