import { generatePath } from 'react-router-dom'
import { TICKETS_PATH } from '@/app/router/paths'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice'
import { License } from '@/store/licenseSlice'
import { getSpotsPageByBookingMode } from '@/utils/getSpotsPageByBookingMode'

export const getBookingPath = ({
  bookingAvailability,
  license,
  selectedBookingType,
}: {
  bookingAvailability: BookingAvailability
  license: License
  selectedBookingType?: BookingAvailability | null
}) => {
  const spotsPage = getSpotsPageByBookingMode(license.bookingMode)
  const generatedSpotPath = generatePath(spotsPage, {
    license: license.license,
  })
  const generatedTicketsPath = generatePath(TICKETS_PATH, {
    license: license.license,
  })

  switch (bookingAvailability) {
    case BookingAvailability.SPOTS:
      return generatedSpotPath
    case BookingAvailability.TICKETS:
      return generatedTicketsPath
    case BookingAvailability.SPOTS_AND_TICKETS:
      return selectedBookingType === BookingAvailability.TICKETS
        ? generatedTicketsPath
        : generatedSpotPath
    default:
      return null
  }
}
