import { cn } from '@spiaggeit/spit-ui'
import {
  ComponentPropsWithoutRef,
  ElementType,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react'
import { SurfaceIcon } from '@/components/Surface/SurfaceIcon'

import { ElementAs } from '../../models/element-as.ts'

export type SurfaceProps<T extends ElementType = ElementType> = ElementAs<T> &
  ComponentPropsWithoutRef<T> & {
    variant?: SurfaceVariant
  }

type SurfaceVariant = 'default' | 'form-section'

const classNameVariantMap: Record<SurfaceVariant, string> = {
  default: 'rounded shadow-md',
  'form-section':
    'rounded-none relative shadow-none lg:shadow-md lg:border lg:rounded px-4 pb-2 lg:pb-4 pt-11 lg:pt-14',
}

export const SurfaceComponent = forwardRef(
  <TProps extends ElementType, TRef extends HTMLDivElement>(
    props: SurfaceProps<TProps>,
    ref: ForwardedRef<TRef>
  ) => {
    const {
      children,
      className,
      variant = 'default',
      as,
      ...otherProps
    } = props

    const ReactElement = as || (variant === 'form-section' ? 'section' : 'div')

    return (
      <ReactElement
        className={cn(
          'overflow-hidden border border-gray-100 bg-white text-primary',
          classNameVariantMap[variant],
          className
        )}
        {...otherProps}
        ref={ref}
      >
        {children}
      </ReactElement>
    )
  }
)

SurfaceComponent.displayName = 'Surface'

type CompoundSurface = ForwardRefExoticComponent<
  // eslint-disable-next-line
  SurfaceProps & RefAttributes<any>
> & {
  Icon: typeof SurfaceIcon
}
;(SurfaceComponent as CompoundSurface).Icon = SurfaceIcon

export const Surface = SurfaceComponent as CompoundSurface
