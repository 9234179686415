import { useSearchParams } from 'react-router-dom'
import { CreateCartPayload } from '@/api/createCart.ts'
import { useAppSelector } from '@/hooks/store.ts'
import { InsertDataFormType } from '@/hooks/useUserDataForm.ts'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'

type CreatePayloadFactory = (data: InsertDataFormType) => CreateCartPayload

const kioskData = {
  email: 'kiosk@spiagge.it',
  firstName: 'Kiosk',
  lastName: 'Kiosk',
  phoneNumber: '+393333333333',
}

export const useFormatPayload = (): CreatePayloadFactory => {
  const channel = useAppSelector(appSlice.selectors.channel)
  const reservations = useAppSelector(cartSlice.selectors.formattedReservations)
  const [searchParams] = useSearchParams()
  const sid = searchParams.get('sid') || ''
  const isKiosk = useAppSelector(appSlice.selectors.isKiosk)

  const weatherInsurance = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )

  return (formData) => {
    const isInvoiceRequest = formData.invoice.request === 'invoice'

    return {
      channel,
      discountCode: formData.promo.discountCode,
      email: formData.user.email,
      firstName: formData.user.firstName,
      invoiceAddress1: formData.invoice.address,
      invoiceAddress2: formData.invoice.houseNumber,
      invoiceCity: formData.invoice.city,
      invoiceCompany: formData.invoice.businessName,
      invoiceCountry: formData.invoice.country,
      invoiceFirstName: isInvoiceRequest ? formData.user.firstName : '',
      invoiceLastName: isInvoiceRequest ? formData.user.lastName : '',
      invoiceLotteryCode: null,
      invoicePec: formData.invoice.certifiedEmail,
      invoiceSdi: formData.invoice.sdiCode,
      invoiceState: formData.invoice.province,
      invoiceTaxCode: formData.invoice.fiscalCode,
      invoiceType: formData.invoice.request,
      invoiceVatCode: formData.invoice.vatNumber,
      invoiceZip: formData.invoice.zipCode,
      lastName: formData.user.lastName,
      marketing: {
        general: formData.promo.newsletter,
        thirdParty: false,
      },
      petBreed: formData.dog.breed,
      petChipCode: formData.dog.chipNumber,
      petGender: formData.dog.gender,
      petName: formData.dog.name,
      phoneNumber: `${formData.user.phonePrefix}${formData.user.phoneNumber}`,
      reservations: Object.entries(reservations).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            ...value,
            notes: formData.notes,
            services: { ...value.services },
          },
        }),
        {}
      ),
      sid,
      travelGroup: null,
      weatherInsurance,
      ...(isKiosk && kioskData),
    }
  }
}
