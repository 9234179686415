import { httpClient } from '@/lib/http/HttpClient.ts'
import { Country } from '@/models/app.ts'
import { BackendErrorModel } from '@/models/backendErrorModel.ts'
import {
  ReservationModelSector,
  ReservationModelService,
} from '@/models/cart.ts'
import { ResponseBase } from '@/models/http.ts'
import { QuoteReservation } from '@/models/quote.ts'

interface CreateCartProps {
  payload: CreateCartPayload
  params: CreateCartParams
}

interface CreateCartParams {
  licenseName: string
  country: Country
}

export const createCart = ({ payload, params }: CreateCartProps) => {
  return httpClient.fetch<ResponseBase<CreateCartResponse>, BackendErrorModel>(
    `beaches/${params.licenseName}/carts`,
    params.country,
    {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
}

export type CreateCartResponse = {
  cart: Cart
  reservationModels: ReservationModel[]
  weatherPolicy?: unknown
  noShowPolicy?: unknown
}

export interface Cart {
  licenseId: string
  reservations: number[]
  order: number
}

export interface ReservationModel {
  id: number
  license_code: string
  shouldShowSpotName?: boolean
  old_id: number
  master_id?: unknown
  cart_id?: unknown
  spot_type: string
  spot_name: string
  status: number
  type: number
  seasonal: boolean
  half_day: number
  deleted: boolean
  is_from_sponsorship: boolean
  contact_id: number
  first_name: string
  last_name: string
  email: string
  phone_area_code: string
  phone_number: string
  notes: string
  start_date: number
  end_date: number
  maxi_beds: number
  beds: number
  deck_chairs: number
  chairs: number
  online: boolean
  hotel: string
  voucher_id: number
  groupon: number
  travel_group?: unknown
  vehicle_plate?: unknown
  channel: string
  invoice_number?: unknown
  invoice_type: number
  invoice_date?: unknown
  invoice_first_name: string
  invoice_last_name: string
  invoice_company: string
  invoice_tax_code: string
  invoice_vat_code: string
  invoice_lottery_code?: unknown
  invoice_place_id?: unknown
  invoice_address1: string
  invoice_address2: string
  invoice_city: string
  invoice_zip: string
  invoice_state: string
  invoice_country: string
  invoice_pec: string
  invoice_sdi: string
  paid: boolean
  list_total: number
  list_beach: number
  list_services: number
  list_expenses: number
  list_additions: number
  forced_total: number
  forced_beach: number
  forced_services?: unknown
  forced_expenses?: unknown
  forced_additions?: unknown
  paid_total: number
  paid_beach?: unknown
  paid_services?: unknown
  paid_expenses?: unknown
  paid_additions?: unknown
  discount?: unknown
  online_payment_fees: number
  portal_payment_fees: number
  gross_booking_value?: unknown
  contact_code?: unknown
  locked: boolean
  created_at: number
  updated_at: number
  hotel_room?: unknown
  revenue_manager_perc?: unknown
  operation_id?: unknown
  cart_language?: unknown
  totalPrice: number
  token: string
  qrCode: string
  sector: ReservationModelSector
  services: ReservationModelService[]
  voucher?: {
    onlinePayment: boolean
  }
}

export interface CreateCartPayload {
  channel: string
  reservations: Record<string, QuoteReservation>
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  invoiceType: string
  invoiceFirstName: string
  invoiceLastName: string
  invoiceCompany: string
  invoiceVatCode: string
  invoiceLotteryCode: string | null
  invoiceTaxCode: string
  invoicePec: string
  invoiceSdi: string
  invoiceAddress1: string
  invoiceAddress2: string
  invoiceCity: string
  invoiceZip: string
  invoiceState: string
  invoiceCountry: string
  discountCode: string
  travelGroup: string | null
  petName: string
  petGender: string
  petBreed: string
  petChipCode: string
  weatherInsurance: boolean
  sid: string
  marketing: {
    general: boolean
    thirdParty: boolean
  }
}
