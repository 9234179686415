import i18next from 'i18next'

import { AppLanguage, languages } from '@/models/app'

const validateAppLanguageFromString = (str: string): str is AppLanguage =>
  languages.includes(str)

const getAppLanguageFromString = (str: string): AppLanguage =>
  validateAppLanguageFromString(str) ? str : 'it'

export const getAppLanguage = (str = i18next.language): AppLanguage =>
  getAppLanguageFromString(str)
