import * as rd from '@devexperts/remote-data-ts'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { useAppPath } from '@/hooks/useAppPath.ts'
import { LicenseBookingMode, licenseSlice } from '@/store/licenseSlice'
import { getSpotsPageByBookingMode } from '@/utils/getSpotsPageByBookingMode'
import {
  CHOOSE_PRODUCT_PATH,
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
  PAY_PATH,
  TICKETS_PATH,
} from '../../../app/router/paths'
import { useAppSelector } from '../../../hooks/store'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../../../store/bookingAvailabilitySlice'
import { cartSlice } from '../../../store/cartSlice'
import { selectIsExtraStepVisible } from '../../../store/extraSlice.ts'

import { Step } from './Step'

function getFirstStepLabel(
  bookingAvailability: BookingAvailability | null,
  bookingMode: LicenseBookingMode,
  currentPath: string
) {
  if (
    (bookingAvailability &&
      bookingAvailability === BookingAvailability.TICKETS) ||
    currentPath === TICKETS_PATH
  ) {
    return 'header.progressBar.step.tickets'
  }

  if (bookingMode === LicenseBookingMode.MAP) {
    return 'header.progressBar.step.map'
  }

  return 'header.progressBar.step.sectors'
}

export const ProgressBar = () => {
  const { t } = useTranslation()
  const license = useAppSelector(licenseSlice.selectors.license)
  const ticketsPath = useAppPath(TICKETS_PATH)

  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )

  const { currentPath } = useCurrentPath()

  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )

  const isExtraStepVisible = useAppSelector(selectIsExtraStepVisible)

  const firstStep = useMemo(() => {
    if (!license) {
      return null
    }
    if (selectedBookingType === BookingAvailability.TICKETS) {
      return {
        href: ticketsPath,
        path: TICKETS_PATH,
      }
    }

    if (
      !selectedBookingType ||
      selectedBookingType === BookingAvailability.SPOTS
    ) {
      const spotsPage = getSpotsPageByBookingMode(license.bookingMode)
      return {
        href: generatePath(spotsPage, { license: license.license }),
        path: spotsPage,
      }
    }

    return null // TODO: add logic for future step (such as details or pay)
  }, [
    currentPath,
    generatePath,
    getSpotsPageByBookingMode,
    license,
    selectedBookingType,
  ])

  const isStepHrefUnset = !firstStep
  const isLicenseUnset = !license
  const isFailedBookingAvailabilityRequest = !rd.isSuccess(bookingAvailability)
  const isInsertPeriodPath = currentPath === INSERT_PERIOD_PATH
  const isPayPath = currentPath === PAY_PATH
  const isChooseProductPath = currentPath === CHOOSE_PRODUCT_PATH
  const isBookingAvailabilityValueSpotsAndTickets =
    rd.isSuccess(bookingAvailability) &&
    bookingAvailability.value.type === BookingAvailability.SPOTS_AND_TICKETS

  const { path } = firstStep!

  const activeStepIndex = {
    [INSERT_DATA_PATH]: 2,
    [INSERT_INSURANCE_PATH]: 1,
    [PAY_PATH]: 3,
    [path]: 0,
  }[currentPath]

  if (
    isStepHrefUnset ||
    isLicenseUnset ||
    (!isPayPath && isFailedBookingAvailabilityRequest) ||
    isInsertPeriodPath ||
    (isChooseProductPath && isBookingAvailabilityValueSpotsAndTickets)
  ) {
    return null
  }

  return (
    <div className="flex min-w-20 items-center pb-5 md:p-0 md:pt-1">
      <Step
        activeStepIndex={activeStepIndex}
        index={0}
        label={t(
          getFirstStepLabel(
            selectedBookingType ? selectedBookingType : null,
            license.bookingMode,
            currentPath
          )
        )}
      />
      <div className="flex-auto border-t" />

      {isExtraStepVisible && (
        <>
          <Step
            activeStepIndex={activeStepIndex}
            index={1}
            label={t('header.progressBar.step.extra')}
          />
          <div className="flex-auto border-t" />
        </>
      )}

      <Step
        activeStepIndex={activeStepIndex}
        index={2}
        label={t('header.progressBar.step.summary')}
      />
      <div className="flex-auto border-t" />
      <Step
        activeStepIndex={activeStepIndex}
        index={3}
        label={t('header.progressBar.step.payment')}
      />
    </div>
  )
}
