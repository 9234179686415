import {
  DecorationElement,
  MapElement,
  SpotLayout,
} from '@spiaggeit/map-canvas'
import {
  Color,
  DecorationType,
  ElementType,
  SpotType,
  isSpotSubType,
} from '@spiaggeit/spit-core'

import { MapElementWithSectorId, MapResponseElement } from '../models/map'

import { MAP_CELL_SIZE } from './constants'

export enum MapDecorationOrientation {
  NONE = '',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

const LINE_HEIGHT = 2
const OLD_LINES_VERTICAL_ADJUST = 0.5 // half a row

function toDecoration(element: MapResponseElement): DecorationElement {
  if (!element.sid) {
    throw new Error('Cannot convert a decoration without an identifier')
  }

  if (element.t === 'mapLineV' || element.t === 'mapLineH') {
    const isVertical = element.t === 'mapLineV'

    const x = element.pX * MAP_CELL_SIZE

    // adjust horizontal lines y position
    const verticalAdjust = isVertical ? 0 : OLD_LINES_VERTICAL_ADJUST
    const y = (element.pY + verticalAdjust) * MAP_CELL_SIZE
    const width = MAP_CELL_SIZE

    return {
      height: LINE_HEIGHT,
      id: String(element.sid),
      name: element.n,
      rotation: isVertical ? 90 : 0,
      subType: DecorationType.LINE,
      type: ElementType.DECORATION,
      url: element.url,
      width,
      x: isVertical ? x + MAP_CELL_SIZE / 2 : x,
      y,
    }
  }

  const isLine = element.t === DecorationType.LINE

  return {
    height: isLine ? LINE_HEIGHT : element.eh * MAP_CELL_SIZE,
    id: String(element.sid),
    name: element.n,
    rotation: element.er,
    subType: element.et as DecorationType,
    type: ElementType.DECORATION,
    url: element.url,
    width: element.ew * MAP_CELL_SIZE,
    x: element.pX * MAP_CELL_SIZE,
    y: element.pY * MAP_CELL_SIZE,
  }
}

function toSpot(element: MapResponseElement): MapElementWithSectorId {
  return {
    ...element,
    height: MAP_CELL_SIZE,
    id: String(element.sid),
    layout: SpotLayout.BOOKING_NAME,
    name: element.t === SpotType.PLAYFIELD ? `${element.n} ` : element.n,
    sectorId: element.s,
    staging: null,
    style: {
      iconColor: element.a ? Color.GREEN : Color.GREY,
    },
    subType: element.t as SpotType,
    type: ElementType.SPOT as ElementType.SPOT,
    width: MAP_CELL_SIZE,
    x: element.pX * MAP_CELL_SIZE,
    y: element.pY * MAP_CELL_SIZE,
  }
}

export const getFormattedMapElement = (
  element: MapResponseElement
): MapElement => {
  if (isSpotSubType(element.t)) {
    return toSpot(element)
  } else {
    return toDecoration(element)
  }
}
