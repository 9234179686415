import { cn } from '@spiaggeit/spit-ui'
import { Skeleton } from '@/components/shadcn/skeleton.tsx'

interface SkeletonTextProps {
  text: string
  isPending: boolean
  className?: string
  textClassName?: string
  containerClassName?: string
}

export const SkeletonText = (props: SkeletonTextProps) => {
  return (
    <span className={cn('relative', props.containerClassName)}>
      <Skeleton
        className={cn(
          'absolute right-0 top-0 h-4 w-16 opacity-100 transition-all ease-in-out',
          {
            'pointer-events-none animate-none opacity-0': !props.isPending,
          },
          props.className
        )}
      />
      <span
        className={cn(
          'opacity-100 transition-opacity ease-in-out',
          {
            'pointer-events-none opacity-0': props.isPending,
          },
          props.textClassName
        )}
      >
        {props.text}
      </span>
    </span>
  )
}
