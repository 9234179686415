import { t } from 'i18next'

import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { cartSlice } from '../../../store/cartSlice/index'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../../Sheet/Sheet'
import { CartOverview } from '../CartOverview/CartOverview'
import { CartPopupContent } from '../CartPopupContent'

export const CartMobileSheet = () => {
  const dispatch = useAppDispatch()
  const cartPopoverIsOpen = useAppSelector(
    cartSlice.selectors.cartPopoverIsOpen
  )
  return (
    <Sheet
      onOpenChange={(open) =>
        dispatch(cartSlice.actions.setCartPopoverIsOpen(open))
      }
      open={cartPopoverIsOpen}
    >
      <SheetContent
        className="sticky overflow-auto rounded-t-lg pt-4 !shadow-none"
        mobileOnly
        showCloseButton={false}
      >
        <SheetTitle className="sr-only">
          {/* Please note: this count does not reflect a real dynamic value,
      we just needed any number greater than one to obtain the translation
      in plural form */}
          {t('map.elementDetail.spot', { count: 2 })}
        </SheetTitle>
        <SheetHeader />
        <div className="max-h-[60dvh] overflow-auto px-4">
          <CartPopupContent />
        </div>
        <CartOverview />
      </SheetContent>
    </Sheet>
  )
}
