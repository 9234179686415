import { useSelector } from 'react-redux'

import { licenseSlice } from '@/store/licenseSlice'
import { RefundPolicyDialogProps } from '../components/RefundPolicyDialog'

export const useRefundPolicyDialogProps = (): RefundPolicyDialogProps => {
  const license = useSelector(licenseSlice.selectors.license)

  return {
    bookingRefundPolicy: license?.bookingRefundPolicy || 0,
    licenseName: license?.name || '',
  }
}
