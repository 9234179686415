import { useId } from 'react'

import { useCartRecapData } from '@/hooks/useCartRecapData.ts'
import { PaymentMethod } from '@/routes/Pay'
import { RecapCard } from '../RecapCard'

interface ReservationRecapCardProps {
  nextPath?: string
  selectedPaymentMethod?: PaymentMethod
  isFinalPayment?: boolean
  recapData?: ReturnType<typeof useCartRecapData>
  useCartInfoData?: boolean
  isDisabled?: boolean
}

export const ReservationRecapCard = (props: ReservationRecapCardProps) => {
  const {
    costs,
    total,
    location,
    reservations,
    beachName,
    endDate,
    startDate,
    isRefundable,
    region,
  } = useCartRecapData(props.useCartInfoData)

  const titleId = useId()
  const descriptionId = useId()
  const headerProps = { descriptionId, isRefundable, titleId }
  const locationProps = { beachName, endDate, location, region, startDate }
  const reservationProps = { reservations }
  const costsProps = { costs, total }
  const footerProps = { ...props, isDisabled: props.isDisabled, total }

  return (
    <RecapCard aria-describedby={descriptionId} aria-labelledby={titleId}>
      <RecapCard.Header {...headerProps} />
      <RecapCard.Location {...locationProps} />
      <hr />
      <RecapCard.Reservations {...reservationProps} />
      <hr />
      <RecapCard.Costs {...costsProps} />
      <RecapCard.Footer {...footerProps} />
    </RecapCard>
  )
}
