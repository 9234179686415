import { Button } from '@spiaggeit/spit-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ButtonLink } from '@/components/ButtonLink'
import { Surface } from '@/components/Surface'
import { useAppSelector } from '@/hooks/store.ts'
import { useHostProfileUrl } from '@/hooks/useHostProfileUrl.ts'
import { appSlice } from '@/store/appSlice.ts'
import { isLogged } from '@/utils/isLogged.ts'

export const AccessCard = () => {
  const { t } = useTranslation()
  const isUserLogged = isLogged()

  const isAppMode = useAppSelector(appSlice.selectors.isAppMode)

  const { profileUrl } = useHostProfileUrl()

  return (
    <Surface
      as="article"
      className="flex w-full flex-col gap-4 p-4 text-center"
    >
      <Surface.Icon iconName="Cup" />

      <div>
        <h3 className="mb-1 font-bold">{t('thankYou.loginCard.title')}</h3>
        <p>{t('thankYou.loginCard.description')}</p>
      </div>

      {isUserLogged ? (
        <ButtonLink label={t('thankYou.loginCard.access')} to={profileUrl} />
      ) : (
        <>
          {isAppMode ? (
            <Button
              className="w-full"
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  '{"event":"go-to-page","page":"registration"}'
                )
              }}
            >
              {t('thankYou.loginCard.createAccount')}
            </Button>
          ) : (
            <ButtonLink
              className="w-full"
              label={t('thankYou.loginCard.createAccount')}
              to="https://www.spiagge.it/registrazione-utente/"
            />
          )}
        </>
      )}

      {isUserLogged ? null : (
        <p className="text-secondary">
          <Trans i18nKey="thankYou.loginCard.login">
            {isAppMode ? (
              <button
                className="text-link"
                onClick={() => {
                  window.ReactNativeWebView.postMessage(
                    '{"event":"go-to-page","page":"login"}'
                  )
                }}
              />
            ) : (
              <Link className="text-link" to="https://www.spiagge.it/login/" />
            )}
          </Trans>
        </p>
      )}
    </Surface>
  )
}
