import { CommandFilter } from '@/components/Combobox'

import { useLocalizedCountries } from '@/hooks/useLocalizedCountries.ts'

export const useNationFilter: () => CommandFilter = () => {
  const countries = useLocalizedCountries()

  return (value, search) => {
    const trimmedSearch = search.toLowerCase().trim()
    const countryName = countries[value].toLowerCase()
    return Number(countryName.includes(trimmedSearch))
  }
}
