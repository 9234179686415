import { isSuccess } from '@devexperts/remote-data-ts'
import { cn } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'
import { Cart } from '@/assets/icons/Cart.tsx'
import { ExclamationMark } from '@/assets/icons/ExclamationMark.tsx'

import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useNextPathAfterChoosingSpot } from '@/hooks/useNextPathAfterChoosingSpot.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { selectCartMapSpots } from '@/store/cartSlice/selectors/selectCartMapSpots.ts'
import { extraSlice } from '@/store/extraSlice.ts'
import { priceFormatter } from '@/utils/price.ts'

import { ButtonLink } from '../../ButtonLink/index.tsx'
import { ServiceCostDialog } from '../../ServiceCostDialog/index.tsx'
import { CartPopupContent } from '../CartPopupContent/index.tsx'

interface CartOverviewProps {
  nextPath?: string
}

export const CartOverview = (props: CartOverviewProps) => {
  const { t } = useTranslation()
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const cartReservationsLength = Object.keys(cartReservations).length

  const cartTotal = useAppSelector(cartSlice.selectors.total)
  const mapSpots = useAppSelector(selectCartMapSpots)
  const dispatch = useAppDispatch()
  const cartPopoverIsOpen = useAppSelector(
    cartSlice.selectors.cartPopoverIsOpen
  )

  const nextPath = useNextPathAfterChoosingSpot(props.nextPath)

  const isWeatherInsuranceAddedToCart = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )

  const weatherInsurancePricePreview = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )

  const formattedTotal = priceFormatter.format(
    isSuccess(weatherInsurancePricePreview) && isWeatherInsuranceAddedToCart
      ? weatherInsurancePricePreview.value + cartTotal
      : cartTotal
  )

  return (
    <div
      className={cn(
        'relative ml-auto flex basis-full items-center justify-between gap-4 p-4 lg:basis-auto lg:p-0',
        {
          'border-t-gray-200 max-lg:border-t': cartPopoverIsOpen,
          'max-lg:shadow-inner': !cartPopoverIsOpen,
        }
      )}
    >
      <div className="flex items-center gap-2 ">
        {mapSpots && mapSpots?.length >= 1 ? (
          <div
            className={cn(
              `absolute top-0 hidden w-[400px] translate-x-[-50%] translate-y-[-100%] rounded-lg bg-white px-4 pt-4 drop-shadow-md 
    transition-opacity duration-300 after:absolute after:-bottom-0 after:left-1/2 after:h-0 after:w-0
    after:-translate-x-1/2 after:translate-y-[100%] after:border-l-[16px] after:border-r-[16px]
    after:border-t-[12px] after:border-l-transparent after:border-r-transparent after:border-t-white 
    after:content-[''] lg:block`,
              {
                'opacity-100': cartPopoverIsOpen,
                'pointer-events-none opacity-0': !cartPopoverIsOpen,
              }
            )}
          >
            <CartPopupContent />
          </div>
        ) : null}
        <button
          aria-label={t('common.open')}
          className={cn('relative h-8 w-8', {
            'cursor-default': cartReservationsLength < 1,
          })}
          onClick={() =>
            cartReservationsLength >= 1 &&
            dispatch(cartSlice.actions.setCartPopoverIsOpen(!cartPopoverIsOpen))
          }
        >
          <span className="absolute bottom-4 left-4 flex min-h-5 min-w-5 items-center justify-center rounded-circle bg-yellow-300 text-xs text-white">
            {cartReservationsLength}
          </span>
          <Cart />
        </button>
        <div className="flex flex-col">
          <span className="left-0 top-1 w-20 font-bold text-primary">
            {formattedTotal}
          </span>
          <ServiceCostDialog
            trigger={
              <button aria-label={t('common.open')}>
                <span className="inline-flex items-center gap-1 text-left text-sm text-secondary underline">
                  {t('tickets.summary.includedFees')}
                  <span className="text-base text-primary">
                    <ExclamationMark height={16} width={16} />
                  </span>
                </span>
              </button>
            }
          />
        </div>
      </div>
      {nextPath && (
        <ButtonLink
          isDisabled={cartReservationsLength < 1}
          label={t('common.proceed')}
          to={nextPath}
        />
      )}
    </div>
  )
}
