import { isSuccess } from '@devexperts/remote-data-ts'
import { useTranslation } from 'react-i18next'
import { ExpandedText } from '@/components/ExpandedText'
import { ReservationList } from '@/components/Reservation/List'
import { ReservationLocation } from '@/components/Reservation/Location'
import { StatusAlert } from '@/components/StatusAlert'
import { Surface } from '@/components/Surface'

import { useAppSelector } from '@/hooks/store.ts'
import { useCartRecapData } from '@/hooks/useCartRecapData.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { formatPrice } from '@/utils/price.ts'

export const ThankYouRecap = () => {
  const { t } = useTranslation()

  const {
    startDate,
    endDate,
    location,
    region,
    reservations,
    beachName,
    total,
  } = useCartRecapData(true)

  const isBookingRefundable = useAppSelector(
    licenseSlice.selectors.isBookingRefundable
  )

  const bookingUserDataNotes = useAppSelector(
    licenseSlice.selectors.bookingUserDataNotes
  )

  const cartDetail = useAppSelector(cartSlice.selectors.cartDetail)

  return (
    <Surface as="section" className="flex flex-col gap-4 p-4">
      <div className="flex items-center justify-between gap-4">
        <h2 className="text-lg font-bold">{t('thankYou.recap.title')}</h2>

        <StatusAlert
          className="text-xs"
          {...(isBookingRefundable
            ? {
                label: t('alerts.refundable'),
                variant: 'success',
              }
            : {
                label: t('alerts.notRefundable'),
                variant: 'danger',
              })}
        />
      </div>

      <ReservationLocation
        {...{ beachName, endDate, location, region, startDate }}
      />

      <p className="flex items-center gap-2 font-bold">
        {formatPrice(total)}

        {isSuccess(cartDetail) && cartDetail.value.cart.weatherPolicy && (
          <span className="rounded-lg bg-yellow-400 px-2 py-0.5 text-xs font-medium text-black">
            {t('common.weatherInsurance')}
          </span>
        )}
      </p>

      <hr />
      <ReservationList mode="thank-you-recap" reservations={reservations} />
      <hr />

      {bookingUserDataNotes && (
        <ExpandedText
          label={t('thankYou.beachNotes')}
          text={bookingUserDataNotes}
        />
      )}
    </Surface>
  )
}
