import * as rd from '@devexperts/remote-data-ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const selectCartDetailServices = (state: CartSliceState) => {
  if (!rd.isSuccess(state.cartDetailResponse)) return []

  return (
    state.cartDetailResponse.value.cart.reservationModels.flatMap(
      (reservation) => ({
        services: reservation.services,
        spotId: `_${reservation.spot_name}_${reservation.spot_type}`,
      })
    ) || []
  )
}
