import { Button } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { SkeletonText } from '@/components/SkeletonText/index.tsx'
import { priceFormatter } from '@/utils/price'

interface WeatherCardFooterProps extends ComponentPropsWithoutRef<'footer'> {
  price?: number
  isCardSelected: boolean
  isPending: boolean
  onSelectInsurance(): void
  setIsSelected: Dispatch<SetStateAction<boolean>>
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  priceWrapperProps?: ComponentPropsWithoutRef<'data'>
}

export const WeatherCardFooter = (props: WeatherCardFooterProps) => {
  const {
    price,
    priceWrapperProps,
    isDialogOpen,
    isCardSelected,
    onSelectInsurance,
    setIsDialogOpen,
    setIsSelected,
    isPending,
    ...rest
  } = props

  const { t } = useTranslation()
  const deselectInsurance = () => setIsSelected(false)

  return (
    <footer className="hidden items-center justify-between lg:flex" {...rest}>
      <data
        className="text-lg font-bold"
        value={props.price}
        {...priceWrapperProps}
      >
        <SkeletonText
          className="left-0"
          isPending={props.isPending}
          text={props.price ? priceFormatter.format(props.price) : ''}
        />
      </data>

      {isCardSelected ? (
        <Button color="error" onClick={deselectInsurance} variant="outline">
          {t('extra.removeInsurance')}
        </Button>
      ) : (
        <Button onClick={() => setIsDialogOpen(true)} variant="outline">
          {t('extra.addInsurance')}
        </Button>
      )}
    </footer>
  )
}
