import { Service, ServiceLimitType } from '../models/service'

export const getServiceMinimumQuantity = (
  service: Service,
  selectedSeatsAmount: number,
  dayDifference: number
) => {
  if (service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES) {
    return selectedSeatsAmount
  } else if (service.minimumLimitType === ServiceLimitType.NUMBER_OF_DAYS) {
    return dayDifference
  } else return service.minimumQuantity
}
