import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Combobox } from '@/components/Combobox'
import { Input, InputProps } from '@/components/Input'

import { usePhoneNationFilter } from '@/hooks/usePhoneNationFilter.ts'
import { usePhonePrefixOptions } from '@/hooks/usePhonePrefixOptions.ts'
import { PHONE_NUMBER_PATTERN, PHONE_NUMBER_REGEX } from '@/utils/regex.ts'

interface PhoneInputProps extends InputProps {
  prefixName: string
}

export const PhoneInput = (props: PhoneInputProps) => {
  const { t } = useTranslation()
  const { label, name, prefixName } = props

  const {
    fieldState: { error },
  } = useController({ name })

  const phonePrefixOptions = usePhonePrefixOptions()
  const phoneNationFilter = usePhoneNationFilter()

  return (
    <div
      className="group flex items-start justify-start gap-1"
      data-phone-input-invalid={!!error}
    >
      <Combobox
        filter={phoneNationFilter}
        filterPlaceholder={t('placeholder.search')}
        label={t('common.phonePrefix')}
        name={prefixName}
        options={phonePrefixOptions}
        rules={{
          required: t('validation.requiredField_m', {
            fieldName: t('common.phonePrefix').toLowerCase(),
          }),
        }}
        useValueAsFilter
      />

      <Input
        label={label}
        name={name}
        pattern={PHONE_NUMBER_PATTERN}
        placeholder="3894895678"
        rules={{
          pattern: {
            message: t('validation.invalidPhoneNumber'),
            value: PHONE_NUMBER_REGEX,
          },
          required: t('validation.requiredField_m', {
            fieldName: t('insertDataForm.user.phone').toLowerCase(),
          }),
        }}
        type="tel"
        wrapperClassName="flex-[3] lg:flex-1"
      />
    </div>
  )
}
