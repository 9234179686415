import { createAsyncThunk } from '@reduxjs/toolkit'
import { changeQuantityActioType, QuoteSetupItems } from '@/models/cart.ts'
import { MapSetupItemKeyName } from '@/models/map.ts'
import { cartSlice } from '@/store/cartSlice'
import { RootState } from '@/store/configureStore.ts'

/* 
  increase or decrease of the seats configuration of our selectedElement.
  Changing the configuration of selectedElement will trigger a new /quote action
  and will update the final price preview for the user to see
*/

export const cartChangeQuotableSeatQuantity = createAsyncThunk<
  void,
  { dynamicKey: MapSetupItemKeyName; actionType: changeQuantityActioType },
  { state: RootState }
>('cart/cartChangeQuotableSeatQuantity', async (action, thunkAPI) => {
  const { dispatch, getState } = thunkAPI
  const rootState = getState()
  const selectedElementQuotableProducts =
    cartSlice.selectors.selectedElementQuotableProducts(rootState)

  const quotableItem =
    selectedElementQuotableProducts[
      action.dynamicKey as keyof Omit<QuoteSetupItems, 'services'>
    ]

  const quotableValue =
    action.actionType === changeQuantityActioType.ADD ? 1 : -1

  const updatedProducts = {
    ...selectedElementQuotableProducts,
    [action.dynamicKey]: quotableItem + quotableValue,
  }

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(updatedProducts)
  )
})
