import { createSelector } from '@reduxjs/toolkit'
import { cartSlice } from '@/store/cartSlice'
import { mapSelectors } from '@/store/mapSlice.ts'

export const selectActiveCartReservation = createSelector(
  [mapSelectors.selectedElement, cartSlice.selectors.cartReservations],
  (selectedElement, cartReservations) => {
    return cartReservations.find(
      (item) =>
        item?.spotName === selectedElement?.name &&
        item.spotType === selectedElement.subType
    )
  }
)
