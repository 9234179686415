import * as rd from '@devexperts/remote-data-ts'
import { cn } from '@spiaggeit/spit-ui'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Header } from '@/components/Header'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { useResetInsertInsuranceProducts } from '@/hooks/useResetInsertInsurance'
import { useSetupWhiteLabel } from '@/hooks/useSetupWhiteLabel.ts'
import { resetAllState } from '@/store/commonActions.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { resetSessionStorageData } from '@/store/sessionStorageMiddleware.ts'
import { selectIsWhiteLabelActive, widgetSlice } from '@/store/widgetSlice.ts'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  MAP_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../app/router/paths'
import { DataLayer } from '../components/DataLayer'
import { Footer } from '../components/Footer'
import { Toast } from '../components/Toast'
import { useAppDispatch, useAppSelector } from '../hooks/store'
import { useApp } from '../hooks/useApp'
import { useCurrentPath } from '../hooks/useCurrentPath'
import { AppMode } from '../models/app'
import { appSlice } from '../store/appSlice'
import { cartSlice } from '../store/cartSlice'

export function Root() {
  useApp()

  const license = useAppSelector(licenseSlice.selectors.licenseRemoteData)
  const isKiosk = useAppSelector(appSlice.selectors.isKiosk)
  const isRootPath = useMatch(ROOT_PATH)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { currentPath } = useCurrentPath()
  const isCartPaid = useAppSelector(cartSlice.selectors.isCartPaid)
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const isWhiteLabelActive = useAppSelector(selectIsWhiteLabelActive)

  const pageBackgroundImage = useAppSelector(
    widgetSlice.selectors.backgroundImage
  )

  const textFont = useAppSelector(widgetSlice.selectors.textFont)

  useEffect(() => {
    if (rd.isSuccess(license)) {
      dispatch(licenseSlice.actions.fetchLicenseStrings())
    }
  }, [license])

  useEffect(() => {
    if (!isRootPath) return

    if (rd.isSuccess(license)) {
      navigate(`${insertPeriodPath}?${searchParams.toString()}`)
    } else {
      resetAllState()
      resetSessionStorageData()
    }
  }, [isRootPath, license])

  useEffect(() => {
    if (!isKiosk) return
    document.body.classList.add('kiosk')

    if (!Cookies.get(import.meta.env.VITE_KIOSK_SETTINGS_COOKIE)) {
      navigate(`${SETTINGS_PATH}?ch=${AppMode.KIOSK}`)
    }

    return () => {
      document.body.classList.remove('kiosk')
    }
  }, [isKiosk])

  const { resetInsertInsuranceProducts } = useResetInsertInsuranceProducts()

  useEffect(() => {
    if ([INSERT_PERIOD_PATH, CHOOSE_PRODUCT_PATH].includes(currentPath)) {
      resetInsertInsuranceProducts()
      dispatch(cartSlice.actions.resetCartSlice())
    }
  }, [currentPath, isCartPaid])

  useSetupWhiteLabel()

  const needsGrayBackground = ![
    CHOOSE_PRODUCT_PATH,
    INSERT_PERIOD_PATH,
    MAP_PATH,
  ].includes(currentPath)

  const isBackgroundImageApplied = isWhiteLabelActive && !!pageBackgroundImage

  return (
    <>
      <Helmet>
        <meta
          content={`width=device-width, initial-scale=${isKiosk ? '2.0' : '1.0'}, user-scalable=no`}
          name="viewport"
        />

        {textFont ? (
          <link
            href={`https://fonts.googleapis.com/css2?family=${textFont}:wght@400;500;700&display=swap`}
            rel="stylesheet"
          />
        ) : null}
      </Helmet>

      <div
        className={cn('flex min-h-dvh flex-col overflow-hidden', {
          [`bg-cover bg-center`]: isBackgroundImageApplied,
          'bg-gray-25': !isWhiteLabelActive && needsGrayBackground,
          'page-background-color': isWhiteLabelActive,
        })}
        {...(isBackgroundImageApplied && {
          style: {
            backgroundImage: `linear-gradient(to right, rgba(0,0,0, 0.2), rgba(0,0,0,0.2)), url(${pageBackgroundImage})`,
          } as React.CSSProperties,
        })}
      >
        <Header />
        <Outlet />
        <Footer />
        <Toast />
        <DataLayer />
      </div>
    </>
  )
}
