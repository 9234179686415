import { cn } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

import { Minus } from '../../../assets/icons/Minus'
import { Plus } from '../../../assets/icons/Plus'
import { IconButton } from '../../../components/IconButton'

interface Props {
  availableQuantity: number
  selectedQuantity: number
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const SectorCardQuantityController = (props: Props) => {
  const { t } = useTranslation()

  const handleRemove = () => {
    props.onRemove()
  }

  const handleAdd = () => {
    props.onAdd()
  }

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 first:rounded-t last:rounded-b'
      )}
    >
      <div className="flex-0 relative flex items-center">
        <IconButton
          ariaLabel={t('itemQuantityController.remove')}
          icon={Minus}
          isDisabled={props.selectedQuantity === 0}
          onClick={handleRemove}
        />

        <div className="px-4 text-sm font-bold">{props.selectedQuantity}</div>

        <IconButton
          ariaLabel={t('itemQuantityController.add')}
          icon={Plus}
          isDisabled={props.availableQuantity === 0}
          onClick={handleAdd}
        />
      </div>
    </div>
  )
}
