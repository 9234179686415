import { useEffect, useState } from 'react'
import { ItemQuantityControllerVariant } from '@/models/components'
import { Service } from '@/models/service'

import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { ItemQuantityController } from '../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice/index'

interface Props {
  available: {
    global: number
    group: number
    ticket: number
  }
  initialQuantity: number
  ticket: Service
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const Ticket = (props: Props) => {
  const dispatch = useAppDispatch()
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const dynamicKey = getServiceKeyFromId(props.ticket.serviceId)

  const [quantity, setQuantity] = useState(props.initialQuantity)

  useEffect(() => {
    dispatch(
      cartSlice.actions.setCartReservations([
        {
          ...cartReservations[0],
          services: {
            ...cartReservations[0]?.services,
            [dynamicKey]: quantity,
          },
        },
      ])
    )
  }, [quantity])

  const handleAdd = () => {
    props.onAdd()
    setQuantity(quantity + 1)
  }

  const handleRemove = () => {
    props.onRemove()
    setQuantity(quantity - 1)
  }

  return (
    <ItemQuantityController
      available={props.available}
      hasLimit={props.ticket.hasLimit}
      icon={props.ticket.icon}
      initialQuantity={quantity}
      minimumQuantity={props.ticket.minimumQuantity}
      name={props.ticket.name}
      onAdd={handleAdd}
      onRemove={handleRemove}
      price={props.ticket.price}
      ticket={props.ticket}
      variant={ItemQuantityControllerVariant.CARD}
    />
  )
}
