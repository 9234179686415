import { isSuccess } from '@devexperts/remote-data-ts'
import { Button } from '@spiaggeit/spit-ui'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Icon } from '@/assets/icons'
import { ButtonLink } from '@/components/ButtonLink'
import { KioskTimeoutDialog } from '@/components/Dialog/KioskTimeoutDialog'
import { DownloadConfirmationButton } from '@/components/DownloadConfirmationButton'
import { GridWithRecap } from '@/components/GridWithRecap'

import { LoaderTrigger } from '@/components/LoaderTrigger'
import { INSERT_PERIOD_PATH } from '@/app/router/paths.ts'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { useHostProfileUrl } from '@/hooks/useHostProfileUrl.ts'
import { useWhiteLabelBgTextClassName } from '@/hooks/useWhiteLabelBgTextClassName.ts'
import { ThankYouCards } from '@/routes/ThankYou/Cards'
import { ThankYouRecap } from '@/routes/ThankYou/Recap'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { resetSessionStorageData } from '@/store/sessionStorageMiddleware.ts'
import { ONE_SECOND, REG_ID_QUERY_PARAM_NAME } from '@/utils/constants.ts'

const TEN_SECONDS = ONE_SECOND * 10

export const ThankYou = () => {
  const [searchParams] = useSearchParams()
  const reservationId = searchParams.get(REG_ID_QUERY_PARAM_NAME)

  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const navigate = useNavigate()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const isAppMode = useAppSelector(appSlice.selectors.isAppMode)
  const isWebsiteMode = useAppSelector(appSlice.selectors.isWebsiteMode)
  const isWidgetMode = useAppSelector(appSlice.selectors.isWidgetMode)
  const cartDetail = useAppSelector(cartSlice.selectors.cartDetail)
  const headingClassName = useWhiteLabelBgTextClassName(
    'mb-2 text-xl font-bold'
  )
  const paragraphClassName = useWhiteLabelBgTextClassName('mb-6 lg:mb-8')

  const { hostUrl, profileUrl } = useHostProfileUrl()

  useEffect(() => {
    dispatch(cartSlice.actions.setIsCartPaid(true))
    resetSessionStorageData()

    if (reservationId) {
      if (isAppMode) {
        window.ReactNativeWebView.postMessage('{"event":"reservation-booking"}')
      }

      dispatch(cartSlice.actions.getCartDetail({ reservationId }))
      return
    }

    navigate(insertPeriodPath)
  }, [])

  if (!isSuccess(cartDetail)) {
    return <LoaderTrigger />
  }

  const [{ first_name: userName }] = cartDetail.value.cart.reservationModels

  return (
    <>
      <div className="container mb-9 pt-4 lg:pt-8">
        {userName && (
          <h1 className={headingClassName}>
            {t('thankYou.title', { userName })}
          </h1>
        )}

        <p className={paragraphClassName}>
          <Trans i18nKey="thankYou.description">
            <Link className="text-link" to={profileUrl} />
          </Trans>
        </p>

        <div className="flex flex-col gap-4">
          <GridWithRecap className="lg:mt-0">
            <GridWithRecap.ContentContainer className="gap-4">
              <ThankYouRecap />

              {isWidgetMode ? null : (
                <div className="flex flex-col gap-4 lg:flex-row">
                  <DownloadConfirmationButton variant="outline" />

                  {isWebsiteMode && (
                    <ButtonLink
                      className="w-full"
                      icon="HomeIcon"
                      iconOnLeftSide
                      label={t('thankYou.backToHome.website')}
                      linkClassName="w-full"
                      to={hostUrl}
                    />
                  )}

                  {isAppMode && (
                    <Button
                      className="w-full"
                      onClick={() => {
                        window.ReactNativeWebView.postMessage(
                          '{"event":"go-to-page","page":"reservations"}'
                        )
                      }}
                    >
                      <Icon className="size-5" name="HomeIcon" />
                      {t('thankYou.backToHome.website')}
                    </Button>
                  )}
                </div>
              )}
            </GridWithRecap.ContentContainer>

            <GridWithRecap.RecapContainer>
              <ThankYouCards />
            </GridWithRecap.RecapContainer>
          </GridWithRecap>
        </div>
      </div>
      <KioskTimeoutDialog timeout={TEN_SECONDS} />
    </>
  )
}
