import { getNames } from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'

export const useLocalizedCountries = () => {
  const {
    i18n: { language },
  } = useTranslation()

  return getNames(language, { select: 'official' })
}
