import { useTranslation } from 'react-i18next'
import { FormSection, FormSectionConfig } from '@/components/FormSection'

import { useAppSelector } from '@/hooks/store.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { NUMBER_PATTERN, NUMBER_REGEX } from '@/utils/regex.ts'

export const DogFormSection = () => {
  const { t } = useTranslation()
  const isVisible = useAppSelector(
    licenseSlice.selectors.isBookingPetFormVisible
  )
  const nameLabel = t('common.name')
  const genderLabel = t('common.gender')
  const breedLabel = t('insertDataForm.dog.breed')
  const chipNumberLabel = t('insertDataForm.dog.chipNumber')

  const inputs: FormSectionConfig[] = [
    {
      className: 'col-span-1',
      label: nameLabel,
      name: 'dog.name',
      placeholder: t('placeholder.dogName'),
    },
    {
      className: 'col-span-1',
      label: breedLabel,
      name: 'dog.breed',
      placeholder: t('placeholder.breed'),
    },
    {
      className: 'col-span-2',
      label: genderLabel,
      name: 'dog.gender',
      options: [
        { label: t('common.male'), value: 'male' },
        { label: t('common.female'), value: 'female' },
      ],
      type: 'radio-group',
    },
    {
      className: 'col-span-2 lg:col-span-1',
      helperText: t('insertDataForm.dog.chipHelperText'),
      label: chipNumberLabel,
      name: 'dog.chipNumber',
      pattern: NUMBER_PATTERN,
      placeholder: '123912781294',
      rules: {
        pattern: {
          message: t('validation.numberOnly'),
          value: NUMBER_REGEX,
        },
      },
    },
  ]

  if (!isVisible) {
    return null
  }

  return <FormSection inputs={inputs} title={t('insertDataForm.dog.title')} />
}
