import { QuoteReservation } from '@/models/quote.ts'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice.ts'
import { cartSlice } from '@/store/cartSlice'
import { getServiceIdFromKey } from '@/utils/serviceIdKeyUtils.ts'
import { SpotType } from '../models/cart.ts'
import { servicesSlice } from '../store/servicesSlice.ts'
import { getReservationSpotId } from '../utils/getReservationSpotId.ts'
import { getVisibleServices } from '../utils/getVisibleServices.ts'
import { isSpotWithSeats } from '../utils/isWithVisibleSeats.ts'

import { useAppSelector } from './store.ts'

export const useCartServicesMapper = () => (reservation: QuoteReservation) => {
  const servicesData = useAppSelector(servicesSlice.selectors.data)
  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )

  const includedServices = servicesData?.includedServices ?? []
  const extraServices = servicesData?.extraServices ?? []
  const ticketServices = servicesData?.ticketServices ?? []
  const featuredServices = useAppSelector(
    servicesSlice.selectors.featuredServices
  )
  const visibleServices = getVisibleServices({
    selectedElementHasVisibleSeats: isSpotWithSeats(
      reservation.spotType as SpotType
    ),
    services: [...includedServices, ...extraServices, ...ticketServices],
  })

  return Object.entries(reservation.services).flatMap(([key, value]) => {
    // If the user is buying entrance Ticket we do not need to take "visible services" into consideration
    // (read the description inside "getVisibleServices" for more context)
    const service = [
      ...(selectedBookingType === BookingAvailability.TICKETS
        ? [ticketServices, featuredServices].flat()
        : visibleServices),
    ].find((service) => service?.serviceId === getServiceIdFromKey(key))

    if (!service || value < 1) return []

    return [
      {
        id: service.serviceId,
        isExtra: true,
        isGift: false,
        name: service.name,
        quantity: value,
        spotId: getReservationSpotId(reservation),
      },
    ]
  })
}

export const useCartSelectedServices = (reservations: QuoteReservation[]) => {
  const mapper = useCartServicesMapper()
  return reservations.flatMap(mapper)
}
