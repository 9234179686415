import * as rd from '@devexperts/remote-data-ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const selectPromotionalService = (state: CartSliceState) => {
  if (rd.isSuccess(state.quoteWithDiscount)) {
    const extraServiceWithDiscount =
      state.quoteWithDiscount.value.extraServiceWithDiscount

    if (extraServiceWithDiscount.service) {
      const { service, servicePrice } = extraServiceWithDiscount
      const { id, name } = service
      return { id, name, price: servicePrice }
    }
  }

  return null
}
