import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { ServiceInfoPurchaseType } from '@/models/service.ts'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { servicesSlice } from '@/store/servicesSlice.ts'

export const useLoadServices = (
  purchaseTypeParam?: ServiceInfoPurchaseType
) => {
  const dispatch = useAppDispatch()
  const services = useSelector(servicesSlice.selectors.data)
  const bookingType = useAppSelector(cartSlice.selectors.selectedBookingType)

  const purchaseType =
    purchaseTypeParam || bookingType === BookingAvailability.TICKETS
      ? ServiceInfoPurchaseType.ALONE
      : ServiceInfoPurchaseType.WITH_SPOT

  useEffect(() => {
    if (!services) {
      dispatch(servicesSlice.actions.load({ purchaseType }))
    }
  }, [services])
}
