import { isSuccess } from '@devexperts/remote-data-ts'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { cartSlice } from '@/store/cartSlice'
import { RootState } from '@/store/configureStore.ts'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils.ts'

export const cartResetInsertInsuranceServices = createAsyncThunk<void>(
  'cart/cartResetInsertInsuranceServices',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const featuredExtraServices = isSuccess(state.services.services)
      ? state.services.services.value.extraFeaturedServices
      : []

    const updatedCartReservations = state.cart.cartReservations.map(
      (reservation) => {
        const featuredServicesIds = featuredExtraServices.map((item) =>
          getServiceKeyFromId(item.serviceId)
        )

        const filteredServices = { ...reservation.services }
        featuredServicesIds.forEach((key) => {
          if (key in filteredServices) {
            delete filteredServices[key]
          }
        })

        return {
          ...reservation,
          services: filteredServices,
        }
      }
    )

    thunkAPI.dispatch(
      cartSlice.actions.setCartReservations(updatedCartReservations)
    )
  }
)
