import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useId } from 'react'
import { ControllerRenderProps, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Icon } from '@/assets/icons'

import { useCollapsedSectionTabIndex } from '@/hooks/useCollapsedSectionTabIndex.ts'
import { InputBase } from '@/models/inputBase.ts'

import { InputError, InputErrorConfig } from './InputError'
import 'react-phone-number-input/style.css'

export interface InputProps
  extends InputBase,
    Omit<ComponentPropsWithoutRef<'input'>, 'name' | 'onChange'> {
  label: string
  isLabelHidden?: boolean
  isTextArea?: boolean
  wrapperClassName?: string
  placeholder?: string
  warning?: string | null
  validMessage?: string
  isReadOnly?: boolean
  helperText?: ReactNode
  applyButton?: ReactNode
  errorMessage?: string
  isRequired?: boolean
}

export const Input = forwardRef(function ForwardedInput(
  props: InputProps & Partial<Omit<ControllerRenderProps, 'name'>>,
  ref
) {
  const {
    label,
    name,
    rules,
    placeholder,
    helperText,
    isLabelHidden,
    isReadOnly,
    wrapperClassName,
    validMessage,
    warning,
    isTextArea = false,
    applyButton,
    tabIndex: propsTabIndex,
    className,
    ...rest
  } = props

  const control = useController({
    name,
  })

  const isRequired = !!rules?.required
  const errorMessage = control.fieldState.error?.message || ''

  const { t } = useTranslation()
  const inputId = useId()
  const helperTextId = useId()
  const errorId = useId()
  const validMessageId = useId()

  let ariaDescribedBy = ''

  if (helperText) {
    ariaDescribedBy += helperTextId
  }

  if (errorMessage) {
    ariaDescribedBy += ` ${errorId}`
  }

  if (validMessage) {
    ariaDescribedBy += ` ${validMessageId}`
  }

  const showInputError = !!warning || !!errorMessage

  const errorConfig: InputErrorConfig = {
    id: errorId,
    ...(warning
      ? {
          message: warning,
          type: 'warning',
        }
      : {
          message: errorMessage!,
          type: 'error',
        }),
  }

  const tabIndex = useCollapsedSectionTabIndex(propsTabIndex)

  const { field } = useController({ name, rules })

  const elementRefCallback = <T,>(el: T) => {
    control.field.ref(el)

    if (typeof ref === 'function') {
      ref(el)
    } else if (ref) {
      ref.current = el
    }
  }

  const commonProps = {
    ...(ariaDescribedBy && { 'aria-describedby': ariaDescribedBy }),
    'aria-invalid': !!errorMessage,
    'aria-required': isRequired,
    className: cn(inputBaseClassName, className, {
      'border-green-500 ring-green-200 focus-visible:border-green-500':
        !!validMessage,
    }),
    'data-warning': !!warning,
    id: inputId,
    onChange: field.onChange,
    readOnly: isReadOnly,
    ref: elementRefCallback,
    tabIndex,
    value: field.value,
    ...(placeholder && {
      placeholder: `${t('common.example')} ${placeholder}`,
    }),
  }

  let InputElement = (
    <input {...(rest as ComponentPropsWithoutRef<'input'>)} {...commonProps} />
  )

  if (isTextArea) {
    InputElement = (
      <textarea
        {...(rest as ComponentPropsWithoutRef<'textarea'>)}
        {...commonProps}
      />
    )
  }

  return (
    <div
      className={cn(
        'flex w-full flex-col items-stretch gap-1',
        wrapperClassName
      )}
    >
      <label
        className={cn('font-bold leading-5', { 'sr-only': isLabelHidden })}
        htmlFor={inputId}
      >
        {label}
      </label>

      <div className="flex gap-2">
        {InputElement}
        {applyButton}
      </div>

      {helperText && (
        <small
          className="mt-1 text-sm leading-5 text-secondary"
          id={helperTextId}
        >
          {helperText}
        </small>
      )}

      {validMessage && (
        <small
          className="flex gap-1 text-sm leading-5 text-green-500"
          id={validMessageId}
        >
          <Icon name="Approve" />
          {validMessage}
        </small>
      )}

      <div
        aria-atomic="true"
        aria-live="polite"
        className={cn({ hidden: !showInputError })}
      >
        {showInputError && <InputError errorConfig={errorConfig} />}
      </div>
    </div>
  )
})

export const inputBaseClassName =
  'min-w-0 w-full rounded-sm border border-gray-300 px-3 py-2.5 ring-blue-200 focus:shadow-blue-500 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 aria-[invalid=true]:border-red-600 aria-[invalid=true]:ring-red-200 data-[warning=true]:border-yellow-500 data-[warning=true]:ring-yellow-200'
