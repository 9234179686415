import { useCallback } from 'react'
import { useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ExternalLink } from '@/components/ExternalLink'
import { FormSection, FormSectionConfig } from '@/components/FormSection'

import { useAppSelector } from '@/hooks/store.ts'
import { useLocalizedCountryOptions } from '@/hooks/useLocalizedCountryOptions.ts'
import { useNationFilter } from '@/hooks/useNationFilter.ts'
import { licenseSlice } from '@/store/licenseSlice'
import {
  EMAIL_REGEX,
  ITALIAN_FISCAL_CODE_PATTERN,
  ITALIAN_FISCAL_CODE_REGEX,
  ITALIAN_VAT_NUMBER_PATTERN,
  ITALIAN_VAT_NUMBER_REGEX,
} from '@/utils/regex.ts'

export const InvoiceFormSection = () => {
  const { t } = useTranslation()

  const bookingFiscalMode = useAppSelector(
    licenseSlice.selectors.bookingFiscalMode
  )

  const businessNameLabel = t('insertDataForm.invoice.businessName')
  const vatNumberLabel = t('insertDataForm.invoice.vatNumber')
  const addressLabel = t('insertDataForm.invoice.address')
  const houseNumberLabel = t('insertDataForm.invoice.houseNumber')
  const cityLabel = t('insertDataForm.invoice.city')
  const zipCodeLabel = t('insertDataForm.invoice.postalCode')
  const countryLabel = t('insertDataForm.invoice.country')
  const provinceLabel = t('insertDataForm.invoice.province')
  const sdiLabel = t('insertDataForm.invoice.sdiCode')
  const certifiedEmailLabel = t('insertDataForm.invoice.certifiedEmail')
  const invoiceRequestValue = useWatch({ name: 'invoice.request' })
  const isInvoiceFormExpanded = invoiceRequestValue === 'invoice'
  const countryOptions = useLocalizedCountryOptions()
  const nationCommandFilter = useNationFilter()

  const expandedValidate = useCallback(
    (errorMessage: string) => (value: string) => {
      if (!isInvoiceFormExpanded) return true
      return !!value || errorMessage
    },
    [isInvoiceFormExpanded]
  )

  const isTaxCodeRequired = useSelector(
    licenseSlice.selectors.isTaxCodeRequired
  )

  const fiscalCodeLabel = t('insertDataForm.user.fiscalCode')

  const inputs: FormSectionConfig[] = [
    ...(isTaxCodeRequired
      ? [
          {
            className: 'col-span-2',
            helperText: (
              <Trans i18nKey="insertDataForm.user.fiscalCodeHelpText">
                <ExternalLink
                  aria-label={t('a11y.calculateFiscalCode')}
                  href="https://www.codicefiscaleonline.com/"
                  isInsideParagraph
                  rel="noreferrer noopener nofollow"
                  target="_blank"
                  title={t('a11y.calculateFiscalCode')}
                />
              </Trans>
            ),
            label: fiscalCodeLabel,
            name: 'invoice.fiscalCode',
            pattern: ITALIAN_FISCAL_CODE_PATTERN,
            placeholder: t('placeholder.fiscalCode'),
            rules: {
              maxLength: {
                message: t('validation.invalidFiscalCode'),
                value: 16,
              },
              minLength: {
                message: t('validation.invalidFiscalCode'),
                value: 16,
              },
              pattern: {
                message: t('validation.invalidFiscalCode'),
                value: ITALIAN_FISCAL_CODE_REGEX,
              },
              required: t('validation.requiredField_m', {
                fieldName: fiscalCodeLabel.toLowerCase(),
              }),
            },
          },
        ]
      : []),
    {
      className: 'col-span-2 lg:col-span-1',
      label: businessNameLabel,
      name: 'invoice.businessName',
      placeholder: t('placeholder.businessName'),
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: businessNameLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-2 lg:col-span-1',
      label: vatNumberLabel,
      name: 'invoice.vatNumber',
      pattern: ITALIAN_VAT_NUMBER_PATTERN,
      placeholder: '01234567891',
      rules: {
        maxLength: {
          message: t('validation.invalidVatNumber'),
          value: 11,
        },
        minLength: {
          message: t('validation.invalidVatNumber'),
          value: 11,
        },
        pattern: {
          message: t('validation.invalidVatNumber'),
          value: ITALIAN_VAT_NUMBER_REGEX,
        },
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: vatNumberLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-1',
      label: addressLabel,
      name: 'invoice.address',
      placeholder: t('placeholder.address'),
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_truncated_m', {
            fieldName: addressLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-1',
      label: houseNumberLabel,
      name: 'invoice.houseNumber',
      placeholder: '35',
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_m', {
            fieldName: houseNumberLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-1',
      label: cityLabel,
      name: 'invoice.city',
      placeholder: t('placeholder.city'),
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: cityLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-1',
      label: zipCodeLabel,
      name: 'invoice.zipCode',
      placeholder: '47922',
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_m', {
            fieldName: zipCodeLabel,
          })
        ),
      },
    },
    {
      className: 'col-span-1',
      label: provinceLabel,
      name: 'invoice.province',
      placeholder: t('placeholder.province'),
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: provinceLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      buttonLabel: t('placeholder.selectNation'),
      className: 'col-span-1',
      filter: nationCommandFilter,
      filterPlaceholder: t('placeholder.search'),
      label: countryLabel,
      name: 'invoice.country',
      noOptionFoundLabel: t('placeholder.noNationFound'),
      options: countryOptions,
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: countryLabel.toLowerCase(),
          })
        ),
      },
      selectPlaceholder: t('placeholder.choose'),
      type: 'autocomplete-select',
    },
    {
      className: 'col-span-2 lg:col-span-1',
      label: sdiLabel,
      name: 'invoice.sdiCode',
      placeholder: 'A1B2C3D',
      rules: {
        validate: expandedValidate(
          t('validation.requiredField_m', {
            fieldName: sdiLabel.toLowerCase(),
          })
        ),
      },
    },
    {
      className: 'col-span-2 lg:col-span-1',
      inputType: 'email',
      label: certifiedEmailLabel,
      name: 'invoice.certifiedEmail',
      placeholder: t('placeholder.pec'),
      rules: {
        pattern: {
          message: t('validation.invalidEmail'),
          value: EMAIL_REGEX,
        },
        validate: expandedValidate(
          t('validation.requiredField_f', {
            fieldName: certifiedEmailLabel,
          })
        ),
      },
    },
  ]

  if (bookingFiscalMode === 'receipt-only') {
    return null
  }

  return (
    <FormSection
      {...(bookingFiscalMode === 'invoice-or-receipt' && {
        collapse: {
          isAnimated: true,
          isExpanded: isInvoiceFormExpanded,
          label: t('insertDataForm.invoice.request'),
          name: 'invoice.request',
          options: [
            {
              label: t('common.receipt'),
              value: 'receipt',
            },
            {
              label: t('common.invoice'),
              value: 'invoice',
            },
          ],
          title: t('insertDataForm.invoice.invoiceData'),
        },
      })}
      inputs={inputs}
      title={t('insertDataForm.invoice.title')}
    />
  )
}
