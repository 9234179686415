import { isSuccess } from '@devexperts/remote-data-ts'
import { Middleware } from '@reduxjs/toolkit'

import { AppSliceState } from '@/store/appSlice.ts'
import { BookingAvailabilitySliceState } from '@/store/bookingAvailabilitySlice.ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'
import { PeriodSliceState } from '@/store/insertPeriodSlice.ts'
import { MapSliceState } from '@/store/mapSlice.ts'
import { cartSliceInitialState } from './cartSlice/initialState'
import { RootState } from './configureStore'

export const SESSION_STORAGE_REDUX_KEY = 'reduxState'
const SESSION_START_TIME_KEY = 'timestamp'
const SESSION_LICENSE_NAME = 'license'

const ONE_MINUTE_MS = 60 * 1000
const EXPIRATION_TIME_MS = 15 * ONE_MINUTE_MS

export interface SessionStorageData {
  app: AppSliceState
  bookingAvailability: BookingAvailabilitySliceState
  cart: CartSliceState
  insertPeriod: PeriodSliceState
  map: MapSliceState
}
export const sessionStorageMiddleware: Middleware =
  (storeAPI) => (next) => (action) => {
    const result = next(action)
    const state: RootState = storeAPI.getState()
    const remoteDataLicense = state.license.license

    // The data we want to persist if the user is in one of the steps where they can still select
    //  a spot or ticket.
    // The cart state is set to its initial value, and the map data is not included yet.
    const initialSessionData = {
      app: state.app,
      bookingAvailability: state.bookingAvailability,
      cart: cartSliceInitialState,
      insertPeriod: state.insertPeriod,
    }

    // The data we want to persist if the user is in one of the steps that come after selecting spots or ticket.
    // The cart now includes its current state, and the map data is also included
    // (we need the mapData to persist since we need to reference map elements and sector names in some components
    // or custom hooks, e.g.: useCartRecapData).

    const updatedSessionData = {
      app: state.app,
      bookingAvailability: state.bookingAvailability,
      cart: state.cart,
      insertPeriod: state.insertPeriod,
      map: state.map,
    }

    const sessionDataMap: Record<string, Partial<SessionStorageData>> = {
      chooseProduct: initialSessionData,
      insertData: updatedSessionData,
      insertInsurance: updatedSessionData,
      insertMap: initialSessionData,
      insertPeriod: { app: state.app },
      insertSector: initialSessionData,
      pay: updatedSessionData,
      thankYou: updatedSessionData,
      tickets: initialSessionData,
    }

    let sessionData: Partial<SessionStorageData> = {
      app: state.app,
      bookingAvailability: state.bookingAvailability,
      cart: state.cart,
      insertPeriod: state.insertPeriod,
      map: state.map,
    }

    const matchedPathName = Object.keys(sessionDataMap).find((key) =>
      window.location.pathname.includes(key)
    )
    if (matchedPathName) {
      sessionData = sessionDataMap[matchedPathName]
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_REDUX_KEY)
    }

    sessionStorage.setItem(
      SESSION_STORAGE_REDUX_KEY,
      JSON.stringify(sessionData)
    )
    sessionStorage.setItem(SESSION_START_TIME_KEY, JSON.stringify(Date.now()))

    if (isSuccess(remoteDataLicense)) {
      sessionStorage.setItem(
        SESSION_LICENSE_NAME,
        remoteDataLicense.value.license.license
      )
    }

    return result
  }

export const checkSessionExpiration = () => {
  const storedState = sessionStorage.getItem(SESSION_STORAGE_REDUX_KEY)
  const timestamp = Number(sessionStorage?.getItem(SESSION_START_TIME_KEY))

  if (
    storedState &&
    (!timestamp || Date.now() - timestamp > EXPIRATION_TIME_MS)
  ) {
    resetSessionStorageData()
    window.location.reload()
  }
}

export function getSessionStorageData(): SessionStorageData | null {
  const data = sessionStorage.getItem(SESSION_STORAGE_REDUX_KEY)
  return data ? JSON.parse(data) : null
}

export function getSessionLicense() {
  return sessionStorage.getItem(SESSION_LICENSE_NAME)
}

export function resetSessionStorageData() {
  sessionStorage.clear()
}

checkSessionExpiration()

setInterval(checkSessionExpiration, ONE_MINUTE_MS)
