import * as rd from '@devexperts/remote-data-ts'
import { PayloadAction } from '@reduxjs/toolkit'

type State = Record<string, unknown>

export const getRemoteDataThunkConfig = (key: string) => ({
  fulfilled: (state: State, action: PayloadAction<unknown>) => {
    state[key] = rd.success(action.payload)
  },
  pending: (state: State) => {
    state[key] = rd.pending
  },
  rejected: (state: State, action: PayloadAction<unknown>) => {
    state[key] = rd.failure(action.payload ?? 'Unknown Error')
  },
})
