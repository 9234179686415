import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, useId, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface ExpandedSectionProps extends ComponentPropsWithoutRef<'p'> {
  isVisible: boolean
  onShowSection(): void
  text: string
  sectionAriaLabel: string
  tooltipText?: string
}

export const ExpandedSection = (props: ExpandedSectionProps) => {
  const { t } = useTranslation()
  const {
    text,
    children,
    isVisible,
    sectionAriaLabel,
    onShowSection,
    className,
    tooltipText,
    ...rest
  } = props

  const sectionId = useId()

  const ref = useRef<HTMLDivElement | null>(null)!

  return (
    <div>
      <p
        aria-hidden={isVisible}
        {...rest}
        className={cn(
          'transition-all',
          {
            'pointer-events-none m-0 h-0 opacity-0': isVisible,
          },
          className
        )}
      >
        {text}&nbsp;
        <button
          aria-controls={sectionId}
          aria-expanded={isVisible}
          aria-label={tooltipText}
          className="font-bold text-link"
          onClick={onShowSection}
          tabIndex={isVisible ? -1 : 0}
          title={tooltipText}
          type="button"
        >
          {t('common.clickHere')}
        </button>
      </p>

      <section
        aria-label={sectionAriaLabel}
        className={cn(
          'flex transition-all',
          {
            'pointer-events-auto translate-y-0 opacity-100': isVisible,
            'pointer-events-none h-0 translate-y-4 opacity-0': !isVisible,
          },
          className
        )}
        id={sectionId}
        ref={ref}
      >
        {children}
      </section>
    </div>
  )
}
