interface GetPolicyTextProps {
  regulation?: string
  beachPrivacyPolicy?: string
}

export type PolicyI18nKey =
  | 'summary.smallPrivacy'
  | 'summary.privacyWithRegulation'
  | 'summary.privacyWithBeachPolicy'
  | 'summary.privacy'

export const getPolicyI18nKey = (props: GetPolicyTextProps): PolicyI18nKey => {
  const { regulation, beachPrivacyPolicy } = props

  if (!regulation && !beachPrivacyPolicy) {
    return 'summary.smallPrivacy'
  }

  if (!!regulation && !beachPrivacyPolicy) {
    return 'summary.privacyWithRegulation'
  }

  if (!regulation && !!beachPrivacyPolicy) {
    return 'summary.privacyWithBeachPolicy'
  }

  return 'summary.privacy'
}
