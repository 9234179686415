import { ComponentPropsWithoutRef } from 'react'

import { Icon } from '../../../../assets/icons'
import { WeatherCardFeature } from '../index.tsx'

interface WeatherCardFeatures extends ComponentPropsWithoutRef<'ul'> {
  features: WeatherCardFeature[]
}

export const WeatherCardFeatures = (props: WeatherCardFeatures) => {
  const { features, ...rest } = props

  return (
    <ul className="flex flex-col gap-2" {...rest}>
      {features.map((item, index) => (
        <li className="flex items-center gap-2" key={index}>
          <Icon className="flex-shrink-0 text-yellow-300" name={item.icon} />
          <span className="text-sm">{item.copy}</span>
        </li>
      ))}
    </ul>
  )
}
