import * as rd from '@devexperts/remote-data-ts'
import { SpotType } from '@/models/cart.ts'
import { QuoteReservation } from '@/models/quote.ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const selectCartDetailReservations = (
  state: CartSliceState
): QuoteReservation[] => {
  if (!rd.isSuccess(state.cartDetailResponse)) return []

  return state.cartDetailResponse.value.cart.reservationModels.map((model) => ({
    beds: model.beds,
    chairs: model.chairs,
    deckChairs: model.deck_chairs,
    endDate: model.end_date,
    halfDay: model.half_day,
    maxiBeds: model.maxi_beds,
    notes: model.notes,
    seasonal: model.seasonal,
    sector: model.sector?.id,
    services: model.services.reduce(
      (acc, cur) => ({
        ...acc,
        [`_${model.spot_type}_${model.spot_name}`]: {
          id: cur.service_id,
          name: cur.service_name,
          price: cur.price,
        },
      }),
      {}
    ),
    shouldShowSpotName: model.shouldShowSpotName,
    spotName: model.spot_name,
    spotType: model.spot_type as SpotType,
    startDate: model.start_date,
  }))
}
