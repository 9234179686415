export enum ServiceLimitType {
  FLAT = 'flat',
  NUMBER_OF_PIECES = 'number-of-pieces',
  NUMBER_OF_DAYS = 'number-of-days',
}

export enum ServiceInfoPurchaseType {
  WITH_SPOT = 'withSpot',
  ALONE = 'alone',
}

export interface ServicesInfoResponse {
  limits: {
    availableQuantity: number
    reservedQuantity: number
    hasLimit: boolean
    serviceGroupLimits: {
      serviceGroupId: number
      hasLimit: boolean
      name: string
      availableQuantity: number
      maximumQuantity: number
      reservedQuantity: number
      serviceLimits: Service[]
    }[]
    extraServiceLimits: FeaturedService[]
  }
}

export type FeaturedService = Service & {
  featured: boolean
  image: string
  description: string
}

export interface Service {
  availableQuantity: number
  description: string
  featured?: boolean
  hasLimit: boolean
  icon: string
  image: string
  maximumLimitType?: ServiceLimitType
  maximumQuantity: number
  minimumLimitType?: ServiceLimitType
  minimumQuantity: number
  name: string
  price: number
  sectors: number[]
  groupLimit?: number
  globalLimit?: number
  groupHasLimit?: boolean
  serviceGroupId?: number
  serviceId: number
}

export interface ServiceGroup {
  serviceGroupId: number
  hasLimit: boolean
  name: string
  availableQuantity: number
  reservedQuantity?: number
  services: Service[]
}

export type GroupIds = number[] | null
