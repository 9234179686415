export const SunUmbrella = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M7 2.33977C7.47829 2.06363 8.08988 2.2275 8.36603 2.70579L8.39388 2.75403C10.2552 1.93996 12.348 1.7758 14.3294 2.30669C16.635 2.92448 18.6008 4.43287 19.7942 6.50002C20.0704 6.97832 19.9065 7.58991 19.4282 7.86605L13.366 11.366L17.4879 18.5054C18.701 18.1197 20.0941 18.446 21.0026 19.4843L21.7526 20.3415C22.1163 20.7571 22.0742 21.3889 21.6586 21.7525C21.2429 22.1162 20.6112 22.0741 20.2475 21.6585L19.4975 20.8013C18.9694 20.1979 18.0307 20.1979 17.5026 20.8013C16.1778 22.3154 13.8223 22.3154 12.4975 20.8013C11.9694 20.1979 11.0307 20.1979 10.5026 20.8013L10.4358 20.8777C9.14631 22.3514 6.85381 22.3514 5.56435 20.8777L5.39374 20.6828C4.96414 20.1918 4.1915 20.2209 3.80006 20.7428C3.46869 21.1846 2.84189 21.2742 2.40006 20.9428C1.95823 20.6114 1.86869 19.9846 2.20006 19.5428C3.35418 18.004 5.63224 17.9181 6.8989 19.3658L7.06951 19.5607C7.56214 20.1237 8.43798 20.1237 8.93061 19.5607L8.99748 19.4843C10.3223 17.9702 12.6778 17.9702 14.0026 19.4843C14.4799 20.0298 15.2927 20.0822 15.8345 19.6416L11.634 12.366L5.5718 15.866C5.34211 15.9987 5.06916 16.0346 4.81298 15.9659C4.5568 15.8973 4.33838 15.7297 4.20577 15.5C3.0123 13.4329 2.68888 10.9763 3.30667 8.67065C3.83756 6.68933 5.02614 4.95898 6.66182 3.75403L6.63398 3.70579C6.35783 3.2275 6.52171 2.61591 7 2.33977ZM8.5 4.93785C10.1078 4.00959 12.0185 3.75804 13.8117 4.23854C15.2798 4.63192 16.5708 5.48958 17.5 6.66988L5.49997 13.5981C4.94241 12.2032 4.84514 10.6564 5.23852 9.18829C5.71902 7.39503 6.89221 5.8661 8.5 4.93785Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
