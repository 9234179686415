import { isFailure, isSuccess } from '@devexperts/remote-data-ts'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { cn } from '@spiaggeit/spit-ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDebounceCallback } from 'usehooks-ts'

import { LoaderTrigger } from '@/components/LoaderTrigger'
import { useCheckBookingSettingsChange } from '@/hooks/useCheckBookingSettingsChange'
import { useDayDifference } from '@/hooks/useDayDifference'
import { useFetchPricePreview } from '@/hooks/useFetchPricePreview.ts'
import { useNextPathAfterChoosingSpot } from '@/hooks/useNextPathAfterChoosingSpot.ts'
import { ServiceInfoPurchaseType } from '@/models/service'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice'
import { selectCartPayload } from '@/store/cartSlice/selectors/selectCartPayload.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { fromISOtoTimestamp } from '@/utils/dateUtils'
import { getServiceUpdatedQuantity } from '@/utils/getServiceUpdatedQuantity'
import { getHalfDayValueFromBookingPeriod } from '@/utils/halfDay'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { ticketsInCartTotalQuantity } from '@/utils/services'

import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { FeesDialog } from '../../components/FeesDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import {
  filteredGroupsSelector,
  servicesSlice,
} from '../../store/servicesSlice'
import { toastSlice } from '../../store/toastSlice'

import { Group } from './Group'
import { MobileDrawer } from './MobileDrawer'
import { Sidebar } from './Sidebar'
import { SubmitButton } from './SubmitButton'

export const TicketsRoute = () => {
  const { t } = useTranslation()
  const [isFeesDialogOpen, setIsFeesDialogOpen] = useState(false)
  const dispatch = useAppDispatch()
  const services = useAppSelector(servicesSlice.selectors.services)
  const quoteRemoteData = useAppSelector(cartSlice.selectors.quote)
  const visibleGroups = useAppSelector(filteredGroupsSelector)
  const totalQuantity = useAppSelector(ticketsInCartTotalQuantity)
  const cartPayload = useAppSelector(selectCartPayload)
  const inputRef = useRef<HTMLInputElement>(null)
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const license = useAppSelector(licenseSlice.selectors.license)
  const [isLoading, setIsLoading] = useState(false)
  const nextPath = useNextPathAfterChoosingSpot()
  const navigate = useNavigate()
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const dayDifference = useDayDifference()
  const quoteCallback = useCallback(() => {
    dispatch(cartSlice.actions.quote({ isQuoteForTickets: true }))
  }, [dispatch])

  const quote = useDebounceCallback(quoteCallback, 500)

  useEffect(() => {
    dispatch(cartSlice.actions.setBookingType(BookingAvailability.TICKETS))
  }, [])
  useEffect(() => {
    dispatch(
      servicesSlice.actions.load({
        purchaseType: ServiceInfoPurchaseType.ALONE,
      })
    )

    dispatch(servicesSlice.actions.fetchFeaturedServices())
  }, [dispatch, period])

  useEffect(() => {
    if (isSuccess(services)) {
      const sanitizedServices = Object.assign(
        {},
        ...services.value.ticketServices.map((service) => {
          const dynamicKey = getServiceKeyFromId(service.serviceId)
          const serviceInCartQuantity =
            cartReservations[0]?.services[dynamicKey]
          const initialQuantity = getServiceUpdatedQuantity({
            dayDifference,
            selectedSeats: 0,
            service,
            serviceInCartQuantity,
          })
          return {
            [dynamicKey]: initialQuantity,
          }
        })
      )

      dispatch(
        cartSlice.actions.setCartReservations([
          {
            beds: 0,
            chairs: 0,
            deckChairs: 0,
            endDate: Number(fromISOtoTimestamp(period?.end)),
            halfDay: getHalfDayValueFromBookingPeriod(bookingPeriod),
            maxiBeds: 0,
            notes: '',
            seasonal: bookingPeriod === BookingPeriod.ALL_SEASON,
            sector: 0,
            services: sanitizedServices,
            spotName: null,
            spotType: null,
            startDate: Number(fromISOtoTimestamp(period?.start)),
          },
        ])
      )
      quote()
    }
  }, [services])

  useEffect(() => {
    quote()
  }, [cartReservations])

  useEffect(() => {
    if (inputRef.current && cartPayload) {
      inputRef.current.value = JSON.stringify(cartPayload)
    }
  }, [cartPayload])

  const { checkBookingAvailabilityChange } = useCheckBookingSettingsChange()

  useEffect(() => {
    checkBookingAvailabilityChange()
  }, [license])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!isSuccess(services)) return
    setIsLoading(true)
    const target = e.target
    e.preventDefault()
    if (!(target instanceof HTMLFormElement)) {
      dispatch(toastSlice.actions.show({ message: t('tickets.error.generic') }))
      setIsLoading(false)
    } else if (
      services.value.hasLimit &&
      services.value.availableQuantity < totalQuantity
    ) {
      dispatch(toastSlice.actions.show({ message: t('tickets.error.soldOut') }))
      setIsLoading(false)
    } else {
      dispatch(cartSlice.actions.quote({ isQuoteForTickets: true }))
        .then(() => {
          if (totalQuantity === 0) {
            dispatch(
              toastSlice.actions.show({ message: t('tickets.error.min') })
            )
          } else if (isFailure(quoteRemoteData)) {
            dispatch(
              toastSlice.actions.show({ message: t('tickets.error.generic') })
            )
          } else {
            navigate(nextPath)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const isLoadingPricePreview = useFetchPricePreview()

  if (!isSuccess(services) || !license || isLoadingPricePreview) {
    return <LoaderTrigger />
  }

  const hasReachedLimit = services.value.hasLimit
    ? totalQuantity === services.value.availableQuantity
    : false

  return (
    <>
      <main className="min-h-full flex-grow pb-28 pt-8 md:pb-16">
        <form className="container max-w-screen-xl" onSubmit={onSubmit}>
          <input
            id="ybBookingServicesFormCart"
            name="cart"
            ref={inputRef}
            type="hidden"
          />

          <div className="md:grid md:grid-cols-12 md:gap-8">
            <div className="md:col-span-7 lg:col-span-8">
              <div
                className={cn(
                  'mb-8 rounded-sm border-l-4 px-3 py-2 text-sm md:text-base',
                  {
                    'border-l-red-300 bg-red-50': hasReachedLimit,
                    'border-l-yellow-300 bg-yellow-50': !hasReachedLimit,
                  }
                )}
              >
                {hasReachedLimit ? (
                  <p>
                    <Trans i18nKey="tickets.banner.soldOut" />
                  </p>
                ) : null}

                <p>
                  <Trans i18nKey="tickets.banner.generic" />
                </p>
              </div>

              {visibleGroups.map((group) => (
                <Group
                  availableQuantity={
                    group.hasLimit ? group.availableQuantity : Infinity
                  }
                  hasLimit={group.hasLimit}
                  key={group.serviceGroupId}
                  name={group.name}
                  onAdd={() => null}
                  onRemove={() => null}
                  serviceGroupId={group.serviceGroupId}
                  tickets={group.services}
                />
              ))}

              <SubmitButton className="hidden md:block" isLoading={isLoading} />
            </div>

            <div className="hidden md:col-span-5 md:block lg:col-span-4">
              <Sidebar openFeesDialog={() => setIsFeesDialogOpen(true)} />
            </div>
          </div>

          <MobileDrawer openFeesDialog={() => setIsFeesDialogOpen(true)} />
        </form>

        <FeesDialog isOpen={isFeesDialogOpen} open={setIsFeesDialogOpen} />
      </main>

      <KioskTimeoutDialog timeout={180000} />
    </>
  )
}
