import * as rd from '@devexperts/remote-data-ts'
import { BookingPeriod, DatePicker } from '@spiaggeit/spit-datepicker'
import { cn } from '@spiaggeit/spit-ui'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { LoaderTrigger } from '@/components/LoaderTrigger'
import { GroupIds } from '@/models/service'
import { LicenseBookingCartMode, licenseSlice } from '@/store/licenseSlice'
import { servicesSlice } from '@/store/servicesSlice'
import { resetSessionStorageData } from '@/store/sessionStorageMiddleware'
import { selectIsWhiteLabelActive } from '@/store/widgetSlice.ts'
import { getSpotsPageByBookingMode } from '@/utils/getSpotsPageByBookingMode'
import { CHOOSE_PRODUCT_PATH, TICKETS_PATH } from '../../app/router/paths'
import { KioskTimeoutDialog } from '../../components/Dialog/KioskTimeoutDialog'
import { SectorRulesDialog } from '../../components/SectorRulesDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useLockDates } from '../../hooks/useLockDates'
import { usePeriod } from '../../hooks/usePeriod'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../../store/bookingAvailabilitySlice'
import {
  insertPeriodSlice,
  periodAsUnixTimeSelector,
  PeriodSliceState,
} from '../../store/insertPeriodSlice'
import { sectorRulesSlice } from '../../store/sectorRulesSlice'
import { getBookingFlowFromSearchParams } from '../../utils/bookingFlow'
import { getShouldValidateForm } from '../../utils/sectorRulesUtils'

import { InsertPeriodAlerts } from './Alerts'

function getNextPath({
  bookingAvailability,
  bookingCartMode,
  bookingPeriod,
  groupIds,
  license,
}: {
  bookingAvailability: BookingAvailability
  bookingCartMode: LicenseBookingCartMode
  bookingPeriod: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
  groupIds: GroupIds
  license: ReturnType<typeof licenseSlice.selectors.license>
}) {
  if (!license) return null

  const chooseProductPath = `${generatePath(CHOOSE_PRODUCT_PATH, { license: license.license })}`
  const ticketPath = `${generatePath(TICKETS_PATH, { license: license.license })}`
  const forceTicketStep = groupIds !== null && groupIds.length > 0
  const spotsPage = getSpotsPageByBookingMode(license.bookingMode)
  const spotsPath = `${generatePath(spotsPage, { license: license.license })}`

  if (bookingCartMode === LicenseBookingCartMode.V_2022) {
    return spotsPath
  }

  if (bookingPeriod === BookingPeriod.ALL_SEASON) {
    return forceTicketStep ? ticketPath : chooseProductPath
  }

  switch (bookingAvailability) {
    case BookingAvailability.SPOTS:
      return spotsPath
    case BookingAvailability.TICKETS:
      return ticketPath
    case BookingAvailability.SPOTS_AND_TICKETS:
      return forceTicketStep ? ticketPath : chooseProductPath
    default:
      return null
  }
}

export function InsertPeriod() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const lockDates = useLockDates()
  const { t, i18n } = useTranslation()

  const period = useSelector(insertPeriodSlice.selectors.period)
  usePeriod(lockDates, period)
  const periodAsUnixTime = useSelector(periodAsUnixTimeSelector)
  const license = useSelector(licenseSlice.selectors.license)
  const appMode = useSelector(appSlice.selectors.mode)
  const country = useSelector(appSlice.selectors.country)
  const sectorRules = useSelector(sectorRulesSlice.selectors.rules)
  const groupIds = useSelector(servicesSlice.selectors.groupIds)
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const areTicketsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areTicketsAvailable
  )
  const areSpotsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areSpotsAvailable
  )
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const bookingAvailabilityWithDefault = rd.getOrElse(() => ({
    type: BookingAvailability.NONE,
  }))(bookingAvailability)
  const seasonalStart = useAppSelector(licenseSlice.selectors.seasonalStart)
  const seasonalEnd = useAppSelector(licenseSlice.selectors.seasonalEnd)

  // local state
  const [showSectorRulesDialog, setShowSectorRulesDialog] =
    React.useState(false)
  const [pageState, setPageState] = useState<
    'loading' | 'loaded' | 'submitted'
  >('loaded')

  const [searchParams] = useSearchParams()
  const periodAsDate = useMemo(
    () =>
      period
        ? {
            end: DateTime.fromISO(period.end).setZone('utc')
              .toJSDate(),
            start: DateTime.fromISO(period.start).setZone('utc')
              .toJSDate(),
          }
        : null,
    [period]
  )

  useEffect(() => {
    resetSessionStorageData()
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get('startdate') && urlParams.get('enddate')) {
      setPageState('submitted')
    }
    if (urlParams.get('from') && urlParams.get('to')) {
      setPageState('submitted')
    }
  }, [])

  useEffect(() => {
    if (!license) {
      return
    }

    dispatch(sectorRulesSlice.actions.load(license.license))
  }, [license, dispatch])

  useEffect(() => {
    if (
      !license ||
      !periodAsUnixTime ||
      !bookingPeriod ||
      bookingAvailabilityWithDefault.type === BookingAvailability.NONE
    ) {
      return
    }

    if (period?.start && period.end && pageState === 'submitted') {
      const isPeriodSoldOut =
        (bookingAvailabilityWithDefault.type === BookingAvailability.SPOTS &&
          !areSpotsAvailable) ||
        (bookingAvailabilityWithDefault.type === BookingAvailability.TICKETS &&
          !areTicketsAvailable) ||
        (bookingAvailabilityWithDefault.type ===
          BookingAvailability.SPOTS_AND_TICKETS &&
          !areTicketsAvailable &&
          !areSpotsAvailable)

      if (!isPeriodSoldOut) {
        const nextPath = getNextPath({
          bookingAvailability: bookingAvailabilityWithDefault.type,
          bookingCartMode: license.bookingCartMode,
          bookingPeriod,
          groupIds,
          license,
        })

        if (!nextPath) return

        navigate(nextPath)
      }
    }
  }, [
    license,
    navigate,
    period,
    periodAsUnixTime,
    searchParams,
    bookingAvailabilityWithDefault.type,
    pageState,
    bookingPeriod,
  ])

  const isWhiteLabelActive = useAppSelector(selectIsWhiteLabelActive)

  if (!license || lockDates === null || sectorRules === null) {
    return <LoaderTrigger />
  }

  const bookingStartPeriodSplit = license.bookingStartPeriod.split('-')
  const bookingStartDay = parseInt(bookingStartPeriodSplit[0])
  const bookingStartMonth = parseInt(bookingStartPeriodSplit[1])

  const bookingEndPeriodSplit = license.bookingEndPeriod.split('-')
  const bookingEndDay = parseInt(bookingEndPeriodSplit[0])
  const bookingEndMonth = parseInt(bookingEndPeriodSplit[1])

  function onChangeBookingPeriod(value: BookingPeriod): void {
    if (!license || !periodAsUnixTime || value === bookingPeriod) {
      return
    }

    dispatch(insertPeriodSlice.actions.setBookingPeriod(value))

    if (
      value === BookingPeriod.ALL_SEASON &&
      seasonalStart &&
      seasonalEnd &&
      periodAsDate?.start
    ) {
      const seasonalStartDateTime = DateTime.fromFormat(
        seasonalStart,
        'dd-LL-yyyy'
      )
      const fromDateTime = DateTime.fromJSDate(periodAsDate.start)

      const formattedStart =
        seasonalStartDateTime > fromDateTime
          ? seasonalStartDateTime.toFormat('yyyy-LL-dd')
          : fromDateTime.toFormat('yyyy-LL-dd')

      const formattedEnd = DateTime.fromFormat(
        seasonalEnd,
        'dd-LL-yyyy'
      ).toFormat('yyyy-LL-dd')

      handleSubmit(
        {
          end: formattedEnd,
          start: formattedStart,
        },
        value
      )
    }
  }

  async function handleSubmit(
    period: PeriodSliceState['period'],
    bookingPeriod: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
  ) {
    if (!license) {
      return
    }

    setPageState('loading')

    if (
      !bookingPeriod ||
      (period?.start !== period?.end &&
        bookingPeriod !== BookingPeriod.ALL_SEASON)
    ) {
      dispatch(
        insertPeriodSlice.actions.setBookingPeriod(BookingPeriod.ALL_DAY)
      )
    }

    dispatch(insertPeriodSlice.actions.setPeriod(period))
    await dispatch(
      bookingAvailabilitySlice.actions.load({
        bookingFlow: getBookingFlowFromSearchParams(searchParams),
      })
    )

    try {
      const shouldValidate = await getShouldValidateForm({
        country,
        license,
        period,
        sectorRules,
      })
      if (typeof shouldValidate !== 'boolean') {
        // something bad happended
        // TODO handle error;
        return
      }

      if (shouldValidate) {
        setShowSectorRulesDialog(true)
      }
    } finally {
      setPageState('submitted')
    }
  }

  return (
    <>
      <main
        className={cn(
          'flex flex-grow flex-col lg:items-center lg:justify-center',
          { 'px-4 pb-12 pt-8': appMode === AppMode.WIDGET }
        )}
      >
        <div
          className={cn('w-full lg:max-w-[50rem]', {
            'mx-auto max-w-xl': appMode === AppMode.WIDGET,
            'px-4 pt-4 lg:mx-auto lg:p-0': appMode !== AppMode.WIDGET,
          })}
        >
          <InsertPeriodAlerts isSubmitted={pageState === 'submitted'} />
        </div>

        {isWhiteLabelActive ? (
          <div className="mx-auto w-full max-w-xl lg:max-w-[50rem]">
            <h2 className="whitelabel__background-text mb-6 self-start text-2xl font-bold">
              {t('insertPeriod.bookNow')}
            </h2>
          </div>
        ) : null}

        <div
          className={cn(
            'w-full bg-white lg:max-w-[50rem]',
            {
              'flex grow flex-col lg:grow-0 lg:overflow-hidden lg:rounded lg:shadow-[0_2px_10px_0_rgba(0,0,0,.1)]':
                appMode !== AppMode.WIDGET,
            },
            {
              'mx-auto max-w-xl overflow-hidden rounded shadow-[0_2px_10px_0_rgba(0,0,0,.1)]':
                appMode === AppMode.WIDGET,
            }
          )}
        >
          <DatePicker
            isLoading={pageState === 'loading'}
            isWidget={appMode === AppMode.WIDGET}
            labels={{
              bookingPeriod: {
                afternoon: t('datepicker.bookingPeriod.afternoon'),
                all_day: t('datepicker.bookingPeriod.allDay'),
                all_season: t('datepicker.bookingPeriod.allSeason'),
                morning: t('datepicker.bookingPeriod.morning'),
              },
              confirmBtn: t('datepicker.confirm'),
              day: t('datepicker.day'),
              days: t('datepicker.days'),
            }}
            lang={i18n.language}
            lockDates={{
              maxDate: new Date(lockDates.maxDate),
              minDate: new Date(lockDates.minDate),
            }}
            lockPluginFilter={(date) => {
              if (Array.isArray(date)) {
                return false
              }

              const day = date.getDate()
              const month = date.getMonth() + 1

              if (month < bookingStartMonth || month > bookingEndMonth) {
                return true
              }

              if (month === bookingStartMonth && day < bookingStartDay) {
                return true
              }

              if (month === bookingEndMonth && day > bookingEndDay) {
                return true
              }

              return false
            }}
            onChangeBookingPeriod={(bookingPeriod) => {
              onChangeBookingPeriod(bookingPeriod)
            }}
            onConfirm={(period) => {
              const storePeriod: PeriodSliceState['period'] = {
                end: DateTime.fromJSDate(period.end).toFormat('yyyy-LL-dd'),
                start: DateTime.fromJSDate(period.start).toFormat('yyyy-LL-dd'),
              }

              handleSubmit(storePeriod, bookingPeriod)
            }}
            period={periodAsDate}
            selectedBookingPeriod={bookingPeriod || BookingPeriod.ALL_DAY}
            settings={{
              bookingHalfDay: license.bookingHalfDay,
              bookingSeasonal: license.bookingSeasonal,
            }}
          />
        </div>

        <SectorRulesDialog
          isOpen={showSectorRulesDialog}
          //E. La doc dice che taxCode non deve essere più un param,
          //ma deve essere semplicemente passato all'endpoint di renderizzazione mappa
          onConfirm={(unavailableSectors, _taxCode) => {
            setShowSectorRulesDialog(false)

            if (!periodAsUnixTime || !bookingPeriod) {
              return
            }

            const newSearchParams = new URLSearchParams(searchParams.toString())

            newSearchParams.set(
              'unavailableSectors',
              unavailableSectors.join('-')
            )

            navigate(
              `${generatePath(TICKETS_PATH, { license: license.license })}`
            )
          }}
          sectorRules={sectorRules}
          setIsOpen={setShowSectorRulesDialog}
        />
      </main>

      <KioskTimeoutDialog timeout={60000} />
    </>
  )
}
