import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { useAppDispatch } from '@/hooks/store.ts'
import { loadingSlice } from '@/store/loadingSlice.ts'

interface LoaderTriggerProps {
  message?: string
}

export const LoaderTrigger = ({ message }: LoaderTriggerProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const id = uuid()
    dispatch(loadingSlice.actions.push({ id, message }))

    return () => {
      window.setTimeout(() => {
        dispatch(loadingSlice.actions.remove(id))
      }, 100)
    }
  }, [])

  return null
}
