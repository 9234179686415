import { DateTime } from 'luxon'

import { licenseSlice } from '@/store/licenseSlice'
import { LockDates } from '../models/insertPeriod'
import { getLockDates } from '../utils/getLockDates'

import { useAppSelector } from './store'

const BEACH_TIMEZONE = 'Europe/Rome'

export function useLockDates(): LockDates {
  const license = useAppSelector(licenseSlice.selectors.license)
  const seasonStart = useAppSelector(licenseSlice.selectors.seasonStart)
  const seasonEnd = useAppSelector(licenseSlice.selectors.seasonEnd)
  const isBookingEnabled = useAppSelector(
    licenseSlice.selectors.isBookingEnabled
  )

  const today = DateTime.local({ zone: 'UTC' }).startOf('day')
  const yesterday = today.minus({ days: 1 })

  const allDisabled: LockDates = {
    maxDate: yesterday.toMillis(),
    minDate: today.toMillis(),
  }

  if (!license || !isBookingEnabled || !seasonStart || !seasonEnd) {
    return allDisabled
  }

  const lockDates = getLockDates({
    license: {
      bookingEndPeriodOffset: license.bookingEndPeriodOffset,
      bookingTimeLimit: license.bookingTimeLimit,
      bookingToday: license.bookingToday,
    },
    now: DateTime.local({ zone: BEACH_TIMEZONE }),
    seasonEndDate: DateTime.fromFormat(seasonEnd, 'dd-LL-yyyy'),
    seasonStartDate: DateTime.fromFormat(seasonStart, 'dd-LL-yyyy'),
    zone: BEACH_TIMEZONE,
  })

  return lockDates
    ? {
        maxDate: lockDates.maxDate.getTime(),
        minDate: lockDates.minDate.getTime(),
      }
    : allDisabled
}
