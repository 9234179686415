import { useHostUrl } from '@/hooks/useHostUrl.ts'

export const useHostProfileUrl = () => {
  const hostUrl = useHostUrl()

  return {
    hostUrl,
    profileUrl: `${hostUrl}/profile`,
  }
}
