import { isSuccess } from '@devexperts/remote-data-ts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookingPolicyDialog } from '@/components/BookingPolicyDialog'
import { Tooltip } from '@/components/Tooltip/Tooltip.tsx'
import { useAppSelector } from '@/hooks/store.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { selectIsWhiteLabelActive, widgetSlice } from '@/store/widgetSlice.ts'

export const WhiteLabelBar = () => {
  const isVisible = useAppSelector(selectIsWhiteLabelActive)
  const regulation = useAppSelector(
    licenseSlice.selectors.license
  )?.bookingPolicy
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { t } = useTranslation()

  const whiteLabel = useAppSelector(widgetSlice.selectors.whiteLabel)

  if (!isVisible || !isSuccess(whiteLabel)) {
    return null
  }

  const { logoUrl, headerDescription, linkWebsite } = whiteLabel.value

  return (
    <>
      <div className="white-label__bar py-4 shadow-md">
        <div className="container flex items-center justify-between">
          <div className="flex items-end gap-4">
            <img alt="" className="h-10" height={40} src={logoUrl} />
            <Tooltip
              className="text-sm"
              content={headerDescription}
              maxLines={1}
            />
          </div>

          <div className="flex gap-4">
            <a href={linkWebsite}>{t('common.home')}</a>

            {regulation ? (
              <button onClick={() => setIsDialogOpen(true)}>
                {t('common.bookingPolicy')}
              </button>
            ) : null}
          </div>
        </div>
      </div>

      {regulation ? (
        <BookingPolicyDialog
          bookingPolicy={regulation}
          isOpen={isDialogOpen}
          setIsOpen={(isOpen) => setIsDialogOpen(isOpen)}
        />
      ) : null}
    </>
  )
}
