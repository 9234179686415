import { cn } from '@spiaggeit/spit-ui'
import React, { useId, useState } from 'react'
import { Controller, useController } from 'react-hook-form'
import { Icon } from '@/assets/icons'
import { InputError } from '@/components/Input/InputError'

import { useCollapsedSectionTabIndex } from '@/hooks/useCollapsedSectionTabIndex.ts'
import { InputBase } from '@/models/inputBase.ts'
import { Option } from '@/models/option.ts'

import { inputBaseClassName } from '../Input'

interface SelectProps extends InputBase {
  wrapperClassName?: string
  options: Option[]
  label?: string
  useValueAsFilter?: boolean
  selectClassName?: string
  placeholder: string
}

export const Select = (props: SelectProps) => {
  const {
    wrapperClassName,
    useValueAsFilter,
    label,
    selectClassName,
    name,
    rules,
    options,
    placeholder,
  } = props

  const {
    field: { onChange },
  } = useController({ name })
  const id = useId()
  const errorId = useId()
  const [isOpen, setIsOpen] = useState(false)

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (['Delete', 'Backspace'].includes(event.code)) {
      onChange('')
    }
  }

  const tabIndex = useCollapsedSectionTabIndex()

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className={cn('flex flex-col gap-1', wrapperClassName)}>
          <label className="font-bold leading-5" htmlFor={id}>
            {label}
          </label>

          <div className="relative">
            <select
              className={cn(
                'min-h-[46px] cursor-pointer appearance-none bg-white',
                inputBaseClassName,
                selectClassName
              )}
              onKeyDown={handleKeyDown}
              tabIndex={tabIndex}
              {...(error?.message && {
                'aria-describedby': errorId,
              })}
              aria-invalid={!!error?.message}
              id={id}
              onBlur={() => setIsOpen(false)}
              onChange={(e) => onChange(e.target.value)}
              onFocus={() => setIsOpen(true)}
              value={value}
            >
              <option value="">{placeholder}</option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {value && useValueAsFilter && (
              <span
                aria-hidden="true"
                className={cn(
                  'pointer-events-none absolute inset-px rounded bg-white px-2.5 py-3 leading-5',
                  selectClassName
                )}
              >
                {value}
              </span>
            )}

            <Icon
              className={cn(
                'pointer-events-none absolute right-3 top-3 transition-transform ease-in-out',
                { 'rotate-180': !isOpen }
              )}
              name="ChevronUp"
            />
          </div>

          {error?.message && (
            <InputError
              errorConfig={{
                id: errorId,
                message: error.message,
                type: 'error',
              }}
            />
          )}
        </div>
      )}
      rules={rules}
    />
  )
}
