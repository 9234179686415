import { Service, ServiceLimitType } from '../models/service'

interface Props {
  service: Service
  selectedSeats: number
  dayDifference: number
  serviceInCartQuantity?: number
  serviceInQuoteReservationQuantity?: number
}
export const getServiceUpdatedQuantity = ({
  service,
  selectedSeats,
  dayDifference,
  serviceInCartQuantity = 0,
  serviceInQuoteReservationQuantity = 0,
}: Props) => {
  const selectedQuantity =
    serviceInCartQuantity || serviceInQuoteReservationQuantity

  if (
    service.minimumLimitType === ServiceLimitType.FLAT &&
    service.maximumLimitType === ServiceLimitType.FLAT
  ) {
    return selectedQuantity || service.minimumQuantity
  } else if (
    service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES &&
    service.maximumLimitType === ServiceLimitType.FLAT &&
    selectedQuantity >= selectedSeats
  ) {
    return selectedQuantity
  } else if (
    service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES &&
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES
  ) {
    return selectedSeats
  } else if (
    service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES &&
    service.maximumLimitType === ServiceLimitType.FLAT &&
    selectedQuantity <= selectedSeats
  ) {
    return selectedSeats
  } else if (
    service.minimumLimitType === ServiceLimitType.FLAT &&
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES &&
    selectedQuantity > selectedSeats
  ) {
    return selectedSeats
  } else if (
    service.minimumLimitType === ServiceLimitType.FLAT &&
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES &&
    selectedQuantity <= selectedSeats
  ) {
    return selectedQuantity
  } else if (
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_DAYS &&
    selectedQuantity > dayDifference
  ) {
    return dayDifference
  } else if (
    service.minimumLimitType === ServiceLimitType.NUMBER_OF_DAYS &&
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_DAYS
  ) {
    return dayDifference
  } else if (
    service.maximumLimitType === ServiceLimitType.NUMBER_OF_DAYS &&
    selectedQuantity <= dayDifference
  ) {
    return selectedQuantity
  } else if (
    service.minimumLimitType === ServiceLimitType.NUMBER_OF_DAYS &&
    selectedQuantity < dayDifference
  ) {
    return dayDifference
  }

  return 0
}
