import { useTranslation } from 'react-i18next'
import { FormSection, FormSectionConfig } from '@/components/FormSection'

import { EMAIL_REGEX } from '@/utils/regex.ts'

export const UserFormSection = () => {
  const { t } = useTranslation()

  const emailLabel = t('insertDataForm.user.email')
  const phoneLabel = t('insertDataForm.user.phone')
  const lastNameLabel = t('insertDataForm.user.lastName')
  const firstNameLabel = t('insertDataForm.user.firstName')

  const inputs: FormSectionConfig[] = [
    {
      className: 'col-span-1',
      label: firstNameLabel,
      name: 'user.firstName',
      placeholder: t('placeholder.firstName'),
      rules: {
        required: t('validation.requiredField_m', {
          fieldName: firstNameLabel.toLowerCase(),
        }),
      },
    },
    {
      className: 'col-span-1',
      label: lastNameLabel,
      name: 'user.lastName',
      placeholder: t('placeholder.lastName'),
      rules: {
        required: t('validation.requiredField_m', {
          fieldName: lastNameLabel.toLowerCase(),
        }),
      },
    },
    {
      className: 'col-span-2 lg:col-span-1',
      label: phoneLabel,
      name: 'user.phoneNumber',
      prefixName: 'user.phonePrefix',
      type: 'tel',
    },
    {
      className: 'col-span-2 lg:col-span-1',
      inputType: 'email',
      label: emailLabel,
      name: 'user.email',
      placeholder: t('placeholder.email'),
      rules: {
        pattern: { message: t('validation.invalidEmail'), value: EMAIL_REGEX },
        required: t('validation.requiredField_truncated_f', {
          fieldName: emailLabel.toLowerCase(),
        }),
      },
    },
  ]

  return <FormSection inputs={inputs} title={t('insertDataForm.user.title')} />
}
