import { createSelector } from '@reduxjs/toolkit'
import { servicesSlice } from '@/store/servicesSlice'
import { cartSlice } from '..'

export const selectAvailableFeaturedServices = createSelector(
  [servicesSlice.selectors.data, cartSlice.selectors.cartReservations],
  (services, cartReservations) => {
    const extraFeaturedServices = services?.extraFeaturedServices
    const cartReservationsSectors = cartReservations.map(
      (reservation) => reservation.sector
    )
    return extraFeaturedServices?.filter((service) =>
      service.sectors.some((sector) => cartReservationsSectors.includes(sector))
    )
  }
)
