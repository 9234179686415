import { init, MapCanvasApi } from '@spiaggeit/map-canvas'
import { Color } from '@spiaggeit/spit-core'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceCallback } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { ServiceInfoPurchaseType } from '@/models/service.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import {
  loadMapData,
  loadSectors,
  mapSelectors,
  mapSlice,
} from '@/store/mapSlice.ts'
import { servicesSlice } from '@/store/servicesSlice.ts'
import { toastSlice } from '@/store/toastSlice.ts'
import {
  MAP_CELL_SIZE,
  USER_HAS_VISITED_MAP_BEFORE_KEY,
} from '@/utils/constants.ts'

export const useMapUtils = () => {
  const canvasAPIRef = useRef<MapCanvasApi | null>(null)
  const cartItemsIds = useAppSelector(cartSlice.selectors.itemsIds)
  const mapData = useAppSelector(mapSelectors.mapData)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const { t } = useTranslation()

  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const dispatch = useAppDispatch()

  const handleMapElementClick = (elementId: string) => {
    if (!mapData?.elements) return

    const element = mapData.elements.find((element) => element.id == elementId)

    if (!element || !element.style) return
    const isAvailable = element.style.iconColor !== Color.GREY

    if (isAvailable) {
      dispatch(cartSlice.actions.setCartPopoverIsOpen(false))
      dispatch(mapSlice.actions.selectMapElement(element))
    } else {
      dispatch(
        toastSlice.actions.show({
          ctaCopy: t('common.messageUnderstood'),
          message: t('map.errors.unavailableMapSpot'),
        })
      )
    }
  }

  const resetCanvasHeightHandler = useDebounceCallback(() => {
    const firstChild = document.getElementById('canvas')
      ?.firstChild as HTMLDivElement

    if (!firstChild) return

    firstChild.style.height = ''
  }, 100)

  const availableSpots = useMemo(
    () => mapData?.elements?.filter((e) => e.style?.iconColor === Color.GREEN),
    [mapData]
  )

  const userHasVisitedMapBefore = localStorage.getItem(
    USER_HAS_VISITED_MAP_BEFORE_KEY
  )

  return {
    availableSpots: {
      areMoreThanOne: availableSpots && availableSpots.length >= 1,
      areNone: availableSpots && availableSpots.length < 1,
      array: availableSpots,
      isOnlyOne: availableSpots && availableSpots.length === 1,
    },
    getCanvasAPI: () => {
      if (!canvasAPIRef.current) {
        throw new Error('Map canvas API is not available.')
      }

      return canvasAPIRef.current
    },
    highlightSelectedItems: function () {
      try {
        if (!mapData?.elements) {
          throw new Error('Map elements are not available.')
        }

        const api = this.getCanvasAPI()

        mapData.elements.forEach((element) => {
          if (
            cartItemsIds.includes(+element.id) ||
            selectedElement?.id === element.id
          ) {
            return api.highlightElement(element.id, Color.BLUE)
          }

          api.clearHighlight([element.id])
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    initMap: async function () {
      if (
        !mapData ||
        !mapData.elements ||
        !mapData.background ||
        !mapData.size
      ) {
        throw new Error('Required map data is not available.')
      }

      canvasAPIRef.current = await init({
        events: {
          onMapElementClick: handleMapElementClick,
        },
        opts: {
          assetsBaseUrl: `${import.meta.env.VITE_ASSETS_URL}/booking/map`,
          background: mapData.background,
          containerId: 'canvas',
          elements: [...(mapData.elements ?? [])],
          initialZoom: 1.1,
          size: {
            height: mapData.size.height * MAP_CELL_SIZE,
            width: mapData.size.width * MAP_CELL_SIZE,
          },
        },
      })

      setIsCanvasReady(true)

      this.highlightSelectedItems()
    },
    isCanvasReady,
    loadInitialData: async function () {
      try {
        await dispatch(loadMapData()).unwrap()
        dispatch(loadSectors())
        dispatch(
          servicesSlice.actions.load({
            purchaseType: ServiceInfoPurchaseType.WITH_SPOT,
          })
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load data:', error)
      }
    },
    resetCanvasHeightHandler,
    userHasVisitedMapBefore,
    zoomIn: function () {
      canvasAPIRef.current?.zoomIn()
    },
    zoomOut: function () {
      canvasAPIRef.current?.zoomOut()
    },
  }
}
