import { cn } from '@spiaggeit/spit-ui'
import { useAppSelector } from '@/hooks/store.ts'
import { selectIsWhiteLabelActive } from '@/store/widgetSlice.ts'

export const useWhiteLabelBgTextClassName = (className = '') => {
  const isWhiteLabelActive = useAppSelector(selectIsWhiteLabelActive)

  return cn(className, {
    'whitelabel__background-text': isWhiteLabelActive,
  })
}
