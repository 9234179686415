import { forwardRef, type KeyboardEvent, useId } from 'react'
import { Controller, useController } from 'react-hook-form'
import { Icon } from '@/assets/icons'

import { InputBase } from '@/models/inputBase.ts'

export interface CheckboxProps extends InputBase {
  label: string
}

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  function ForwardedCheckbox(props, ref) {
    const labelId = useId()

    const { field } = useController({ name: props.name })

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault()
      }

      if (['NumpadEnter', 'Enter', 'Space'].includes(e.code)) {
        field.onChange(!field.value)
      }
    }

    return (
      <Controller
        name={props.name}
        render={({ field }) => (
          <div
            className="relative inline-flex cursor-pointer items-center gap-2"
            onClick={() => field.onChange(!field.value)}
            onKeyDown={handleKeyDown}
          >
            <span
              aria-checked={!!field.value}
              aria-labelledby={labelId}
              className="peer inline-flex h-5 w-5 items-center justify-center rounded-xs border border-gray-400 transition-colors aria-checked:border-blue-500 aria-checked:bg-blue-500"
              ref={ref}
              role="checkbox"
              tabIndex={0}
            />

            <Icon
              className="absolute translate-y-2 text-white opacity-0 transition-all peer-aria-checked:translate-y-0 peer-aria-checked:opacity-100"
              name="ApproveTick"
            />

            <span className="leading-5" id={labelId}>
              {props.label}
            </span>
          </div>
        )}
        rules={props.rules}
      />
    )
  }
)
