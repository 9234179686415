import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { Icon } from '@/assets/icons'
import { Surface } from '@/components/Surface'
import { useAppSelector } from '@/hooks/store.ts'

import { licenseSlice } from '@/store/licenseSlice'

export const MapPreviewCard = () => {
  const coordinates = useAppSelector(licenseSlice.selectors.beachCoordinates)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_MAPS_KEY,
    id: 'google-map-script',
  })

  const address = useAppSelector(licenseSlice.selectors.beachAddress)

  const { t } = useTranslation()

  if (!isLoaded || !coordinates || !address) return null

  return (
    <Surface as="article">
      <GoogleMap
        center={coordinates}
        mapContainerStyle={{ height: '12.5rem', width: '100%' }}
        options={{
          cameraControl: false,
          clickableIcons: false,
          fullscreenControl: false,
          gestureHandling: 'none',
          keyboardShortcuts: false,
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false,
          streetViewControl: false,
        }}
        zoom={10}
      >
        <Marker position={coordinates} />
      </GoogleMap>

      <div className="p-4">
        <h3 className="font-bold">{address.name}</h3>
        <p className="mb-4 text-sm">{address.address}</p>

        <a
          className="flex items-center gap-2 text-link"
          href={`https://google.com/maps/dir/?api=1&origin=current+location&destination=${coordinates.lat},${coordinates.lng}`}
          rel="noreferrer" target="_blank"
        >
          <Icon className="size-5" name="ExternalLink" />
          {t('thankYou.getDirections')}
        </a>
      </div>
    </Surface>
  )
}
