import { ComponentPropsWithoutRef } from 'react'

import { DateRange } from '../../../DateRange'
import { Tooltip } from '../../../Tooltip/Tooltip.tsx'
import { ReservationTotal } from '../../Total'
import { ReservationLocationProps } from '../index.tsx'

export type ReservationLocationMobileProps = ReservationLocationProps &
  ComponentPropsWithoutRef<'div'> & {
    total?: number
  }

export const ReservationLocationMobile = (
  props: ReservationLocationMobileProps
) => {
  const { beachName, total, location, region, endDate, startDate, ...rest } =
    props

  return (
    <div className="w-full" {...rest}>
      <div className="flex items-center justify-between gap-2">
        <div onClick={(e) => e.preventDefault()}>
          <Tooltip
            className="font-bold"
            content={beachName}
            maxLines={1}
            wrapper="h3"
          />
        </div>

        {!!total && <ReservationTotal total={total} />}
      </div>

      <p className="flex flex-wrap items-center justify-start overflow-hidden text-sm leading-5 text-secondary">
        {location}, {region}
        <span aria-hidden="true" className="mx-0.5">
          ·
        </span>
        <DateRange endDate={endDate} startDate={startDate} />
      </p>
    </div>
  )
}
