import { isSuccess } from '@devexperts/remote-data-ts'
import { useState } from 'react'
import { Trans } from 'react-i18next'

import { useDayDifference } from '@/hooks/useDayDifference'
import { useWhiteLabelBgTextClassName } from '@/hooks/useWhiteLabelBgTextClassName.ts'
import { Service } from '@/models/service'
import { getServiceSingleLimit } from '@/utils/getServiceSingleLimit'
import { getServiceUpdatedQuantity } from '@/utils/getServiceUpdatedQuantity'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { ticketsInCartTotalQuantity } from '@/utils/services'
import { useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice/index'
import { servicesSlice } from '../../store/servicesSlice'
import { Ticket } from './Ticket'

interface Props {
  availableQuantity: number
  hasLimit: boolean
  name: string
  tickets: Service[]
  serviceGroupId: number
  onAdd: VoidFunction
  onRemove: VoidFunction
}

export const Group = (props: Props) => {
  const minimumQuantity = props.tickets.reduce(
    (acc, ticket) => acc + ticket.minimumQuantity,
    0
  )
  const services = useAppSelector(servicesSlice.selectors.services)
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)

  const totalQuantity = useAppSelector(ticketsInCartTotalQuantity)

  const [groupQuantity, setGroupQuantity] = useState(minimumQuantity)
  const dayDifference = useDayDifference()

  const headingClassName = useWhiteLabelBgTextClassName('text-lg font-bold')
  const paragraphClassName = useWhiteLabelBgTextClassName('mt-1')

  if (!isSuccess(services) || props.tickets.length === 0) return

  return (
    <div className="mb-8">
      <h2 className={headingClassName}>{props.name}</h2>

      {props.hasLimit && props.availableQuantity > 0 ? (
        <p className={paragraphClassName}>
          <Trans
            i18nKey="tickets.group.max"
            tOptions={{ value: props.availableQuantity }}
          />
        </p>
      ) : null}

      <div className="mt-2 grid grid-cols-1 items-start gap-4 lg:grid-cols-2">
        {props.tickets.map((ticket) => {
          const dynamicKey = getServiceKeyFromId(ticket.serviceId)
          const serviceInCartQuantity =
            cartReservations[0]?.services[dynamicKey]
          const initialQuantity = getServiceUpdatedQuantity({
            dayDifference,
            selectedSeats: 0,
            service: ticket,
            serviceInCartQuantity,
          })

          return (
            <Ticket
              available={{
                global: services.value.hasLimit
                  ? Math.max(
                    0,
                    services.value.availableQuantity - totalQuantity
                  )
                  : Infinity,
                group: props.hasLimit
                  ? Math.max(0, props.availableQuantity - groupQuantity)
                  : Infinity,
                ticket: getServiceSingleLimit({
                  dayDifference,
                  service: ticket,
                }),
              }}
              initialQuantity={initialQuantity}
              key={ticket.serviceId}
              onAdd={() =>
                setGroupQuantity((prevQuantity) => {
                  return prevQuantity + 1
                })
              }
              onRemove={() =>
                setGroupQuantity((prevQuantity) => prevQuantity - 1)
              }
              ticket={ticket}
            />
          )
        })}
      </div>
    </div>
  )
}
