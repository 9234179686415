import { Dialog } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderTrigger } from '@/components/LoaderTrigger'
import { useAppSelector } from '@/hooks/store.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { selectServiceCostsString } from '@/store/licenseSlice/selectors/selectServiceCostsString.ts'

export const ServiceCostDialog = ({ trigger }: { trigger: ReactNode }) => {
  const { t } = useTranslation()

  const serviceCostsString = useAppSelector(selectServiceCostsString)

  const isLoadingStrings = useAppSelector(
    licenseSlice.selectors.isLoadingLicenseStrings
  )

  if (isLoadingStrings) {
    return (
      <>
        <LoaderTrigger />
        <p>{t('map.elementDetail.serviceCost.title')}</p>
      </>
    )
  }

  return (
    <Dialog
      scrollContent
      size="sm"
      title={t('map.elementDetail.serviceCost.title')}
      trigger={trigger}
    >
      {serviceCostsString ? (
        <Content dangerouslySetInnerHTML={{ __html: serviceCostsString }} />
      ) : (
        <Content>
          <p>{t('map.elementDetail.serviceCost.firstParagraph')}</p>

          <section>
            <h3 className="mb-1 font-bold">
              {t('map.elementDetail.serviceCost.secondParagraphTitle')}
            </h3>
            <p>{t('map.elementDetail.serviceCost.secondParagraphContent')}</p>
          </section>
        </Content>
      )}
    </Dialog>
  )
}

const Content = (props: ComponentPropsWithoutRef<'div'>) => {
  const { children, ...rest } = props

  return (
    <div
      className="flex flex-col items-stretch justify-between gap-4 p-4"
      {...rest}
    >
      {children}
    </div>
  )
}
