import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef } from 'react'

import styles from './HtmlContent.module.css'

interface HtmlContentProps extends ComponentPropsWithoutRef<'div'> {
  description: string
}

export const HtmlContent = (props: HtmlContentProps) => {
  const { description, ...rest } = props

  return (
    <div
      className={cn('mb-4 leading-5', styles.HtmlContent)}
      dangerouslySetInnerHTML={{ __html: props.description }}
      {...rest}
    />
  )
}
