import { Outlet } from 'react-router-dom'
import { Loader } from '@/components/Loader'
import { useAppSelector } from '@/hooks/store.ts'
import { loadingSlice } from '@/store/loadingSlice.ts'

export const LoaderContainer = () => {
  const loadingMessage = useAppSelector(loadingSlice.selectors.loadingMessage)

  return (
    <>
      {loadingMessage !== null && <Loader loadingLabel={loadingMessage} />}
      <Outlet />
    </>
  )
}
