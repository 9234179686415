import * as rd from '@devexperts/remote-data-ts'
import { selectAvailableFeaturedServices } from '@/store/cartSlice/selectors/selectAvailableFeaturedServices.ts'
import { extraSlice, selectIsExtraStepVisible } from '@/store/extraSlice'
import { useAppSelector } from './store'

export const useIsExtraStepNecessary = () => {
  const isExtraStepVisible = useAppSelector(selectIsExtraStepVisible)
  const weatherInsurancePrice = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )
  const availableFeaturedServices = useAppSelector(
    selectAvailableFeaturedServices
  )

  const isWeatherInsuranceAvailable =
    rd.isSuccess(weatherInsurancePrice) && weatherInsurancePrice.value >= 1

  // this condition is true if at least one of the following conditions is true
  // 1) weather insurance is available for the selected period
  // 2) there are >=1 featured services available for the user

  const extraStepHasContent =
    (availableFeaturedServices && availableFeaturedServices?.length >= 1) ||
    isWeatherInsuranceAvailable

  return isExtraStepVisible && extraStepHasContent
}
