import { PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { resetAllState } from '@/store/commonActions.ts'
import { createAppSlice } from '@/store/createAppSlice.ts'

interface LoadingSliceState {
  stack: [string, string][]
}

const initialState: LoadingSliceState = {
  stack: [],
}

export const loadingSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)
  },
  initialState,
  name: 'loading',
  reducers: (create) => ({
    push: create.reducer(
      (state, action: PayloadAction<{ message?: string; id: string }>) => {
        const { id, message } = action.payload
        const fallbackMessage = i18next.isInitialized
          ? i18next.t('common.loading')
          : ''

        state.stack.push([id, message || fallbackMessage])
      }
    ),
    remove: create.reducer((state, action: PayloadAction<string>) => {
      state.stack = state.stack.filter(([id]) => id !== action.payload)
    }),
  }),
  selectors: {
    isLoadingStackEmpty: (state) => {
      return state.stack.length === 0
    },
    loadingMessage: (state) => {
      if (state.stack.length > 0) {
        const [, message] = state.stack.at(-1)!
        return message
      }

      return null
    },
  },
})
