import * as rd from '@devexperts/remote-data-ts'
import { isSuccess } from '@devexperts/remote-data-ts'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { SummaryCost } from '@/components/Reservation/Costs'
import { SummaryReservation } from '@/components/Reservation/List'

import { QuoteReservation } from '@/models/quote.ts'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice.ts'
import { cartSlice } from '@/store/cartSlice'
import { extraSlice } from '@/store/extraSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { mapSelectors } from '@/store/mapSlice.ts'
import { insertPeriodSlice } from '../store/insertPeriodSlice.ts'
import { getReservationSpotId } from '../utils/getReservationSpotId.ts'

import { useAppSelector } from './store.ts'
import { useCartSelectedProducts } from './useCartSelectedProducts.ts'
import { useCartSelectedServices } from './useCartSelectedServices.ts'
import { useMapSpotName } from './useMapSpotName.ts'

export const useCartRecapData = (isUsingCartInfoData?: boolean) => {
  const { t } = useTranslation()

  const license = useAppSelector(licenseSlice.selectors.license)
  const insertPeriod = useAppSelector(insertPeriodSlice.selectors.period)
  const cartDetail = useAppSelector(cartSlice.selectors.cartDetail)
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const cartDetailServices = useAppSelector(
    cartSlice.selectors.cartDetailServices
  )
  const cartDetailPrices = useAppSelector(cartSlice.selectors.cartDetailPrices)
  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )
  const isWeatherInsuranceAddedToCart = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )
  const quote = useAppSelector(cartSlice.selectors.quote)
  const quoteWithDiscount = useAppSelector(
    cartSlice.selectors.quoteWithDiscount
  )
  const showWeatherInsurancePrice =
    isWeatherInsuranceAddedToCart ||
    (isSuccess(cartDetail) && !!cartDetail.value.cart.weatherPolicy)

  const weatherInsurancePricePreview = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )

  const weatherInsuranceIsAddedToCart = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )

  const formattedCartInfoReservations = useAppSelector(
    cartSlice.selectors.cartDetailReservations
  )

  const reservations = isUsingCartInfoData
    ? formattedCartInfoReservations
    : cartReservations

  const selectedProducts = useCartSelectedProducts(reservations)

  const { reservationServices, promoService } =
    useReservationServicesWithPromo(reservations)

  const today = DateTime.now().toISODate()

  const isRefundable = useAppSelector(
    licenseSlice.selectors.isBookingRefundable
  )

  const isQuoteWithDiscount = isSuccess(quoteWithDiscount)

  let weatherInsurancePrice = 0

  if (isUsingCartInfoData && isSuccess(cartDetail)) {
    weatherInsurancePrice = cartDetail?.value.cart.weatherPolicy?.price || 0
  } else if (rd.isSuccess(weatherInsurancePricePreview)) {
    weatherInsurancePrice = weatherInsurancePricePreview.value
  }

  const mapElements = useAppSelector(mapSelectors.mapData)?.elements
  const { getMapSpotName } = useMapSpotName()
  const summaryReservations = reservations.map((reservation) => {
    const spotId = getReservationSpotId(reservation)

    const FEE_SERVICE_IDS = [512, 1613]

    const spot = mapElements?.find(
      (element) =>
        element.subType === reservation.spotType &&
        element.name === reservation.spotName
    )

    const title = getMapSpotName(spot, {
      shouldShowSpotName: reservation.shouldShowSpotName,
    })

    return {
      productsSelected: [
        ...selectedProducts
          .filter((r) => r.spotId === spotId)
          .map((product) => ({
            isExtra: false,
            isGift: false,
            name: product.name,
            quantity: product.quantity,
          })),
        ...(isUsingCartInfoData
          ? cartDetailServices
            .find((r) => r.spotId === spotId)
            ?.services.filter(
              (service) => !FEE_SERVICE_IDS.includes(service.service_id)
            )
            .map((service) => ({
              isExtra: true,
              isGift: false,
              name: service.service_name,
              quantity: service.bought,
            })) || []
          : reservationServices
            .filter((r) => r.spotId === spotId)
            .map((service) => ({
              ...service,
              isExtra: true,
            }))),
        ...(promoService ? [promoService] : []),
      ],
      spotType: reservation.spotType,
      title: title,
    } as SummaryReservation
  })

  const getFeesPrice = () => {
    if (isUsingCartInfoData && isSuccess(cartDetail)) {
      return cartDetail.value.cart.totalFeesValue
    }

    if (isQuoteWithDiscount) {
      return quoteWithDiscount.value.fees
    }

    if (isSuccess(quote)) {
      return quote.value.fees
    }

    return 0
  }

  const getServicesPrice = () => {
    if (isUsingCartInfoData) {
      return cartDetailPrices?.totalServicesValue || 0
    }

    return 0
  }

  const getTotalPrice = () => {
    if (isUsingCartInfoData) {
      return cartDetailPrices?.totalPrice || 0
    }

    if (isQuoteWithDiscount) {
      return quoteWithDiscount.value.totalPrice
    }

    if (isSuccess(quote)) {
      return quote.value.totalPrice
    }

    return 0
  }

  const totalSpotPrice = isUsingCartInfoData
    ? cartDetailPrices?.totalSpotsValue || 0
    : getTotalPrice() - getServicesPrice() - getFeesPrice()

  const servicesPrice = getServicesPrice()

  let discountPrice

  if (isUsingCartInfoData) {
    discountPrice = cartDetailPrices?.totalDiscountValue
  } else if (isQuoteWithDiscount) {
    discountPrice = quoteWithDiscount.value.discountValue
  }

  const hasDiscountApplied = isUsingCartInfoData
    ? cartDetailPrices && cartDetailPrices.totalDiscountValue > 0
    : isQuoteWithDiscount

  const costs = [
    {
      name:
        selectedBookingType === BookingAvailability.TICKETS
          ? t('common.tickets')
          : t('common.spot', {
            count: cartReservations.length,
          }),
      price: totalSpotPrice + servicesPrice,
    },
    ...(showWeatherInsurancePrice
      ? [
          {
            name: t('extra.weatherInsurance.title'),
            price: weatherInsurancePrice,
          },
        ]
      : []),
    {
      hasTooltip: true,
      name: t('common.serviceCosts'),
      price: getFeesPrice(),
    },
    ...(hasDiscountApplied
      ? [
          {
            isDiscount: true,
            name: t('insertDataForm.promo.discountCode'),
            price: discountPrice,
          },
        ]
      : []),
  ] as SummaryCost[]

  const total = weatherInsuranceIsAddedToCart
    ? getTotalPrice() + weatherInsurancePrice
    : getTotalPrice()

  return {
    beachName: license?.name || '',
    costs,
    endDate: insertPeriod?.end || today,
    isRefundable: !!isRefundable,
    location: license?.beach.city || '',
    region: license?.beach.region || '',
    reservations: summaryReservations,
    startDate: insertPeriod?.start || today,
    total,
  }
}

function useReservationServicesWithPromo(reservations: QuoteReservation[]) {
  const promotionalService = useAppSelector(
    cartSlice.selectors.promotionalService
  )

  const reservationServices = useCartSelectedServices(reservations)

  if (!promotionalService) {
    return {
      promoService: null,
      reservationServices,
    }
  }

  // Finds the first reservation that has the same service id of the promotional service
  const sameServiceIndex = reservationServices.findIndex(
    (service) => service.id === promotionalService.id
  )

  if (sameServiceIndex > 0) {
    return {
      promoService: null,
      reservationServices: reservationServices.map((service, index) => {
        if (sameServiceIndex === index) {
          return {
            ...service,
            isExtra: true,
            isGift: true,
            quantity: service.quantity + 1,
          }
        }

        return service
      }),
    }
  }

  return {
    promoService: {
      isExtra: true,
      isGift: true,
      name: promotionalService.name,
      quantity: 1,
    },
    reservationServices,
  }
}
