import { ComponentPropsWithoutRef, useId, useRef } from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'

import { Option } from '@/models/option.ts'

import { Radio } from '../Radio'

export interface RadioGroupProps {
  label: string
  name: string
  rules?: UseControllerProps['rules']
  options: (Option & ComponentPropsWithoutRef<'span'>)[]
}

export const RadioGroup = (props: RadioGroupProps) => {
  const id = useId()

  const refs = useRef<(HTMLDivElement | null)[]>(
    Array.from({ length: props.options.length })
  )

  return (
    <Controller
      name={props.name}
      render={({ field }) => (
        <div
          aria-labelledby={id}
          className="flex flex-col gap-1"
          role="radiogroup"
        >
          <span className="font-bold" id={id}>
            {props.label}
          </span>

          <div className="flex items-center gap-8">
            {props.options.map(({ label, value, ...rest }, index, arr) => {
              const handleSelect = (type: 'prev' | 'next') => () => {
                const newIndex =
                  (type === 'prev' ? index - 1 : index + 1) % arr.length
                field.onChange(arr[newIndex].value)
                refs.current[newIndex]?.focus()
              }

              return (
                <Radio
                  {...rest}
                  isRequired={!!props.rules?.required}
                  isSelected={field.value === value}
                  key={value}
                  label={label}
                  onChange={() => field.onChange(value)}
                  onSelectNext={handleSelect('next')}
                  onSelectPrev={handleSelect('prev')}
                  ref={(el) => (refs.current[index] = el)}
                  value={value}
                />
              )
            })}
          </div>
        </div>
      )}
      rules={props.rules}
    />
  )
}
