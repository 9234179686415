import { cn } from '@spiaggeit/spit-ui'
import { Icon } from '@/assets/icons'

interface Props {
  label: string
  index: number
  activeStepIndex: number
}

export const Step = (props: Props) => {
  const { index, label, activeStepIndex } = props
  const isActive = index === activeStepIndex
  const isDone = index < activeStepIndex

  return (
    <div className="flex-0 flex flex-col items-center">
      {isActive && (
        <div className="flex-0 rounded-circle border border-yellow-300 p-1">
          <div className="size-2 rounded-circle bg-yellow-300 md:size-3" />
        </div>
      )}

      {isDone && (
        <div className="flex-0 rounded-circle border border-yellow-300 bg-yellow-300 text-white lg:p-0.5">
          <Icon className="size-4" name="ApproveTick" />
        </div>
      )}

      {!isActive && !isDone && (
        <div className="flex-0 border-secondary size-4 rounded-circle border md:size-5" />
      )}

      <div
        className={cn(
          'absolute translate-y-6 pt-1 text-xs text-secondary md:translate-y-full',
          { 'font-bold': isActive }
        )}
      >
        {label}
      </div>
    </div>
  )
}
