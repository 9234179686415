import { KeyboardEvent, useEffect, useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'usehooks-ts'

import { SkeletonText } from '@/components/SkeletonText/index.tsx'
import { priceFormatter } from '@/utils/price.ts'
import { AppIcon } from '../../../assets/icons'
import { CardWithImage } from '../../../components/CardWithImage'
import { RadioCard } from '../../../components/RadioCard'
import { Image } from '../../../models/image.ts'

import { InsuranceDialog } from './InsuranceDialog'
import { WeatherCardFeatures } from './WeatherCardFeatures'
import { WeatherCardFooter } from './WeatherCardFooter'
import { WeatherCardHeader } from './WeatherCardHeader'
import { WeatherCardStatus } from './WeatherCardStatus'

export interface WeatherCardProvider {
  name: string
  image: Image
}

export interface WeatherCardFeature {
  icon: AppIcon
  copy: string
}

interface WeatherCardProps {
  title: string
  image: Image
  insuranceProvider: WeatherCardProvider
  isPending: boolean
  isInitiallySelected: boolean
  description: string
  features: WeatherCardFeature[]
  price?: number
  onSelectChange(isSelected: boolean): void
}

export const WeatherCard = (props: WeatherCardProps) => {
  const { t } = useTranslation()

  const [isSelected, setIsSelected] = useState(props.isInitiallySelected)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const titleId = useId()
  const priceId = useId()
  const statusId = useId()
  const descriptionId = useId()
  const mobilePriceId = useId()
  const optionsLabelId = useId()
  const insuranceProviderId = useId()

  const insuranceCardRef = useRef<HTMLDivElement | null>(null)
  const noInsuranceCardRef = useRef<HTMLDivElement | null>(null)

  const isDesktop = useMediaQuery('(min-width: 992px)')
  const isRadio = !isDesktop

  useEffect(() => {
    props.onSelectChange(isSelected)
  }, [isSelected])

  const handleOpenDialog = () => {
    if (isSelected) return
    setIsDialogOpen(true)
  }

  const resetSelection = () => {
    setIsSelected(false)
    noInsuranceCardRef.current?.focus()
  }

  const handleRadioCardKeyDown = (type: 'reset' | 'open-dialog') => {
    return (e: KeyboardEvent<HTMLDivElement>) => {
      if (isSelected) return

      if (e.code !== 'Tab' && type === 'reset') {
        resetSelection()
      } else if (['Enter', 'NumpadEnter', 'Space'].includes(e.code)) {
        e.preventDefault()
        setIsDialogOpen(true)
      }
    }
  }

  const handleRadioGroupKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const currentDataValue = target.dataset['value']

    if (
      ['ArrowDown', 'ArrowRight'].includes(e.code) &&
      currentDataValue === 'no-insurance'
    ) {
      setIsDialogOpen(true)
    } else if (
      ['ArrowUp', 'ArrowLeft'].includes(e.code) &&
      currentDataValue === 'insurance'
    ) {
      resetSelection()
    }
  }

  const handleSelectInsurance = () => {
    setIsSelected(true)
    setIsDialogOpen(false)
    queueMicrotask(() => {
      insuranceCardRef.current?.focus()
    })
  }

  let ariaTitle = `${titleId} ${isDesktop ? priceId : mobilePriceId}`

  if (isSelected) {
    ariaTitle += ` ${statusId}`
  }

  return (
    <div
      className="flex flex-col gap-2"
      {...(!isDesktop && {
        'aria-labelledby': optionsLabelId,
        role: 'radiogroup',
      })}
      onKeyDown={handleRadioGroupKeyDown}
    >
      <p className="sr-only lg:hidden" id={optionsLabelId}>
        {t('a11y.selectOption')}
      </p>

      <RadioCard
        className="lg:hidden"
        data-value="no-insurance"
        isSelected={isSelected}
        label={t('extra.noInsurance')}
        onKeyDown={handleRadioCardKeyDown('reset')}
        onSelect={resetSelection}
        ref={noInsuranceCardRef}
        tabIndex={!isSelected ? 0 : -1}
      />

      <CardWithImage
        aria-atomic="true"
        aria-describedby={descriptionId}
        aria-labelledby={ariaTitle}
        aria-live="polite"
        as="article"
        contentWrapperProps={{
          className: 'relative flex flex-col gap-4',
        }}
        image={props.image}
        imageWrapperProps={{
          className: 'hidden lg:block',
        }}
        ref={insuranceCardRef}
        {...(isRadio && {
          'aria-checked': isSelected,
          className: 'aria-checked:border-blue-500 border-2',
          'data-value': 'insurance',
          onClick: handleOpenDialog,
          onKeyDown: handleRadioCardKeyDown('open-dialog'),
          role: 'radio',
          tabIndex: isSelected ? 0 : -1,
        })}
      >
        {isSelected && (
          <WeatherCardStatus
            className="hidden self-start lg:inline-flex"
            id={statusId}
          />
        )}

        <WeatherCardHeader
          id={titleId}
          insuranceProvider={props.insuranceProvider}
          insuranceWrapperProps={{
            id: insuranceProviderId,
          }}
          isSelected={isSelected}
          title={props.title}
        />

        <p className="text-sm leading-5" id={descriptionId}>
          {props.description}
        </p>

        <data
          className="absolute right-4 top-4 font-bold lg:hidden"
          id={mobilePriceId}
          value={props.price}
        >
          <SkeletonText
            isPending={props.isPending}
            text={props.price ? priceFormatter.format(props.price) : ''}
          />
        </data>

        <WeatherCardFeatures features={props.features} />

        <WeatherCardFooter
          isCardSelected={isSelected}
          isDialogOpen={isDialogOpen}
          isPending={props.isPending}
          onSelectInsurance={handleSelectInsurance}
          price={props.price}
          priceWrapperProps={{ id: priceId }}
          setIsDialogOpen={setIsDialogOpen}
          setIsSelected={setIsSelected}
        />
      </CardWithImage>

      <InsuranceDialog
        isDialogOpen={isDialogOpen}
        onSelectInsurance={handleSelectInsurance}
        setIsOpen={setIsDialogOpen}
        trigger={<></>}
      />
    </div>
  )
}
