import { cn } from '@spiaggeit/spit-ui'
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
} from 'react'

import { Image } from '@/models/image.ts'

import { Surface, SurfaceProps } from '../Surface'

interface ImageCardProps extends SurfaceProps {
  image?: Image & ComponentPropsWithoutRef<'img'>
  contentWrapperProps?: ComponentPropsWithoutRef<'div'>
  imageWrapperProps?: ComponentPropsWithoutRef<'div'>
}

export const CardWithImage = forwardRef(function ForwardedRoundedImageCard(
  props: PropsWithChildren<ImageCardProps>,
  ref: ForwardedRef<HTMLElement>
) {
  const {
    image,
    children,
    className: propsClassName,
    imageWrapperProps,
    contentWrapperProps,
    ...surfaceProps
  } = props

  const { alt, className: imageClassName, ...restImageProps } = image || {}
  const isImageSet = !!image?.src

  const { className: imageWrapperClassName, ...imageWrapperPropsRest } =
    imageWrapperProps || {}
  const { className: contentWrapperClassName, ...contentWrapperPropsRest } =
    contentWrapperProps || {}

  return (
    <Surface
      as="article"
      className={cn('flex flex-col lg:flex-row', propsClassName)}
      ref={ref}
      {...surfaceProps}
    >
      {isImageSet && (
        <div
          className={cn(
            'w-full lg:w-auto lg:flex-shrink-0',
            imageWrapperClassName
          )}
          {...imageWrapperPropsRest}
        >
          {/* The alt attribute is specified explicitly to avoid an a11y warning */}
          <img
            alt={alt || ''}
            className={cn(
              'h-[11.5rem] w-full object-cover object-center lg:h-full lg:w-[16.5rem]',
              imageClassName
            )}
            {...restImageProps}
          />
        </div>
      )}

      <div
        className={cn('w-full p-4', contentWrapperClassName)}
        {...contentWrapperPropsRest}
      >
        {children}
      </div>
    </Surface>
  )
})
