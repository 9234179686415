import { isSuccess } from '@devexperts/remote-data-ts'
import { Button, cn } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Icon } from '@/assets/icons'
import { useAppSelector } from '@/hooks/store.ts'
import { httpClient } from '@/lib/http/HttpClient.ts'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'

import { licenseSlice } from '@/store/licenseSlice'

interface DownloadConfirmationButtonProps {
  variant?: 'outline'
}

export const DownloadConfirmationButton = (
  props: DownloadConfirmationButtonProps
) => {
  const isOutlineVariant = props.variant === 'outline'
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const payParam = searchParams.get('pay')
  const cartDetail = useAppSelector(cartSlice.selectors.cartDetail)
  const country = useAppSelector(appSlice.selectors.country)
  const { t } = useTranslation()
  const license = useAppSelector(licenseSlice.selectors.licenseCode)

  const downloadHandler = async () => {
    if (!isSuccess(cartDetail)) {
      throw new Error('Reservation id not found')
    }

    const queryParams = new URLSearchParams()

    if (payParam) {
      queryParams.set('pay', payParam)
    }

    setIsLoading(true)

    const reservationIds = cartDetail.value.cart.reservationModels.map(
      (reservation) => reservation.id
    )

    const responses = await Promise.all(
      reservationIds.map((reservationId) =>
        httpClient.fetch<string>(
          `beaches/${license}/reservations/${reservationId}?${queryParams.toString()}`,
          country,
          {
            headers: {
              Accept: 'text/html',
            },
          },
          true
        )
      )
    )

    setIsLoading(false)

    const spaceHtml = `<html><body><div style="padding-bottom: 80px; background-color: #EFEFF0"></div></body></html>`

    const blobParts: BlobPart[] = [
      spaceHtml,
      ...responses.flatMap((response, index, array) => {
        if (response.status === 'error') return ''

        return index < array.length - 1
          ? [response.data, spaceHtml]
          : response.data
      }),
      spaceHtml,
    ]

    // all the blob parts are combined in a single html file that includes
    // all the content in a single body element
    const blob = new Blob(blobParts, { type: 'text/html' })
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }

  return (
    <Button
      className={cn('w-full', {
        'btn-outline-secondary--loading': isOutlineVariant,
      })}
      isLoading={isLoading}
      label={t('thankYou.downloadReservationLabel')}
      onClick={downloadHandler}
      variant={props.variant}
    >
      <Icon className="size-5" name="DownloadIcon" />
      {t('thankYou.downloadReservationLabel')}
    </Button>
  )
}
