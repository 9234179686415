import { httpClient } from '@/lib/http/HttpClient.ts'
import { Country } from '@/models/app.ts'
import { ResponseBase } from '@/models/http.ts'
import { QuoteResponse } from '@/models/quote.ts'

interface QuotePayload {
  channel: string
  reservations: Record<string, object>
}

interface QuoteProps {
  licenseName: string
  country: Country
  payload: QuotePayload
}

export const fetchCartQuotation = async (props: QuoteProps) => {
  return httpClient.fetch<ResponseBase<QuoteResponse>>(
    `beaches/${props.licenseName}/carts/quote`,
    props.country,
    {
      body: JSON.stringify(props.payload),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
}
