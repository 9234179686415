import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { selectTicketRemainingAvailableQuantity } from '@/store/cartSlice/selectors/selectTicketRemainingAvailableQuantity.ts'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { useDayDifference } from '../../../../hooks/useDayDifference'
import { Service, ServiceLimitType } from '../../../../models/service'
import { cartSlice } from '../../../../store/cartSlice'
import {
  mapSelectedElementHasVisibleSeatsSelector,
  mapSelectedElementServices,
  mapSelectors,
} from '../../../../store/mapSlice'
import { getServiceUpdatedQuantity } from '../../../../utils/getServiceUpdatedQuantity'
import { getVisibleServices } from '../../../../utils/getVisibleServices'

import { SetupServiceListItem } from './SetupServiceListItem'

export const SetupServiceList = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const selectedElementQuotableProducts = useAppSelector(
    cartSlice.selectors.selectedElementQuotableProducts
  )
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)

  const currentCartElementReservation = cartReservations.find(
    (reservation) =>
      reservation.spotName === selectedElement?.name &&
      reservation.spotType === selectedElement.subType
  )

  const selectedElementHasVisibleSeats = useAppSelector(
    mapSelectedElementHasVisibleSeatsSelector
  )

  const dayDifference = useDayDifference()
  const selectedElementServices = useAppSelector(mapSelectedElementServices)
  const includedServices = selectedElementServices.includedServices
  const extraServices = selectedElementServices.extraServices
  const ticketServices = selectedElementServices.ticketServices

  const visibleIncludedServices = getVisibleServices({
    selectedElementHasVisibleSeats,
    services: includedServices,
  })
  const visibleExtraServices = getVisibleServices({
    selectedElementHasVisibleSeats,
    services: extraServices,
  })
  const visibleTicketServices = getVisibleServices({
    selectedElementHasVisibleSeats,
    services: ticketServices,
  })

  const ticketGlobalLimit = useAppSelector(
    selectTicketRemainingAvailableQuantity
  )

  useEffect(() => {
    const finalizeServiceSelectedQuantity = (service: Service) => {
      const dynamicKey = getServiceKeyFromId(service?.serviceId)
      const previousQuantity =
        currentCartElementReservation?.services[dynamicKey] ||
        selectedElementQuotableProducts.services[dynamicKey]

      const calculatedQuantity = getServiceUpdatedQuantity({
        dayDifference,
        selectedSeats: selectedElementQuotedSeatsAmount,
        service,
        serviceInCartQuantity:
          currentCartElementReservation?.services[dynamicKey],
      })

      const finalQuantity = Math.max(previousQuantity, calculatedQuantity)

      if (service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES) {
        return Math.max(finalQuantity, selectedElementQuotedSeatsAmount)
      } else if (
        service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES
      ) {
        return Math.min(finalQuantity, selectedElementQuotedSeatsAmount)
      }

      return finalQuantity
    }

    const newServicesState = [
      ...includedServices,
      ...extraServices,
      ...ticketServices,
    ].reduce(
      (acc, service) => {
        const finalQuantity = finalizeServiceSelectedQuantity(service)

        acc[getServiceKeyFromId(service?.serviceId)] = finalQuantity

        return acc
      },
      {} as Record<string, number>
    )

    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts({
        ...selectedElementQuotableProducts,
        services: {
          ...selectedElementQuotableProducts.services,
          ...newServicesState,
        },
      })
    )
  }, [
    includedServices,
    extraServices,
    ticketServices,
    selectedElementQuotedSeatsAmount,
    dayDifference,
    currentCartElementReservation,
  ])

  if (!selectedElement) return null

  return (
    <>
      <div className="flex flex-col">
        {visibleIncludedServices.length > 0 && (
          <div className="flex flex-col gap-3 border-b border-solid py-3">
            <h3 className="font-bold text-primary">
              {t('map.elementDetail.includedServices')}
            </h3>
            {visibleIncludedServices.map((service) => {
              return (
                <SetupServiceListItem
                  isEditable={false}
                  key={service.serviceId}
                  service={service}
                />
              )
            })}
          </div>
        )}

        {visibleTicketServices.length > 0 && (
          <div className="flex flex-col gap-3 border-b border-solid py-3">
            <h3 className="font-bold text-primary">
              {t('map.elementDetail.ticketServices')}
            </h3>
            {visibleTicketServices.map((service) => {
              const selectedGroupServices = ticketServices
                .filter(
                  (ticket) => ticket.serviceGroupId === service.serviceGroupId
                )
                .map(
                  (ticket) =>
                    selectedElementQuotableProducts.services[
                      `id${ticket.serviceId}`
                    ]
                )

              const selectedGroupServicesQuantity =
                selectedGroupServices.reduce((acc, curr) => acc + curr)
              return (
                <SetupServiceListItem
                  isEditable={true}
                  key={service.serviceId}
                  service={{
                    ...service,
                    globalLimit: ticketGlobalLimit,
                    groupLimit:
                      service.groupLimit && service.groupLimit >= 1
                        ? service.groupLimit - selectedGroupServicesQuantity
                        : undefined,
                  }}
                />
              )
            })}
          </div>
        )}

        {visibleExtraServices.length > 0 && (
          <div className="flex flex-col gap-3 py-3">
            <h3 className="font-bold text-primary">
              {t('map.elementDetail.extraServices')}
            </h3>
            {visibleExtraServices.map((service) => (
              <SetupServiceListItem
                isEditable={true}
                key={service.serviceId}
                service={service}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
