import * as rd from '@devexperts/remote-data-ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const selectCartDetailPrices = (state: CartSliceState) => {
  if (!rd.isSuccess(state.cartDetailResponse)) return null

  const {
    totalPrice,
    totalDiscountValue,
    totalFeesValue,
    totalServicesValue,
    totalSpotsValue,
  } = state.cartDetailResponse.value.cart

  return {
    totalDiscountValue: totalDiscountValue || 0,
    totalFeesValue: totalFeesValue || 0,
    totalPrice: totalPrice || 0,
    totalServicesValue: totalServicesValue || 0,
    totalSpotsValue: totalSpotsValue || 0,
  }
}
