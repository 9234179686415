import * as rd from '@devexperts/remote-data-ts'
import { isPending, isSuccess } from '@devexperts/remote-data-ts'
import { PayloadAction } from '@reduxjs/toolkit'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import {
  createCart,
  CreateCartPayload,
  CreateCartResponse,
} from '@/api/createCart.ts'
import { payWithCard, PayWithCardPayload } from '@/api/payWithCard.ts'
import { fetchCartQuotation } from '@/api/quote.ts'
import { httpClient } from '@/lib/http/HttpClient.ts'
import { BackendErrorModel } from '@/models/backendErrorModel.ts'
import {
  CartDetailResponse,
  CartInfoParams,
  CartWithDiscountPayload,
  CartWithDiscountResponse,
  InitStripePaymentPayload,
  QuoteSetupItems,
} from '@/models/cart.ts'
import { ResponseBase } from '@/models/http.ts'
import { MapElementWithSectorId } from '@/models/map'
import { PaymentCard } from '@/models/paymentCard.ts'
import { QuoteReservation } from '@/models/quote.ts'
import { InitStripePaymentResponse } from '@/models/stripe.ts'
import { appSlice } from '@/store/appSlice.ts'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice.ts'
import { cartSliceInitialState as initialState } from '@/store/cartSlice/initialState.ts'
import { addItemToCart } from '@/store/cartSlice/reducers/addItemToCart.ts'
import { removeReservationFromCart } from '@/store/cartSlice/reducers/removeReservationFromCart.ts'
import { selectActiveElementQuotedSeatsAmount } from '@/store/cartSlice/selectors/selectActiveElementQuotedSeatsAmount.ts'
import { selectCartDetailPrices } from '@/store/cartSlice/selectors/selectCartDetailPrices.ts'
import { selectCartDetailReservations } from '@/store/cartSlice/selectors/selectCartDetailReservations.ts'
import { selectCartDetailServices } from '@/store/cartSlice/selectors/selectCartDetailServices.ts'
import { selectCartDetailTotalPrice } from '@/store/cartSlice/selectors/selectCartDetailTotalPrice.ts'
import { selectCartTotalQuantity } from '@/store/cartSlice/selectors/selectCartTotalQuantity.ts'
import { selectFormattedReservations } from '@/store/cartSlice/selectors/selectFormattedReservations.ts'
import { selectPromotionalService } from '@/store/cartSlice/selectors/selectPromotionalService.ts'
import {
  QuoteReturn,
  QuoteThunkArg,
  QuoteThunkConfig,
} from '@/store/cartSlice/types/quote.ts'
import { getOffersSum } from '@/store/cartSlice/utils/quote/getOffersSum.ts'
import { getReservationsObjectFrom } from '@/store/cartSlice/utils/quote/getReservationsObject.ts'
import { getSpotReservations } from '@/store/cartSlice/utils/quote/getSpotReservations.ts'
import { RootState } from '@/store/configureStore.ts'
import { insertPeriodSlice } from '@/store/insertPeriodSlice.ts'
import { Errors } from '@/utils/errors.ts'
import { getHashedObject } from '@/utils/getHashedObject.ts'
import { getRemoteDataThunkConfig } from '@/utils/getRemoteDataThunkConfig.ts'
import { createAppSlice } from '../createAppSlice'
import { licenseSlice } from '../licenseSlice'

export const cartSlice = createAppSlice({
  initialState,
  name: 'cart',
  reducers: (create) => ({
    /**
     * Add a new item into the cart
     */
    addItemToCart: create.reducer(addItemToCart),

    /**
     * Sends an HTTP request to confirm the payment with the provided data
     */
    confirmPayment: create.asyncThunk<
      void,
      { stripe: Stripe; elements: StripeElements; reservationId: string }
    >(async (payload, thunkAPI): Promise<void> => {
      const rootState = thunkAPI.getState() as RootState
      const license = licenseSlice.selectors.licenseCode(rootState)

      const { stripe, elements } = payload
      const { origin, searchParams } = new URL(window.location.href)

      const result = await stripe.confirmPayment({
        confirmParams: {
          return_url: `${origin}/${license}/thankYou?${searchParams.toString()}&ch=${rootState.app.channel}`,
        },
        elements,
      })

      if (result.error) {
        throw thunkAPI.rejectWithValue(result.error.message)
      }
    }, getRemoteDataThunkConfig('confirmPayment')),

    /**
     * Sends an HTTP request to create the cart based on the data inserted by
     * the user. The data is then stored in the field 'cart'.
     * RemoteData is used to keep track of all the different states of the request
     */
    createCart: create.asyncThunk<
      CreateCartResponse,
      CreateCartPayload,
      { rejectValue: string }
    >(async (payload, thunkApi): Promise<CreateCartResponse> => {
      const rootState = thunkApi.getState() as RootState
      const country = appSlice.selectors.country(rootState)
      const license = licenseSlice.selectors.licenseCode(rootState)

      const response = await createCart({
        params: { country, licenseName: license },
        payload,
      })

      if (response.status === 'error') {
        throw thunkApi.rejectWithValue(
          'code' in response.error ? response.error.code : response.error.error
        )
      }

      return response.data.result
    }, getRemoteDataThunkConfig('cart')),

    /**
     * Decrement the quantity of the item in the cart or remove it completely
     */
    decrementItemQuantityOrRemoveItem: create.reducer(
      (state, action: PayloadAction<{ id: number }>) => {
        const cartItem = state.cartItems[action.payload.id]

        if (cartItem && cartItem.quantity > 1) {
          state.cartItems[action.payload.id].quantity = cartItem.quantity - 1
        } else {
          delete state.cartItems[action.payload.id]
        }
      }
    ),

    /**
     * Sends an HTTP request to fetch the user payment cards.
     * The data is then stored in the field 'paymentCards'.
     * RemoteData is used to keep track of all the different states of the request.
     */
    fetchPaymentCards: create.asyncThunk<PaymentCard[], number>(
      async (reservationId, thunkAPI): Promise<PaymentCard[]> => {
        const rootState = thunkAPI.getState() as RootState
        const license = licenseSlice.selectors.licenseCode(rootState)
        const country = appSlice.selectors.country(rootState)

        const response = await httpClient.fetch<
          ResponseBase<{ cards: PaymentCard[] }>
        >(`beaches/${license}/carts/${reservationId}/cards`, country)

        if (response.status === 'error') {
          throw thunkAPI.rejectWithValue(response.error)
        }

        return response.data.result.cards
      },
      getRemoteDataThunkConfig('paymentCards')
    ),

    /**
     * Sends an HTTP request to fetch the data of the cart.
     * The data is then stored in the 'cartDetailResponse' field.
     * RemoteData is used to keep track of all the different states of the request
     */
    getCartDetail: create.asyncThunk<
      CartDetailResponse,
      CartInfoParams,
      { rejectValue: string }
    >(async (data, thunkAPI): Promise<CartDetailResponse> => {
      const rootState = thunkAPI.getState() as RootState
      const country = appSlice.selectors.country(rootState)
      const license = licenseSlice.selectors.licenseCode(rootState)

      let url = `beaches/${license}/carts/${data.reservationId}`

      if (data.paymentLinkId) {
        url += `?pay=${data.paymentLinkId}`
      }

      const response = await httpClient.fetch<ResponseBase<CartDetailResponse>>(
        url,
        country
      )

      if (response.status === 'error') {
        throw thunkAPI.rejectWithValue(response.error)
      }

      return response.data.result
    }, getRemoteDataThunkConfig('cartDetailResponse')),

    /**
     * Sends an HTTP request to init the stripe payment.
     * The data received is then stored in the 'stripeInitPaymentResponse' field.
     * RemoteData is used to keep track of all the different states of the request
     */
    initStripePayment: create.asyncThunk<
      InitStripePaymentResponse,
      InitStripePaymentPayload,
      { rejectValue: string }
    >(async (data, thunkAPI): Promise<InitStripePaymentResponse> => {
      const rootState = thunkAPI.getState() as RootState
      const license = licenseSlice.selectors.licenseCode(rootState)
      const country = appSlice.selectors.country(rootState)

      const { reservationId, ...payload } = data

      const response = await httpClient.fetch<
        ResponseBase<InitStripePaymentResponse>
      >(`beaches/${license}/carts/${data.reservationId}/stripe`, country, {
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      })

      if (response.status === 'error') {
        throw thunkAPI.rejectWithValue(response.error)
      }

      return response.data.result
    }, getRemoteDataThunkConfig('stripeInitPaymentResponse')),

    /**
     * Sends an HTTP request to pay with a card selected by the user.
     * The response data is then stored in the 'paymentWithCard' field.
     * RemoteData is used to keep track of all the different states of the request
     */
    payWithCard: create.asyncThunk<
      void,
      PayWithCardPayload & { reservationId: string }
    >(async (params, thunkAPI) => {
      const { reservationId, ...payload } = params

      const rootState = thunkAPI.getState() as RootState
      const license = licenseSlice.selectors.licenseCode(rootState)
      const country = appSlice.selectors.country(rootState)

      const response = await payWithCard({
        params: { country, licenseName: license, reservationId },
        payload,
      })

      if (response.status === 'error') {
        throw thunkAPI.rejectWithValue(response.error)
      }
    }, getRemoteDataThunkConfig('paymentWithCard')),

    /**
     * Get an updated price quotation for the items currently in the cart.
     */
    quote: create.asyncThunk<QuoteReturn, QuoteThunkArg, QuoteThunkConfig>(
      async (
        { isQuoteForTickets, needsToUpdateCartTotal } = {},
        thunkApi
      ): Promise<Omit<QuoteReturn, 'offer'>> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.licenseCode(rootState)
        const period = insertPeriodSlice.selectors.period(rootState)
        const bookingPeriod =
          insertPeriodSlice.selectors.bookingPeriod(rootState)
        const channel = appSlice.selectors.channel(rootState)
        const country = appSlice.selectors.country(rootState)
        const cartReservations = cartSlice.selectors.cartReservations(rootState)
        const selectedElementReservation =
          cartSlice.selectors.selectedElementReservation(rootState)
        const previousQuotePayloadHashed =
          cartSlice.selectors.previousQuotePayloadHashed(rootState)
        const quote = cartSlice.selectors.quote(rootState)

        if (!period || !bookingPeriod) {
          throw thunkApi.rejectWithValue(Errors.PeriodNotFound)
        }

        const spotReservations = getSpotReservations({
          cartReservations,
          selectedElementReservation,
        })

        const currentQuotePayload = {
          channel,
          reservations: getReservationsObjectFrom({
            isQuoteForTickets,
            reservations: spotReservations as QuoteReservation[],
          }),
        }

        const currentQuotePayloadHashed = getHashedObject(currentQuotePayload)

        // This avoids repeating identical API calls
        if (
          currentQuotePayloadHashed === previousQuotePayloadHashed &&
          rd.isSuccess(quote)
        ) {
          return quote.value as Omit<QuoteReturn, 'offer'>
        }

        const res = await fetchCartQuotation({
          country,
          licenseName: license,
          payload: currentQuotePayload,
        })

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        thunkApi.dispatch(
          cartSlice.actions.setPreviousQuotePayloadHash(
            currentQuotePayloadHashed
          )
        )

        return {
          ...res.data.result,
          isQuoteForTickets,
          needsToUpdateCartTotal,
        }
      },
      {
        ...getRemoteDataThunkConfig('quote'),
        fulfilled: (state, action) => {
          state.quote = rd.success({
            ...action.payload,
            offer: getOffersSum(action.payload.offer),
          })
          if (
            action.payload.isQuoteForTickets ||
            action.payload.needsToUpdateCartTotal
          ) {
            state.fees = action.payload.fees
            state.total = action.payload.totalPrice
          }
        },
      }
    ),

    /**
     * Sends an HTTP request to fetch the quotation of the cart with a
     * discount code applied. The data is then stored in the 'quoteWithDiscount'
     * field. RemoteData is used to keep track of all the different states of the request
     */
    quoteWithDiscount: create.asyncThunk<
      CartWithDiscountResponse,
      CartWithDiscountPayload,
      { rejectValue: BackendErrorModel | { code: string } }
    >(async (data, thunkAPI): Promise<CartWithDiscountResponse> => {
      const rootState = thunkAPI.getState() as RootState
      const license = licenseSlice.selectors.licenseCode(rootState)
      const country = appSlice.selectors.country(rootState)

      const response = await httpClient.fetch<
        ResponseBase<CartWithDiscountResponse>,
        BackendErrorModel
      >(`beaches/${license}/carts/quote-with-discount`, country, {
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      })

      if (response.status === 'error') {
        throw thunkAPI.rejectWithValue(response.error)
      }

      return response.data.result
    }, getRemoteDataThunkConfig('quoteWithDiscount')),

    /**
     * Removes a reservation from the cart
     */
    removeCartReservation: create.reducer(removeReservationFromCart),

    /**
     * Reset the state of the cartSlice to its initialState
     * useful when the user goes back to the /chooseProduct or /insertPeriod page
     * and we need to empty their cart
     */
    resetCartSlice: create.reducer(() => initialState),

    /**
     * Resets the data contained in the 'quoteWithDiscount' field and the
     * discountCode value
     */
    resetDiscount: create.reducer((state) => {
      state.quoteWithDiscount = rd.initial
      state.discountCode = ''
    }),

    /**
     * Sets the "selectedBookingType" field with the data sent in the payload
     */
    setBookingType: create.reducer(
      (state, action: PayloadAction<BookingAvailability>) => {
        state.activeBookingType = action.payload
      }
    ),

    /**
     * Set a cart item to the payload sent in the action
     */
    setCartItem: create.reducer(
      (state, action: PayloadAction<{ id: number; quantity: number }>) => {
        state.cartItems[action.payload.id] = action.payload
      }
    ),

    /**
     * Updates the state of the cartPopoverIsOpen flag
     */
    setCartPopoverIsOpen: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.cartPopoverIsOpen = action.payload
      }
    ),

    /**
     * Sets the field with the provided data
     */
    setCartReservations: create.reducer(
      (
        state,
        action: PayloadAction<
          (Omit<QuoteReservation, 'spotType' | 'spotName'> & {
            spotType: MapElementWithSectorId['subType'] | null
            spotName: string | null
          })[]
        >
      ) => {
        state.cartReservations = action.payload as QuoteReservation[]
      }
    ),

    /**
     * Sets the field with the provided string
     */
    setDiscountCode: create.reducer((state, action: PayloadAction<string>) => {
      state.discountCode = action.payload
    }),

    setIsCartPaid: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isCartPaid = action.payload
    }),

    /**
     * Sets the field with the provided data
     */
    setIsWeatherInsuranceSelected: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.isWeatherInsuranceAddedToCart = action.payload
      }
    ),

    /**
     * Sets the field with the provided string
     */
    setPreviousQuotePayloadHash: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.previousQuotePayloadHashed = action.payload
      }
    ),

    /**
     * Sets the field with the provided data
     * in this case, we update the selectedElement
     * services and seats (beds, chairs, deckChairs and maxiBeds) quantity
     * everytime something changes in the configuration of selectedElement
     * a new /quote action is dispatched
     */
    setSelectedElementQuotableProducts: create.reducer(
      (state, action: PayloadAction<QuoteSetupItems>) => {
        state.selectedElementQuotableProducts = action.payload
      }
    ),

    /**
     * Sets the field with the provided data,
     * in this case we define the reservation associated with the current
     * map/sector element
     */
    setSelectedElementReservation: create.reducer(
      (state, action: PayloadAction<QuoteReservation | null>) => {
        state.selectedElementReservation = action.payload
      }
    ),
  }),
  selectors: {
    cart: (state) => state.cart,
    cartDetail: (state) => state.cartDetailResponse,
    cartDetailPrices: selectCartDetailPrices,
    cartDetailReservations: selectCartDetailReservations,
    cartDetailServices: selectCartDetailServices,
    cartDetailTotalPrice: selectCartDetailTotalPrice,
    cartPopoverIsOpen: (state) => state.cartPopoverIsOpen,
    cartReservations: (state) => state.cartReservations,
    discountCode: (state) => state.discountCode,
    fees: (state) => state.fees,
    formattedReservations: selectFormattedReservations,
    isCartPaid: (state) => state.isCartPaid,
    isCreateCartPending: (state) => rd.isPending(state.cart),
    isPayingWithCard: (state) => isPending(state.paymentWithCard),
    isPayingWithStripe: (state) => isPending(state.confirmPayment),
    isQuoteOrDiscountQuotePending: (state) =>
      rd.isPending(state.quote) || rd.isPending(state.quoteWithDiscount),
    isQuotePending: (state) => isPending(state.quote),
    isQuoteSuccessful: (state) => isSuccess(state.quote),
    isWeatherInsuranceAddedToCart: (state) =>
      state.isWeatherInsuranceAddedToCart,
    itemById: (state, id: number) => state.cartItems[id],
    items: (state) => state.cartItems,
    itemsIds: (state) => Object.values(state.cartItems).map((item) => item.id),
    payWithCard: (state) => state.paymentWithCard,
    paymentCards: (state) => state.paymentCards,
    previousQuotePayloadHashed: (state) => state.previousQuotePayloadHashed,
    promotionalService: selectPromotionalService,
    quote: (state) => state.quote,
    quoteFees: (state) =>
      rd.isSuccess(state.quote) ? state.quote.value.fees : 0,
    quoteTotal: (state) =>
      rd.isSuccess(state.quote) ? state.quote.value.totalPrice : 0,
    quoteWithDiscount: (state) => state.quoteWithDiscount,
    reservationPrices: (state) =>
      rd.isSuccess(state.quote) ? state.quote.value.reservationPrices : {},
    selectedBookingType: (state) => state.activeBookingType,
    selectedElementQuotableProducts: (state) =>
      state.selectedElementQuotableProducts,
    selectedElementQuotedSeatsAmount: selectActiveElementQuotedSeatsAmount,
    selectedElementReservation: (state) => state.selectedElementReservation,
    stripePaymentInfo: (state) => state.stripeInitPaymentResponse,
    total: (state) => state.total,
    totalQuantity: selectCartTotalQuantity,
  },
})
