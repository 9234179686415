import { cn } from '@spiaggeit/spit-ui'
import React, { ComponentPropsWithoutRef, forwardRef, useId } from 'react'

export interface RadioProps extends ComponentPropsWithoutRef<'span'> {
  isSelected: boolean
  isRequired?: boolean
  label?: string
  value?: string
  visualOnly?: boolean
  onChange?(): void
  onSelectNext?: () => void
  onSelectPrev?: () => void
}

export const Radio = forwardRef<HTMLDivElement, RadioProps>(
  function ForwardedRadio(props, ref) {
    const {
      isSelected,
      isRequired = false,
      visualOnly = false,
      className,
      label,
      onSelectPrev,
      onSelectNext,
      onChange,
      value,
      ...rest
    } = props

    const labelId = useId()

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (['Space', 'NumpadEnter', 'Enter'].includes(e.code)) {
        return onChange?.()
      }

      if (['ArrowRight', 'ArrowDown'].includes(e.code)) {
        return onSelectNext?.()
      }

      if (['ArrowLeft', 'ArrowUp'].includes(e.code)) {
        return onSelectPrev?.()
      }
    }

    return (
      <div
        className={cn(className, {
          'flex cursor-pointer items-center gap-1': !!label,
        })}
        {...(!visualOnly && { onClick: onChange, onKeyDown: handleKeyDown })}
      >
        <span
          {...rest}
          {...(label && { 'aria-labelledby': labelId })}
          className="group flex h-5 w-5 items-center justify-center rounded-circle border-2 border-gray-300 transition-colors aria-checked:border-blue-500 data-[checked=true]:border-blue-500"
          {...(visualOnly
            ? { 'aria-hidden': true, 'data-checked': isSelected }
            : {
                'aria-checked': isSelected,
                'aria-required': isRequired,
                ref,
                role: 'radio',
                tabIndex: isSelected ? 0 : -1,
                ...(value && { 'data-value': value }),
              })}
        >
          <span className="h-2.5 w-2.5 rounded-circle transition-colors group-aria-checked:bg-blue-500 group-data-[checked=true]:bg-blue-500" />
        </span>
        {label && <span id={labelId}>{label}</span>}
      </div>
    )
  }
)
