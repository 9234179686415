import * as rd from '@devexperts/remote-data-ts'
import { RemoteData } from '@devexperts/remote-data-ts'

import { resetAllState } from '@/store/commonActions.ts'
import * as remoteDataUtils from '@/utils/remoteData.ts'
import { httpClient } from '../lib/http/HttpClient.ts'
import { Country } from '../models/app.ts'
import { ResponseBase } from '../models/http.ts'
import { getHalfDayValueFromBookingPeriod } from '../utils/halfDay.ts'

import { appSlice } from './appSlice.ts'
import { BookingAvailability } from './bookingAvailabilitySlice.ts'
import { RootState } from './configureStore.ts'
import { createAppSlice } from './createAppSlice.ts'
import { PeriodSliceState } from './insertPeriodSlice.ts'
import { licenseSlice } from './licenseSlice/index.tsx'

interface ExtraState {
  weatherInsurancePricePreview: RemoteData<unknown, number>
  isWeatherInsuranceInitiallyVisible?: boolean
}

const initialState: ExtraState = {
  weatherInsurancePricePreview: rd.initial,
}

export const selectIsProgressBarPending = (state: RootState) => {
  const priceRemoteData = state.extra.weatherInsurancePricePreview
  const servicesRemoteData = state.services.services
  const mapData = state.map.mapData
  const selectedBookingType = state.cart.activeBookingType

  if (selectedBookingType === BookingAvailability.TICKETS) {
    return rd.isPending(priceRemoteData) || rd.isPending(servicesRemoteData)
  }

  return (
    rd.isPending(priceRemoteData) ||
    rd.isPending(servicesRemoteData) ||
    rd.isPending(mapData)
  )
}

export const selectIsExtraStepVisible = (state: RootState) => {
  const priceRemoteData = state.extra.weatherInsurancePricePreview
  const servicesRemoteData = state.services.services
  const featuredServices = state.services.featuredServices
  const selectedBookingType = state.cart.activeBookingType
  if (selectedBookingType === BookingAvailability.TICKETS) {
    return (
      remoteDataUtils.onSuccess(priceRemoteData, (price) => price > 0) ||
      remoteDataUtils.onSuccess(
        featuredServices,
        (extraServicesLimits) => extraServicesLimits.length > 0
      )
    )
  }
  return (
    remoteDataUtils.onSuccess(priceRemoteData, (price) => price > 0) ||
    remoteDataUtils.onSuccess(
      servicesRemoteData,
      ({ extraFeaturedServices }) => extraFeaturedServices.length > 0
    )
  )
}

const DEFAULT_PRICE_PREVIEW_AMOUNT = 50

export const extraSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)
  },
  initialState,
  name: 'extra',
  reducers: (create) => ({
    fetchWeatherInsurancePricePreview: create.asyncThunk<
      FetchPricePreviewResponse,
      { amount?: number } | undefined
    >(
      async ({ amount = DEFAULT_PRICE_PREVIEW_AMOUNT } = {}, thunkAPI) => {
        const rootState = thunkAPI.getState() as RootState
        const country = appSlice.selectors.country(rootState)
        const license = licenseSlice.selectors.licenseCode(rootState)

        const res = await fetchPricePreview({
          amount,
          country,
          insertPeriod: rootState.insertPeriod,
          license,
        })

        if (res.status === 'error') {
          throw thunkAPI.rejectWithValue(res.error)
        }

        return res.data
      },
      {
        fulfilled: (state, action) => {
          state.weatherInsurancePricePreview = rd.success(
            action.payload.result.price
          )
          state.isWeatherInsuranceInitiallyVisible =
            action.payload.result.price > 0
        },
        pending: (state) => {
          state.weatherInsurancePricePreview = rd.pending
        },
        rejected: (state, action) => {
          state.weatherInsurancePricePreview = rd.failure(action.payload)
        },
      }
    ),
  }),
  selectors: {
    isWeatherInsuranceInitiallyVisible: (state) =>
      state.isWeatherInsuranceInitiallyVisible,
    weatherInsurancePricePreview: (state) => state.weatherInsurancePricePreview,
  },
})

interface FetchPricePreviewProps {
  insertPeriod: PeriodSliceState
  license: string
  amount?: number
  country: Country
}

type FetchPricePreviewResponse = ResponseBase<{ price: number }>

export const fetchPricePreview = (props: FetchPricePreviewProps) => {
  const { license, country, insertPeriod } = props
  const { period, bookingPeriod } = insertPeriod

  return httpClient.fetch<FetchPricePreviewResponse>(
    `beaches/${license}/weather-insurance/price-preview`,
    country,
    {
      body: JSON.stringify({
        amount: props.amount,
        from: new Date(period?.start || Date.now()).getTime() / 1000,
        halfDay: getHalfDayValueFromBookingPeriod(bookingPeriod),
        to: new Date(period?.end || Date.now()).getTime() / 1000,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }
  )
}
