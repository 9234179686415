import { Country } from '@/models/app'
import { getAppLanguage } from '@/utils/localeUtils'

export type HttpClientResponse<T, E> =
  | {
      status: 'success'
      data: T
    }
  | {
      error: E
      status: 'error'
    }

export class HttpClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async fetch<T, E = any>(
    url: string,
    country: Country,
    config?: RequestInit,
    isText?: boolean
  ): Promise<HttpClientResponse<T, E | { code: string }>> {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/${url}`,
        {
          ...config,
          headers: {
            ...(config?.headers ? config.headers : {}),
            'Accept-Language': getAppLanguage(),
            'Locale-Country': country,
          },
          method: config?.method ?? 'GET',
        }
      )

      if (!response.ok) {
        return {
          error: await response.json(),
          status: 'error',
        }
      }

      return {
        data: await (isText ? response.text() : response.json()),
        status: 'success',
      }
    } catch (error) {
      return {
        error: {
          code: error instanceof Error ? error.message : 'Unknown error',
        } as unknown as E,
        status: 'error',
      }
    }
  }
}

export const httpClient = new HttpClient()
