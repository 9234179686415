import * as rd from '@devexperts/remote-data-ts'
import { Button } from '@spiaggeit/spit-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CartFooter } from '@/components/CartFooter'
import { LoaderTrigger } from '@/components/LoaderTrigger'
import { SelectedElementDrawer } from '@/components/SelectedElementDrawer'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useCheckBookingSettingsChange } from '@/hooks/useCheckBookingSettingsChange'
import { useFetchPricePreview } from '@/hooks/useFetchPricePreview.ts'
import { useResetInsertInsuranceProducts } from '@/hooks/useResetInsertInsurance'
import { LastSpotDialog } from '@/routes/Map/LastSpotDialog.tsx'
import { UnavailableSpotsDialog } from '@/routes/Map/UnavailableSpotsDialog.tsx'
import { useMapUtils } from '@/routes/Map/useMapUtils.ts'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { extraSlice } from '@/store/extraSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { mapSelectors, mapSlice } from '@/store/mapSlice.ts'

import { servicesSlice } from '@/store/servicesSlice.ts'
import { FirstVisitDialog } from './FirstVisitDialog'
import { LegendDialog } from './LegendDialog'

export const MapRoute = () => {
  const { t } = useTranslation()
  const utils = useMapUtils()
  const dispatch = useAppDispatch()

  const selectedItemsIds = useAppSelector(cartSlice.selectors.itemsIds)

  const mapRemoteData = useAppSelector(mapSelectors.mapRemoteData)
  const services = useAppSelector(servicesSlice.selectors.services)

  const weatherInsurancePrice = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )

  const license = useAppSelector(licenseSlice.selectors.license)

  const areRequestsSuccessful =
    rd.isSuccess(services) &&
    rd.isSuccess(mapRemoteData) &&
    rd.isSuccess(weatherInsurancePrice)

  const selectedElement = useAppSelector(mapSelectors.selectedElement)

  const [isUnavailableSpotsDialogOpen, setIsUnavailableSpotsDialogOpen] =
    useState(false)

  const [isLastSpotDialogOpen, setIsLastSpotDialogOpen] = useState(false)

  const { resetInsertInsuranceProducts } = useResetInsertInsuranceProducts()

  useFetchPricePreview()

  useEffect(() => {
    /* Load initial map data required for the page */
    void utils.loadInitialData()

    /*resets previosly added extra services and weather insurance
    (in case user is coming back from 'insertInsurance' page)*/
    resetInsertInsuranceProducts()
  }, [])

  useEffect(() => {
    if (areRequestsSuccessful) {
      void utils.initMap()
    }
  }, [areRequestsSuccessful])

  useEffect(() => {
    if (!areRequestsSuccessful || !utils.isCanvasReady) return
    utils.highlightSelectedItems()
  }, [utils.isCanvasReady, areRequestsSuccessful, selectedItemsIds])

  useEffect(() => {
    if (!utils.isCanvasReady) return

    /* Reset canvas height on resize to force recalculations */
    window.addEventListener('resize', utils.resetCanvasHeightHandler)

    return () =>
      window.removeEventListener('resize', utils.resetCanvasHeightHandler)
  }, [utils.isCanvasReady])

  useEffect(() => {
    /* Open dialogs depending on the number of spots available */
    if (!areRequestsSuccessful) return

    if (utils.availableSpots.areNone) {
      setIsUnavailableSpotsDialogOpen(true)
    } else if (utils.availableSpots.isOnlyOne) {
      setIsLastSpotDialogOpen(true)
    }
  }, [
    areRequestsSuccessful,
    utils.availableSpots.areMoreThanOne,
    utils.availableSpots.isOnlyOne,
  ])

  useEffect(() => {
    dispatch(cartSlice.actions.setBookingType(BookingAvailability.SPOTS))
  }, [])

  const { checkBookingAvailabilityChange } = useCheckBookingSettingsChange()

  useEffect(() => {
    checkBookingAvailabilityChange()
  }, [license])

  const handleSelectLastSpot = () => {
    if (!utils.availableSpots.array) return
    dispatch(mapSlice.actions.selectMapElement(utils.availableSpots.array[0]))
    setIsLastSpotDialogOpen(false)
  }

  if (!areRequestsSuccessful) {
    return <LoaderTrigger message={t('map.loading')} />
  }

  return (
    <>
      <main className="relative flex flex-1 flex-col">
        <div className="max-w-full flex-1 overflow-hidden" id="canvas"></div>

        {selectedElement && <SelectedElementDrawer />}

        <span className="fixed left-4 top-32 z-20 lg:hidden">
          <LegendDialog />
        </span>

        {!utils.userHasVisitedMapBefore &&
          utils.availableSpots.areMoreThanOne && <FirstVisitDialog />}

        <UnavailableSpotsDialog
          isOpen={isUnavailableSpotsDialogOpen}
          setIsOpen={setIsUnavailableSpotsDialogOpen}
        />

        <LastSpotDialog
          isOpen={isLastSpotDialogOpen}
          setIsOpen={setIsLastSpotDialogOpen}
        >
          <Button onClick={handleSelectLastSpot}>
            {t('map.lastAvailableSpotDialog.seeAvailableElement')}
          </Button>
        </LastSpotDialog>
      </main>

      <CartFooter zoomIn={utils.zoomIn} zoomOut={utils.zoomOut} />
    </>
  )
}
