import { useMemo } from 'react'

import { formatDateRange } from '../utils/formatDateRange.ts'

export const useFormattedDateRange = (startDate: string, endDate: string) => {
  return useMemo(() => {
    const [locale] = window.navigator.language.split('-')

    return formatDateRange(startDate, endDate, locale)
  }, [startDate, endDate])
}
