import { useTranslation } from 'react-i18next'

import { Minus } from '../../assets/icons/Minus.tsx'
import { Plus } from '../../assets/icons/Plus.tsx'
import { IconButton } from '../IconButton'

interface InputQuantityProps {
  max?: number
  productName: string
  quantity: number
  quantityChange: (quantity: number) => void
}

export const InputQuantity = (props: InputQuantityProps) => {
  const { t } = useTranslation()

  const decrement = () => {
    props.quantityChange(Math.max(0, props.quantity - 1))
  }

  const increment = () => {
    const { quantityChange, max, quantity } = props
    const increasedQuantity = quantity + 1

    if (!max) {
      return quantityChange(increasedQuantity)
    }

    quantityChange(Math.min(max, increasedQuantity))
  }

  const quantityIsZero = props.quantity === 0
  const quantityIsMax = !!props.max && props.quantity === props.max

  return (
    <div
      aria-label={t('a11y.quantitySelector', { name: props.productName })}
      className="flex items-center"
      role="group"
    >
      <IconButton
        ariaLabel={t('a11y.reduceQuantity')}
        icon={Minus}
        isDisabled={quantityIsZero}
        onClick={decrement}
      />
      <span
        aria-atomic="true"
        aria-live="polite"
        className="mx-4 text-sm font-bold leading-4"
      >
        {props.quantity}
      </span>
      <IconButton
        ariaLabel={t('a11y.increaseQuantity')}
        icon={Plus}
        isDisabled={quantityIsMax}
        onClick={increment}
      />
    </div>
  )
}
