import { HTMLInputTypeAttribute, ReactNode } from 'react'
import { UseControllerProps } from 'react-hook-form'
import { Checkbox } from '@/components/Checkbox'
import { Combobox, CommandFilter } from '@/components/Combobox'
import { Input } from '@/components/Input'

import { Option } from '@/models/option.ts'

import { Fieldset, FieldsetProps } from '../Fieldset'
import { PhoneInput } from '../PhoneInput'
import { RadioGroup } from '../RadioGroup'

export type FormSectionConfig = {
  name: string
  label: string
  helperText?: ReactNode
  className?: string
  rules?: UseControllerProps['rules']
} & (
  | {
      type?: 'text'
      placeholder: string
      pattern?: string
      inputType?: HTMLInputTypeAttribute
    }
  | { type: 'checkbox' }
  | { type: 'tel'; prefixName: string }
  | {
      type: 'radio-group'
      options: Option[]
    }
  | {
      filterPlaceholder: string
      selectPlaceholder: string
      type: 'autocomplete-select'
      options: Option[]
      buttonLabel: string
      filter?: CommandFilter
      noOptionFoundLabel?: string
    }
)

interface FormSectionProps extends FieldsetProps {
  inputs: FormSectionConfig[]
}

export const FormSection = (props: FormSectionProps) => {
  const { inputs, ...fieldsetProps } = props

  return (
    <Fieldset {...fieldsetProps}>
      <div className="grid grid-cols-2 gap-4">
        {inputs.map((input) => (
          <div className={input.className} key={input.name}>
            {input.type === 'autocomplete-select' && (
              <Combobox
                filter={input.filter}
                filterPlaceholder={input.filterPlaceholder}
                label={input.label}
                name={input.name}
                noOptionFoundLabel={input.noOptionFoundLabel}
                options={input.options}
                rules={input.rules}
                selectPlaceholder={input.selectPlaceholder}
              />
            )}

            {input.type === 'tel' && (
              <PhoneInput
                label={input.label}
                name={input.name}
                prefixName={input.prefixName}
                rules={input.rules}
              />
            )}

            {input.type === 'radio-group' && (
              <RadioGroup
                label={input.label}
                name={input.name}
                options={input.options}
                rules={input.rules}
              />
            )}

            {input.type === 'checkbox' && (
              <Checkbox
                label={input.label}
                name={input.name}
                rules={input.rules}
              />
            )}

            {(!input.type || input.type === 'text') && (
              <Input
                helperText={input.helperText}
                label={input.label}
                name={input.name}
                pattern={input.pattern}
                placeholder={input.placeholder}
                rules={input.rules}
                type={input.inputType}
              />
            )}
          </div>
        ))}
      </div>
    </Fieldset>
  )
}
