import { t } from 'i18next'

import { ServiceLimitType } from '../models/service'

export enum ItemQuantityBoundary {
  MIN = 'min',
  MAX = 'max',
}
export const getItemQuantityLabel = ({
  boundary,
  flatLimitAmount,
  limitType,
}: {
  boundary: ItemQuantityBoundary
  flatLimitAmount?: number
  limitType?: ServiceLimitType
}) => {
  const localeObject = `map.elementDetail.serviceQuantity.${boundary}`
  if (limitType === ServiceLimitType.NUMBER_OF_PIECES) {
    return t(`${localeObject}.numberOfPieces`)
  } else if (limitType === ServiceLimitType.NUMBER_OF_DAYS) {
    return t(`${localeObject}.numberOfDays`)
  } else if (limitType === ServiceLimitType.FLAT) {
    return t(`${localeObject}.flat`, { count: flatLimitAmount })
  } else return undefined
}
