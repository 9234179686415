import { isPending, isSuccess } from '@devexperts/remote-data-ts'
import { Button } from '@spiaggeit/spit-ui'
import { type KeyboardEvent, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@/components/Checkbox'
import { ExpandedSection } from '@/components/ExpandedSection'
import { Fieldset } from '@/components/Fieldset'
import { Input } from '@/components/Input'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { InsertDataFormType } from '@/hooks/useUserDataForm.ts'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { extraSlice } from '@/store/extraSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { isBackendErrorResponse } from '@/utils/typeGuards.ts'

export const PromoFormSection = () => {
  const discountCode = useWatch({ name: 'promo.discountCode' })
  const isKiosk = useAppSelector(appSlice.selectors.isKiosk)
  const reduxDiscountCode = useAppSelector(cartSlice.selectors.discountCode)
  const isDiscountCodeVisible = useAppSelector(
    licenseSlice.selectors.isDiscountCodeVisible
  )

  const [showDiscountCode, setShowDiscountCode] = useState(
    !!discountCode || !!reduxDiscountCode
  )

  const isWeatherInsuranceAdded = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const quote = useAppSelector(cartSlice.selectors.quote)
  const quoteWithDiscount = useAppSelector(
    cartSlice.selectors.quoteWithDiscount
  )

  const { getValues, setError, clearErrors } =
    useFormContext<InsertDataFormType>()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [warningMessage, setWarningMessage] = useState<string | null>(null)
  const [isDiscountCodeValidated, setIsDiscountCodeValidated] = useState(false)
  const formattedReservations = useAppSelector(
    cartSlice.selectors.formattedReservations
  )

  const { status, message } = useMemo(() => {
    if (errorMessage) {
      return { message: errorMessage, status: 'error' } as const
    }

    if (warningMessage) {
      return { message: warningMessage, status: 'warning' } as const
    }

    if (isDiscountCodeValidated) {
      return { status: 'valid' } as const
    }

    return { status: 'idle' } as const
  }, [errorMessage, warningMessage, isDiscountCodeValidated])

  const handleRemoveDiscountCode = () => {
    dispatch(cartSlice.actions.resetDiscount())

    if (isWeatherInsuranceAdded && isSuccess(quote)) {
      dispatch(
        extraSlice.actions.fetchWeatherInsurancePricePreview({
          amount: quote.value.totalPrice,
        })
      )
    }

    setIsDiscountCodeValidated(false)
    inputRef.current?.focus()
  }

  const handleSubmitDiscountCode = async () => {
    const discountCode = getValues('promo.discountCode')

    try {
      dispatch(cartSlice.actions.setDiscountCode(discountCode))

      const quote = await dispatch(
        cartSlice.actions.quoteWithDiscount({
          discountCode,
          reservations: formattedReservations,
        })
      ).unwrap()

      let showWarningCondition = false

      if (isWeatherInsuranceAdded) {
        const weatherInsurance = await dispatch(
          extraSlice.actions.fetchWeatherInsurancePricePreview({
            amount: quote.totalPrice,
          })
        ).unwrap()

        showWarningCondition =
          !!weatherInsurance.error || !weatherInsurance.result.price
      }

      if (showWarningCondition) {
        setWarningMessage(t('validation.invalidDiscountWeatherInsurance'))

        dispatch(cartSlice.actions.setIsWeatherInsuranceSelected(false))
      } else {
        setWarningMessage(null)
        setIsDiscountCodeValidated(true)
      }

      clearErrors('promo.discountCode')
      setErrorMessage(null)
    } catch (error) {
      if (isBackendErrorResponse(error)) {
        setIsDiscountCodeValidated(false)

        const errorMessage =
          error.error_ex?.message ||
          t('genericError', {
            error: error.error,
          })

        setError('promo.discountCode', {
          message: errorMessage,
          type: 'error',
        })

        setErrorMessage(errorMessage)
      }
    }
  }

  const validateDiscountCode = (value: string) => {
    if (!value) return true

    if (status === 'error') {
      return message
    }

    return true
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (['NumpadEnter', 'Enter'].includes(event.code)) {
      event.preventDefault()
      handleSubmitDiscountCode()
    }
  }

  const handleShowSection = () => {
    inputRef.current?.focus()
    setShowDiscountCode(true)
  }

  const isDiscountCodeApplied = isSuccess(quoteWithDiscount)

  if (isKiosk && !isDiscountCodeVisible) {
    return null
  }

  return (
    <Fieldset
      className="flex flex-col gap-4"
      title={t('insertDataForm.promo.title')}
    >
      {!isKiosk ? (
        <Checkbox
          label={t('insertDataForm.promo.newsletter')}
          name="promo.newsletter"
        />
      ) : null}

      {isDiscountCodeVisible ? (
        <ExpandedSection
          isVisible={showDiscountCode}
          onShowSection={handleShowSection}
          sectionAriaLabel={t('insertDataForm.promo.insertDiscountCode')}
          text={t('insertDataForm.promo.discountCodeText')}
          tooltipText={t('a11y.applyDiscountCode')}
        >
          <Input
            applyButton={
              <Button
                className="btn-outline-secondary--loading relative px-4 py-2.5"
                color="outline-secondary"
                isLoading={isPending(quoteWithDiscount)}
                onClick={
                  isDiscountCodeApplied
                    ? handleRemoveDiscountCode
                    : handleSubmitDiscountCode
                }
                tabIndex={showDiscountCode ? 0 : -1}
                type="button"
              >
                {t(isDiscountCodeApplied ? 'common.remove' : 'common.apply')}
              </Button>
            }
            isReadOnly={isDiscountCodeApplied}
            label={t('insertDataForm.promo.discountCode')}
            name="promo.discountCode"
            onKeyDown={handleKeyDown}
            placeholder="ABCDEFGH"
            ref={(el: HTMLInputElement) => (inputRef.current = el)}
            rules={{
              validate: validateDiscountCode,
            }}
            tabIndex={showDiscountCode ? 0 : -1}
            {...(status === 'valid' && {
              validMessage: t('validation.validDiscountCode'),
            })}
            {...(status === 'warning' && { warning: message })}
          />
        </ExpandedSection>
      ) : null}
    </Fieldset>
  )
}
