import { cn } from '@spiaggeit/spit-ui'
import { useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ExpandedTextProps {
  text: string
  label?: string
}

export const ExpandedText = (props: ExpandedTextProps) => {
  const { t } = useTranslation()
  const textId = useId()
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  const textRef = useRef<HTMLParagraphElement | null>(null)

  const toggleTextExpand = () => {
    setIsTextExpanded((isExpanded) => !isExpanded)
  }

  const buttonLabelI18nKey = isTextExpanded
    ? 'common.showLess'
    : 'common.readMore'

  const buttonTooltipI18nKey = isTextExpanded
    ? 'common.hideExtraContent'
    : 'common.showHiddenContent'

  return (
    <div>
      <p
        className={cn('mr-2 inline text-secondary', {
          'line-clamp-1': !isTextExpanded,
        })}
        ref={textRef}
        {...(!isTextExpanded && {
          title: props.text,
        })}
      >
        {props.label && (
          <span className="font-bold text-black">{props.label}:&nbsp;</span>
        )}
        {props.text}
      </p>

      <button
        aria-controls={textId}
        aria-expanded={isTextExpanded}
        className="inline font-bold leading-5 text-blue-500"
        onClick={toggleTextExpand}
        title={t(buttonTooltipI18nKey)}
        type="button"
      >
        {t(buttonLabelI18nKey)}
      </button>
    </div>
  )
}
