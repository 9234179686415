import { PayloadAction } from '@reduxjs/toolkit'
import { MapElementWithSectorId } from '@/models/map.ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const removeReservationFromCart = (
  state: CartSliceState,
  action: PayloadAction<MapElementWithSectorId | undefined>
) => {
  const element = action.payload
  const cartReservations = state.cartReservations

  if (!cartReservations || !element) {
    return
  }

  const reservationToRemove = cartReservations.find((item) => {
    return item.spotName === element?.name && item.spotType === element.subType
  })

  if (!reservationToRemove) {
    return
  }

  state.cartReservations = state.cartReservations?.filter((item) => {
    return (
      String(item.spotName + item.spotType) !==
      String(reservationToRemove.spotName + reservationToRemove.spotType)
    )
  })
}
