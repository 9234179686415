import phoneMetadata from 'libphonenumber-js/metadata.min.json'

export const getLocalPhonePrefix = () => {
  const languageCode = window.navigator.language.split('-')
  const [localeCode, countryCode] = languageCode

  // the navigator.language array of languages like french do not have a country
  // code so the first value destructured from the languageCode array is taken
  // only when the countryCode does not exist.
  const code = countryCode || localeCode

  try {
    const [phonePrefix] = (phoneMetadata.countries as Record<string, string>)[
      code.toUpperCase()
    ]

    return `+${phonePrefix}`
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)

    // Return italian prefix as a fallback code in case of errors
    return '+39'
  }
}
