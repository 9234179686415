import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { KioskTimeoutDialog } from '@/components/Dialog/KioskTimeoutDialog'
import { GridWithRecap } from '@/components/GridWithRecap'
import { ReservationFooter } from '@/components/Reservation/Footer'
import { ReservationRecapCard } from '@/components/ReservationRecapCard'
import { ReservationRecapDetails } from '@/components/ReservationRecapDetails'

import {
  INSERT_PERIOD_PATH,
  PAY_PATH,
  PAYMENT_KIOSK_PATH,
} from '@/app/router/paths.ts'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { useCartRecapData } from '@/hooks/useCartRecapData.ts'
import { useFetchPricePreview } from '@/hooks/useFetchPricePreview.ts'
import { useLoadServices } from '@/hooks/useLoadServices.ts'
import { InsertDataFormType } from '@/hooks/useUserDataForm.ts'
import { InfoFormSection } from '@/routes/InsertData/InfoFormSection'
import { PromoFormSection } from '@/routes/InsertData/PromoFormSection'
import { useFormatPayload } from '@/routes/InsertData/useFormatPayload.ts'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice'
import { ONE_MINUTE, REG_ID_QUERY_PARAM_NAME } from '@/utils/constants.ts'
import { isBackendErrorResponse } from '@/utils/typeGuards.ts'
import { DogFormSection } from './DogFormSection'
import { InvoiceFormSection } from './InvoiceFormSection'
import { UserFormSection } from './UserFormSection'

export const InsertData = () => {
  const formatPayload = useFormatPayload()
  const { handleSubmit } = useFormContext<InsertDataFormType>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isKiosk = useAppSelector(appSlice.selectors.isKiosk)
  const payPath = useAppPath(PAY_PATH)
  const kioskPayPath = useAppPath(PAYMENT_KIOSK_PATH)
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const { total } = useCartRecapData()

  useLoadServices()
  useFetchPricePreview()

  const handlePayment = async (formData: InsertDataFormType) => {
    try {
      const response = await dispatch(
        cartSlice.actions.createCart(formatPayload(formData))
      ).unwrap()

      navigate(
        isKiosk
          ? {
              pathname: kioskPayPath,
              search: `?${REG_ID_QUERY_PARAM_NAME}=${response.cart.order}&token=${import.meta.env.VITE_STRIPE_PUBLIC_KEY}`,
            }
          : {
              pathname: payPath,
              search: `?${REG_ID_QUERY_PARAM_NAME}=${response.cart.order}`,
            }
      )
    } catch (e) {
      if (isBackendErrorResponse(e)) {
        switch (e.internal_error_code) {
          case 'INTERNAL_MANAGED_ERROR_EXCEPTION_I_R_001':
          case 'INTERNAL_MANAGED_ERROR_EXCEPTION_I_R_010':
          case 'TICKET_OVERBOOKING_EXCEPTION_ERROR_001':
            navigate(insertPeriodPath)
            break
        }
      }
    }
  }

  return (
    <>
      <GridWithRecap
        as="form"
        className="lg:container"
        onSubmit={handleSubmit(handlePayment)}
      >
        <GridWithRecap.ContentContainer>
          <div className="bg-white lg:bg-transparent">
            <div className="p-4 lg:hidden">
              <ReservationRecapDetails />
            </div>
            {isKiosk ? <PromoFormSection /> : <UserFormSection />}
          </div>

          {isKiosk ? null : (
            <>
              <InvoiceFormSection />
              <DogFormSection />
              <PromoFormSection />
            </>
          )}

          <InfoFormSection />

          <ReservationFooter
            className="border-t border-gray-100 bg-white px-4 py-2 lg:hidden"
            total={total}
          />
        </GridWithRecap.ContentContainer>

        <GridWithRecap.RecapContainer className="hidden lg:flex">
          <ReservationRecapCard />
        </GridWithRecap.RecapContainer>
      </GridWithRecap>

      <KioskTimeoutDialog timeout={ONE_MINUTE} />
    </>
  )
}
