import { cn } from '@spiaggeit/spit-ui'
import { useId } from 'react'

import { useCalculateChildrenHeight } from '../../hooks/useCalculateChildrenHeight.ts'
import { RadioCollapsedSection } from '../RadioCollapsedSection'
import { RadioGroupProps } from '../RadioGroup'
import { Surface, SurfaceProps } from '../Surface'

export interface FieldsetProps extends SurfaceProps {
  title: string
  collapse?: {
    isExpanded: boolean
    isAnimated?: boolean
    options: RadioGroupProps['options']
    label: string
    name: string
    title: string
  }
}

export const Fieldset = (props: FieldsetProps) => {
  const { title, children, collapse, className, ...rest } = props
  const titleId = useId()

  const { ref, childrenWrapperRef, height } = useCalculateChildrenHeight({
    isHeightExpanded: collapse?.isExpanded || false,
    shouldAnimate: collapse?.isAnimated,
  })

  return (
    <Surface
      {...rest}
      aria-labelledby={titleId}
      as="fieldset"
      className={cn(
        'duration overflow-hidden transition-[max-height] duration-500 ease-in-out',
        className
      )}
      ref={ref}
      variant="form-section"
      {...(height && { style: { maxHeight: height } })}
    >
      <legend
        className="absolute top-2 font-bold lg:top-4 lg:text-lg"
        id={titleId}
      >
        {title}
      </legend>

      {collapse ? (
        <RadioCollapsedSection {...collapse} ref={childrenWrapperRef}>
          {children}
        </RadioCollapsedSection>
      ) : (
        <>{children}</>
      )}
    </Surface>
  )
}
