import { useAppSelector } from '@/hooks/store.ts'
import { Country } from '@/models/app.ts'
import { appSlice } from '@/store/appSlice.ts'

export const useHostUrl = () => {
  const country = useAppSelector(appSlice.selectors.country)

  const isFrenchWebsite = country === Country.FR

  return isFrenchWebsite
    ? import.meta.env.VITE_FR_HOST
    : import.meta.env.VITE_IT_HOST
}
