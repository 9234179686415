import { useTranslation } from 'react-i18next'

import { formatPrice } from '../../../utils/price.ts'

interface ReservationTotalProps {
  total: number
}

export const ReservationTotal = (props: ReservationTotalProps) => {
  const { t } = useTranslation()

  return (
    <strong className="whitespace-nowrap">
      <abbr
        aria-label={t('common.total')}
        className="no-underline"
        title={t('common.total')}
      >
        {t('common.abbreviatedTotal')}
        <span aria-hidden="true">.</span>
      </abbr>
      &nbsp;
      <output>{formatPrice(props.total)}</output>
    </strong>
  )
}
