import * as rd from '@devexperts/remote-data-ts'

import { cartSlice } from '@/store/cartSlice'
import { LicenseBookingCartMode, licenseSlice } from '@/store/licenseSlice'
import { getBookingPath } from '@/utils/getBookingPath.ts'
import {
  CHOOSE_PRODUCT_PATH,
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  MAP_PATH,
  PAYMENT_KIOSK_PATH,
  SECTOR_PATH,
  TICKETS_PATH,
} from '../app/router/paths'
import { AppMode } from '../models/app'
import { appSlice } from '../store/appSlice'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../store/bookingAvailabilitySlice'

import { useAppSelector } from './store'
import { useAppPath } from './useAppPath.ts'
import { useCurrentPath } from './useCurrentPath'
import { useIsExtraStepNecessary } from './useIsExtraStepNecessary.ts'

export function useBackLink(): string | null {
  const { currentPath } = useCurrentPath()
  const license = useAppSelector(licenseSlice.selectors.license)
  const appMode = useAppSelector(appSlice.selectors.mode)
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const bookingCartMode = useAppSelector(licenseSlice.selectors.bookingCartMode)

  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const landingKioskPath = useAppPath(LANDING_KIOSK_PATH)
  const chooseProductPath = useAppPath(CHOOSE_PRODUCT_PATH)
  const insertInsurancePath = useAppPath(INSERT_INSURANCE_PATH)
  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )
  const isExtraStepNecessary = useIsExtraStepNecessary()

  if (!license) {
    return null
  }

  const isBookingAvailabilityLoaded = rd.isSuccess(bookingAvailability)

  const bookingPath = isBookingAvailabilityLoaded
    ? getBookingPath({
      bookingAvailability: bookingAvailability.value.type,
      license,
      selectedBookingType,
    })
    : null

  switch (currentPath) {
    case INSERT_PERIOD_PATH: {
      if (appMode === AppMode.WEBSITE) {
        return `${license.beach.permalink}`
      }

      if (appMode === AppMode.KIOSK) {
        return landingKioskPath
      }

      return null
    }

    case CHOOSE_PRODUCT_PATH:
      return insertPeriodPath

    case INSERT_DATA_PATH: {
      if (isExtraStepNecessary) {
        return insertInsurancePath
      }

      return bookingPath
    }

    case INSERT_INSURANCE_PATH:
      return bookingPath

    case TICKETS_PATH: {
      if (!isBookingAvailabilityLoaded) {
        return insertPeriodPath
      }

      return bookingAvailability.value.type ===
        BookingAvailability.SPOTS_AND_TICKETS
        ? chooseProductPath
        : insertPeriodPath
    }

    case PAYMENT_KIOSK_PATH: {
      if (!isBookingAvailabilityLoaded) {
        return insertPeriodPath
      }

      return landingKioskPath
    }

    case SECTOR_PATH:
    case MAP_PATH: {
      if (
        !isBookingAvailabilityLoaded ||
        bookingCartMode === LicenseBookingCartMode.V_2022 ||
        bookingAvailability.value.type === BookingAvailability.SPOTS
      ) {
        return insertPeriodPath
      }

      return chooseProductPath
    }

    default:
      return null
  }
}
