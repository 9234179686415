import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/hooks/store'
import { INSERT_PERIOD_PATH, MAP_PATH, TICKETS_PATH } from '../app/router/paths'
import { useCurrentPath } from '../hooks/useCurrentPath'
import { appSlice } from '../store/appSlice'

export const Footer = () => {
  const isWebsite = useAppSelector(appSlice.selectors.isWebsiteMode)
  const { currentPath } = useCurrentPath()
  const { t } = useTranslation()

  /**
   * TODO: this logic could be moved within store
   * ui {
   *   footer: boolean
   * }
   **/
  if (
    !isWebsite ||
    [INSERT_PERIOD_PATH, MAP_PATH, TICKETS_PATH].includes(currentPath)
  ) {
    return null
  }

  return (
    <footer className="mt-auto bg-gray-900 px-4 py-8 text-center text-xs text-gray-50">
      <p className="mb-4">
        {t('footer.legalAddress')}
        <br />
        {t('footer.operationalAddress')}
        <br />
        {t('footer.copyright')}
      </p>
    </footer>
  )
}
