import { t } from 'i18next'
import { MapElementWithSectorId } from '@/models/map'
import { LicenseBookingMode, licenseSlice } from '@/store/licenseSlice'
import { mapSelectors } from '@/store/mapSlice'
import { mappedSpotTypes } from '@/utils/constants'
import { useAppSelector } from './store'

//When the user is choosing their reservation spot through sector view instead of map,
//we do not show the spot name, but only the spot type + sector name
//E.g.: "Umbrella 11" becomes "Umbrella - Settore 1° fila"

export const useMapSpotName = () => {
  const license = useAppSelector(licenseSlice.selectors.license)
  const bookingMode = license?.bookingMode
  const sectors = useAppSelector(mapSelectors.sectors)

  const getMapSpotName = (
    spot?: MapElementWithSectorId | null,
    config?: { shouldShowSpotName?: boolean }
  ) => {
    if (!spot) return t('common.tickets')

    const sector = sectors.find((sector) => sector.header.id === spot?.sectorId)

    const translatedSpotType = spot?.subType
      ? t(mappedSpotTypes[spot.subType].label)
      : ''

    let visibleName

    const spotName = spot?.name
    const sectorName = sector?.header.name

    if (config) {
      visibleName = config.shouldShowSpotName ? spotName : sectorName
    } else {
      visibleName =
        bookingMode === LicenseBookingMode.SECTORS ? sectorName : spotName
    }

    return `${translatedSpotType} ${visibleName}`
  }
  return { getMapSpotName }
}
