import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

export interface UseCalculateChildrenHeightProps {
  shouldAnimate?: boolean
  isHeightExpanded: boolean
  calculateChildrenHeight?: boolean
}

export const useCalculateChildrenHeight = (
  props: UseCalculateChildrenHeightProps
) => {
  const { shouldAnimate, isHeightExpanded } = props

  const ref = useRef<HTMLFieldSetElement | null>(null)
  const childrenWrapperRef = useRef<HTMLDivElement | null>(null)
  const resizeTimeoutId = useRef<number | null>(null)

  const [height, setHeight] = useState<string | null>(null)
  const [isDesktop, setIsDesktop] = useState(false)

  const calculateHeight = () => {
    if (!ref.current || !childrenWrapperRef.current) return

    const scrollHeight = ref.current.scrollHeight

    // Additional height to ensure max-height does not clip content
    const maxHeightAdjustment = 500

    const height = isHeightExpanded
      ? scrollHeight + maxHeightAdjustment
      : scrollHeight - childrenWrapperRef.current.scrollHeight

    setHeight(`${height / 16}rem`)
  }

  const resizeEventListener: EventListener = useCallback(() => {
    if (resizeTimeoutId.current) {
      clearTimeout(resizeTimeoutId.current)
    }

    resizeTimeoutId.current = window.setTimeout(() => {
      if (isDesktop && window.innerWidth < 992) {
        setIsDesktop(false)
        calculateHeight()
      }

      if (!isDesktop && window.innerWidth >= 992) {
        setIsDesktop(true)
        calculateHeight()
      }
    }, 100)
  }, [isDesktop])

  const debouncedRezizeEventListener = useDebounceCallback(
    resizeEventListener,
    100
  )

  useEffect(() => {
    if (!shouldAnimate) return
    setIsDesktop(window.innerWidth >= 992)
    window.addEventListener('resize', debouncedRezizeEventListener)
    return () =>
      window.removeEventListener('resize', debouncedRezizeEventListener)
  }, [debouncedRezizeEventListener, shouldAnimate])

  useEffect(() => {
    if (!shouldAnimate) return
    calculateHeight()
  }, [calculateHeight, shouldAnimate])

  return {
    childrenWrapperRef,
    height,
    ref,
  }
}
