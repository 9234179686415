import { ComponentPropsWithoutRef } from 'react'

export const ApproveTick = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5917 6.00834C15.5142 5.93023 15.4221 5.86824 15.3205 5.82593C15.219 5.78362 15.11 5.76184 15 5.76184C14.89 5.76184 14.7811 5.78362 14.6796 5.82593C14.578 5.86824 14.4858 5.93023 14.4084 6.00834L8.20004 12.225L5.59171 9.60834C5.51127 9.53064 5.41632 9.46955 5.31227 9.42854C5.20823 9.38754 5.09713 9.36743 4.98531 9.36936C4.87349 9.3713 4.76315 9.39524 4.66058 9.43982C4.55802 9.48439 4.46524 9.54874 4.38754 9.62917C4.30984 9.70961 4.24875 9.80456 4.20774 9.9086C4.16674 10.0127 4.14663 10.1238 4.14856 10.2356C4.1505 10.3474 4.17444 10.4577 4.21902 10.5603C4.2636 10.6629 4.32794 10.7556 4.40837 10.8333L7.60837 14.0333C7.68584 14.1114 7.77801 14.1734 7.87956 14.2157C7.98111 14.2581 8.09003 14.2798 8.20004 14.2798C8.31005 14.2798 8.41897 14.2581 8.52052 14.2157C8.62207 14.1734 8.71424 14.1114 8.79171 14.0333L15.5917 7.23334C15.6763 7.1553 15.7438 7.06059 15.79 6.95518C15.8361 6.84976 15.86 6.73592 15.86 6.62084C15.86 6.50575 15.8361 6.39192 15.79 6.2865C15.7438 6.18108 15.6763 6.08637 15.5917 6.00834Z"
        fill="currentColor"
      />
    </svg>
  )
}
