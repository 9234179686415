import { isPending, isSuccess } from '@devexperts/remote-data-ts'
import { Button } from '@spiaggeit/spit-ui'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { SkeletonText } from '@/components/SkeletonText'
import { useNextPathAfterChoosingSpot } from '@/hooks/useNextPathAfterChoosingSpot'
import { selectCartQuoteErrorMessage } from '@/store/cartSlice/selectors/selectCartQuoteErrorMessage.ts'
import { ArrowRight } from '../../assets/icons/ArrowRight'
import { Cart } from '../../assets/icons/Cart'
import { ExclamationMark } from '../../assets/icons/ExclamationMark'
import { Info } from '../../assets/icons/Info'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useTotalPriceLabel } from '../../hooks/useTotalPriceLabel'
import { APISectorDetailKeyType } from '../../models/map'
import { cartSlice } from '../../store/cartSlice'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import {
  mapSelectedElementSetupDataSelector,
  mapSelectors,
} from '../../store/mapSlice'
import { priceFormatter } from '../../utils/price'
import { RemoveElementFromCartDialog } from '../RemoveElementFromCartDialog'
import { ServiceCostDialog } from '../ServiceCostDialog'
import { QuoteReservation } from './QuoteReservation'

interface SetupCartActionsProps {
  handleCloseWithQuote: VoidFunction
  handleCloseDrawer: VoidFunction
}

export const SetupCartActions = (props: SetupCartActionsProps) => {
  const { t } = useTranslation()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const cartItems = useAppSelector(cartSlice.selectors.items)

  const dispatch = useAppDispatch()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  /**
   * This state is set to true when the "finish booking" button is pressed.
   * It ensures that the nextPath variable is recalculated before proceeding
   * after adding the reservation to the cart.
   */
  const [isRedirecting, setIsRedirecting] = useState(false)

  const quote = useAppSelector(cartSlice.selectors.quote)
  const isQuotePending = isPending(quote)

  const totalPriceLabel = useTotalPriceLabel({ bookingPeriod, period })

  const isElementAddedToCart = useMemo(() => {
    return Object.keys(cartItems).includes(`${selectedElement?.id}`)
  }, [cartItems, selectedElement])

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)
  const selectedElementSetupLimit = setupData?.selectedElementSetupLimit
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const [areCartButtonsEnabled, setAreCartButtonsEnabled] = useState(false)
  const error = useAppSelector(selectCartQuoteErrorMessage)

  const navigate = useNavigate()
  const nextPath = useNextPathAfterChoosingSpot()

  useEffect(() => {
    const isMinimumSeatAmountRespected =
      selectedElementSetupLimit &&
      selectedElementQuotedSeatsAmount >=
        selectedElementSetupLimit[APISectorDetailKeyType.MIN]

    if (!isMinimumSeatAmountRespected) {
      setAreCartButtonsEnabled(false)
    } else setAreCartButtonsEnabled(true)
  }, [
    error,
    selectedElementSetupLimit,
    selectedElementQuotedSeatsAmount,
    setAreCartButtonsEnabled,
  ])

  if (!selectedElement) return null

  const onSaveEdit = () => {
    onAddToCart()
  }

  const onAddToCart = async () => {
    if (!selectedElement) return
    await dispatch(cartSlice.actions.quote())

    dispatch(
      cartSlice.actions.addItemToCart({
        id: Number(selectedElement.id),
      })
    )

    props.handleCloseWithQuote()
  }

  const handleFinishBooking = async () => {
    await onAddToCart()
    setIsRedirecting(true)
  }

  useEffect(() => {
    if (!nextPath || !isRedirecting) return
    navigate(nextPath)
  }, [isRedirecting, nextPath])

  let quotedReservations: [string, number][] = []

  if (isSuccess(quote)) {
    quotedReservations = Object.entries(
      quote.value.reservationPrices || []
    ).filter(([key]) => key !== '0')
  }

  return (
    <div className="w-full border-t border-solid border-gray-200 p-4 lg:mt-auto">
      {quotedReservations.length >= 1 &&
        quotedReservations.map(([key, value]) => {
          return (
            <QuoteReservation
              isQuotePending={isQuotePending}
              key={key}
              reservationKey={key}
              value={value}
            />
          )
        })}

      <div className="flex justify-between gap-1 text-sm text-secondary">
        <ServiceCostDialog
          trigger={
            <button
              aria-label={t('common.open')}
              className="flex items-center gap-1 text-left underline"
            >
              {t('map.elementDetail.serviceCost.title')}
              <span className="text-gray-900">
                <ExclamationMark height={16} width={16} />
              </span>
            </button>
          }
        />

        <SkeletonText
          className="w-12"
          isPending={isQuotePending}
          text={priceFormatter.format(isSuccess(quote) ? quote.value.fees : 0)}
        />
      </div>
      {isSuccess(quote) && quote.value.offer > 0 ? (
        <div className="flex items-center justify-between gap-1 text-sm text-secondary">
          <span> {t('map.elementDetail.offer')}</span>
          <span>-{priceFormatter.format(quote.value.offer)}</span>
        </div>
      ) : null}

      <div className="flex justify-between gap-1 pt-1 font-medium leading-none text-primary">
        <span>{totalPriceLabel}</span>
        <SkeletonText
          isPending={isQuotePending}
          text={priceFormatter.format(
            isSuccess(quote) ? quote.value.totalPrice : 0
          )}
        />
      </div>

      {isElementAddedToCart ? (
        <div className="mt-4 flex gap-2 lg:flex-row">
          <Button
            className="basis-[50%]"
            color="error"
            disabled={!areCartButtonsEnabled}
            onClick={() => setDialogIsOpen(true)}
            variant="outline"
          >
            {t('common.remove')}
          </Button>
          <Button
            className="basis-[50%]"
            disabled={!areCartButtonsEnabled}
            onClick={() => onSaveEdit()}
          >
            {t('map.elementDetail.saveEdit')}
          </Button>
        </div>
      ) : (
        <>
          {error ? (
            <div className="mt-4 flex items-center gap-1 rounded-md border border-red-100 bg-red-25 p-1">
              <Info className="shrink-0 text-red-500" />
              <span className="whitespace-normal text-sm">{error}</span>
            </div>
          ) : (
            <div className="flex flex-col gap-2 pt-4">
              <Button
                color="primary"
                disabled={!areCartButtonsEnabled}
                onClick={onAddToCart}
                variant="outline"
              >
                {t('map.elementDetail.addToCart')}
                <Cart />
              </Button>
              <Button
                disabled={!areCartButtonsEnabled}
                onClick={handleFinishBooking}
              >
                {t('map.elementDetail.finishBooking')}
                <ArrowRight />
              </Button>
            </div>
          )}
        </>
      )}
      <RemoveElementFromCartDialog
        element={selectedElement}
        handleClose={props.handleCloseDrawer}
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
      />
    </div>
  )
}
