import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  MAP_PATH,
} from '@/app/router/paths'
import { licenseSlice } from '@/store/licenseSlice'
import { useAppSelector } from './store'
import { useCurrentPath } from './useCurrentPath'

export const useCurrentPathNotes = () => {
  const { currentPath } = useCurrentPath()
  const license = useAppSelector(licenseSlice.selectors.license)

  if (
    currentPath === INSERT_PERIOD_PATH ||
    currentPath === CHOOSE_PRODUCT_PATH
  ) {
    return license?.bookingSelectPeriodNotes
  } else if (currentPath === MAP_PATH) {
    return license?.bookingMapNotes
  } else return null
}
