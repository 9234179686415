import * as rd from '@devexperts/remote-data-ts'
import { RemoteData } from '@devexperts/remote-data-ts'
import { PayloadAction } from '@reduxjs/toolkit'
import { BookingHalfDay } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'
import { httpClient } from '@/lib/http/HttpClient.ts'
import { assertNever } from '@/lib/types/utils.ts'
import { BackendErrorModel } from '@/models/backendErrorModel.ts'
import { ResponseBase } from '@/models/http.ts'
import { appSlice } from '@/store/appSlice.ts'
import { resetAllState } from '@/store/commonActions.ts'
import { RootState } from '@/store/configureStore.ts'
import { createAppSlice } from '@/store/createAppSlice.ts'
import { getLicenseFromUrl } from '@/utils/getLicenseFromUrl'

export enum LicenseBookingMode {
  MAP = 1,
  SECTORS = 2,
}

export enum LicenseBookingCartMode {
  V_2022 = 1,
  V_2024 = 2,
}

export type License = {
  bookingCartMode: LicenseBookingCartMode
  bookingEndPeriodOffset: number | null
  bookingHalfDay: BookingHalfDay
  bookingStartPeriod: string
  bookingEndPeriod: string
  bookingTimeLimit?: string
  bookingNextYear: boolean
  bookingSeasonal: boolean
  bookingDiscountCodeDisabled: boolean
  bookingPetFormVisible: boolean
  bookingPaymentCreditcardLimit: number
  bookingPaymentIban: string
  bookingFiscalInformationMode:
    | 'receipt-only'
    | 'invoice-only'
    | 'invoice-or-receipt'
  featureFlags: {
    widgetWhiteLabelSettingsEnabled: boolean
  }
  bookingPolicyUrl: string
  bookingTaxCodeRequired: boolean
  bookingPolicy: string
  bookingStatus: boolean
  bookingMode: LicenseBookingMode
  bookingUserDataNotes: string
  bookingToday: boolean
  bookingUserUmbrellasLimit?: number
  bookingSelectPeriodNotes: string
  bookingMapNotes: string
  bookingServicesNotes: string
  bookingRefundPolicy?: number
  id: number
  license: string
  name: string
  seasonalStartDate: string
  seasonalEndDate: string
  beach: {
    lat: number
    long: number
    address: string
    region: string
    city: string
    name: string
    permalink: string
    state: string
    stateModel?: {
      name?: string
    }
  }
}

export interface LicenseString {
  /**
   * A unique id for the string (numeric value)
   */
  stringId: number

  /**
   * The key of the string
   */
  key: string

  /**
   * The language of the string
   */
  language: string

  /**
   * The license code of the string
   */
  licenseCode: string

  /**
   * The namespace of the strings
   */
  namespace: string

  /**
   * This value contains HTML tags
   */
  value: string
}

interface LicenseData {
  license: License
  isBookingEnabled: boolean
  seasonStartYear: number
  seasonEndYear: number
  seasonStart: string
  seasonEnd: string
  seasonalStart: string
  seasonalEnd: string
}

export type LicenseSliceState = {
  strings: RemoteData<string, LicenseString[]>
  license: RemoteData<string, LicenseData>
  licenseCode: string
}

const initialState: LicenseSliceState = {
  license: rd.initial,
  licenseCode: getLicenseFromUrl(),
  strings: rd.initial,
}

export const licenseSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)
  },
  initialState: initialState satisfies LicenseSliceState as LicenseSliceState,
  name: 'license',
  reducers: (create) => ({
    fetchLicenseStrings: create.asyncThunk<
      LicenseString[],
      void,
      { rejectValue: string }
    >(
      async (_, thunkApi): Promise<LicenseString[]> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.licenseCode(rootState)
        const country = appSlice.selectors.country(rootState)

        const response = await httpClient.fetch<
          ResponseBase<LicenseString[]>,
          BackendErrorModel
        >(`beaches/${license}/i18n/license-strings`, country)

        if (response.status === 'error') {
          throw thunkApi.rejectWithValue(
            'code' in response.error
              ? response.error.code
              : response.error.error
          )
        }
        return response.data.result
      },
      {
        fulfilled: (state, action) => {
          state.strings = rd.success(action.payload)
        },
        pending: (state) => {
          state.strings = rd.pending
        },
        rejected: (state, action) => {
          state.strings = rd.failure(action.payload ?? 'Unknown Error')
        },
      }
    ),
    loadLicense: create.asyncThunk<License, string, { rejectValue: null }>(
      async (licenseName, thunkApi): Promise<License> => {
        const rootState = thunkApi.getState() as RootState
        const country = appSlice.selectors.country(rootState)

        const resp = await httpClient.fetch<{ result: { license: License } }>(
          `beaches/${licenseName}?expand=beach,featureFlags`,
          country
        )

        switch (resp.status) {
          case 'success':
            return resp.data.result.license

          case 'error':
            throw thunkApi.rejectWithValue(null)

          default:
            assertNever(resp)
        }
      },
      {
        fulfilled: (state, action) => {
          const license = action.payload
          const now = DateTime.utc()
          let seasonStartYear = now.year
          let seasonEndYear = seasonStartYear
          const seasonEnd = DateTime.fromFormat(
            `${license.bookingEndPeriod}-${seasonStartYear}`,
            'dd-LL-yyyy'
          ).toMillis()

          // New year enabled, current season not over yet and is october or later -> next year calendar
          if (license.bookingNextYear && now.month > 8) {
            seasonEndYear++
            if (now.toMillis() > seasonEnd) {
              seasonStartYear++
            }
          }

          const isBookingEnabled = !!license.bookingStatus
          const seasonalStartDate = DateTime.fromFormat(
            `${license.seasonalStartDate}-${seasonEndYear}`,
            'dd-LL-yyyy'
          )

          state.license = rd.success({
            bookingMode: license.bookingMode,
            isBookingEnabled,
            license,
            seasonEnd: `${license.bookingEndPeriod}-${seasonEndYear}`,
            seasonEndYear,
            seasonStart: `${license.bookingStartPeriod}-${seasonStartYear}`,
            seasonStartYear,
            seasonalEnd: `${license.seasonalEndDate}-${seasonEndYear}`,
            seasonalStart: (now.toMillis() >= seasonalStartDate.toMillis()
              ? now.plus({ days: license.bookingToday ? 0 : 1 })
              : seasonalStartDate
            ).toFormat('dd-LL-yyyy'),
          })
        },
        options: {
          condition: (_, thunkApi) => {
            const state = thunkApi.getState() as RootState

            if (!rd.isInitial(state.license.license)) {
              return false
            }
          },
        },
        pending: (state) => {
          state.license = rd.pending
        },
        rejected: (state, action) => {
          state.license = rd.failure(action.payload ?? 'Unknown Error')
        },
      }
    ),
    setLicenseCode: create.reducer((state, action: PayloadAction<string>) => {
      state.licenseCode = action.payload
    }),
  }),
  selectors: {
    beachAddress: getLicenseState((state) => ({
      address: `${state.license.beach.address}, ${state.license.beach.city} (${state.license.beach.state})`,
      name: state.license.beach.name,
    })),
    beachCoordinates: getLicenseState((state) => ({
      lat: state.license.beach.lat,
      lng: state.license.beach.long,
    })),
    beachLocation: getLicenseState((state) => {
      const city = state.license.beach.city
      const stateName =
        state.license.beach.stateModel?.name ?? state.license.beach.state
      return stateName.length === 0 || city === stateName
        ? city
        : `${city}, ${stateName}`
    }),
    bookingCartMode: getLicenseState((state) => state.license.bookingCartMode),
    bookingFiscalMode: getLicenseState(
      (state) => state.license.bookingFiscalInformationMode
    ),
    bookingPaymentIban: getLicenseState(
      (state) => state.license.bookingPaymentIban
    ),
    bookingUserDataNotes: getLicenseState(
      (state) => state.license.bookingUserDataNotes
    ),
    creditCardLimit: getLicenseState(
      (state) => state.license.bookingPaymentCreditcardLimit || Infinity
    ),
    isBookingEnabled: getLicenseState((state) => state.isBookingEnabled),
    isBookingPetFormVisible: getLicenseState(
      (state) => state.license.bookingPetFormVisible
    ),
    isBookingRefundable: getLicenseState(
      (state) =>
        !!state.license.bookingRefundPolicy &&
        state.license.bookingRefundPolicy >= 1
    ),
    isDiscountCodeVisible: getLicenseState(
      (state) => !state.license.bookingDiscountCodeDisabled
    ),
    isLoadingLicenseStrings: (state) => rd.isPending(state.strings),
    isTaxCodeRequired: getLicenseState(
      (state) => state.license.bookingTaxCodeRequired
    ),
    isWidgetWhiteLabelActive: getLicenseState(
      (state) => state.license.featureFlags.widgetWhiteLabelSettingsEnabled
    ),
    license: getLicenseState((state) => state.license),
    licenseCode: (state) => state.licenseCode,
    licenseRemoteData: (state) => state.license,
    seasonEnd: getLicenseState((state) => state.seasonEnd),
    seasonStart: getLicenseState((state) => state.seasonStart),
    seasonStartYear: getLicenseState((state) => state.seasonStartYear),
    seasonalEnd: getLicenseState((state) => state.seasonalEnd),
    seasonalStart: getLicenseState((state) => state.seasonalStart),
    strings: (state) => state.strings,
    stringsValue: (state) =>
      rd.isSuccess(state.strings) ? state.strings.value : [],
  },
})
type GetLicenseStateCallback<T> = (licenseData: LicenseData) => T

function getLicenseState<T>(callback: GetLicenseStateCallback<T>) {
  return (state: LicenseSliceState) =>
    rd.isSuccess(state.license) ? callback(state.license.value) : null
}
