import * as rd from '@devexperts/remote-data-ts'
import { isPending } from '@devexperts/remote-data-ts'
import { useEffect } from 'react'
import {
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  MAP_PATH,
  SECTOR_PATH,
  TICKETS_PATH,
} from '@/app/router/paths'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { cartSlice } from '@/store/cartSlice'
import { extraSlice } from '@/store/extraSlice.ts'
import { insertPeriodSlice } from '@/store/insertPeriodSlice'
import { useCurrentPath } from './useCurrentPath'

export const useFetchPricePreview = () => {
  const dispatch = useAppDispatch()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const weatherInsurancePrice = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )
  const { currentPath } = useCurrentPath()
  const total = useAppSelector(cartSlice.selectors.total)
  const fees = useAppSelector(cartSlice.selectors.fees)
  const amount = total - fees
  useEffect(() => {
    if ([MAP_PATH, SECTOR_PATH, TICKETS_PATH].includes(currentPath)) {
      dispatch(extraSlice.actions.fetchWeatherInsurancePricePreview())
    }
  }, [period])

  useEffect(() => {
    if ([INSERT_INSURANCE_PATH, INSERT_DATA_PATH].includes(currentPath)) {
      const isWeatherInsurancePresent =
        rd.isSuccess(weatherInsurancePrice) && weatherInsurancePrice.value > 0

      // this condition avoids dispatching firing API call if no weather insurance is available at all
      // for the selected period
      isWeatherInsurancePresent &&
        dispatch(
          extraSlice.actions.fetchWeatherInsurancePricePreview({
            amount,
          })
        )
    }
  }, [total])

  return isPending(weatherInsurancePrice)
}
