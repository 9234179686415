import { useMemo } from 'react'

import { useLocalizedCountries } from '@/hooks/useLocalizedCountries.ts'
import { Option } from '@/models/option.ts'

export const useLocalizedCountryOptions = (): Option[] => {
  const localizedCountries = useLocalizedCountries()

  return useMemo(() => {
    return Object.entries(localizedCountries)
      .map(([countryCode, countryName]) => ({
        label: countryName,
        value: countryCode,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [])
}
