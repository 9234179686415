import { createSelector } from '@reduxjs/toolkit'
import { cartSlice } from '@/store/cartSlice'
import { mapSelectors } from '@/store/mapSlice.ts'

export const selectCartMapSpots = createSelector(
  [mapSelectors.mapData, cartSlice.selectors.items],
  (mapData, items) => {
    return mapData?.elements
      ?.filter((element) => Object.keys(items).includes(element.id))
      .filter(Boolean)
  }
)
