import { combineSlices, configureStore } from '@reduxjs/toolkit'

import { cartSlice } from '@/store/cartSlice/index'
import { licenseSlice } from '@/store/licenseSlice'
import { loadingSlice } from '@/store/loadingSlice.ts'
import { widgetSlice } from '@/store/widgetSlice.ts'
import { appSlice } from './appSlice'
import { bookingAvailabilitySlice } from './bookingAvailabilitySlice'
import { extraSlice } from './extraSlice.ts'
import { insertPeriodSlice } from './insertPeriodSlice'
import { kioskSlice } from './kioskSlice'
import { mapSlice } from './mapSlice'
import { sectorRulesSlice } from './sectorRulesSlice'
import { servicesSlice } from './servicesSlice'
import {
  getSessionLicense,
  getSessionStorageData,
  resetSessionStorageData,
  sessionStorageMiddleware,
} from './sessionStorageMiddleware.ts'
import { toastSlice } from './toastSlice'

const loadState = (): Partial<RootState> | undefined => {
  try {
    const data = getSessionStorageData()

    if (!data) return

    const license = getSessionLicense()
    const url = new URL(window.location.href)

    if (license && !url.pathname.includes(license)) {
      resetSessionStorageData()
      return
    }

    return data
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error loading state from session storage', err)
    return undefined
  }
}

export const rootReducer = combineSlices(
  appSlice,
  loadingSlice,
  cartSlice,
  bookingAvailabilitySlice,
  licenseSlice,
  insertPeriodSlice,
  widgetSlice,
  kioskSlice,
  servicesSlice,
  sectorRulesSlice,
  toastSlice,
  mapSlice,
  extraSlice
)

const preloadedState = loadState()

export const store = configureStore({
  devTools: import.meta.env.MODE === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sessionStorageMiddleware),
  preloadedState,
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']
