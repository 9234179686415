import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '@/hooks/store.ts'
import { useUserDataForm } from '@/hooks/useUserDataForm.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'

export const FormContainer = () => {
  const form = useUserDataForm()
  const discountCode = useAppSelector(cartSlice.selectors.discountCode)

  useEffect(() => {
    if (!discountCode) {
      form.setValue('promo.discountCode', '')
    }
  }, [discountCode])

  return (
    <FormProvider {...form}>
      <Outlet />
    </FormProvider>
  )
}
