import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, ElementType, FC } from 'react'

import { ElementAs } from '@/models/element-as.ts'

import { ReservationCosts, ReservationCostsProps } from '../Reservation/Costs'
import {
  ReservationFooter,
  ReservationFooterProps,
} from '../Reservation/Footer'
import {
  ReservationHeader,
  ReservationHeaderProps,
} from '../Reservation/Header'
import { ReservationList, ReservationListProps } from '../Reservation/List'
import {
  ReservationLocation,
  ReservationLocationProps,
} from '../Reservation/Location'
import { Surface } from '../Surface'

type RecapCardProps<T extends ElementType> =
  ComponentPropsWithoutRef<'article'> & ElementAs<T>

interface RecapCardType extends FC<RecapCardProps<ElementType>> {
  Header: FC<ReservationHeaderProps>
  Location: FC<ReservationLocationProps>
  Reservations: FC<ReservationListProps>
  Costs: FC<ReservationCostsProps>
  Footer: FC<ReservationFooterProps>
}

export const RecapCard: RecapCardType = (
  props: RecapCardProps<ElementType>
) => {
  const { children, className, as = 'article', ...rest } = props

  return (
    <Surface
      as={as}
      className={cn('sticky top-[84px] flex flex-col gap-4 p-4', className)}
      {...rest}
    >
      {props.children}
    </Surface>
  )
}

RecapCard.Header = ReservationHeader
RecapCard.Location = ReservationLocation
RecapCard.Reservations = ReservationList
RecapCard.Costs = ReservationCosts
RecapCard.Footer = ReservationFooter
