import { Trans } from 'react-i18next'

import { getPolicyI18nKey } from '../../utils/getPolicyI18nKey.ts'
import { ExternalLink } from '../ExternalLink'

export interface TranslatedPolicyTextProps {
  beachPrivacyPolicy?: string
  regulation?: string
  onBookingPolicyDialogOpen(): void
  onRefundPolicyDialogOpen(): void
}

export const TranslatedPolicyText = (props: TranslatedPolicyTextProps) => {
  const policyI18nKey = getPolicyI18nKey({
    beachPrivacyPolicy: props.beachPrivacyPolicy,
    regulation: props.regulation,
  })

  const {
    OpenPrivacyPolicyAnchor,
    OpenBeachPrivacyPolicyAnchor,
    OpenTermsAnchor,
    OpenBookingPolicyDialogButton,
    OpenRefundPolicyDialogButton,
  } = usePolicyElements(props)

  switch (policyI18nKey) {
    case 'summary.privacy':
      return (
        <Trans i18nKey={policyI18nKey}>
          {OpenPrivacyPolicyAnchor}
          {OpenBeachPrivacyPolicyAnchor}
          {OpenTermsAnchor}
          {OpenBookingPolicyDialogButton}
          {OpenRefundPolicyDialogButton}
        </Trans>
      )

    case 'summary.smallPrivacy':
      return (
        <Trans i18nKey={policyI18nKey}>
          {OpenPrivacyPolicyAnchor}
          {OpenTermsAnchor}
          {OpenRefundPolicyDialogButton}
        </Trans>
      )

    case 'summary.privacyWithRegulation':
      return (
        <Trans i18nKey={policyI18nKey}>
          {OpenPrivacyPolicyAnchor}
          {OpenTermsAnchor}
          {OpenBookingPolicyDialogButton}
          {OpenRefundPolicyDialogButton}
        </Trans>
      )

    case 'summary.privacyWithBeachPolicy':
      return (
        <Trans i18nKey={policyI18nKey}>
          {OpenPrivacyPolicyAnchor}
          {OpenBeachPrivacyPolicyAnchor}
          {OpenTermsAnchor}
          {OpenRefundPolicyDialogButton}
        </Trans>
      )
  }
}

const usePolicyElements = (props: TranslatedPolicyTextProps) => {
  const OpenPrivacyPolicyAnchor = (
    <ExternalLink
      href="https://www.spiagge.it/privacy-policy"
      isInsideParagraph
    />
  )

  const OpenBeachPrivacyPolicyAnchor = (
    <ExternalLink href={props.beachPrivacyPolicy} isInsideParagraph />
  )

  const OpenTermsAnchor = (
    <ExternalLink href="https://www.spiagge.it/terms" isInsideParagraph />
  )

  const OpenBookingPolicyDialogButton = (
    <ExternalLink
      as="button"
      isInsideParagraph
      onClick={props.onBookingPolicyDialogOpen}
      type="button"
    />
  )

  const OpenRefundPolicyDialogButton = (
    <ExternalLink
      as="button"
      isInsideParagraph
      onClick={props.onRefundPolicyDialogOpen}
      type="button"
    />
  )

  return {
    OpenBeachPrivacyPolicyAnchor,
    OpenBookingPolicyDialogButton,
    OpenPrivacyPolicyAnchor,
    OpenRefundPolicyDialogButton,
    OpenTermsAnchor,
  }
}
