import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react'

export const GridWithRecap = (
  props: ComponentPropsWithoutRef<'form'> & { as?: ElementType }
) => {
  const { as: Element = 'div', className, children, ...rest } = props

  return (
    <Element
      className={cn(
        'grid grid-cols-1 items-start gap-4 lg:mb-40 lg:mt-8 lg:grid-cols-3 lg:gap-8',
        className
      )}
      {...rest}
    >
      {children}
    </Element>
  )
}

GridWithRecap.ContentContainer = function Content(
  props: PropsWithChildren<{ className?: string }>
) {
  return (
    <div
      className={cn(
        'col-span-1 flex flex-col items-stretch justify-start gap-2 lg:col-span-2 lg:gap-4',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

GridWithRecap.RecapContainer = function Recap(
  props: PropsWithChildren<{ className?: string }>
) {
  return (
    <div className={cn('sticky top-[5.25rem] col-span-1', props.className)}>
      {props.children}
    </div>
  )
}
