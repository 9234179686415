import { Button, cn } from '@spiaggeit/spit-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@/components/ButtonLink'

import { useAppSelector } from '@/hooks/store.ts'
import { useRefundPolicyDialogProps } from '@/hooks/useRefundPolicyDialogProps.ts'
import { PaymentMethod } from '@/routes/Pay'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { formatPrice } from '@/utils/price.ts'

import { BookingPolicyDialog } from '../../BookingPolicyDialog'
import { RefundPolicyDialog } from '../../RefundPolicyDialog'
import { RefundPolicy } from '../../SelectedElementDrawer/RefundPolicy.tsx'
import { TranslatedPolicyText } from '../../TranslatedPolicyText'

export interface ReservationFooterProps {
  nextPath?: string
  isFinalPayment?: boolean
  isDisabled?: boolean
  total: number
  paymentMethod?: PaymentMethod
  className?: string
}

export const ReservationFooter = (props: ReservationFooterProps) => {
  const license = useAppSelector(licenseSlice.selectors.license)

  const beachPrivacyPolicy = license?.bookingPolicyUrl
  const regulation = license?.bookingPolicy
  const isBankTransferMethod = props.paymentMethod === 'bank-transfer'

  const isPayingWithCard = useAppSelector(cartSlice.selectors.isPayingWithCard)
  const isCreatingCart = useAppSelector(cartSlice.selectors.isCreateCartPending)
  const isPayingWithStripe = useAppSelector(
    cartSlice.selectors.isPayingWithStripe
  )

  const isLoading = isPayingWithCard || isCreatingCart || isPayingWithStripe

  const { t } = useTranslation()

  const [showDialogStatus, setShowDialogStatus] = useState<
    'closed' | 'booking-policy' | 'refund-policy'
  >('closed')

  const getCtaLabel = () => {
    const formattedPrice = formatPrice(props.total)

    if (props.isFinalPayment) {
      if (isBankTransferMethod) {
        return t('cta.payWithBankTransfer')
      }

      return [t('cta.confirmAndPay'), formattedPrice].join(' ')
    }

    return [t('cta.goToPayment'), formattedPrice].join(' ')
  }

  const refundPolicyDialogProps = useRefundPolicyDialogProps()
  return (
    <footer className={cn('flex flex-col gap-1', props.className)}>
      <RefundPolicy />

      {props.nextPath ? (
        <ButtonLink
          className="w-full"
          icon="ArrowRight"
          label={t('cta.continue')}
          to={props.nextPath}
        />
      ) : (
        <Button disabled={props.isDisabled} isLoading={isLoading} type="submit">
          {getCtaLabel()}
        </Button>
      )}

      <p className="text-xs text-secondary">
        <TranslatedPolicyText
          beachPrivacyPolicy={beachPrivacyPolicy}
          onBookingPolicyDialogOpen={() =>
            setShowDialogStatus('booking-policy')
          }
          onRefundPolicyDialogOpen={() => setShowDialogStatus('refund-policy')}
          regulation={regulation}
        />
      </p>

      <BookingPolicyDialog
        bookingPolicy={regulation}
        isOpen={showDialogStatus === 'booking-policy'}
        setIsOpen={(isOpen) =>
          setShowDialogStatus(isOpen ? 'booking-policy' : 'closed')
        }
      />

      <RefundPolicyDialog
        {...refundPolicyDialogProps}
        isOpen={showDialogStatus === 'refund-policy'}
        setIsOpen={(isOpen) =>
          setShowDialogStatus(isOpen ? 'refund-policy' : 'closed')
        }
      />
    </footer>
  )
}
