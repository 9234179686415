export enum AppMode {
  APP = 'app',
  KIOSK = 'kiosk',
  WIDGET = 'widget',
  WEBSITE = 'website',
}

export enum Country {
  FR = 'fr',
  IT = 'it',
}

export const languages = Object.freeze(['it', 'en', 'fr', 'pt', 'es', 'de'])
export type AppLanguage = (typeof languages)[number]
