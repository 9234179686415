import { cn } from '@spiaggeit/spit-ui'
import { createContext, forwardRef, PropsWithChildren, useId } from 'react'

import { RadioGroup, RadioGroupProps } from '../RadioGroup'

interface RadioCollapsedSectionProps {
  isExpanded: boolean
  isAnimated?: boolean
  options: RadioGroupProps['options']
  label: string
  name: string
  title: string
}

export const CollapsedSectionContext = createContext({
  isExpanded: true,
})

export const RadioCollapsedSection = forwardRef<
  HTMLDivElement,
  PropsWithChildren<RadioCollapsedSectionProps>
>(function ForwardedRadioCollapsedSection(props, ref) {
  const { options, isAnimated, title, isExpanded, name, label, children } =
    props

  const controlId = useId()

  return (
    <>
      <RadioGroup
        label={label}
        name={name}
        options={options.map((o) => ({
          ...o,
          'aria-controls': controlId,
          'aria-expanded': isExpanded,
        }))}
      />

      <div
        aria-hidden={!isExpanded}
        className={cn(
          'pt-4 filter transition-all duration-500',
          isAnimated
            ? {
                'pointer-events-none -translate-y-3 opacity-0 blur-sm':
                  !isExpanded,
                'translate-y-0 opacity-100 blur-0': isExpanded,
              }
            : {}
        )}
        id={controlId}
        ref={ref}
      >
        <h3 className="sr-only">{title}</h3>

        <CollapsedSectionContext.Provider value={{ isExpanded }}>
          {children}
        </CollapsedSectionContext.Provider>
      </div>
    </>
  )
})
