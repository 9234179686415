import { createAsyncThunk } from '@reduxjs/toolkit'
import { MapElementWithSectorId } from '@/models/map.ts'
import { cartSlice } from '@/store/cartSlice'
import { cartSliceInitialState as initialState } from '@/store/cartSlice/initialState.ts'
import { RootState } from '@/store/configureStore.ts'
import { mapSlice } from '@/store/mapSlice.ts'

export const cartRemoveElementAndUpdateQuoteAction = createAsyncThunk<
  void,
  MapElementWithSectorId,
  { state: RootState }
>('cart/cartRemoveElementAndUpdateQuoteAction', async (element, thunkAPI) => {
  const { dispatch } = thunkAPI

  dispatch(cartSlice.actions.removeCartReservation(element))
  dispatch(cartSlice.actions.setSelectedElementReservation(null))

  await dispatch(
    cartSlice.actions.quote({ needsToUpdateCartTotal: true })
  ).unwrap()

  dispatch(
    cartSlice.actions.decrementItemQuantityOrRemoveItem({
      id: Number(element.id),
    })
  )

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(
      initialState.selectedElementQuotableProducts
    )
  )

  dispatch(mapSlice.actions.selectMapElement(null))
})
