import { createSelector } from '@reduxjs/toolkit'
import { licenseSlice } from '@/store/licenseSlice'
import { LicenseStrings } from '@/store/licenseSlice/utils/licenseStrings.ts'

export const selectServiceCostsString = createSelector(
  [licenseSlice.selectors.stringsValue],
  (strings) => {
    return (
      strings.find(
        (translatedString) =>
          translatedString.namespace === LicenseStrings.Namespace &&
          translatedString.key === LicenseStrings.ServiceCostsTextKey
      )?.value || null
    )
  }
)
