import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef } from 'react'

import { Icon } from '../../../assets/icons'
import {
  ReservationLocationMobile,
  ReservationLocationMobileProps,
} from '../Location/Mobile'

export type ReservationSummaryProps = ReservationLocationMobileProps &
  ComponentPropsWithoutRef<'summary'>

export const ReservationSummary = (props: ReservationSummaryProps) => {
  const { beachName, endDate, location, region, startDate, total } = props

  const reservationLocationProps = {
    beachName,
    endDate,
    location,
    region,
    startDate,
    total,
  }

  const Element = total ? 'summary' : 'div'

  return (
    <Element
      className={cn(
        'flex list-none items-center justify-between gap-2 rounded border-b-gray-100 py-3 pl-4 pr-2 transition-colors marker:hidden group-open:rounded-b-none group-open:border-b group-open:bg-gray-25',
        {
          'cursor-pointer': !!total,
        }
      )}
    >
      <ReservationLocationMobile {...reservationLocationProps} />

      {!!total && (
        <Icon
          className="flex-shrink-0 rotate-180  group-open:rotate-0"
          height="24"
          name="ChevronUp"
          width="24"
        />
      )}
    </Element>
  )
}
