import { useContext } from 'react'
import { CollapsedSectionContext } from '@/components/RadioCollapsedSection'

export const useCollapsedSectionTabIndex = (tabIndex?: number) => {
  const context = useContext(CollapsedSectionContext)

  if (tabIndex) {
    return tabIndex
  }

  let collapsedSectionTabIndex = 0

  if (context) {
    collapsedSectionTabIndex = context.isExpanded ? 0 : -1
  }

  return collapsedSectionTabIndex
}
