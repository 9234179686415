import { Dialog } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'
import { DialogProps } from '../../models/dialog-props.ts'

interface BookingPolicyDialogProps extends DialogProps {
  bookingPolicy?: string
}

export const BookingPolicyDialog = (props: BookingPolicyDialogProps) => {
  const { t } = useTranslation()
  const { bookingPolicy, ...rest } = props

  if (!bookingPolicy) return null

  return (
    <Dialog scrollContent size="sm" title={t('common.bookingPolicy')} {...rest}>
      <p className="p-4">{bookingPolicy}</p>
    </Dialog>
  )
}
