import { DateTime } from 'luxon'

export const formatDateRange = (
  startDate: string,
  endDate: string,
  locale: string
) => {
  const start = DateTime.fromISO(startDate).setLocale(locale)
  const end = DateTime.fromISO(endDate).setLocale(locale)

  const shortFormat = 'd LLL'
  const fullFormat = 'd MMMM yyyy'

  if (start.equals(end)) {
    return {
      full: start.toFormat(fullFormat),
      short: start.toFormat(shortFormat),
    }
  }
  return {
    full: `${start.toFormat(fullFormat)} - ${end.toFormat(fullFormat)}`,
    short: `${start.toFormat(shortFormat)} - ${end.toFormat(shortFormat)}`,
  }
}
