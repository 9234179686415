import * as rd from '@devexperts/remote-data-ts'
import { isSuccess, RemoteData } from '@devexperts/remote-data-ts'
import { createSelector } from '@reduxjs/toolkit'
import { httpClient } from '@/lib/http/HttpClient.ts'
import { BackendErrorModel } from '@/models/backendErrorModel.ts'
import { ResponseBase } from '@/models/http.ts'
import { WidgetWhiteLabel } from '@/models/widget.ts'
import { appSlice } from '@/store/appSlice.ts'
import { RootState } from '@/store/configureStore.ts'
import { createAppSlice } from '@/store/createAppSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { isBrowserError } from '@/utils/typeGuards.ts'

interface WidgetSliceState {
  whiteLabel: RemoteData<string, WidgetWhiteLabel>
}

const initialState: WidgetSliceState = {
  whiteLabel: rd.initial,
}

export const widgetSlice = createAppSlice({
  initialState,
  name: 'widget',
  reducers: (create) => ({
    fetchWidgetWhiteLabel: create.asyncThunk<
      WidgetWhiteLabel,
      { previewCode: string | null },
      { rejectValue: BackendErrorModel | { code: string } }
    >(
      async ({ previewCode }, thunkAPI): Promise<WidgetWhiteLabel> => {
        const rootState = thunkAPI.getState() as RootState

        const license = rd.isSuccess(rootState.license.license)
          ? rootState.license.license.value.license.license
          : ''

        if (!license) {
          throw thunkAPI.rejectWithValue({ code: 'License not found' })
        }

        const country = rootState.app.country
        const queryString = previewCode ? `?previewCode=${previewCode}` : ''

        const response = await httpClient.fetch<
          ResponseBase<WidgetWhiteLabel>,
          BackendErrorModel
        >(`beaches/${license}/widget/white-label${queryString}`, country, {
          headers: {
            'content-type': 'application/json',
          },
        })

        if (response.status === 'error') {
          throw thunkAPI.rejectWithValue(response.error)
        }

        return response.data.result
      },
      {
        fulfilled: (state, action) => {
          state.whiteLabel = rd.success(action.payload)
        },
        pending: (state) => {
          state.whiteLabel = rd.pending
        },
        rejected: (state, action) => {
          state.whiteLabel = rd.failure(
            isBrowserError(action.payload)
              ? action.payload.code
              : action.payload?.error || ''
          )
        },
      }
    ),
  }),
  selectors: {
    backgroundImage: getWhiteLabelState(
      (whiteLabel) => whiteLabel.pageBackgroundImage
    ),
    textFont: getWhiteLabelState((whiteLabel) => whiteLabel.textFont),
    whiteLabel: (state) => state.whiteLabel,
  },
})

type GetWhiteLabelDataCallback<T> = (state: WidgetWhiteLabel) => T

function getWhiteLabelState<T>(callback: GetWhiteLabelDataCallback<T>) {
  return (state: WidgetSliceState) =>
    isSuccess(state.whiteLabel) && state.whiteLabel.value
      ? callback(state.whiteLabel.value)
      : null
}

export const selectIsWhiteLabelActive = createSelector(
  [
    licenseSlice.selectors.isWidgetWhiteLabelActive,
    appSlice.selectors.isWidgetMode,
  ],
  (isWhiteLabelActive, isWidgetMode) => isWhiteLabelActive && isWidgetMode
)
