import { t } from 'i18next'

import { cartChangeQuotableSeatQuantity } from '@/store/cartSlice/thunks/cartChangeQuotableSeatQuantity.ts'
import { ItemQuantityController } from '../../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch } from '../../../hooks/store'
import { changeQuantityActioType } from '../../../models/cart'
import { MapSetupItem } from '../../../models/map'
import { toastSlice } from '../../../store/toastSlice'

interface Props {
  remainingGlobal: number
  item: MapSetupItem
}
export const SetupSeatListItem = (props: Props) => {
  const dispatch = useAppDispatch()

  const dynamicKey = props.item.keyName

  const handleAddSeat = () => {
    dispatch(
      cartChangeQuotableSeatQuantity({
        actionType: changeQuantityActioType.ADD,
        dynamicKey,
      })
    )
  }

  const handleRemoveSeat = () => {
    dispatch(
      cartChangeQuotableSeatQuantity({
        actionType: changeQuantityActioType.REMOVE,
        dynamicKey,
      })
    )
  }

  const onAddDisabled = () =>
    dispatch(
      toastSlice.actions.show({
        message: t('map.elementDetail.maximumSeatsLimitReached'),
      })
    )
  return (
    <div className="relative">
      <ItemQuantityController
        available={{
          global: props.remainingGlobal,
          group: props.item?.max,
          ticket: props.item.max,
        }}
        hasLimit={props.item.max >= 0}
        icon={props.item.icon}
        initialQuantity={props.item.d}
        key={props.item.keyName}
        minimumQuantity={props.item?.min}
        name={props.item.label}
        onAdd={() => handleAddSeat()}
        onAddDisabled={() => onAddDisabled()}
        onRemove={() => handleRemoveSeat()}
      />
    </div>
  )
}
