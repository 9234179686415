import * as RadixTooltip from '@radix-ui/react-tooltip'
import { cn, Dialog } from '@spiaggeit/spit-ui'
import {
  ComponentPropsWithoutRef,
  ElementType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  content: string
  maxLines: 1 | 2 | 3
  wrapper?: ElementType
  className?: string
  id?: string
  overflowStyle?: string
  dialogWrapperProps?: ComponentPropsWithoutRef<'div'>
}

export const Tooltip = (props: Props) => {
  const { wrapper: Wrapper = 'div', id, dialogWrapperProps } = props
  const { className: dialogWrapperClassName, ...rest } =
    dialogWrapperProps || {}

  const { textRef, isOverflowing, tooltipContainerHeight } =
    useCheckOverflowing({
      maxLines: props.maxLines,
    })

  const className = cn(
    `overflow-hidden text-ellipsis text-left break-words`,
    {
      [`${props?.className}`]: props.className,
      [`${props?.overflowStyle} cursor-pointer underline lg:cursor-help`]:
        isOverflowing,
    },
    // please note: this workaround is necessary since tailwind does not work well with
    // dynamically generated classes, it needs a full and 'ready to use' class name,
    // as explained here https://stackoverflow.com/a/71792010
    // of course if we ever need to use the tooltip with a maxLines > we can update
    // the component with a larger number
    {
      'line-clamp-1': props.maxLines === 1,
      'line-clamp-2': props.maxLines === 2,
      'line-clamp-3': props.maxLines === 3,
    }
  )

  const WrappedContent = (
    <Wrapper aria-label={props.content} className={className}>
      {props.content}
    </Wrapper>
  )

  return (
    <div style={{ maxHeight: isOverflowing ? tooltipContainerHeight : 'auto' }}>
      {isOverflowing && (
        <>
          <div className="hidden lg:block">
            <RadixTooltip.Root>
              <RadixTooltip.Trigger asChild className="cursor-pointer">
                {WrappedContent}
              </RadixTooltip.Trigger>
              <RadixTooltip.Content
                className="z-900 max-w-80 rounded bg-black  p-2 text-sm text-white"
                side="bottom"
              >
                {props.content}
                <RadixTooltip.Arrow />
              </RadixTooltip.Content>
            </RadixTooltip.Root>
          </div>

          <div className={cn('lg:hidden', dialogWrapperClassName)} {...rest}>
            <Dialog
              mobileMode="bottomSheet"
              title={props.content}
              trigger={WrappedContent}
            />
          </div>
        </>
      )}

      <Wrapper
        aria-label={props.content}
        className={cn(className, {
          'pointer-events-none opacity-0': isOverflowing,
        })}
        id={id}
        ref={textRef}
        {...(isOverflowing && {
          'aria-hidden': true,
        })}
      >
        {props.content}
      </Wrapper>
    </div>
  )
}

const useCheckOverflowing = (params: { maxLines: number }) => {
  const textRef = useRef<HTMLParagraphElement>(null)
  const { maxLines } = params
  const [isOverflowing, setIsOverflowing] = useState(false)
  const checkTimeoutId = useRef<number | null>(null)

  const [tooltipContainerHeight, setTooltipContainerHeight] =
    useState<number>(0)

  const location = useLocation()

  const checkOverflow = useCallback(() => {
    if (checkTimeoutId.current) {
      window.clearTimeout(checkTimeoutId.current)
    }

    checkTimeoutId.current = window.setTimeout(() => {
      const element = textRef.current
      if (!element) return

      const lineHeight = parseFloat(getComputedStyle(element).lineHeight)

      const contentHeight = element.scrollHeight
      const maxHeight = lineHeight * maxLines
      if (contentHeight > maxHeight) {
        setTooltipContainerHeight(maxHeight)
      } else {
        setTooltipContainerHeight(contentHeight)
      }
      setIsOverflowing(contentHeight > maxHeight)
    }, 100)
  }, [maxLines])

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [checkOverflow, location])

  return {
    isOverflowing,
    textRef,
    tooltipContainerHeight,
  }
}
