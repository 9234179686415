import countries from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import es from 'i18n-iso-countries/langs/es.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import it from 'i18n-iso-countries/langs/it.json'
import pt from 'i18n-iso-countries/langs/pt.json'

export const registerLocales = () => {
  countries.registerLocale(en)
  countries.registerLocale(it)
  countries.registerLocale(es)
  countries.registerLocale(fr)
  countries.registerLocale(pt)
  countries.registerLocale(de)
}
