import { ReactNode, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { INSERT_PERIOD_PATH } from '@/app/router/paths.ts'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { store } from '@/store/configureStore.ts'
import { REG_ID_QUERY_PARAM_NAME } from '@/utils/constants.ts'

export const PayGuard = ({
  children,
}: {
  children: (params: {
    paymentLinkId?: string | null
    reservationId: string
  }) => ReactNode
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)

  const reservationId = searchParams.get(REG_ID_QUERY_PARAM_NAME)
  const paymentLinkId = searchParams.get('pay')

  const getCartDetail = async () => {
    if (!reservationId) {
      navigate(insertPeriodPath)
      return
    }

    try {
      await store
        .dispatch(
          cartSlice.actions.getCartDetail({
            reservationId,
            ...(paymentLinkId && { paymentLinkId }),
          })
        )
        .unwrap()
    } catch {
      navigate(insertPeriodPath)
    }
  }

  useEffect(() => {
    void getCartDetail()
  }, [])

  return <>{children({ paymentLinkId, reservationId: reservationId! })}</>
}
