import { ComponentPropsWithoutRef, useId } from 'react'

import { DateRange } from '../../DateRange'

export interface ReservationLocationProps
  extends ComponentPropsWithoutRef<'section'> {
  beachName: string
  location: string
  region: string
  startDate: string
  endDate: string
}

export const ReservationLocation = (props: ReservationLocationProps) => {
  const { beachName, location, region, startDate, endDate, ...rest } = props
  const titleId = useId()
  const descriptionId = useId()

  return (
    <section
      aria-describedby={descriptionId}
      aria-labelledby={titleId}
      {...rest}
    >
      <h3 className="font-bold" id={titleId}>
        {beachName}
      </h3>
      <p className="text-secondary" id={descriptionId}>
        {location}, {region}
        <br />
        <DateRange endDate={endDate} startDate={startDate} />
      </p>
    </section>
  )
}
