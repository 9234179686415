import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface ReservationHeaderProps
  extends ComponentPropsWithoutRef<'header'> {
  isRefundable: boolean
  titleId: string
  descriptionId: string
}

export const ReservationHeader = (props: ReservationHeaderProps) => {
  const { isRefundable, titleId, descriptionId, ...rest } = props
  const { t } = useTranslation()

  return (
    <header className="flex items-center justify-between" {...rest}>
      <h2 className="text-lg font-bold" id={titleId}>
        {t('common.recap')}
      </h2>

      <p className="sr-only" id={descriptionId}>
        {t('common.recapDescription')}
      </p>
    </header>
  )
}
