import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { cn } from '@spiaggeit/spit-ui'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { licenseSlice } from '@/store/licenseSlice'
import { selectIsWhiteLabelActive } from '@/store/widgetSlice'
import {
  CHOOSE_PRODUCT_PATH,
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
  MAP_PATH,
  SECTOR_PATH,
  TICKETS_PATH,
} from '../../app/router/paths'
import { Calendar } from '../../assets/icons/Calendar'
import { useAppSelector } from '../../hooks/store'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'

export function InsertPeriodLink() {
  const license = useAppSelector(licenseSlice.selectors.license)
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const bookingPeriod = useAppSelector(
    insertPeriodSlice.selectors.bookingPeriod
  )
  const isWhiteLabelActive = useAppSelector(selectIsWhiteLabelActive)

  const { i18n } = useTranslation()

  const { currentPath } = useCurrentPath()

  if (
    !license ||
    !period ||
    ![
      CHOOSE_PRODUCT_PATH,
      INSERT_INSURANCE_PATH,
      INSERT_DATA_PATH,
      TICKETS_PATH,
      SECTOR_PATH,
      MAP_PATH,
    ].includes(currentPath)
  ) {
    return null
  }

  const startDateTime = DateTime.fromISO(period.start, {
    locale: i18n.language,
    zone: 'utc',
  })

  const endDateTime = DateTime.fromISO(period.end, {
    locale: i18n.language,
    zone: 'utc',
  })

  const arePeriodsEqual = period?.end === period?.start
  const areMonthsEqual = startDateTime.month === endDateTime.month

  let startDateFormat = 'dd'

  if (arePeriodsEqual || !areMonthsEqual) {
    startDateFormat = 'dd MMM'
  }

  const startDate = startDateTime.toFormat(startDateFormat)
  const endDate = arePeriodsEqual ? null : endDateTime.toFormat('dd MMM')
  const isBookingPeriodHalfDay =
    bookingPeriod === BookingPeriod.MORNING ||
    bookingPeriod === BookingPeriod.AFTERNOON
  const dateString = endDate ? `${startDate} - ${endDate}` : startDate
  const bookingPeriodString = t(`datepicker.bookingPeriod.${bookingPeriod}`)

  return (
    <Link
      className={cn(
        'border-secondary flex flex-initial items-center space-x-2 rounded-sm border px-3 py-2 text-sm font-bold text-primary',
        {
          'space-x-1': isWhiteLabelActive,
        }
      )}
      to={`${generatePath(INSERT_PERIOD_PATH, {
        license: license.license,
      })}`}
    >
      <Calendar className="h-5 w-5" />

      <span className="text-nowrap">
        <span>{dateString}</span>
        {isBookingPeriodHalfDay ? ` · ${bookingPeriodString}` : null}
      </span>
    </Link>
  )
}
