import { useForm } from 'react-hook-form'
import { useAppSelector } from '@/hooks/store.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { getLocalPhonePrefix } from '@/utils/getLocalPhonePrefix.ts'

export const useUserDataForm = () => {
  const discountCode = useAppSelector(cartSlice.selectors.discountCode)

  return useForm<InsertDataFormType>({
    defaultValues: getDefaultValues(discountCode),
    mode: 'onBlur',
  })
}

const getDefaultValues = (discountCode: string) => ({
  dog: {
    breed: '',
    chipNumber: '',
    gender: 'male',
    name: '',
  },
  invoice: {
    address: '',
    businessName: '',
    certifiedEmail: '',
    city: '',
    country: '',
    fiscalCode: '',
    houseNumber: '',
    province: '',
    request: 'receipt',
    sdiCode: '',
    vatNumber: '',
    zipCode: '',
  },
  notes: '',
  promo: {
    discountCode,
    newsletter: false,
  },
  user: {
    email: '',
    firstName: '',
    fiscalCode: '',
    lastName: '',
    phoneNumber: '',
    phonePrefix: getLocalPhonePrefix(),
  },
})

export type InsertDataFormType = ReturnType<typeof getDefaultValues>
