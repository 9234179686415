import { useFormattedDateRange } from '../../hooks/useFormattedDateRange.ts'

interface DateRangeProps {
  startDate: string
  endDate: string
}

export const DateRange = (props: DateRangeProps) => {
  const formattedDateRange = useFormattedDateRange(
    props.startDate,
    props.endDate
  )

  return (
    <span aria-label={formattedDateRange.full} title={formattedDateRange.full}>
      {formattedDateRange.short}
    </span>
  )
}
