import * as rd from '@devexperts/remote-data-ts'
import { isSuccess, RemoteData } from '@devexperts/remote-data-ts'
import { createSelector } from '@reduxjs/toolkit'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'

import { ResponseBase } from '@/models/http.ts'
import { Errors } from '@/utils/errors.ts'
import { httpClient } from '../lib/http/HttpClient'
import {
  FeaturedService,
  GroupIds,
  Service,
  ServiceGroup,
  ServiceInfoPurchaseType,
  ServiceLimitType,
  ServicesInfoResponse,
} from '../models/service'

import { appSlice } from './appSlice'
import { resetAllState, setupByUrl } from './commonActions'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'
import {
  insertPeriodSlice,
  periodAsUnixTimeSelector,
} from './insertPeriodSlice'
import { licenseSlice } from './licenseSlice'

function getGroupIds(groupIds: string | null): GroupIds {
  if (groupIds === null) {
    return null
  }

  const validGroupIds = groupIds
    .split(',')
    .map((value) => parseInt(value))
    .filter((number) => !isNaN(number) && number >= 0)

  return validGroupIds.length > 0 ? validGroupIds : null
}

interface ServicesResponse {
  availableQuantity: number
  hasLimit: boolean
  serviceGroups: ServiceGroup[]
  globalLimit: number
  includedServices: Service[]
  extraFeaturedServices: FeaturedService[]
  extraServices: Service[]
  ticketServices: Service[]
}

type ServicesSliceState = {
  groupIds: GroupIds
  featuredServices: RemoteData<string, FeaturedService[]>
  services: RemoteData<string, ServicesResponse>
}

const initialState: ServicesSliceState = {
  featuredServices: rd.initial,
  groupIds: null,
  services: rd.initial,
}

function mapBookingPeriod(
  value: ReturnType<typeof insertPeriodSlice.selectors.bookingPeriod>
) {
  switch (value) {
    case BookingPeriod.ALL_DAY:
      return 0
    case BookingPeriod.MORNING:
      return 1
    case BookingPeriod.AFTERNOON:
      return 2
    default:
      return 0
  }
}

export const servicesSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)
    builder.addCase(setupByUrl, (state, action) => {
      const url = new URL(action.payload)
      state.groupIds = getGroupIds(url.searchParams.get('groupIds'))
    })
  },
  initialState: initialState satisfies ServicesSliceState as ServicesSliceState,
  name: 'services',
  reducers: (create) => ({
    fetchFeaturedServices: create.asyncThunk<
      ServicesInfoResponse,
      void,
      { rejectValue: string }
    >(
      async (_, thunkAPI): Promise<ServicesInfoResponse> => {
        const rootState = thunkAPI.getState() as RootState
        const period = periodAsUnixTimeSelector(rootState)
        const country = appSlice.selectors.country(rootState)
        const bookingPeriod =
          insertPeriodSlice.selectors.bookingPeriod(rootState)
        const license = licenseSlice.selectors.licenseCode(rootState)

        if (!period) {
          throw thunkAPI.rejectWithValue('Period not found')
        }

        const res = await httpClient.fetch<ResponseBase<ServicesInfoResponse>>(
          `beaches/${license}/services-info`,
          country,
          {
            body: JSON.stringify({
              featured: true,
              from: period.start,
              halfDay: mapBookingPeriod(bookingPeriod),
              serviceType: 1,
              to: period.end,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          throw thunkAPI.rejectWithValue(res.error)
        }
        return res.data.result
      },
      {
        fulfilled: (state, action) => {
          state.featuredServices = rd.success(
            action.payload.limits.extraServiceLimits
          )
        },
        pending: (state) => {
          state.featuredServices = rd.pending
        },
        rejected: (state, action) => {
          state.featuredServices = rd.failure(action.payload || 'Unknown Error')
        },
      }
    ),
    load: create.asyncThunk<
      ServicesInfoResponse,
      { purchaseType: ServiceInfoPurchaseType },
      { rejectValue: string }
    >(
      async ({ purchaseType }, thunkApi): Promise<ServicesInfoResponse> => {
        const rootState = thunkApi.getState() as RootState
        const country = appSlice.selectors.country(rootState)
        const period = periodAsUnixTimeSelector(rootState)
        const bookingPeriod =
          insertPeriodSlice.selectors.bookingPeriod(rootState)
        const isRequestOnlyForTickets =
          purchaseType === ServiceInfoPurchaseType.ALONE
        const license = licenseSlice.selectors.licenseCode(rootState)

        if (!period) {
          throw thunkApi.rejectWithValue(Errors.PeriodNotFound)
        }

        const res = await httpClient.fetch<ResponseBase<ServicesInfoResponse>>(
          `beaches/${license}/services-info`,
          country,
          {
            body: JSON.stringify({
              from: period.start,
              halfDay: mapBookingPeriod(bookingPeriod),
              ...(isRequestOnlyForTickets
                ? { purchaseOnlineAlone: true, serviceType: 3 }
                : { purchaseOnlineWithSpot: true }),
              to: period.end,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data.result
      },
      {
        fulfilled: (state, action) => {
          const globalLimit =
            action.payload.limits.availableQuantity -
            action.payload.limits.reservedQuantity

          const serviceGroups = action.payload.limits.serviceGroupLimits.map(
            (group) => ({
              availableQuantity: group.availableQuantity,
              hasLimit: group.hasLimit,
              name: group.name,
              reservedQuantity: group.reservedQuantity,
              serviceGroupId: group.serviceGroupId,
              services: group.serviceLimits,
            })
          )

          const ticketServices = serviceGroups?.flatMap((group) =>
            group.services.map((service) => {
              return {
                ...service,
                groupHasLimit: group.hasLimit,
                groupLimit: group.availableQuantity - group.reservedQuantity,
                serviceGroupId: group.serviceGroupId,
              }
            })
          )

          const extraServicesLimits = action.payload.limits.extraServiceLimits

          const extraServices = extraServicesLimits?.filter(
            (service) =>
              service.minimumLimitType === ServiceLimitType.FLAT &&
              service.minimumQuantity < 1
          )

          const includedServices = extraServicesLimits?.filter(
            (service) =>
              service.minimumLimitType === ServiceLimitType.NUMBER_OF_DAYS ||
              service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES ||
              service.minimumQuantity > 0
          )

          state.services = rd.success({
            availableQuantity: action.payload.limits.availableQuantity,
            extraFeaturedServices:
              action.payload.limits.extraServiceLimits.filter(
                (service) => service.featured
              ),
            extraServices,
            globalLimit,
            hasLimit: action.payload.limits.hasLimit,
            includedServices,
            serviceGroups,
            ticketServices,
          })
        },
        pending: (state) => {
          state.services = rd.pending
        },
        rejected: (state, action) => {
          state.services = rd.failure(action.payload || 'Unknown Error')
        },
      }
    ),
  }),
  selectors: {
    data: (state) => (isSuccess(state.services) ? state.services.value : null),
    featuredServices: (state) =>
      isSuccess(state.featuredServices) ? state.featuredServices.value : null,
    groupIds: (state) => state.groupIds,
    services: (state) => state.services,
  },
})

export const filteredGroupsSelector = createSelector(
  [servicesSlice.selectors.data, servicesSlice.selectors.groupIds],
  (data, groupIds): ServiceGroup[] => {
    if (data === null) {
      return []
    }
    const globalLimit = data.globalLimit

    const serviceGroups = data.serviceGroups.map((group) => ({
      ...group,
      services: group.services.map((service) => {
        return {
          ...service,
          globalLimit,
          groupHasLimit: group.hasLimit,
          groupLimit: group.availableQuantity - (group.reservedQuantity ?? 0),
          serviceGroupId: group.serviceGroupId,
        }
      }),
    }))

    if (groupIds === null) {
      // no filter
      return serviceGroups
    }

    const filteredServiceGroups = serviceGroups.filter((group) =>
      groupIds.includes(group.serviceGroupId)
    )

    return filteredServiceGroups
  }
)
