import { useCartRecapData } from '@/hooks/useCartRecapData.ts'

import { RecapDetails } from '../RecapDetails'

interface ReservationRecapDetailsProps {
  className?: string
  useCartInfoData?: boolean
}

export const ReservationRecapDetails = (
  props: ReservationRecapDetailsProps
) => {
  const { useCartInfoData, ...restProps } = props

  const {
    costs,
    total,
    location,
    reservations,
    beachName,
    endDate,
    startDate,
    region,
  } = useCartRecapData(useCartInfoData)

  const summaryProps = {
    beachName,
    endDate,
    location,
    region,
    startDate,
    total,
  }

  return (
    <RecapDetails {...restProps} {...(!total && { as: 'div' })}>
      <RecapDetails.Summary {...summaryProps} />

      {!!total && (
        <>
          <RecapDetails.Reservations
            itemWrapperClassName="p-4"
            reservations={reservations}
            separatorClassName="my-0 border-gray-100"
          />

          <RecapDetails.Costs
            className="border-t border-t-gray-100 bg-gray-25 p-4"
            costs={costs}
            total={total}
          />
        </>
      )}
    </RecapDetails>
  )
}
