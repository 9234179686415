import { createAsyncThunk } from '@reduxjs/toolkit'
import { changeQuantityActioType, QuoteSetupItems } from '@/models/cart.ts'
import { cartSlice } from '@/store/cartSlice'
import { RootState } from '@/store/configureStore.ts'

/* 
  increase or decrease of the service configuration of our selectedElement.
  Changing the configuration of selectedElement will trigger a new /quote action
  and will update the final price preview for the user to see
*/
export const cartChangeQuotableServiceQuantity = createAsyncThunk<
  void,
  //dynamicKey of a service will always have the following structure: "id"+"serviceId" (e.g. "id039202")
  { dynamicKey: string; actionType: changeQuantityActioType },
  { state: RootState }
>('cart/cartChangeQuotableServiceQuantity', async (action, thunkAPI) => {
  const { dispatch, getState } = thunkAPI
  const rootState = getState()
  const selectedElementQuotableProducts =
    cartSlice.selectors.selectedElementQuotableProducts(rootState)

  const quotableItem =
    selectedElementQuotableProducts.services[
      action.dynamicKey as keyof Omit<QuoteSetupItems, 'services'>
    ] || 0 //please note: this 0 is necessary for cases when a service is not already quoted (e.g.: extra services which have not been added to quote yet)

  const quotableValue =
    action.actionType === changeQuantityActioType.ADD ? 1 : -1

  const updatedProducts = {
    ...selectedElementQuotableProducts,
    services: {
      ...selectedElementQuotableProducts?.services,
      [action.dynamicKey]: quotableItem + quotableValue,
    },
  }

  dispatch(
    cartSlice.actions.setSelectedElementQuotableProducts(updatedProducts)
  )
})
