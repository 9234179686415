import { Dialog } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

import { LegendContent } from './LegendContent'

export const LegendDialog = () => {
  const { t } = useTranslation()

  return (
    <Dialog
      scrollContent
      size="sm"
      title={t('map.legend.title')}
      trigger={
        <button className="flex items-center rounded border bg-white px-4 py-2">
          <span className="text-sm font-bold text-primary lg:hidden">
            {t('map.legend.title')}
          </span>
          <span className="hidden text-sm font-bold text-primary lg:inline">
            {t('map.legend.showLegendCta')}
          </span>
        </button>
      }
    >
      <div className="flex items-start gap-3 rounded p-3">
        <LegendContent />
      </div>
    </Dialog>
  )
}
