import {
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
} from '@/app/router/paths'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { useAppSelector } from './store'
import { useIsExtraStepNecessary } from './useIsExtraStepNecessary'

export const useNextPathAfterChoosingSpot = (path?: string) => {
  const license = useAppSelector(licenseSlice.selectors.license)
  const isExtraStepNecessary = useIsExtraStepNecessary()
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const insertInsurancePath = useAppPath(INSERT_INSURANCE_PATH)
  const insertDataPath = useAppPath(INSERT_DATA_PATH)

  if (path) {
    return path
  }

  if (!license) {
    return insertPeriodPath
  }

  if (isExtraStepNecessary) {
    return insertInsurancePath
  }

  return insertDataPath
}
