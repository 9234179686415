import { isSuccess } from '@devexperts/remote-data-ts'
import { PayloadAction } from '@reduxjs/toolkit'
import { CartSliceState } from '@/store/cartSlice/type.ts'

/**
 * Add item in cart
 * @param state
 * @param action
 */
export const addItemToCart = (
  state: CartSliceState,
  action: PayloadAction<{
    id: number
  }>
) => {
  state.cartItems[action.payload.id] = {
    id: action.payload.id,
    quantity: 1,
  }

  if (state.selectedElementReservation) {
    const filtered = state.cartReservations.filter((reservation) => {
      return (
        `${reservation.spotName}${reservation.spotType}` !==
        `${state.selectedElementReservation?.spotName}${state.selectedElementReservation?.spotType}`
      )
    })
    if (isSuccess(state.quote)) {
      state.total = state.quote.value.totalPrice
      state.fees = state.quote.value.fees
    }
    state.cartReservations = [...filtered, state.selectedElementReservation]
  }
}
