import * as rd from '@devexperts/remote-data-ts'

export const isUnset = (data: rd.RemoteData<unknown, unknown>) => {
  return rd.isInitial(data) || rd.isPending(data)
}

export const onSuccess = <TData, TReturn>(
  remoteData: rd.RemoteData<unknown, TData>,
  callback: (data: TData) => TReturn
) => {
  if (!rd.isSuccess(remoteData)) return false

  return callback(remoteData.value)
}
