import { QuoteReservation } from '@/models/quote.ts'
import { APISectorDetailKeyType, MapSetupItem } from '../models/map'

import { mapSetupElements } from './constants'

interface Props {
  setupItem: MapSetupItem
  selectedElementCartReservation?: QuoteReservation
}
export const getSeatDefaultQuantity = ({
  setupItem,
  selectedElementCartReservation,
}: Props) => {
  if (selectedElementCartReservation) {
    const dynamicKey = mapSetupElements[setupItem.keyName].quoteReservationKey
    return selectedElementCartReservation[
      dynamicKey as keyof Pick<
        QuoteReservation,
        'beds' | 'chairs' | 'deckChairs' | 'maxiBeds'
      >
    ]
  } else if (
    setupItem[APISectorDetailKeyType.MIN] >
    setupItem[APISectorDetailKeyType.DEFAULT]
  ) {
    return setupItem[APISectorDetailKeyType.MIN]
  } else if (
    setupItem[APISectorDetailKeyType.DEFAULT] >
    setupItem[APISectorDetailKeyType.MAX]
  ) {
    return setupItem[APISectorDetailKeyType.MAX]
  } else return setupItem[APISectorDetailKeyType.DEFAULT]
}
