import * as RadixTooltip from '@radix-ui/react-tooltip'
import * as Sentry from '@sentry/react'
import { ToastProvider } from '@spiaggeit/spit-ui'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './app/i18n'
import './global.scss'
import './setupSentry'
import { Provider } from 'react-redux'
import { LoaderTrigger } from '@/components/LoaderTrigger'
import { Router } from '@/app/router'
import * as printerIncludes from '@/utils/anm22Printer'
import { registerLocales } from '@/utils/registerLocales.ts'
import { store } from './store/configureStore'

registerLocales()

window.androidAppEnable = true

Object.assign(window, printerIncludes)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
      <Provider store={store}>
        <Suspense fallback={<LoaderTrigger />}>
          <ToastProvider>
            <RadixTooltip.Provider>
              <Router />
            </RadixTooltip.Provider>
          </ToastProvider>
        </Suspense>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
