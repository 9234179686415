import { createSelector } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { SpotType } from '@/models/cart.ts'
import { APISectorDetailKeyType } from '@/models/map.ts'
import { cartSlice } from '@/store/cartSlice'
import { licenseSlice } from '@/store/licenseSlice'
import {
  mapSelectedElementSetupDataSelector,
  mapSelectors,
} from '@/store/mapSlice.ts'
import {
  FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT,
  spotTypesWithoutSetupSeats,
} from '@/utils/constants.ts'

export const selectCartQuoteErrorMessage = createSelector(
  [
    mapSelectedElementSetupDataSelector,
    cartSlice.selectors.selectedElementQuotedSeatsAmount,
    cartSlice.selectors.items,
    licenseSlice.selectors.license,
    mapSelectors.selectedElement,
  ],
  (
    mapData,
    selectedElementQuotedSeatsAmount,
    cartItems,
    license,
    selectedElement
  ) => {
    const selectedElementSetupLimit = mapData?.selectedElementSetupLimit
    const isMaximumSeatAmountRespected =
      !selectedElementSetupLimit ||
      selectedElementQuotedSeatsAmount <=
        selectedElementSetupLimit[APISectorDetailKeyType.MAX]
    const isMinimumSeatAmountRespected =
      !selectedElementSetupLimit ||
      selectedElementQuotedSeatsAmount >=
        selectedElementSetupLimit[APISectorDetailKeyType.MIN]
    const isElementWithoutSeats = spotTypesWithoutSetupSeats.includes(
      selectedElement?.subType as SpotType
    )
    const cartItemsLimit =
      license?.bookingUserUmbrellasLimit ||
      FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT
    const cartItemsAmount = Object.values(cartItems).length
    const isMaximumCartItemsReached = cartItemsAmount === cartItemsLimit
    if (isMaximumCartItemsReached) {
      return t('map.errors.mapSpotsLimitExceeded')
    } else if (!isMaximumSeatAmountRespected) {
      return t('map.errors.invalidSetupConfiguration')
    } else if (!isMinimumSeatAmountRespected && isElementWithoutSeats) {
      //please note: this is necessary when an element of type boat/parking/playfield
      // has been created in a sector requires a minimum number of seats but does not
      return t('map.errors.invalidSetupConfiguration')
    } else return ''
  }
)
