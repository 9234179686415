import { Service, ServiceLimitType } from '../models/service'

export const getVisibleServices = ({
  selectedElementHasVisibleSeats,
  services,
}: {
  selectedElementHasVisibleSeats: boolean
  services: Service[]
}) => {
  // If the user is buying a spot without visible seats we need to hide any reference
  // to services with a limit type NUMBER_OF_PIECES (check out this discord thread for more info: https://discord.com/channels/955037698492612618/1311360172668751903/1334855700421148786)
  return services.filter((service) => {
    const serviceHasNumberOfPiecesLimitType =
      service.minimumLimitType === ServiceLimitType.NUMBER_OF_PIECES ||
      service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES
    return !serviceHasNumberOfPiecesLimitType || selectedElementHasVisibleSeats
  })
}
