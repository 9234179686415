import { Button, Dialog } from '@spiaggeit/spit-ui'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'

import { licenseSlice } from '@/store/licenseSlice'
import { LANDING_KIOSK_PATH } from '../../../app/router/paths'
import { useAppSelector } from '../../../hooks/store'

import { Props } from '.'

export const KioskTimeoutDialogContent = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [timer, setTimer] = useState(20)
  const license = useAppSelector(licenseSlice.selectors.license)

  useEffect(() => {
    window.setTimeout(() => {
      setIsOpen(true)
    }, props.timeout)
  }, [])

  useInterval(() => {
    if (!isOpen) return

    if (timer <= 0 && license) {
      onKill()
    }
    setTimer((state) => Math.max(0, state - 1))
  }, 1000)

  const onKeep = () => {
    setIsOpen(false)
    setTimer(20)
    window.setTimeout(() => {
      setIsOpen(true)
    }, props.timeout)
  }

  const onKill = async () => {
    if (props.onKill) {
      await props.onKill()
    }

    setIsOpen(false)

    if (license) {
      navigate(
        generatePath(LANDING_KIOSK_PATH, {
          license: license.license,
        })
      )
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={(value: boolean) => {
        setIsOpen(value)
        onKeep()
      }}
    >
      <div className="p-4">
        <p className="mb-px font-bold">{t('kiosk.inactivityDialog.title')}</p>

        <p className="mb-6 text-sm">
          <Trans
            i18nKey={t('kiosk.inactivityDialog.description', {
              time: timer,
            })}
          />
        </p>

        <div className="flex justify-between space-x-4">
          <Button
            color="secondary"
            fullWidth
            onClick={onKill}
            size="sm"
            variant="outline"
          >
            {t('kiosk.inactivityDialog.kill')}
          </Button>

          <Button fullWidth onClick={onKeep} size="sm">
            {t('kiosk.inactivityDialog.keep')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
