import { QuoteReservation } from '@/models/quote.ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const selectFormattedReservations = (
  state: CartSliceState
): Record<string, QuoteReservation> => {
  return state.cartReservations.reduce(
    (acc, reservation) => ({
      ...acc,
      [`_${reservation.spotType}_${reservation.spotName}`]: {
        ...reservation,
        services: Object.entries(reservation.services).reduce(
          (acc, [key, value]) => {
            if (value === 0) return acc
            return { ...acc, [key]: value }
          },
          {}
        ),
      },
    }),
    {}
  )
}
