import { Button, cn } from '@spiaggeit/spit-ui'
import { Check, ChevronsUpDown } from 'lucide-react'
import { useId, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { inputBaseClassName } from '@/components/Input'
import { InputError } from '@/components/Input/InputError'
import { Select } from '@/components/Select'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shadcn/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shadcn/popover'

import { useCollapsedSectionTabIndex } from '@/hooks/useCollapsedSectionTabIndex.ts'
import { InputBase } from '@/models/inputBase.ts'
import { Option } from '@/models/option.ts'

export type CommandFilter = (
  value: string,
  search: string,
  keywords?: string[]
) => number

interface ComboboxProps extends InputBase {
  options: Option[]
  name: string
  useValueAsFilter?: boolean
  label?: string
  noOptionFoundLabel?: string
  filterPlaceholder?: string
  selectPlaceholder?: string
  filter?: CommandFilter
}

export function Combobox(props: ComboboxProps) {
  const { t } = useTranslation()

  const {
    options,
    name,
    useValueAsFilter,
    label,
    noOptionFoundLabel = t('placeholder.noOptionFound'),
    rules,
    selectPlaceholder = t('placeholder.choose'),
    filterPlaceholder = t('common.search'),
    filter,
  } = props

  const [open, setOpen] = useState(false)

  const {
    field: { value: currentValue, onChange },
    fieldState: { error },
  } = useController({ name, rules })

  const selectedOptionLabel = options.find(
    (option) => option.value === currentValue
  )?.label

  const id = useId()
  const errorId = useId()

  const handleKeyDown = (event: KeyboardEvent) => {
    if (['Delete', 'Backspace'].includes(event.code)) {
      onChange('')
      setOpen(false)
    }
  }

  let buttonLabel = t('placeholder.choose')

  if (currentValue) {
    if (useValueAsFilter) {
      buttonLabel = currentValue
    } else if (selectedOptionLabel) {
      buttonLabel = selectedOptionLabel
    }
  }

  const tabIndex = useCollapsedSectionTabIndex()

  return (
    <>
      <div className="hidden lg:block">
        <Popover onOpenChange={setOpen} open={open}>
          <div className="flex w-full flex-col gap-1">
            {label && (
              <label className="inline-block font-bold leading-5" htmlFor={id}>
                {label}
              </label>
            )}

            <PopoverTrigger asChild>
              <Button
                aria-expanded={open}
                aria-invalid={!!error?.message}
                className={cn(
                  inputBaseClassName,
                  'justify-between gap-2 text-left font-normal text-secondary hover:border-gray-300 hover:bg-white hover:text-secondary',
                  {
                    'text-black hover:text-black': !!currentValue,
                  }
                )}
                id={id}
                onKeyDown={handleKeyDown}
                role="combobox"
                tabIndex={tabIndex}
                variant="outline"
              >
                {buttonLabel}
                <ChevronsUpDown className="size-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>

            <div
              aria-atomic="true"
              aria-live="polite"
              className={cn({ hidden: !error?.message })}
            >
              {error?.message && (
                <InputError
                  errorConfig={{
                    id: errorId,
                    message: error.message,
                    type: 'error',
                  }}
                />
              )}
            </div>
          </div>

          <PopoverContent className="p-0">
            <Command {...(filter && { filter })}>
              <CommandInput placeholder={filterPlaceholder} />

              <CommandList>
                <CommandEmpty>{noOptionFoundLabel}</CommandEmpty>

                <CommandGroup>
                  {options.map((option) => (
                    <CommandItem
                      className="cursor-pointer"
                      key={option.value}
                      onSelect={() => {
                        onChange(option.value)
                        setOpen(false)
                      }}
                      value={option.value}
                    >
                      <Check
                        className={cn(
                          'mr-2 size-4',
                          currentValue === option.value
                            ? 'opacity-100'
                            : 'opacity-0'
                        )}
                      />

                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      {/* Display standard select on mobile layouts */}
      <Select
        label={label}
        name={name}
        options={options}
        placeholder={selectPlaceholder}
        rules={rules}
        useValueAsFilter={useValueAsFilter}
        wrapperClassName="lg:hidden flex-1"
      />
    </>
  )
}
