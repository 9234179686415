import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Radio } from '../../../../components/Radio'
import { WeatherCardProvider } from '../index.tsx'

interface WeatherCardTitleProps extends ComponentPropsWithoutRef<'header'> {
  title: string
  headingProps?: ComponentPropsWithoutRef<'h3'>
  insuranceProvider: WeatherCardProvider
  isSelected: boolean
  insuranceWrapperProps?: ComponentPropsWithoutRef<'span'>
}

export const WeatherCardHeader = (props: WeatherCardTitleProps) => {
  const {
    title,
    insuranceProvider,
    insuranceWrapperProps,
    headingProps,
    isSelected,
    ...rest
  } = props

  const { t } = useTranslation()

  return (
    <header className="flex items-center gap-2" {...rest}>
      <Radio className="lg:hidden" isSelected={isSelected} visualOnly />

      <div className="flex w-full flex-col items-start justify-between lg:flex-row lg:gap-1">
        <div className="flex flex-col gap-1">
          <h3
            className="text-md font-bold leading-5 lg:text-lg"
            {...headingProps}
          >
            {props.title}
          </h3>

          <span className="text-sm max-lg:hidden" {...insuranceWrapperProps}>
            {t('extra.insuranceProvider', {
              providerName: props.insuranceProvider.name,
            })}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <span className="text-sm lg:hidden" {...insuranceWrapperProps}>
            {t('extra.insuranceProvider', {
              providerName: props.insuranceProvider.name,
            })}
          </span>

          <img
            alt={props.insuranceProvider.image.alt}
            className="h-[14px] w-[67px] lg:h-[16px] lg:w-[77px]"
            height={16}
            src={props.insuranceProvider.image.src}
            width={77}
          />
        </div>
      </div>
    </header>
  )
}
