import { SpotType } from '@spiaggeit/spit-core'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Surface } from '@/components/Surface'

import { mappedSpotTypes } from '../../../utils/constants'

import { SectorCardQuantityController } from './SectorCardQuantityController'

interface Props {
  imageUrl: string | null
  sectorName: string
  spotType: SpotType
  availableQuantity: number
  selectedQuanitity: number
  onAdd: () => void
  onRemove: () => void
}

export const SectorCard = (props: Props) => {
  const { t } = useTranslation()
  const { icon: Icon, label } = mappedSpotTypes[props.spotType]

  const title = t('a11y.sectorCardTitle', {
    itemName: t(label),
    sectorName: props.sectorName,
  })

  const titleId = useId()

  return (
    <Surface aria-labelledBy={titleId} as="article">
      <h2 className="sr-only" id={titleId}>
        {title}
      </h2>

      {props.imageUrl ? (
        <img
          alt=""
          className="h-[136px] w-full object-cover"
          src={props.imageUrl}
        />
      ) : null}

      <div className="flex justify-between gap-4 p-4">
        <div className="flex flex-col gap-2">
          <strong className="line-clamp-1 overflow-hidden text-ellipsis text-base font-bold">
            {props.sectorName}
          </strong>

          <p className="flex items-center gap-2 text-base">
            <Icon aria-hidden="true" className="h-6 w-6" />
            {t(label)}
          </p>
        </div>

        <SectorCardQuantityController
          availableQuantity={props.availableQuantity}
          onAdd={props.onAdd}
          onRemove={props.onRemove}
          selectedQuantity={props.selectedQuanitity}
        />
      </div>
    </Surface>
  )
}
