import { cartSlice } from '@/store/cartSlice'
import { cartResetInsertInsuranceServices } from '@/store/cartSlice/thunks/cartResetInsertInsuranceServices.ts'
import { useAppDispatch } from './store'

//This hook removes the weatherInsurance
// and removes all the extraServices that were added to cart in the /insertInsurance page
//it's useful when the user decides to go back to the spot booking page
// (either insertMap or insertSector)

export const useResetInsertInsuranceProducts = () => {
  const dispatch = useAppDispatch()
  const resetInsertInsuranceProducts = () => {
    dispatch(cartResetInsertInsuranceServices())
    dispatch(cartSlice.actions.setIsWeatherInsuranceSelected(false))
    dispatch(cartSlice.actions.quote({ needsToUpdateCartTotal: true }))
  }

  return { resetInsertInsuranceProducts }
}
