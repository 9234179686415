import { t } from 'i18next'

import { Service } from '@/models/service'

export const getServiceLimitReachedMessage = ({
  groupName,
  service,
}: {
  groupName?: string
  service: Service
}) => {
  if (service.serviceGroupId && service.groupLimit === 0) {
    return t('map.elementDetail.serviceGroupLimitReached', { groupName })
  } else if (service.serviceGroupId && service.globalLimit === 0) {
    return t('map.elementDetail.serviceGlobalLimitReached')
  } else return t('map.elementDetail.maximumServiceLimitReached')
}
