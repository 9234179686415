import phoneMetadata from 'libphonenumber-js/metadata.min.json'
import { useTranslation } from 'react-i18next'

import { useLocalizedCountries } from '@/hooks/useLocalizedCountries.ts'

export const usePhonePrefixOptions = () => {
  const localizedCountries = useLocalizedCountries()
  const { t } = useTranslation()

  return Object.entries(phoneMetadata.country_calling_codes)
    .map(([phonePrefix, countries]) => {
      const formattedPrefix = `+${phonePrefix}`

      const joinedCountries = countries
        .map((country) => localizedCountries[country])
        .join(', ')

      if (!joinedCountries.length) return { label: '', value: '' }

      let label = `${joinedCountries} (${formattedPrefix})`

      if (phonePrefix === '1') {
        label = `${t('common.international')} (+1)`
      }

      return {
        label,
        value: formattedPrefix,
      }
    })
    .filter((option) => !!option.value)
    .sort((a, b) => a.label.localeCompare(b.label))
}
