import { isSuccess } from '@devexperts/remote-data-ts'
import { isPending } from '@reduxjs/toolkit'
import { cn, Dialog } from '@spiaggeit/spit-ui'
import { t } from 'i18next'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { WhiteLabelBar } from '@/components/WhiteLabelBar'
import { useCurrentPathNotes } from '@/hooks/useCurrentPathNotes'
import { selectIsProgressBarPending } from '@/store/extraSlice'
import { licenseSlice } from '@/store/licenseSlice'
import { loadingSlice } from '@/store/loadingSlice'
import { servicesSlice } from '@/store/servicesSlice'
import { selectIsWhiteLabelActive, widgetSlice } from '@/store/widgetSlice'
import {
  CHOOSE_PRODUCT_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  PAY_PATH,
  PAYMENT_KIOSK_PATH,
  THANK_YOU_PATH,
} from '../../app/router/paths'
import { ArrowLeft } from '../../assets/icons/ArrowLeft'
import { Info } from '../../assets/icons/Info'
import { useAppSelector } from '../../hooks/store'
import { useBackLink } from '../../hooks/useBackLink'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { appSlice } from '../../store/appSlice'
import { LoaderTrigger } from '../LoaderTrigger'
import { Tooltip } from '../Tooltip/Tooltip'
import { InsertPeriodLink } from './InsertPeriodLink'
import { ProgressBar } from './ProgressBar'
import { TopBar } from './TopBar'

export const Header = () => {
  const beachLocation = useAppSelector(licenseSlice.selectors.beachLocation)
  const isWidget = useAppSelector(appSlice.selectors.isWidgetMode)
  const isWebsite = useAppSelector(appSlice.selectors.isWebsiteMode)
  const license = useAppSelector(licenseSlice.selectors.license)

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { currentPath } = useCurrentPath()
  const backLink = useBackLink()
  const notes = useCurrentPathNotes()
  const isPayPath = currentPath === PAY_PATH
  const services = useAppSelector(servicesSlice.selectors.services)

  const isProgressBarPending = useAppSelector(selectIsProgressBarPending)
  const isLoadingStackEmpty = useAppSelector(
    loadingSlice.selectors.isLoadingStackEmpty
  )

  const whiteLabel = useAppSelector(widgetSlice.selectors.whiteLabel)
  const isWhiteLabelActive = useAppSelector(selectIsWhiteLabelActive)

  const isLoadingProgressBar = isPayPath
    ? isPending(services)
    : isProgressBarPending

  const isLoadingHeader =
    !license ||
    isLoadingProgressBar ||
    (isWhiteLabelActive && !isSuccess(whiteLabel))

  if (isLoadingHeader) {
    return <LoaderTrigger />
  }

  if (
    [LANDING_KIOSK_PATH, PAYMENT_KIOSK_PATH].includes(currentPath) ||
    (isPayPath && !isLoadingStackEmpty)
  ) {
    return null
  }

  return (
    <>
      <header
        className={cn('sticky top-0 z-100 bg-white ', {
          hidden: currentPath === INSERT_PERIOD_PATH && isWhiteLabelActive,
          'lg:pb-3': currentPath === PAY_PATH && isWhiteLabelActive,
          'lg:shadow-md': currentPath === INSERT_PERIOD_PATH && !isWidget,
          'shadow-md': currentPath !== INSERT_PERIOD_PATH,
        })}
      >
        <WhiteLabelBar />
        <TopBar />

        {currentPath !== THANK_YOU_PATH && (
          <div className="container py-3 md:flex">
            <div className="flex w-full flex-auto items-center justify-between space-x-12 md:w-auto md:justify-start">
              <div className="flex flex-initial space-x-3">
                {backLink && !isWebsite ? (
                  <NavLink to={backLink}>
                    <ArrowLeft className="size-8" />
                  </NavLink>
                ) : null}

                <div className={cn('max-w-60', { hidden: isWhiteLabelActive })}>
                  <div className="mr-2 flex cursor-pointer gap-2 lg:space-x-2 ">
                    <Tooltip
                      className="w-full font-bold leading-tight text-primary"
                      content={license?.name || ''}
                      dialogWrapperProps={{ className: 'basis-full' }}
                      maxLines={1}
                    />

                    {notes ? (
                      <button
                        aria-label={t('common.moreInfo')}
                        onClick={() => {
                          setIsDialogOpen(true)
                        }}
                      >
                        <Info className="shrink-0 text-primary" />
                      </button>
                    ) : null}
                  </div>
                  <Tooltip
                    className="text-sm text-secondary"
                    content={beachLocation || ''}
                    maxLines={1}
                  />
                </div>
              </div>

              <InsertPeriodLink />
            </div>

            <div
              className={cn('mx-0 mt-2 flex-auto md:mt-0', {
                hidden: [
                  INSERT_PERIOD_PATH,
                  THANK_YOU_PATH,
                  CHOOSE_PRODUCT_PATH,
                ].includes(currentPath),
              })}
            >
              <ProgressBar />
            </div>
          </div>
        )}
      </header>

      {notes ? (
        <Dialog
          isOpen={isDialogOpen}
          mobileMode="bottomSheet"
          setIsOpen={setIsDialogOpen}
          size="sm"
          title={license?.name || ''}
          trigger={null}
        >
          <div className="p-4">{notes}</div>
        </Dialog>
      ) : null}
    </>
  )
}
