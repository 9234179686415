import * as rd from '@devexperts/remote-data-ts'
import { CartSliceState } from '@/store/cartSlice/type.ts'

export const cartSliceInitialState: CartSliceState = {
  activeBookingType: null,
  cart: rd.initial,
  cartDetailResponse: rd.initial,
  cartItems: {},
  cartPopoverIsOpen: false,
  cartReservations: [],
  confirmPayment: rd.initial,
  discountCode: '',
  fees: 0,
  isCartPaid: false,
  isWeatherInsuranceAddedToCart: false,
  paymentCards: rd.initial,
  paymentWithCard: rd.initial,
  previousQuotePayloadHashed: null,
  quote: rd.initial,
  quoteWithDiscount: rd.initial,
  selectedElementQuotableProducts: {
    b: 0,
    c: 0,
    d: 0,
    m: 0,
    services: {},
  },
  selectedElementReservation: null,
  stripeInitPaymentResponse: rd.initial,
  total: 0,
}
