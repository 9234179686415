import { Button, cn } from '@spiaggeit/spit-ui'
import { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { AppIcon, Icon } from '@/assets/icons'

interface ButtonLinkProps {
  to: string
  isDisabled?: boolean
  label: string | ReactNode
  target?: HTMLAttributeAnchorTarget
  className?: string
  icon?: AppIcon
  iconOnLeftSide?: boolean
  linkClassName?: string
  variant?: 'outline'
}

export const ButtonLink = (props: ButtonLinkProps) => {
  if (props.isDisabled) {
    return <Button disabled>{props.label}</Button>
  }

  return (
    <Link className={props.linkClassName} target={props.target} to={props.to}>
      <span
        className={cn(
          'inline-flex select-none items-center justify-center gap-2 whitespace-nowrap rounded-sm border border-blue-500 bg-blue-500 px-6 py-[calc(0.75rem_-_1px)] text-center align-middle text-base font-bold text-primary-inverted transition-colors hover:border-blue-700 hover:bg-blue-700 focus-visible:outline-none focus-visible:ring-1',
          props.className,
          {
            'bg-white text-blue-500 hover:text-white':
              props.variant === 'outline',
          }
        )}
      >
        {props.icon && props.iconOnLeftSide && (
          <Icon className="size-5" name={props.icon} />
        )}
        {props.label}
        {props.icon && !props.iconOnLeftSide && (
          <Icon className="size-5" name={props.icon} />
        )}
      </span>
    </Link>
  )
}
