import { createSelector, PayloadAction } from '@reduxjs/toolkit'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { DateTime } from 'luxon'

import { getBookingPeriodFromHalfDay } from '@/utils/halfDay'
import { resetAllState, setupByUrl } from './commonActions'
import { createAppSlice } from './createAppSlice'

type Period = { start: string; end: string }

export type PeriodSliceState = {
  period: Period | null
  bookingPeriod: BookingPeriod | null
}

const initialState: PeriodSliceState = {
  bookingPeriod: BookingPeriod.ALL_DAY,
  period: null,
}

export const insertPeriodSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)

    builder.addCase(setupByUrl, (state, action) => {
      const url = new URL(action.payload)

      const halfDayParam = url.searchParams.get('hd')
      const seasonalParam = url.searchParams.get('seasonal')

      if (seasonalParam === '1') {
        state.bookingPeriod = BookingPeriod.ALL_SEASON
      } else if (halfDayParam) {
        state.bookingPeriod = getBookingPeriodFromHalfDay(+halfDayParam)
      }

      const startDateParam = url.searchParams.get('startdate')
      const endDateParam = url.searchParams.get('enddate')
      const fromParam = url.searchParams.get('from')
      const toParam = url.searchParams.get('to')

      if (startDateParam && endDateParam) {
        const start = DateTime.fromISO(startDateParam)
        const end = DateTime.fromISO(endDateParam)
        const datesParamsAreValid = start.isValid && end.isValid

        if (datesParamsAreValid) {
          state.period = {
            end: endDateParam,
            start: startDateParam,
          }
        }

        //if the url contains a valid start and end date but not half day parameter,
        // we give the bookingPeriod a default value of ALL_DAY
        if (datesParamsAreValid && !halfDayParam) {
          state.bookingPeriod = BookingPeriod.ALL_DAY
        }
      } else if (fromParam && toParam) {
        const start = DateTime.fromSeconds(+fromParam)
        const end = DateTime.fromSeconds(+toParam)
        const datesParamsAreValid = start.isValid && end.isValid

        if (datesParamsAreValid) {
          state.period = {
            end: end.toISODate(),
            start: start.toISODate(),
          }
        }

        if (datesParamsAreValid && !halfDayParam) {
          state.bookingPeriod = BookingPeriod.ALL_DAY
        }
      } else return
    })
  },

  initialState,
  name: 'insertPeriod',
  reducers: (create) => ({
    setBookingPeriod: create.reducer(
      (state, action: PayloadAction<BookingPeriod | null>) => {
        state.bookingPeriod = action.payload
      }
    ),
    setPeriod: create.reducer((state, action: PayloadAction<Period | null>) => {
      state.period = action.payload
    }),
  }),
  selectors: {
    bookingPeriod: (state) => state.bookingPeriod,
    period: (state) => state.period,
  },
})

export const periodAsUnixTimeSelector = createSelector(
  insertPeriodSlice.selectors.period,
  (period) => {
    if (!period) return null

    return {
      end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
      start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
        zone: 'utc',
      }).toSeconds(),
    }
  }
)
