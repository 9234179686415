import { cartSlice } from '@/store/cartSlice'
import { selectActiveCartReservation } from '@/store/cartSlice/selectors/selectActiveCartReservation.ts'
import { cartChangeQuotableServiceQuantity } from '@/store/cartSlice/thunks/cartChangeQuotableServiceQuantity.ts'
import { servicesSlice } from '@/store/servicesSlice'
import { getServiceLimitReachedMessage } from '@/utils/getServiceLimitReachedMessage'

import { getServiceSingleLimit } from '@/utils/getServiceSingleLimit'

import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { ItemQuantityController } from '../../../../components/ItemQuantityController/ItemQuantityController'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { useDayDifference } from '../../../../hooks/useDayDifference'
import { changeQuantityActioType } from '../../../../models/cart'
import { Service, ServiceLimitType } from '../../../../models/service'
import { toastSlice } from '../../../../store/toastSlice'
import {
  getItemQuantityLabel,
  ItemQuantityBoundary,
} from '../../../../utils/getItemQuantityLabel'
import { getServiceMinimumQuantity } from '../../../../utils/getServiceMinimumQuantity'
import { getServiceUpdatedQuantity } from '../../../../utils/getServiceUpdatedQuantity'

interface Props {
  service: Service
  isEditable?: boolean
}
export const SetupServiceListItem = (props: Props) => {
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )
  const dayDifference = useDayDifference()
  const selectedElementCartReservation = useAppSelector(
    selectActiveCartReservation
  )

  const dispatch = useAppDispatch()

  const currentServiceKey = getServiceKeyFromId(props.service.serviceId)

  const handleAddService = () => {
    dispatch(
      cartChangeQuotableServiceQuantity({
        actionType: changeQuantityActioType.ADD,
        dynamicKey: currentServiceKey,
      })
    )
  }

  const handleRemoveService = () => {
    dispatch(
      cartChangeQuotableServiceQuantity({
        actionType: changeQuantityActioType.REMOVE,
        dynamicKey: currentServiceKey,
      })
    )
  }

  const ticketLimit = getServiceSingleLimit({
    dayDifference,
    seatsAmount: selectedElementQuotedSeatsAmount,
    service: props.service,
  })

  const initialQuantity = getServiceUpdatedQuantity({
    dayDifference,
    selectedSeats: selectedElementQuotedSeatsAmount,
    service: props.service,
    serviceInCartQuantity:
      selectedElementCartReservation?.services[currentServiceKey],
  })

  const minimumQuantity = getServiceMinimumQuantity(
    props.service,
    selectedElementQuotedSeatsAmount,
    dayDifference
  )

  const serviceGroups = useAppSelector(
    servicesSlice.selectors.data
  )?.serviceGroups
  const currentServiceGroupName = serviceGroups?.find(
    (group) => group.serviceGroupId === props.service.serviceGroupId
  )?.name

  const message = getServiceLimitReachedMessage({
    groupName: currentServiceGroupName,
    service: props.service,
  })
  const onAddDisabled = () => {
    dispatch(
      toastSlice.actions.show({
        message: message,
      })
    )
  }
  const maxLabel = getItemQuantityLabel({
    boundary: ItemQuantityBoundary.MAX,
    flatLimitAmount: props.service.availableQuantity,
    limitType: props.service.maximumLimitType,
  })
  const minLabel = getItemQuantityLabel({
    boundary: ItemQuantityBoundary.MIN,
    flatLimitAmount: props.service.minimumQuantity,
    limitType: props.service.minimumLimitType,
  })

  const serviceHasFlatLimitType =
    props.service.minimumLimitType === ServiceLimitType.FLAT ||
    props.service.maximumLimitType === ServiceLimitType.FLAT

  const { globalLimit, groupHasLimit, groupLimit } = props.service

  //included services shall not be edited by the user, so we always need to treat them like limited services
  //and give them the same maximum and minimum quantity
  const available = props.isEditable
    ? {
        global: globalLimit || ticketLimit,
        group: groupHasLimit ? groupLimit : ticketLimit,
        ticket: ticketLimit,
      }
    : {
        ticket: initialQuantity,
      }

  return (
    <ItemQuantityController
      //This is needed when a service has minimumLimitType 'number-of-pieces'
      // but the current number of selected seats is 0
      alwaysShowLabel={!serviceHasFlatLimitType}
      available={available}
      hasLimit={props.isEditable ? props.service.hasLimit : true}
      icon={props.service.icon}
      initialQuantity={initialQuantity}
      key={props.service.serviceId}
      maxLabel={maxLabel}
      minLabel={minLabel}
      minimumQuantity={minimumQuantity}
      name={props.service.name}
      onAdd={handleAddService}
      onAddDisabled={onAddDisabled}
      onRemove={handleRemoveService}
    />
  )
}
