import { PropsWithChildren, useId } from 'react'
import { useWhiteLabelBgTextClassName } from '@/hooks/useWhiteLabelBgTextClassName.ts'

interface PageSectionProps {
  title: string
  description?: string
}

export const PageSection = (props: PropsWithChildren<PageSectionProps>) => {
  const titleId = useId()
  const descriptionId = useId()
  const headingClassName = useWhiteLabelBgTextClassName(
    'mb-1 text-lg font-bold leading-6'
  )
  const descriptionClassName = useWhiteLabelBgTextClassName()

  return (
    <section
      aria-describedby={descriptionId}
      aria-labelledby={titleId}
      className="flex flex-col gap-4"
    >
      <div>
        <h2 className={headingClassName} id={titleId}>
          {props.title}
        </h2>
        {props.description && (
          <p className={descriptionClassName} id={descriptionId}>
            {props.description}
          </p>
        )}
      </div>
      {props.children}
    </section>
  )
}
