import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, ElementType, FC } from 'react'

import { ElementAs } from '../../models/element-as.ts'
import { ReservationCosts, ReservationCostsProps } from '../Reservation/Costs'
import { ReservationList, ReservationListProps } from '../Reservation/List'
import {
  ReservationLocationMobile,
  ReservationLocationMobileProps,
} from '../Reservation/Location/Mobile'
import {
  ReservationSummary,
  ReservationSummaryProps,
} from '../Reservation/Summary'

type RecapDetailsProps<T extends ElementType> = ComponentPropsWithoutRef<T> &
  ElementAs<T>

interface RecapDetailsType<T extends ElementType>
  extends FC<RecapDetailsProps<T>> {
  Summary: FC<ReservationSummaryProps>
  Location: FC<ReservationLocationMobileProps>
  Reservations: FC<ReservationListProps>
  Costs: FC<ReservationCostsProps>
}

export const RecapDetails: RecapDetailsType<ElementType> = (
  props: RecapDetailsProps<ElementType>
) => {
  const { className, children, as: Element = 'details', ...rest } = props

  return (
    <Element
      className={cn(
        'duration-400 group appearance-none overflow-hidden rounded border border-gray-100 bg-white transition-all ease-out',
        className
      )}
      {...rest}
    >
      {children}
    </Element>
  )
}

RecapDetails.Summary = ReservationSummary
RecapDetails.Location = ReservationLocationMobile
RecapDetails.Reservations = ReservationList
RecapDetails.Costs = ReservationCosts
