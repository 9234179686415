import { createAsyncThunk } from '@reduxjs/toolkit'
import { BookingPeriod } from '@spiaggeit/spit-datepicker'
import { SpotType } from '@/models/cart.ts'
import { cartSlice } from '@/store/cartSlice'
import { RootState } from '@/store/configureStore.ts'
import { insertPeriodSlice } from '@/store/insertPeriodSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { mapCurrentSectorSelector, mapSelectors } from '@/store/mapSlice.ts'
import { FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT } from '@/utils/constants.ts'
import { fromISOtoTimestamp } from '@/utils/dateUtils.ts'
import { getHalfDayValueFromBookingPeriod } from '@/utils/halfDay.ts'

export const cartAddReservationToQuote = createAsyncThunk(
  'cart/cartAddReservationToQuote',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const rootState = getState() as RootState

    const period = insertPeriodSlice.selectors.period(rootState)
    const license = licenseSlice.selectors.license(rootState)
    const bookingPeriod = insertPeriodSlice.selectors.bookingPeriod(rootState)
    const currentSector = mapCurrentSectorSelector(rootState)
    const selectedElement = mapSelectors.selectedElement(rootState)
    const selectedElementQuotableProducts =
      cartSlice.selectors.selectedElementQuotableProducts(rootState)
    const cartReservations = cartSlice.selectors.cartReservations(rootState)

    const cartItems = cartSlice.selectors.items(rootState)

    // Hard limit on the number of items that can be added to the cart
    // if this limit is not specified we use 9999 as a fallback value (as agreed upon on this discord thread: https://discord.com/channels/955037698492612618/1311360172668751903/1318151231171985450)
    const cartItemsLimit =
      license?.bookingUserUmbrellasLimit ||
      FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT

    // Number of items in the cart
    const cartItemsAmount = Object.values(cartItems).length

    const isMaximumCartItemsReached = cartItemsAmount === cartItemsLimit

    const cartSelectedElementCartReservation = cartReservations.find(
      (item) =>
        item?.spotName === selectedElement?.name &&
        item.spotType === selectedElement.subType
    )

    try {
      if (!period?.end || !period?.start || !currentSector || !selectedElement)
        return

      const { services, ...restOfQuoteSetupItems } =
        selectedElementQuotableProducts

      const sanitizedServices = Object.fromEntries(
        //This makes sure no service has ever a "null" or "undefined" quantity
        Object.entries(services).map(([key, value]) => [key, value ?? 0])
      )

      const reservation = {
        beds: restOfQuoteSetupItems.b,
        chairs: restOfQuoteSetupItems.c,
        deckChairs: restOfQuoteSetupItems.d,
        endDate: Number(fromISOtoTimestamp(period?.end)),
        halfDay: getHalfDayValueFromBookingPeriod(bookingPeriod),
        maxiBeds: restOfQuoteSetupItems.m,
        notes: '',
        seasonal: bookingPeriod === BookingPeriod.ALL_SEASON,
        sector: currentSector?.header.id,
        services: sanitizedServices,
        spotName: selectedElement?.name || '',
        spotType: selectedElement?.subType || SpotType.UMBRELLA,
        startDate: Number(fromISOtoTimestamp(period?.start)),
      }

      /*please note: this condition is necessary because we need to respect two conditions:
        1) if we have reached the maximum spot limit and we are selecting a map spot
           that has NOT been added to cart, we must not add this spot reservation to our quote request
           and return the function here
        2) if we have reached the maximum spot limit and we select a map spot ALREADY added to cart
           we will need to dispatch this reservation (in case the user wants to add/delete seats or services)
           and our /quote request will change accordignly
      */
      if (isMaximumCartItemsReached && !cartSelectedElementCartReservation) {
        return
      }

      dispatch(cartSlice.actions.setSelectedElementReservation(reservation))
    } catch (error) {
      return error
    }
  }
)
