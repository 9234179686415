import * as rd from '@devexperts/remote-data-ts'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { setupByUrl } from '@/store/commonActions'

import { licenseSlice } from '@/store/licenseSlice'
import { bookingAvailabilitySlice } from '../store/bookingAvailabilitySlice'
import { getBookingFlowFromSearchParams } from '../utils/bookingFlow'

import { useAppDispatch, useAppSelector } from './store'

export function useApp() {
  const dispatch = useAppDispatch()
  const routeParams = useParams()
  const [searchParams] = useSearchParams()
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )

  const license = useAppSelector(licenseSlice.selectors.licenseRemoteData)

  useEffect(() => {
    dispatch(setupByUrl(window.location.href))
  }, [dispatch])

  useEffect(() => {
    if (routeParams.license && rd.isInitial(license)) {
      dispatch(licenseSlice.actions.loadLicense(routeParams.license))
    }
  }, [dispatch, routeParams, license, searchParams])

  useEffect(() => {
    if (rd.isSuccess(license) && rd.isInitial(bookingAvailability)) {
      dispatch(
        bookingAvailabilitySlice.actions.load({
          bookingFlow: getBookingFlowFromSearchParams(searchParams),
        })
      )
    }
  }, [license, bookingAvailability])
}
