import { Dialog } from '@spiaggeit/spit-ui'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import { Logo } from '@/components/Logo'
import { useAppSelector } from '@/hooks/store.ts'
import { appSlice } from '@/store/appSlice.ts'

type LoaderProps = {
  loadingLabel?: string
}

export const Loader = memo(function AppLoader(props: LoaderProps) {
  const { t } = useTranslation()
  const isWidget = useAppSelector(appSlice.selectors.isWidgetMode)

  const { loadingLabel = t('common.loading') } = props

  return (
    <Dialog isOpen={true} setIsOpen={null} size="sm" trigger={null}>
      {/* This empty button is to avoid automatic outlining due to the focus trap of the Dialog component*/}
      <button className="pointer-events-none opacity-0" type="button" />

      <div className="flex flex-col items-center justify-center gap-4 p-4 pt-6">
        {isWidget ? null : <Logo className="text-yellow-300" />}

        <BeatLoader size={8} />

        <div className="text-center text-lg font-bold">{loadingLabel}</div>
      </div>
    </Dialog>
  )
})
