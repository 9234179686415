import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { licenseSlice } from '@/store/licenseSlice'
import { LockDates } from '../models/insertPeriod'
import { insertPeriodSlice, PeriodSliceState } from '../store/insertPeriodSlice'

import { useAppDispatch } from './store'

function getPeriodFromSearchParams(
  searchParams: URLSearchParams,
  defaultValue: {
    start: DateTime<true>
    end: DateTime<true>
  }
): {
  start: DateTime<true>
  end: DateTime<true>
} {
  const startDate = searchParams.get('startdate')
  const endDate = searchParams.get('enddate')

  if (startDate && endDate) {
    const start = DateTime.fromISO(startDate)
    const end = DateTime.fromISO(endDate)
    return {
      end: end.isValid ? end : defaultValue.end,
      start: start.isValid ? start : defaultValue.start,
    }
  } else {
    return defaultValue
  }
}

export function usePeriod(
  lockDates: LockDates,
  previouslySelectedPeriod: PeriodSliceState['period'] //this is needed when the user goes back to /insertPeriod and we want to show the days they previously selected in the date-picker
) {
  const license = useSelector(licenseSlice.selectors.license)
  const isBookingEnabled = useSelector(licenseSlice.selectors.isBookingEnabled)

  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    if (!license || !isBookingEnabled || previouslySelectedPeriod) {
      // cannot set period
      return
    }

    if (lockDates.minDate > lockDates.maxDate) {
      // TODO throw / log error?
      return
    }

    const defaultStart = DateTime.fromMillis(lockDates.minDate)

    if (!defaultStart.isValid) {
      return
    }

    const { start, end } = getPeriodFromSearchParams(searchParams, {
      end: defaultStart,
      start: defaultStart,
    })

    const period =
      end < start
        ? {
            end: defaultStart,
            start: defaultStart,
          }
        : {
            end: end,
            start: start,
          }

    // check if period is valid
    function isValidPeriod(): boolean {
      const lockStart = DateTime.fromMillis(lockDates.minDate)
      const lockEnd = DateTime.fromMillis(lockDates.maxDate)

      if (
        period.start < lockStart ||
        period.start > lockEnd ||
        period.end < lockStart ||
        period.end > lockEnd
      ) {
        return false
      }

      return true
    }

    if (isValidPeriod()) {
      dispatch(
        insertPeriodSlice.actions.setPeriod({
          end: period.end.toISODate(),
          start: period.start.toISODate(),
        })
      )
    } else {
      dispatch(insertPeriodSlice.actions.setPeriod(null))
    }
  }, [
    dispatch,
    isBookingEnabled,
    license,
    lockDates.maxDate,
    lockDates.minDate,
  ])
}
