import { SpotType } from '@spiaggeit/spit-core'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LoaderTrigger } from '@/components/LoaderTrigger'
import { useCheckBookingSettingsChange } from '@/hooks/useCheckBookingSettingsChange'
import { useFetchPricePreview } from '@/hooks/useFetchPricePreview'
import { useResetInsertInsuranceProducts } from '@/hooks/useResetInsertInsurance'
import { ServiceInfoPurchaseType } from '@/models/service'

import { BookingAvailability } from '@/store/bookingAvailabilitySlice'
import { licenseSlice } from '@/store/licenseSlice'
import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { CartFooter } from '../../components/CartFooter'
import { SelectedElementDrawer } from '../../components/SelectedElementDrawer'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice/index'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import {
  loadMapData,
  loadSectors,
  mapSelectors,
  mapSlice,
} from '../../store/mapSlice'
import { servicesSlice } from '../../store/servicesSlice'

import { SectorCard } from './SectorCard'

export const SectorsRoute = () => {
  const dispatch = useAppDispatch()
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const navigate = useNavigate()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const license = useAppSelector(licenseSlice.selectors.license)
  const cartItems = useAppSelector(cartSlice.selectors.items)

  useEffect(() => {
    if (!period || !period.end || !period.start) {
      navigate(generatePath(INSERT_PERIOD_PATH, { license: license?.license }))
    }
  }, [period, INSERT_PERIOD_PATH, license])

  const { resetInsertInsuranceProducts } = useResetInsertInsuranceProducts()

  useFetchPricePreview()

  useEffect(() => {
    dispatch(loadMapData())
    dispatch(loadSectors())
    dispatch(
      servicesSlice.actions.load({
        purchaseType: ServiceInfoPurchaseType.WITH_SPOT,
      })
    )

    resetInsertInsuranceProducts()
  }, [])

  useEffect(() => {
    dispatch(cartSlice.actions.setBookingType(BookingAvailability.SPOTS))
  }, [])

  const { checkBookingAvailabilityChange } = useCheckBookingSettingsChange()

  useEffect(() => {
    checkBookingAvailabilityChange()
  }, [license])

  const mappedSectors = useAppSelector(mapSelectors.mappedSectors)
  const cartItemsIds = Object.keys(cartItems)
  const isLoading = useAppSelector(mapSelectors.isLoading)

  const { t } = useTranslation()

  if (isLoading) {
    return <LoaderTrigger message={t('sectors.loading')} />
  }

  return (
    <>
      <main>
        <div className="mx-auto flex flex-1 flex-col items-stretch gap-4 overflow-auto p-4 lg:grid lg:grid-cols-3 lg:flex-row lg:items-start xl:w-[62rem] xl:px-0">
          {mappedSectors?.map((sector) => {
            return sector.elementsGroupedByType?.map((group) => {
              const firstAvailableSpot = group.find(
                (spot) => !cartItemsIds.includes(spot.id)
              )

              const groupSpotsInCart = group.filter((spot) => {
                return cartItemsIds.includes(String(spot.id))
              })

              const onAdd = () => {
                if (!firstAvailableSpot) return
                dispatch(mapSlice.actions.selectMapElement(firstAvailableSpot))
              }

              const onRemove = () => {
                dispatch(cartSlice.actions.setCartPopoverIsOpen(true))
              }

              const availableQuantity = group.length - groupSpotsInCart.length

              return (
                <SectorCard
                  availableQuantity={availableQuantity}
                  imageUrl={sector.image}
                  key={sector.id + group[0].subType}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  sectorName={sector.name}
                  selectedQuanitity={groupSpotsInCart.length}
                  /*  please note: this type assetions is unfortunately necessary 
                    since subType of a map element is typed as SpotType | DecorationType.
                    We are already filtering out any decoration elements in mapSlice -> mapSelectors -> mappedSectors
                    so this is purely to avoid typescript warnings 
                */
                  spotType={group[0].subType as SpotType}
                />
              )
            })
          })}
        </div>
        {selectedElement && <SelectedElementDrawer />}
      </main>
      <CartFooter />
    </>
  )
}
