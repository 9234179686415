import * as rd from '@devexperts/remote-data-ts'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceCallback } from 'usehooks-ts'

import { CartFooter } from '@/components/CartFooter'
import { GridWithRecap } from '@/components/GridWithRecap'
import { LoaderTrigger } from '@/components/LoaderTrigger'
import { PageMainContent } from '@/components/PageMainContent'
import { PageSection } from '@/components/PageSection'
import { ReservationRecapCard } from '@/components/ReservationRecapCard'

import { INSERT_DATA_PATH } from '@/app/router/paths.ts'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { useDayDifference } from '@/hooks/useDayDifference'

import { useFetchPricePreview } from '@/hooks/useFetchPricePreview.ts'
import { QuoteReservation } from '@/models/quote.ts'
import { ServiceInfoPurchaseType } from '@/models/service'
import { BookingAvailability } from '@/store/bookingAvailabilitySlice'

import { cartSlice } from '@/store/cartSlice'
import { selectAvailableFeaturedServices } from '@/store/cartSlice/selectors/selectAvailableFeaturedServices.ts'
import { extraSlice } from '@/store/extraSlice.ts'
import { servicesSlice } from '@/store/servicesSlice.ts'

import { getServiceSingleLimit } from '@/utils/getServiceSingleLimit'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'

import weatherInsuranceImage from '../../../public/images/weather-insurance.png'
import { CardExtra } from './CardExtra'
import { WeatherCard } from './WeatherCard'

export function InsertInsurance() {
  const { t } = useTranslation()

  const weatherInsurancePrice = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )
  const isWeatherInsuranceInitiallyVisible = useAppSelector(
    extraSlice.selectors.isWeatherInsuranceInitiallyVisible
  )

  useFetchPricePreview()

  const services = useAppSelector(servicesSlice.selectors.data)
  const bookingType = useAppSelector(cartSlice.selectors.selectedBookingType)
  const isWeatherInsuranceAddedToCart = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const selectedBookingType = useAppSelector(
    cartSlice.selectors.selectedBookingType
  )
  const dayDifference = useDayDifference()
  const insertDataPath = useAppPath(INSERT_DATA_PATH)

  const purchaseType =
    bookingType === BookingAvailability.TICKETS
      ? ServiceInfoPurchaseType.ALONE
      : ServiceInfoPurchaseType.WITH_SPOT

  const featuredExtraServices = useAppSelector(selectAvailableFeaturedServices)

  const formattedServices = featuredExtraServices?.reduce<
    Record<string, { sectors: number[]; id: number }>
  >((acc, item) => {
    const key = getServiceKeyFromId(item.serviceId)
    return {
      ...acc,
      [key]: {
        id: item.serviceId,
        sectors: item.sectors,
      },
    }
  }, {})

  const dispatch = useAppDispatch()

  const quoteCallback = useCallback(() => {
    dispatch(
      cartSlice.actions.quote({
        isQuoteForTickets: selectedBookingType === BookingAvailability.TICKETS,
        needsToUpdateCartTotal: true,
      })
    )
  }, [dispatch])

  const quote = useDebounceCallback(quoteCallback, 500)

  const handleUpdateCartWeather = (isWeatherInsuranceSelected: boolean) => {
    dispatch(
      cartSlice.actions.setIsWeatherInsuranceSelected(
        isWeatherInsuranceSelected
      )
    )
  }

  const handleUpdateCartProductQuantity = (id: number) => {
    const key = getServiceKeyFromId(id)

    return (selectedQuantity: number) => {
      const reservationToUpdate =
        formattedServices &&
        cartReservations.find((res) =>
          formattedServices[key]?.sectors.includes(res.sector)
        )
      const updatedCartReservations =
        selectedBookingType === BookingAvailability.TICKETS
          ? [
              {
                ...cartReservations[0],
                services: {
                  ...cartReservations[0].services,
                  [key]: selectedQuantity,
                },
              },
            ]
          : [
              ...cartReservations.map((res) => {
                if (
                  res.spotName !== reservationToUpdate?.spotName ||
                  res.spotType !== reservationToUpdate?.spotType
                ) {
                  return res
                } else
                  return {
                    ...res,
                    services: { ...res.services, [key]: selectedQuantity },
                  }
              }),
            ]
      dispatch(cartSlice.actions.setCartReservations(updatedCartReservations))
      quote()
    }
  }

  const sections = [
    ...(isWeatherInsuranceInitiallyVisible
      ? [
          {
            content: (
              <WeatherCard
                description={t('extra.weatherInsurance.description')}
                features={[
                  {
                    copy: t('extra.weatherInsurance.firstListItem'),
                    icon: 'Business',
                  },
                  {
                    copy: t('extra.weatherInsurance.secondListItem'),
                    icon: 'Weather',
                  },
                  {
                    copy: t('extra.weatherInsurance.thirdListItem'),
                    icon: 'Communication',
                  },
                ]}
                image={{ alt: '', src: weatherInsuranceImage }}
                insuranceProvider={{
                  image: { src: '/images/logo-revo.svg' },
                  name: 'REVO Insurance S.p.A',
                }}
                isInitiallySelected={isWeatherInsuranceAddedToCart}
                isPending={rd.isPending(weatherInsurancePrice)}
                onSelectChange={handleUpdateCartWeather}
                price={
                  rd.isSuccess(weatherInsurancePrice)
                    ? weatherInsurancePrice.value
                    : undefined
                }
                title={t('extra.weatherInsurance.title')}
              />
            ),
            title: t('extra.protectYourself.title'),
          },
        ]
      : []),
    ...(featuredExtraServices?.length
      ? [
          {
            content: (
              <>
                {featuredExtraServices.map((item) => {
                  const firstCompatibleReservation = cartReservations.find(
                    (res) => item.sectors.includes(res.sector)
                  )

                  const seatKeys: (keyof QuoteReservation)[] = [
                    'beds',
                    'chairs',
                    'deckChairs',
                    'maxiBeds',
                  ]
                  const seatsAmount = firstCompatibleReservation
                    ? seatKeys.reduce(
                      (sum, key) =>
                        sum +
                          ((firstCompatibleReservation[key] as number) || 0),
                      0
                    )
                    : 0

                  const maxQuantity = getServiceSingleLimit({
                    dayDifference,
                    seatsAmount,
                    service: item,
                  })

                  const key = getServiceKeyFromId(item.serviceId)
                  const initialQuantity =
                    firstCompatibleReservation?.services[key] ?? 0

                  return (
                    <CardExtra
                      description={item.description}
                      hasLimit={item.hasLimit}
                      image={{ alt: '', src: item.image }}
                      initialQuantity={initialQuantity}
                      key={item.serviceId}
                      maxQuantity={maxQuantity}
                      onQuantityChange={handleUpdateCartProductQuantity(
                        item.serviceId
                      )}
                      price={item.price}
                      title={item.name}
                    />
                  )
                })}
              </>
            ),
            description: t('extra.food.description'),
            title: t('extra.food.title'),
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (!services) {
      dispatch(servicesSlice.actions.load({ purchaseType }))
    }
  }, [services, dispatch, purchaseType])

  useEffect(() => {
    dispatch(cartSlice.actions.setSelectedElementReservation(null))
  }, [dispatch])

  if (rd.isInitial(weatherInsurancePrice)) {
    return <LoaderTrigger />
  }

  return (
    <>
      <PageMainContent className="lg:mb-0">
        <GridWithRecap className="mt-0 lg:mt-0">
          <GridWithRecap.ContentContainer>
            <h1 className="sr-only">Extra</h1>
            {sections.map((section, index) => (
              <PageSection
                description={section.description}
                key={index}
                title={section.title}
              >
                {section.content}
              </PageSection>
            ))}
          </GridWithRecap.ContentContainer>

          <GridWithRecap.RecapContainer className="hidden lg:flex">
            <ReservationRecapCard nextPath={insertDataPath} />
          </GridWithRecap.RecapContainer>
        </GridWithRecap>
      </PageMainContent>

      <CartFooter className="lg:hidden" nextPath={insertDataPath} />
    </>
  )
}
