import * as rd from '@devexperts/remote-data-ts'
import * as Sentry from '@sentry/react'

import { resetAllState } from '@/store/commonActions.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { isBrowserError } from '@/utils/typeGuards.ts'
import { httpClient } from '../lib/http/HttpClient'
import {
  AbortPaymentIntentResponse,
  KioskPosTerminalError,
  KioskPosTerminalResponse,
  KioskPosTerminalResponseError,
  KioskSettingsResponse,
  PaymentIntentStatusResponse,
} from '../models/cart'

import { appSlice } from './appSlice'
import { RootState } from './configureStore'
import { createAppSlice } from './createAppSlice'

interface SliceState {
  isPaymentComplete: boolean
  paymentIntentId: rd.RemoteData<string, string>
  settings: rd.RemoteData<
    string,
    KioskSettingsResponse['result']['kioskSettings']
  >
}

const initialState: SliceState = {
  isPaymentComplete: false,
  paymentIntentId: rd.initial,
  settings: rd.initial,
}

const captureError = (error: unknown) => {
  Sentry.captureException(new Error(String(error)), {
    tags: {
      reduxSlice: 'kiosk',
    },
  })
}

export const kioskSlice = createAppSlice({
  extraReducers(builder) {
    builder.addCase(resetAllState, () => initialState)
  },
  initialState: initialState satisfies SliceState as SliceState,
  name: 'kiosk',
  reducers: (create) => ({
    abortPaymentIntent: create.asyncThunk<
      boolean,
      void,
      { rejectValue: string }
    >(
      async (_, thunkApi): Promise<boolean> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.licenseCode(rootState)
        const paymentIntentId = kioskSlice.selectors.paymentIntentId(rootState)
        const settings = kioskSlice.selectors.settings(rootState)
        const country = appSlice.selectors.country(rootState)

        if (
          license === null ||
          !rd.isSuccess(paymentIntentId) ||
          !rd.isSuccess(settings)
        ) {
          const errorMessage =
            'License or payment intent ID missing or settings missing'
          captureError(errorMessage)
          throw thunkApi.rejectWithValue(errorMessage)
        }

        const res = await httpClient.fetch<AbortPaymentIntentResponse>(
          `beaches/${license}/payment-intents/${paymentIntentId.value}/abort/${settings.value.posTerminalId}`,
          country,
          { method: 'POST' }
        )

        if (res.status === 'error') {
          captureError(res.error)
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data.result.canceled
      },
      {
        fulfilled: (state, action) => {
          if (action.payload) {
            state.paymentIntentId = rd.initial
          }
        },
        options: {
          condition: (_, thunkApi) => {
            const rootState = thunkApi.getState() as RootState
            const license = licenseSlice.selectors.license(rootState)
            const paymentIntentId =
              kioskSlice.selectors.paymentIntentId(rootState)

            if (license === null || !rd.isSuccess(paymentIntentId)) {
              return false
            }

            return true
          },
        },
      }
    ),
    checkPaymentIntentStatus: create.asyncThunk<
      number,
      void,
      { rejectValue: string }
    >(
      async (_, thunkApi): Promise<number> => {
        const rootState = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.licenseCode(rootState)
        const paymentIntentId = kioskSlice.selectors.paymentIntentId(rootState)
        const country = appSlice.selectors.country(rootState)

        if (license === null || !rd.isSuccess(paymentIntentId)) {
          const errorMessage = 'License or payment intent ID missing'
          captureError(errorMessage)
          throw thunkApi.rejectWithValue(errorMessage)
        }

        const res = await httpClient.fetch<PaymentIntentStatusResponse>(
          `beaches/${license}/payment-intents/${paymentIntentId.value}`,
          country
        )

        if (res.status === 'error') {
          captureError(res.error)
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data.result.completed
      },
      {
        fulfilled: (state, action) => {
          state.isPaymentComplete = action.payload === 1
        },
        options: {
          condition: (_, thunkApi) => {
            const rootState = thunkApi.getState() as RootState
            const license = licenseSlice.selectors.license(rootState)
            const paymentIntentId =
              kioskSlice.selectors.paymentIntentId(rootState)

            if (license === null || !rd.isSuccess(paymentIntentId)) {
              return false
            }

            return true
          },
        },
        rejected: (state) => {
          state.isPaymentComplete = false
        },
      }
    ),
    createPaymentIntent: create.asyncThunk<
      string,
      { cartId: string; terminal: string },
      { rejectValue: string }
    >(
      async (payload, thunkApi): Promise<string> => {
        const state = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(state)
        const country = appSlice.selectors.country(state)

        if (license === null) {
          const errorMessage = 'License missing'
          captureError(errorMessage)
          throw thunkApi.rejectWithValue(errorMessage)
        }

        const res = await httpClient.fetch<
          KioskPosTerminalResponse,
          KioskPosTerminalResponseError
        >(
          `beaches/${license.license}/carts/${payload.cartId}/kiosk-pos-terminal`,
          country,
          {
            body: JSON.stringify({
              terminal: payload.terminal,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        )

        if (res.status === 'error') {
          captureError(res.error)

          if (isBrowserError(res.error)) {
            throw thunkApi.rejectWithValue(res.error.code)
          }

          throw thunkApi.rejectWithValue(
            res.error.internal_error_code ?? 'Unknown Error'
          )
        }

        if (
          res.data.result.paymentIntent.stripeTerminalProcessIntent === null
        ) {
          const errorMessage =
            'There was an error creating the payment intent. Stripe Terminal Process Intent is null.'
          captureError(errorMessage)
          throw thunkApi.rejectWithValue(errorMessage)
        }

        return res.data.result.paymentIntent.stripeIntent.id
      },
      {
        fulfilled: (state, action) => {
          state.paymentIntentId = rd.success(action.payload)
        },
        options: {
          condition: (_, thunkApi) => {
            const state = thunkApi.getState() as RootState
            const license = licenseSlice.selectors.license(state)
            const paymentIntentId = kioskSlice.selectors.paymentIntentId(state)

            if (license === null || rd.isPending(paymentIntentId)) {
              return false
            }

            return true
          },
        },
        pending: (state) => {
          state.paymentIntentId = rd.pending
        },
        rejected: (state, action) => {
          state.paymentIntentId = rd.failure(action.payload ?? 'Unknown error')
        },
      }
    ),
    fetchSettings: create.asyncThunk<
      KioskSettingsResponse,
      { kioskId: string },
      { rejectValue: string }
    >(
      async (payload, thunkApi): Promise<KioskSettingsResponse> => {
        const state = thunkApi.getState() as RootState
        const license = licenseSlice.selectors.license(state)
        const country = appSlice.selectors.country(state)

        if (license === null) {
          const errorMessage = 'License missing'
          captureError(errorMessage)
          throw thunkApi.rejectWithValue(errorMessage)
        }

        const res = await httpClient.fetch<KioskSettingsResponse>(
          `beaches/${license.license}/kiosk/${payload.kioskId}/settings`,
          country
        )

        if (res.status === 'error') {
          captureError(res.error)
          throw thunkApi.rejectWithValue(res.error)
        }

        return res.data
      },
      {
        fulfilled: (state, action) => {
          state.settings = rd.success(action.payload.result.kioskSettings)
        },
        pending: (state) => {
          state.settings = rd.pending
        },
        rejected: (state, action) => {
          state.settings = rd.failure(action.payload ?? 'Unknown error')
        },
      }
    ),
    logPrintFailure: create.asyncThunk<
      void,
      { kioskId: string; reservationId: number },
      { rejectValue: string }
    >(async (payload, thunkApi): Promise<void> => {
      const state = thunkApi.getState() as RootState
      const license = licenseSlice.selectors.license(state)
      const country = appSlice.selectors.country(state)

      if (license === null) {
        const errorMessage = 'License missing'
        captureError(errorMessage)
        throw thunkApi.rejectWithValue(errorMessage)
      }
      await httpClient.fetch(
        `beaches/${license.license}/kiosk/${payload.kioskId}/receipt-print-error`,
        country,
        {
          body: JSON.stringify({
            reservationId: payload.reservationId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      )
    }),
    logPrintStart: create.asyncThunk<
      void,
      {
        kioskId: string
        reservationId: number
      },
      { rejectValue: string }
    >(async (payload, thunkApi): Promise<void> => {
      const state = thunkApi.getState() as RootState
      const license = licenseSlice.selectors.license(state)
      const country = appSlice.selectors.country(state)

      if (license === null) {
        const errorMessage = 'License missing'
        captureError(errorMessage)
        throw thunkApi.rejectWithValue(errorMessage)
      }

      await httpClient.fetch(
        `beaches/${license.license}/kiosk/${payload.kioskId}/receipt-print-try`,
        country,
        {
          body: JSON.stringify({
            reservationId: payload.reservationId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      )
    }),
    logPrintSuccess: create.asyncThunk<
      void,
      {
        kioskId: string
        reservationId: number
        receiptNumber: string
        receiptDate: number
        total: number
        paymentMethod: string
        printerId: string
      },
      { rejectValue: string }
    >(async (payload, thunkApi): Promise<void> => {
      const state = thunkApi.getState() as RootState
      const license = licenseSlice.selectors.license(state)
      const country = appSlice.selectors.country(state)

      if (license === null) {
        const errorMessage = 'License missing'
        captureError(errorMessage)
        throw thunkApi.rejectWithValue(errorMessage)
      }

      await httpClient.fetch(
        `beaches/${license.license}/kiosk/${payload.kioskId}/receipt-print-success`,
        country,
        {
          body: JSON.stringify({
            method: payload.paymentMethod,
            printerId: payload.printerId,
            receiptDate: payload.receiptDate,
            receiptNumber: payload.receiptNumber,
            reservationId: payload.reservationId,
            total: payload.total,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }
      )
    }),
  }),
  selectors: {
    isPaymentComplete: (state) => {
      if (
        rd.isFailure(state.paymentIntentId) &&
        state.paymentIntentId.error ===
          KioskPosTerminalError.PAYMENT_INTENT_EXCEPTION_ERROR_001
      ) {
        return true
      }

      return state.isPaymentComplete
    },
    paymentIntentId: (state) => state.paymentIntentId,
    settings: (state) => state.settings,
  },
})
