import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef } from 'react'

import { AppIcon, Icon } from '../../assets/icons'

type StatusAlertVariant = 'danger' | 'success'

interface StatusAlertProps extends ComponentPropsWithoutRef<'div'> {
  variant: StatusAlertVariant
  label: string
  hideIcon?: boolean
  labelClassName?: string
}

const variantStyle: Record<
  StatusAlertVariant,
  { className: string; icon: AppIcon }
> = {
  danger: {
    className: 'text-red-600 border-red-300 bg-red-50',
    icon: 'TriangleExclamationMark',
  },
  success: {
    className: 'border-green-300 bg-green-50 text-green-600',
    icon: 'ApproveTick',
  },
}

export const StatusAlert = (props: StatusAlertProps) => {
  const {
    variant,
    label,
    hideIcon = false,
    className: propsClassName,
    ...rest
  } = props
  const { icon, className: variantClassName } = variantStyle[props.variant]

  return (
    <div
      className={cn(
        'inline-flex items-center gap-2 rounded-sm border px-3 py-[0.3125rem] text-sm font-medium leading-5',
        propsClassName,
        variantClassName
      )}
      role="status"
      {...rest}
    >
      {!hideIcon && icon && <Icon name={icon} />}
      <span className={props.labelClassName}>{label}</span>
    </div>
  )
}
