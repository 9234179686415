import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

import { StatusAlert } from '../../../../components/StatusAlert'

export const WeatherCardStatus = (props: ComponentPropsWithoutRef<'div'>) => {
  const { t } = useTranslation()

  return (
    <StatusAlert
      label={t('extra.insuranceAdded')}
      variant="success"
      {...props}
    />
  )
}
