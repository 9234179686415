import { cn } from '@spiaggeit/spit-ui'

import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@/components/ButtonLink'
import { DownloadConfirmationButton } from '@/components/DownloadConfirmationButton'
import { MapPreviewCard } from '@/components/MapPreviewCard'
import { useAppSelector } from '@/hooks/store'
import { useHostUrl } from '@/hooks/useHostUrl'
import { AccessCard } from '@/routes/ThankYou/AccessCard'
import { appSlice } from '@/store/appSlice'

interface ThankYouCardsProps {
  className?: string
}

export const ThankYouCards = (props: ThankYouCardsProps) => {
  const isWidgetMode = useAppSelector(appSlice.selectors.isWidgetMode)
  const { t } = useTranslation()
  const hostUrl = useHostUrl()

  return (
    <div
      className={cn('flex flex-col gap-4 lg:flex-col', props.className, {
        'flex-col-reverse': isWidgetMode,
      })}
    >
      {isWidgetMode ? (
        <>
          <MapPreviewCard />

          <ButtonLink
            className="w-full"
            label={
              <>
                <HomeIcon className="size-5" />
                {t('thankYou.backToHome.widget')}
              </>
            }
            to={hostUrl}
            variant="outline"
          />

          <DownloadConfirmationButton />
        </>
      ) : (
        <>
          {/*<PointsCard />*/}
          <AccessCard />
        </>
      )}
    </div>
  )
}
