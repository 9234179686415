import { useTranslation } from 'react-i18next'
import { Minus } from '@/assets/icons/Minus'
import { Plus } from '@/assets/icons/Plus'

import { Service } from '@/models/service'
import { priceFormatter } from '@/utils/price'

import { IconButton } from '../IconButton'

import styles from './TicketDescription.module.css'

type TicketCardProps = {
  isAddDisable: boolean
  isRemoveDisabled: boolean
  maxLabel?: string
  minLabel?: string
  onAdd: VoidFunction
  onRemove: VoidFunction
  quantity: number
  ticket: Service
}

export function TicketCard(props: TicketCardProps) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col overflow-hidden rounded border bg-white shadow">
      {props.ticket.image ? (
        <img
          alt={props.ticket.name}
          className="block h-44 w-full object-cover"
          height="176"
          src={props.ticket.image}
          width="374"
        />
      ) : null}

      <div className="flex grow flex-col break-words p-4">
        <h3 className="font-bold">{props.ticket.name}</h3>

        {props.ticket.description ? (
          <div
            className={styles['ticket-description']}
            dangerouslySetInnerHTML={{ __html: props.ticket.description }}
          />
        ) : null}

        <div className="mt-auto flex items-center justify-between gap-2 pt-2">
          <div className="min-w-0">
            <div className="text-lg font-bold">
              {priceFormatter.format(props.ticket.price)}
            </div>

            <div className="text-sm">
              {props.ticket.minimumQuantity > 0 ? props.minLabel : null}
              {props.ticket.minimumQuantity > 0 && props.ticket.hasLimit
                ? ' · '
                : null}
              {props.ticket.hasLimit ? props.maxLabel : null}
            </div>
          </div>

          <div className="flex shrink-0">
            <IconButton
              ariaLabel={t('itemQuantityController.remove')}
              icon={Minus}
              isDisabled={props.isRemoveDisabled}
              onClick={props.onRemove}
            />

            <div className="flex w-10 items-center justify-center px-0.5 font-medium">
              {props.quantity}
            </div>

            <IconButton
              ariaLabel={t('itemQuantityController.add')}
              icon={Plus}
              isDisabled={props.isAddDisable}
              onClick={props.onAdd}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
