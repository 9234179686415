import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cn } from '@spiaggeit/spit-ui'
import { cva, type VariantProps } from 'class-variance-authority'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import * as React from 'react'

import { Close } from '../../assets/icons/Close'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-[200] bg-black/25  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName
SheetOverlay.propTypes = {
  className: PropTypes.string,
}

const sheetVariants = cva(
  'fixed z-[200] gap-4 bg-white shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-200 data-[state=open]:duration-200',
  {
    defaultVariants: {
      desktopSide: 'right',
      mobileSide: 'bottom',
    },
    variants: {
      desktopSide: {
        bottom:
          'lg:inset-x-0 lg:bottom-0 lg:data-[state=closed]:slide-out-to-bottom lg:data-[state=open]:slide-in-from-bottom',
        left: 'lg:inset-y-0 lg:left-0 lg:h-full lg:w-3/4 lg:data-[state=closed]:slide-out-to-left lg:data-[state=open]:slide-in-from-left lg:sm:max-w-sm lg:right-auto',
        right:
          'lg:inset-y-0 lg:right-0 lg:h-full lg:w-3/4 lg:data-[state=closed]:slide-out-to-right lg:data-[state=open]:slide-in-from-right lg:sm:max-w-sm lg:left-auto',
        top: 'lg:inset-x-0 lg:top-0 lg:data-[state=closed]:slide-out-to-top lg:data-[state=open]:slide-in-from-top',
      },
      mobileSide: {
        bottom:
          'max-lg:top-[10%] max-lg:bottom-0 max-lg:inset-x-0 max-lg:bottom-0 max-lg:data-[state=closed]:slide-out-to-bottom max-lg:data-[state=open]:slide-in-from-bottom',
        left: 'max-lg:inset-y-0 max-lg:left-0 max-lg:h-full max-lg:w-3/4 max-lg:data-[state=closed]:slide-out-to-left max-lg:data-[state=open]:slide-in-from-left max-lg:sm:max-w-sm',
        right:
          'max-lg:inset-y-0 max-lg:right-0 max-lg:h-full max-lg:w-3/4 max-lg:data-[state=closed]:slide-out-to-right max-lg:data-[state=open]:slide-in-from-right max-lg:sm:max-w-sm',
        top: 'max-lg:inset-x-0 max-lg:top-0 max-lg:data-[state=closed]:slide-out-to-top max-lg:data-[state=open]:slide-in-from-top',
      },
    },
  }
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  mobileOnly?: boolean
  showCloseButton?: boolean
  overlayClassName?: string
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    {
      desktopSide = 'right',
      mobileSide = 'bottom',
      mobileOnly = false,
      showCloseButton = true,
      className,
      overlayClassName,
      children,
      ...props
    },
    ref
  ) => (
    <SheetPortal>
      <SheetOverlay
        className={cn({ 'lg:hidden': mobileOnly }, overlayClassName)}
      />
      <SheetPrimitive.Content
        className={cn(
          sheetVariants({ desktopSide, mobileSide }),
          { 'lg:hidden': mobileOnly },
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
        {showCloseButton ? (
          <SheetPrimitive.Close className="absolute right-4 top-4 disabled:pointer-events-none">
            <Close className="h-5 w-5 bg-white" />
            <span className="sr-only">{t('common.closeImperativeVerb')}</span>
          </SheetPrimitive.Close>
        ) : null}
      </SheetPrimitive.Content>
    </SheetPortal>
  )
)
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={className} {...props} />
)
SheetHeader.displayName = 'SheetHeader'
SheetHeader.propTypes = {
  className: PropTypes.string,
}

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={className} {...props} />
)
SheetFooter.displayName = 'SheetFooter'
SheetFooter.propTypes = {
  className: PropTypes.string,
}

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title className={className} ref={ref} {...props} />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName
SheetTitle.propTypes = {
  className: PropTypes.string,
}

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description className={className} ref={ref} {...props} />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName
SheetDescription.propTypes = {
  className: PropTypes.string,
}

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
}
