import { Dialog } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderTrigger } from '@/components/LoaderTrigger'
import { useAppSelector } from '@/hooks/store.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { selectBookingPolicyString } from '@/store/licenseSlice/selectors/selectBookingPolicyString.ts'
import { DialogProps } from '../../models/dialog-props.ts'

export interface RefundPolicyDialogProps extends DialogProps {
  bookingRefundPolicy?: number
  licenseName: string
}

export const RefundPolicyDialog = (props: RefundPolicyDialogProps) => {
  const { bookingRefundPolicy: days = 0, licenseName, ...dialogProps } = props
  const { t } = useTranslation()

  const paragraphs = [
    {
      description: t('bookingConditions.advancePaymentDescription'),
      title: t('bookingConditions.advancePaymentTitle'),
    },
    {
      description: t('bookingConditions.refundableByVoucherDescription', {
        days,
        licenseName,
      }),
      title: t('bookingConditions.refundableByVoucherTitle'),
    },
    {
      description: t('bookingConditions.refundTimetableDescription', {
        days,
      }),
      title: t('bookingConditions.refundTimetableTitle'),
    },
  ]

  const bookingPolicyText = useAppSelector(selectBookingPolicyString)

  const isLoadingStrings = useAppSelector(
    licenseSlice.selectors.isLoadingLicenseStrings
  )

  if (isLoadingStrings) {
    return (
      <>
        <LoaderTrigger />
        <p>{t('bookingConditions.title')}</p>
      </>
    )
  }

  return (
    <Dialog
      scrollContent
      size="sm"
      title={t('bookingConditions.title')}
      {...dialogProps}
    >
      {bookingPolicyText ? (
        <Content dangerouslySetInnerHTML={{ __html: bookingPolicyText }} />
      ) : (
        <Content>
          {paragraphs.map((paragraph) => (
            <div className="pb-4" key={paragraph.title}>
              <h3 className="font-bold text-primary">{paragraph.title}</h3>
              <p className="text-secondary">{paragraph.description}</p>
            </div>
          ))}
        </Content>
      )}
    </Dialog>
  )
}

const Content = (props: ComponentPropsWithoutRef<'div'>) => {
  const { children, ...rest } = props

  return (
    <div className="p-4" {...rest}>
      {children}
    </div>
  )
}
