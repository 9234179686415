import * as rd from '@devexperts/remote-data-ts'
import { useEffect } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { licenseSlice } from '@/store/licenseSlice'
import { INSERT_PERIOD_PATH } from '../../app/router/paths'
import { useAppSelector } from '../../hooks/store'
import { AppMode } from '../../models/app'
import { appSlice } from '../../store/appSlice'
import {
  BookingAvailability,
  bookingAvailabilitySlice,
} from '../../store/bookingAvailabilitySlice'
import { periodAsUnixTimeSelector } from '../../store/insertPeriodSlice'

import { ChooseProductDefault } from './Default'
import { ChooseProductKiosk } from './Kiosk'

export const ChooseProductRoute = () => {
  const navigate = useNavigate()
  const bookingAvailability = useAppSelector(
    bookingAvailabilitySlice.selectors.self
  )
  const areTicketsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areTicketsAvailable
  )
  const areSpotsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areSpotsAvailable
  )
  const license = useAppSelector(licenseSlice.selectors.license)
  const periodAsUnixTime = useAppSelector(periodAsUnixTimeSelector)
  const [searchParams] = useSearchParams()
  const mode = useAppSelector(appSlice.selectors.mode)

  useEffect(() => {
    if (!license) {
      return
    }

    if (
      rd.isFailure(bookingAvailability) ||
      (rd.isSuccess(bookingAvailability) &&
        bookingAvailability.value.type !==
          BookingAvailability.SPOTS_AND_TICKETS) ||
      !periodAsUnixTime ||
      !license
    ) {
      navigate(
        `${generatePath(INSERT_PERIOD_PATH, { license: license.license })}?${searchParams.toString()}`
      )
    }
  }, [bookingAvailability])

  if (!rd.isSuccess(bookingAvailability) || !license || !periodAsUnixTime) {
    return null
  }

  if (mode === AppMode.KIOSK) {
    return (
      <ChooseProductKiosk
        areSpotsAvailable={areSpotsAvailable}
        areTicketsAvailable={areTicketsAvailable}
      />
    )
  }

  return (
    <ChooseProductDefault
      areSpotsAvailable={areSpotsAvailable}
      areTicketsAvailable={areTicketsAvailable}
    />
  )
}
