import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { appSlice } from '@/store/appSlice.ts'
import { cartSlice } from '@/store/cartSlice'
import { insertPeriodSlice } from '@/store/insertPeriodSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils.ts'

export const selectCartPayload = createSelector(
  [
    appSlice.selectors.channel,
    licenseSlice.selectors.license,
    insertPeriodSlice.selectors.period,
    cartSlice.selectors.items,
  ],
  (channel, license, period, items) => {
    if (!channel || !license || !period) return null

    return {
      beachLicense: license.license,
      channel,
      registrations: {
        _ticket_00: {
          beds: 0,
          chairs: 0,
          deckchairs: 0,
          end: DateTime.fromFormat(period.end, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
          halfDay: 0,
          maxibeds: 0,
          note: '',
          seasonal: 0,
          sector: 0,
          services: Object.values(items).reduce((acc, item) => {
            const key = getServiceKeyFromId(item.id)
            return {
              ...acc,
              [key]: item.quantity,
            }
          }, {}),
          spotName: '',
          spotType: 'ticket',
          start: DateTime.fromFormat(period.start, 'yyyy-LL-dd', {
            zone: 'utc',
          }).toSeconds(),
        },
      },
    }
  }
)
