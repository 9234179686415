import { cn } from '@spiaggeit/spit-ui'
import { useEffect } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { BREAKPOINTS } from '@/utils/breakpoints.ts'

import { Minus } from '../../assets/icons/Minus.tsx'
import { Plus } from '../../assets/icons/Plus.tsx'
import { LegendDialog } from '../../routes/Map/LegendDialog.tsx'

import { CartMobileSheet } from './CartMobileSheet/CartMobileSheet.tsx'
import { CartOverview } from './CartOverview/CartOverview.tsx'

interface Props {
  zoomIn?(): void
  zoomOut?(): void
  nextPath?: string
  className?: string
}

export const CartFooter = (props: Props) => {
  const cartItems = useAppSelector(cartSlice.selectors.items)
  const cartItemsLength = Object.keys(cartItems).length
  const dispatch = useAppDispatch()
  const cartPopoverIsOpen = useAppSelector(
    cartSlice.selectors.cartPopoverIsOpen
  )

  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.lg}px)`)

  useEffect(() => {
    if (cartItemsLength < 1) {
      setTimeout(() => {
        dispatch(cartSlice.actions.setCartPopoverIsOpen(false))
      }, 200)
    }
  }, [dispatch, cartItemsLength])

  useEffect(() => {
    dispatch(cartSlice.actions.setCartPopoverIsOpen(false))
  }, [])

  return (
    <footer
      className={cn(
        'sticky bottom-0 z-30 mt-auto bg-white lg:p-4 lg:shadow-inner',
        { 'rounded-none': cartPopoverIsOpen },
        props.className
      )}
    >
      <div className="mx-auto flex flex-col gap-3 xl:max-w-screen-xl">
        {!isDesktop ? <CartMobileSheet /> : null}

        <div className="flex justify-between">
          {props.zoomIn && props.zoomOut ? (
            <div className="hidden items-center gap-4 lg:flex">
              <div className="flex items-center gap-2">
                <button
                  className="flex h-10 w-10 items-center justify-center rounded border"
                  onClick={props.zoomOut}
                >
                  <Minus height={20} width={20} />
                </button>
                <button
                  className="flex h-10 w-10 items-center justify-center rounded border"
                  onClick={props.zoomIn}
                >
                  <Plus height={20} width={20} />
                </button>
              </div>
              <LegendDialog />
            </div>
          ) : (
            <></>
          )}

          <CartOverview nextPath={props.nextPath} />
        </div>
      </div>
    </footer>
  )
}
