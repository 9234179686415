import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { ElementAs } from '../../models/element-as.ts'

interface ExternalLinkProps<T extends ElementType>
  extends ElementAs<T>,
    ComponentPropsWithoutRef<'a'> {
  href?: string
  isInsideParagraph?: boolean
}

export const ExternalLink = (props: ExternalLinkProps<ElementType>) => {
  const {
    href = '#',
    as: Element = 'a',
    isInsideParagraph = false,
    ...rest
  } = props

  return (
    <Element
      className={cn('text-link', {
        'hover:underline': !isInsideParagraph,
        underline: isInsideParagraph,
      })}
      {...(Element === 'a' && {
        href,
        target: '_blank',
      })}
      {...rest}
    >
      {props.children}
    </Element>
  )
}
