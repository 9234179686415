import { useTranslation } from 'react-i18next'

import { QuoteReservation } from '@/models/quote.ts'
import { cartSetupElements } from '../utils/constants.ts'
import { getReservationSpotId } from '../utils/getReservationSpotId.ts'

interface SelectedCartProduct {
  type: string
  name: string
  quantity: number
  spotId: string
}

export const useCartProductMapper = () => {
  const { t } = useTranslation()

  return (reservation: QuoteReservation): SelectedCartProduct[] =>
    cartSetupElements
      .map((element) => {
        const reservationValue =
          reservation?.[
            element.quoteReservationKey as keyof Pick<
              QuoteReservation,
              'beds' | 'chairs' | 'deckChairs' | 'maxiBeds'
            >
          ]

        if (!reservationValue)
          return { name: '', quantity: -1, spotId: '', type: '' }

        return {
          name: t(element.label, { count: reservationValue }),
          quantity: reservationValue,
          spotId: getReservationSpotId(reservation),
          type: element.quoteReservationKey,
        }
      })
      .filter((element) => element.quantity !== -1)
}

export const useCartSelectedProducts = (reservations: QuoteReservation[]) => {
  const mapper = useCartProductMapper()
  return reservations.flatMap(mapper)
}
