import { Button, Dialog } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { INSERT_PERIOD_PATH } from '@/app/router/paths.ts'
import { useAppPath } from '@/hooks/useAppPath.ts'
import { DialogWrapperProps } from '@/models/dialogWrapper.props.ts'

export const UnavailableSpotsDialog = (props: DialogWrapperProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)

  return (
    <Dialog
      isOpen={props.isOpen}
      scrollContent
      setIsOpen={props.setIsOpen}
      size="sm"
      title={t('map.unavailableSpotsDialog.title')}
      trigger={null}
    >
      <div className="p-4">
        <p className="text-base">{t('map.unavailableSpotsDialog.content')}</p>
        <div className="mb-2 mt-8 flex flex-col gap-2 pt-8 lg:flex-row-reverse">
          <Button>
            {t('map.unavailableSpotsDialog.goBack')} {/* TODO: add redirect */}
          </Button>

          <Button
            onClick={() => {
              props.setIsOpen(false)
              navigate(insertPeriodPath)
            }}
            variant="outline"
          >
            {t('map.unavailableSpotsDialog.changeDates')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
