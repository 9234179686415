import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, Fragment, useId } from 'react'
import { useTranslation } from 'react-i18next'

import { SpotType } from '@/models/cart.ts'
import { mappedSpotTypes } from '@/utils/constants.ts'

export interface SummaryProductSelected {
  name: string
  isExtra?: boolean
  quantity: number
  isGift: boolean
}

export interface SummaryReservation {
  title: string
  spotType: SpotType
  productsSelected: SummaryProductSelected[]
}

export interface ReservationListProps
  extends ComponentPropsWithoutRef<'section'> {
  reservations: SummaryReservation[]
  mode?: 'thank-you-recap'
  itemWrapperClassName?: string
  separatorClassName?: string
}

export const ReservationList = (props: ReservationListProps) => {
  const { reservations, itemWrapperClassName, separatorClassName, ...rest } =
    props
  const { t } = useTranslation()
  const titleId = useId()

  const getProductQuantity = (product: SummaryProductSelected) => {
    if (product.isGift) {
      if (product.quantity > 1) {
        return `${product.quantity - 1} + 1 ${t('common.gift')}`
      }

      return t('common.gift')
    }

    return product.quantity
  }

  const getProductString = (
    products: SummaryProductSelected[],
    mode: 'extra-only' | 'location-only' = 'location-only'
  ) => {
    return products
      .filter((p) => {
        if (mode === 'location-only') {
          return !p.isExtra
        }

        if (mode === 'extra-only') {
          return p.isExtra
        }

        throw new Error('not allowed')
      })
      .map((product) => `${product.quantity} ${product.name}`)
      .join(' · ')
  }

  return (
    <section aria-labelledby={titleId} {...rest}>
      <h3 className="sr-only" id={titleId}>
        {t('a11y.reservationDetails')}
      </h3>

      {props.reservations.map((reservation, index, array) => {
        const spotTypeData = reservation.spotType
          ? mappedSpotTypes[reservation.spotType]
          : null
        const Icon = spotTypeData?.icon ?? null
        const extras = getProductString(
          reservation.productsSelected,
          'extra-only'
        )

        return (
          <Fragment key={index}>
            {props.mode === 'thank-you-recap' ? (
              <div className="flex gap-2" key={index}>
                {Icon ? (
                  <Icon aria-hidden="true" className="size-5 text-yellow-300" />
                ) : null}
                <div>
                  <p>
                    <span className="font-bold">{reservation.title}</span>
                    :&nbsp;
                    {getProductString(reservation.productsSelected)}
                  </p>
                  {extras ? <p>Extra: {extras}</p> : null}
                </div>
              </div>
            ) : (
              <div className={itemWrapperClassName}>
                <h4 className="mb-1 font-bold">{reservation.title}</h4>
                <ul className="flex flex-col items-stretch justify-between gap-1">
                  {reservation.productsSelected.map((product, index) => (
                    <li
                      className="flex items-center justify-between gap-4 text-secondary"
                      key={index}
                    >
                      {product.name}
                      <strong className="font-normal">
                        {getProductQuantity(product)}
                      </strong>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {index + 1 !== array.length && (
              <hr className={cn('my-4', separatorClassName)} />
            )}
          </Fragment>
        )
      })}
    </section>
  )
}
