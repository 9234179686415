import { generatePath, useNavigate } from 'react-router-dom'
import {
  INSERT_PERIOD_PATH,
  MAP_PATH,
  SECTOR_PATH,
  TICKETS_PATH,
} from '@/app/router/paths'
import { bookingAvailabilitySlice } from '@/store/bookingAvailabilitySlice'
import { LicenseBookingMode, licenseSlice } from '@/store/licenseSlice'
import { getSpotsPageByBookingMode } from '@/utils/getSpotsPageByBookingMode'
import { useAppSelector } from './store'
import { useCurrentPath } from './useCurrentPath'

export const useCheckBookingSettingsChange = () => {
  const areSpotsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areSpotsAvailable
  )
  const areTicketsAvailable = useAppSelector(
    bookingAvailabilitySlice.selectors.areTicketsAvailable
  )
  const license = useAppSelector(licenseSlice.selectors.license)

  const { currentPath } = useCurrentPath()
  const navigate = useNavigate()

  const checkBookingAvailabilityChange = () => {
    if (!license) return
    const spotsPage = getSpotsPageByBookingMode(license.bookingMode)
    const generatedSpotPath = generatePath(spotsPage, {
      license: license.license,
    })
    const generatedTicketsPath = generatePath(TICKETS_PATH, {
      license: license.license,
    })

    if (!areSpotsAvailable && !areTicketsAvailable) {
      navigate(generatePath(INSERT_PERIOD_PATH, { license: license.license }))
    }

    if (currentPath === MAP_PATH && !areSpotsAvailable) {
      navigate(generatedTicketsPath)
    }

    if (currentPath === SECTOR_PATH && !areSpotsAvailable) {
      navigate(generatedTicketsPath)
    }

    if (currentPath === SECTOR_PATH && !areSpotsAvailable) {
      navigate(generatedTicketsPath)
    }

    if (
      currentPath === SECTOR_PATH &&
      areSpotsAvailable &&
      license.bookingMode !== LicenseBookingMode.SECTORS
    ) {
      navigate(generatedSpotPath)
    }

    if (
      currentPath === MAP_PATH &&
      areSpotsAvailable &&
      license.bookingMode !== LicenseBookingMode.MAP
    ) {
      navigate(generatedSpotPath)
    }

    if (currentPath === TICKETS_PATH && !areTicketsAvailable) {
      navigate(generatedSpotPath)
    }
  }
  return { checkBookingAvailabilityChange }
}
