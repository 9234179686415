import { createSelector } from '@reduxjs/toolkit'
import { cartSlice } from '@/store/cartSlice'
import { servicesSlice } from '@/store/servicesSlice.ts'

export const selectTicketRemainingAvailableQuantity = createSelector(
  [
    servicesSlice.selectors.data,
    cartSlice.selectors.cartReservations,
    cartSlice.selectors.selectedElementReservation,
  ],
  (services, cartReservations, selectedElementReservation) => {
    if (!services?.hasLimit) {
      return Infinity
    } else {
      const reservations = [
        selectedElementReservation,
        ...cartReservations.filter(
          (res) =>
            `${res.spotName}${res.spotType}` !==
            `${selectedElementReservation?.spotName}${selectedElementReservation?.spotType}`
        ),
      ]
      const ticketServices = services?.ticketServices
      const ticketIds = ticketServices?.map((service) => service.serviceId)
      const totalTicketsInCart = reservations.reduce((total, reservation) => {
        const services = reservation?.services
        for (const key in services) {
          const serviceId = key.replace('id', '')
          if (ticketIds?.includes(Number(serviceId))) {
            total += services[key]
          }
        }

        return total
      }, 0)
      return services.globalLimit - totalTicketsInCart
    }
  }
)
