import { SpotType } from '@spiaggeit/spit-core'

export const getFormattedReservationKey = (input: string) => {
  const updatedString = input.startsWith('_') ? input.slice(1) : input

  const parts = updatedString.split('_')

  const spotType = Object.values(SpotType).includes(parts[0] as SpotType)
    ? parts[0]
    : SpotType.UMBRELLA

  const name = parts.slice(1).join(' ')

  return {
    name,
    spotType,
  }
}
