import { cn } from '@spiaggeit/spit-ui'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

export const PageMainContent = (
  props: PropsWithChildren<ComponentPropsWithoutRef<'main'>>
) => {
  const { className, ...restProps } = props

  return (
    <main
      className={cn('container mb-12 mt-8 flex flex-col gap-8', className)}
      {...restProps}
    >
      {props.children}
    </main>
  )
}
