import { cn } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/hooks/store.ts'

import { licenseSlice } from '@/store/licenseSlice'
import { Icon } from '../../assets/icons'
import { RefundPolicyDialog } from '../RefundPolicyDialog'

export const RefundPolicy = () => {
  const { t } = useTranslation()

  const license = useAppSelector(licenseSlice.selectors.license)
  const isBookingRefundable = useAppSelector(
    licenseSlice.selectors.isBookingRefundable
  )

  if (!license) return null

  return (
    <div
      className={cn('rounded-sm border p-1', {
        'border-green-100 bg-green-25': isBookingRefundable,
        'border-red-100 bg-red-25': !isBookingRefundable,
      })}
      role="status"
    >
      <div className="flex items-start gap-1">
        {isBookingRefundable ? (
          <Icon className="h-5 w-5 shrink-0 text-green-500" name="Approve" />
        ) : (
          <Icon
            className="shrink-0 text-red-500"
            name="TriangleExclamationMark"
          />
        )}

        <span className="text-sm">
          {isBookingRefundable
            ? t('map.elementDetail.refundPolicy.positive', {
              count: license.bookingRefundPolicy,
              licenseName: license.name,
            })
            : t('map.elementDetail.refundPolicy.negative')}
        </span>
      </div>

      <RefundPolicyDialog
        bookingRefundPolicy={license.bookingRefundPolicy}
        licenseName={license.name}
        trigger={
          <button className="ml-1 text-xs font-bold">
            <span aria-hidden="true">+</span> {t('common.moreInfo')}
          </button>
        }
      />
    </div>
  )
}
