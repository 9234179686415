import { useTranslation } from 'react-i18next'

import { SkeletonText } from '@/components/SkeletonText'
import { licenseSlice } from '@/store/licenseSlice'
import { formatDateRange } from '@/utils/formatDateRange'
import { Info } from '../../assets/icons/Info'
import { useAppSelector } from '../../hooks/store'
import { cartSlice } from '../../store/cartSlice/index'
import { insertPeriodSlice } from '../../store/insertPeriodSlice'
import { priceFormatter } from '../../utils/price'
import { servicesWithCartQuantitySelector } from '../../utils/services'

interface Props {
  openFeesDialog: VoidFunction
}

export const Sidebar = (props: Props) => {
  const { i18n, t } = useTranslation()
  const cartTotal = useAppSelector(cartSlice.selectors.total)
  const cartFees = useAppSelector(cartSlice.selectors.quoteFees)
  const servicesWithCartQuantity = useAppSelector(
    servicesWithCartQuantitySelector
  )
  const isQuotePending = useAppSelector(cartSlice.selectors.isQuotePending)
  const license = useAppSelector(licenseSlice.selectors.license)
  const beachLocation = useAppSelector(licenseSlice.selectors.beachLocation)
  const period = useAppSelector(insertPeriodSlice.selectors.period)

  if (!license || !period) return

  const dateString = formatDateRange(period.start, period.end, i18n.language)

  return (
    <div className="sticky top-24 rounded border bg-white p-4 leading-tight shadow">
      <div className="divide-y">
        <div className="space-y-1 pb-4">
          <h4 className="mb-4 text-lg font-bold">
            {t('tickets.summary.title')}
          </h4>

          <div className="font-bold">{license.name}</div>

          <div className="text-secondary">{beachLocation}</div>

          <div className="text-secondary">{dateString.short}</div>
        </div>

        {servicesWithCartQuantity?.map((group) => (
          <div className="space-y-1 py-4" key={group.serviceGroupId}>
            <div className="font-bold">{group.name}</div>

            {group.services.map((item) => (
              <div className="flex gap-2 text-secondary" key={item.serviceId}>
                <img
                  alt={item.name}
                  className="size-5 shrink-0"
                  src={`/icons/${item.icon}`}
                />

                <div className="min-w-0 grow">
                  {item.quantity} x {item.name}
                </div>

                <div className="shrink-0">
                  {priceFormatter.format(item.price * item.quantity)}
                </div>
              </div>
            ))}
          </div>
        ))}

        <div className="space-y-1 pt-4">
          <div className="flex justify-between gap-2 text-secondary">
            <div className="min-w-0">{t('tickets.summary.ticket')}</div>

            <div className="shrink-0">
              {priceFormatter.format(cartTotal - cartFees)}
            </div>
          </div>

          <div className="flex justify-between gap-2 text-secondary">
            <div
              className="flex min-w-0 cursor-pointer items-center gap-1"
              onClick={props.openFeesDialog}
            >
              <div className="underline">{t('tickets.summary.fees')}</div>

              <Info className="size-5 shrink-0" />
            </div>

            <SkeletonText
              className="shrink-0"
              isPending={isQuotePending}
              text={priceFormatter.format(cartFees)}
            />
          </div>

          <div className="flex justify-between gap-2 font-bold">
            <div className="min-w-0">{t('tickets.summary.total')}</div>
            <SkeletonText
              className="shrink-0"
              isPending={isQuotePending}
              text={priceFormatter.format(cartTotal)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
