import { cn } from '@spiaggeit/spit-ui'
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  useId,
} from 'react'

import { Radio } from '../Radio'
import { Surface } from '../Surface'

interface RadioBoxProps extends ComponentPropsWithoutRef<'div'> {
  isSelected: boolean
  onSelect(): void
  label: string
}

export const RadioCard = forwardRef(function ForwardedRadioCard(
  props: RadioBoxProps,
  ref: ForwardedRef<HTMLElement>
) {
  const {
    isSelected,
    onSelect,
    label,
    className: propsClassName,
    ...restProps
  } = props
  const labelId = useId()

  return (
    <Surface
      aria-checked={!isSelected}
      aria-labelledby={labelId}
      className={cn(
        'flex items-center gap-2 rounded border-2 border-gray-100 p-4 aria-checked:border-blue-500',
        propsClassName
      )}
      onClick={onSelect}
      ref={ref}
      role="radio"
      {...restProps}
    >
      <Radio id="radio" isSelected={!isSelected} visualOnly />
      <span className="font-bold leading-5">{label}</span>
    </Surface>
  )
})
