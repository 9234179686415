import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { cartSlice } from '@/store/cartSlice'
import { cartSliceInitialState } from '@/store/cartSlice/initialState.ts'
import { selectActiveCartReservation } from '@/store/cartSlice/selectors/selectActiveCartReservation.ts'
import { cartAddReservationToQuote } from '@/store/cartSlice/thunks/cartAddReservationToQuote.ts'
import { getServiceKeyFromId } from '@/utils/serviceIdKeyUtils'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useDayDifference } from '../../hooks/useDayDifference'
import { QuoteSetupItems } from '../../models/cart'
import { MapSetupItemKeyName } from '../../models/map'
import {
  mapCurrentSectorDetailsSelector,
  mapSelectedElementServices,
  mapSelectedElementSetupDataSelector,
  mapSelectors,
  mapSlice,
} from '../../store/mapSlice'
import { getObjectsComparison } from '../../utils/getObjectsComparison'
import { getSeatDefaultQuantity } from '../../utils/getSeatDefaultQuantity'
import { getServiceUpdatedQuantity } from '../../utils/getServiceUpdatedQuantity'
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '../Sheet/Sheet'

import { DiscardChangesDialog } from './DiscardChangesDialog'
import { SelectedElementHeader } from './SelectedElementHeader'
import { SetupCartActions } from './SetupCartActions'
import { SetupProductsList } from './SetupProductsList'

export const SelectedElementDrawer = () => {
  const dispatch = useAppDispatch()
  const dayDifference = useDayDifference()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)

  const sectorDetails = useAppSelector(mapCurrentSectorDetailsSelector)

  const cartItems = useAppSelector(cartSlice.selectors.items)

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)

  const selectedElementSetupItems = setupData?.selectedElementSetupItems

  const isElementAddedToCart = useMemo(() => {
    return Object.keys(cartItems).includes(`${selectedElement?.id}`)
  }, [cartItems, selectedElement])

  const selectedElementServices = useAppSelector(mapSelectedElementServices)
  const includedServices = selectedElementServices.includedServices
  const extraServices = selectedElementServices.extraServices
  const ticketServices = selectedElementServices.ticketServices

  const selectedElementQuotableProducts = useAppSelector(
    cartSlice.selectors.selectedElementQuotableProducts
  )

  const activeCartReservation = useAppSelector(selectActiveCartReservation)

  const selectedElementReservation = useAppSelector(
    cartSlice.selectors.selectedElementReservation
  )

  const selectedElementSeats = [
    MapSetupItemKeyName.BED,
    MapSetupItemKeyName.CHAIR,
    MapSetupItemKeyName.DECK_CHAIR,
    MapSetupItemKeyName.MAXI_BED,
  ].reduce<Omit<QuoteSetupItems, 'services'>>(
    (acc, key) => {
      const setupItem = selectedElementSetupItems?.find(
        (item) => item.keyName === key
      )
      if (!setupItem) return acc

      return {
        ...acc,
        [key]: getSeatDefaultQuantity({
          selectedElementCartReservation: activeCartReservation,
          setupItem: setupItem,
        }),
      }
    },
    { b: 0, c: 0, d: 0, m: 0 }
  )

  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )

  const quotableServices = [
    ...includedServices,
    ...ticketServices,
    ...extraServices,
  ].reduce(
    (acc, service) => {
      const dynamicKey = getServiceKeyFromId(service?.serviceId)
      const serviceInCartQuantity = activeCartReservation?.services[dynamicKey]
      acc[dynamicKey] = getServiceUpdatedQuantity({
        dayDifference,
        selectedSeats: selectedElementQuotedSeatsAmount,
        service,
        serviceInCartQuantity,
      })
      return acc
    },
    {} as Record<string, number>
  )

  const initialQuoteSetupItems = {
    ...selectedElementSeats,
    services: quotableServices,
  }

  useEffect(() => {
    dispatch(
      cartSlice.actions.setSelectedElementQuotableProducts(
        initialQuoteSetupItems
      )
    )
  }, [])

  const quoteCallback = useCallback(() => {
    dispatch(cartSlice.actions.quote())
  }, [dispatch])

  const quote = useDebounceCallback(quoteCallback, 500)

  useEffect(() => {
    dispatch(cartAddReservationToQuote())
    quote()
  }, [
    selectedElement,
    selectedElementQuotableProducts,
    selectedElementQuotedSeatsAmount,
  ])

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false)

  const handleClose = () => {
    dispatch(cartSlice.actions.setSelectedElementReservation(null))

    quote()

    setOpen(false)

    // Please note: this timeout is necessary to complete the exit animation
    setTimeout(() => {
      dispatch(mapSlice.actions.selectMapElement(null))

      dispatch(
        cartSlice.actions.setSelectedElementQuotableProducts(
          cartSliceInitialState.selectedElementQuotableProducts
        )
      )

      document.body.style.pointerEvents = 'auto'
    }, 200)
  }

  const onCloseClick = () => {
    if (!selectedElement) return

    const areReservationsEqual = getObjectsComparison(
      activeCartReservation,
      selectedElementReservation || {}
    )

    if (isElementAddedToCart && !areReservationsEqual) {
      setIsDiscardChangesDialogOpen(true)
    } else {
      handleClose()
    }
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  if (!sectorDetails || !selectedElement || !selectedElementSetupItems)
    return null

  return (
    <Sheet
      onOpenChange={(open) => {
        if (!open) {
          onCloseClick()
        }
      }}
      open={open}
    >
      <SheetContent
        className="flex flex-col justify-between gap-0 overflow-hidden rounded-t-lg pt-4 lg:h-full lg:rounded-none"
        onCloseAutoFocus={(event) => {
          // https://github.com/radix-ui/primitives/issues/1241
          event.preventDefault()
          document.body.style.pointerEvents = ''
        }}
      >
        <div className="flex flex-grow flex-col justify-start max-lg:overflow-auto  lg:overflow-hidden">
          <SheetTitle className="sr-only">
            <span>{selectedElement?.name}</span>
          </SheetTitle>

          <SheetHeader className="w-full lg:sticky lg:top-0">
            <SelectedElementHeader />

            <DiscardChangesDialog
              handleClose={handleClose}
              isOpen={isDiscardChangesDialogOpen}
              setIsOpen={setIsDiscardChangesDialogOpen}
            />
          </SheetHeader>

          <div className="w-full lg:overflow-auto">
            <SetupProductsList />
          </div>

          <SheetFooter className="sticky bottom-0 left-0 right-0 mt-auto flex bg-white">
            <SetupCartActions
              handleCloseDrawer={() => setOpen(false)}
              handleCloseWithQuote={handleClose}
            />
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  )
}
