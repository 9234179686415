import { QuoteReservation } from '@/models/quote.ts'

interface GetSpotReservationsProps {
  selectedElementReservation: QuoteReservation | null
  cartReservations: QuoteReservation[]
}

/* We can divide the spot reservations in two categories:
    1) reservations that have already been configured and added to cart by the user;
    2) the selectedElementReservation, which is the reservation of an element the user 
        is currently configuring but has not necessarily added to cart yet. We listent for
        changes in the selectedElementReservation configuration and re-fire the quote API
        call in order to update the price we show the user.
    
*/
export const getSpotReservations = (props: GetSpotReservationsProps) => {
  const { selectedElementReservation, cartReservations } = props

  return selectedElementReservation
    ? [
        selectedElementReservation,
        /* If the user selects a spot that they already added to cart, 
        we need to filter this out from the cartReservation, 
        this way we can listen to any change in the spot configuration and show the user
        an updated price 
        */
        ...cartReservations.filter(
          (res) =>
            `${res.spotName}${res.spotType}` !==
            `${selectedElementReservation.spotName}${selectedElementReservation.spotType}`
        ),
      ]
    : cartReservations
}
