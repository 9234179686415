import { lazy } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { FormContainer } from '@/components/FormContainer'
import { LoaderContainer } from '@/components/LoaderContainer'
import { PeriodProtectedRoute } from '@/components/PeriodProtectedRoute'

import { ChooseProductRoute } from '@/routes/ChooseProduct'
import { InsertData } from '@/routes/InsertData'
import { InsertInsurance } from '@/routes/InsertInsurance'
import { InsertPeriod } from '@/routes/InsertPeriod'
import { LandingKioskRoute } from '@/routes/LandingKiosk'
import { MapRoute } from '@/routes/Map'
import { PayGuard } from '@/routes/Pay/PayGuard'
import { PaymentKioskRoute } from '@/routes/PaymentKiosk'
import { SectorsRoute } from '@/routes/Sectors'
import { SettingsRoute } from '@/routes/Settings'
import { ThankYou } from '@/routes/ThankYou'
import { TicketsRoute } from '@/routes/Tickets'

import { Root } from '../../routes/Root'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  MAP_PATH,
  PAY_PATH,
  PAYMENT_KIOSK_PATH,
  ROOT_PATH,
  SECTOR_PATH,
  SETTINGS_PATH,
  THANK_YOU_PATH,
  TICKETS_PATH,
} from './paths'

const Pay = lazy(() =>
  import('@/routes/Pay/index').then((m) => ({ default: m.Pay }))
)

const router = createBrowserRouter([
  {
    children: [
      {
        children: [
          {
            element: <InsertPeriod />,
            path: INSERT_PERIOD_PATH,
          },
          {
            element: <LandingKioskRoute />,
            path: LANDING_KIOSK_PATH,
          },
          {
            children: [
              {
                element: <PaymentKioskRoute />,
                path: PAYMENT_KIOSK_PATH,
              },
              {
                element: <ChooseProductRoute />,
                path: CHOOSE_PRODUCT_PATH,
              },
              {
                children: [
                  {
                    element: <InsertInsurance />,
                    path: INSERT_INSURANCE_PATH,
                  },
                  {
                    element: <MapRoute />,
                    path: MAP_PATH,
                  },
                  {
                    element: <SectorsRoute />,
                    path: SECTOR_PATH,
                  },
                  {
                    element: <TicketsRoute />,
                    path: TICKETS_PATH,
                  },
                  {
                    element: <InsertData />,
                    path: INSERT_DATA_PATH,
                  },
                ],
                element: <FormContainer />,
              },
            ],
            element: <PeriodProtectedRoute />,
          },
          {
            element: <PayGuard>{(props) => <Pay {...props} />}</PayGuard>,
            path: PAY_PATH,
          },
          {
            element: <ThankYou />,
            path: THANK_YOU_PATH,
          },
        ],
        element: <Root />,
        path: ROOT_PATH,
      },
      {
        element: <SettingsRoute />,
        path: SETTINGS_PATH,
      },
    ],
    element: <LoaderContainer />,
  },
])

export const Router = () => <RouterProvider router={router} />
