import { useEffect, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardWithImage } from '../../../components/CardWithImage'
import { HtmlContent } from '../../../components/HtmlContent'
import { InputQuantity } from '../../../components/InputQuantity'
import { priceFormatter } from '../../../utils/price.ts'

interface CardExtraProps {
  image?: {
    alt?: string
    src: string
  }
  title: string
  description: string
  price: number
  initialQuantity: number
  maxQuantity: number
  hasLimit: boolean
  onQuantityChange(quantity: number): void
}

export function CardExtra(props: CardExtraProps) {
  const { t } = useTranslation()

  const [quantitySelected, setQuantitySelected] = useState(
    props.initialQuantity
  )

  useEffect(() => {
    props.onQuantityChange(quantitySelected)
  }, [quantitySelected])

  const featureListDescription = useId()
  const priceId = useId()
  const articleTitleId = useId()

  return (
    <CardWithImage
      aria-labelledby={`${articleTitleId} ${priceId}`}
      contentWrapperProps={{ className: 'flex flex-col justify-between gap-4' }}
      image={props.image}
    >
      <div>
        <h3
          className="mb-1 break-all text-lg font-bold leading-6"
          id={articleTitleId}
        >
          {props.title}
        </h3>

        <HtmlContent
          className="text-sm leading-6"
          description={props.description}
          id={featureListDescription}
        />
      </div>

      <div className="flex justify-between">
        <div>
          <h4 className="sr-only">{t('a11y.priceAndMaxQuantity')}</h4>
          <data className="text-lg font-bold" id={priceId} value={props.price}>
            {priceFormatter.format(props.price)}
          </data>
          {props.hasLimit ? (
            <p className="text-xs">
              {t('extra.maxQuantity', { maxQuantity: props.maxQuantity })}
            </p>
          ) : null}
        </div>

        <InputQuantity
          max={props.maxQuantity}
          productName={props.title}
          quantity={quantitySelected}
          quantityChange={setQuantitySelected}
        />
      </div>
    </CardWithImage>
  )
}
