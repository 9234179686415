import { Button, Dialog } from '@spiaggeit/spit-ui'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/hooks/store.ts'
import { cartRemoveElementAndUpdateQuoteAction } from '@/store/cartSlice/thunks/cartRemoveElementAndUpdateQuoteAction.ts'
import { Close } from '../assets/icons/Close'
import { MapElementWithSectorId } from '../models/map'

export const RemoveElementFromCartDialog = ({
  element,
  isOpen,
  setIsOpen,
  handleClose,
}: {
  element: MapElementWithSectorId
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleClose?: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onClose = () => setIsOpen(false)

  const onRemoveElement = () => {
    onClose()
    handleClose?.()
    dispatch(cartRemoveElementAndUpdateQuoteAction(element))
  }

  return (
    <Dialog isOpen={isOpen} scrollContent setIsOpen={setIsOpen} size="sm">
      <div className="flex justify-between border-b border-b-gray-300 p-4">
        <h3 className="font-bold text-primary">
          {t('map.elementDetail.removeFromCart')}
        </h3>
        <button aria-label={t('common.closeImperativeVerb')} onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className="p-4">
        <span className="size-4 text-gray-900">
          {t('map.elementDetail.confirmRemoveFromCart')}
        </span>
        <div className="flex flex-col gap-2 pt-8 lg:flex-row-reverse">
          <Button color="error" onClick={onRemoveElement}>
            {t('common.remove')}
          </Button>
          <Button onClick={onClose} variant="outline">
            {t('common.cancel')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
