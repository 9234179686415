import { httpClient } from '@/lib/http/HttpClient.ts'
import { Country } from '@/models/app.ts'
import { Errors } from '@/utils/errors.ts'

export interface PayWithCardPayload {
  cardId: number
  totalToPay: number
}

interface PayWithCardParams {
  licenseName: string | null
  reservationId: string
  country: Country
}

interface PayWithCardProps {
  payload: PayWithCardPayload
  params: PayWithCardParams
}

export const payWithCard = ({ payload, params }: PayWithCardProps) => {
  if (!params.licenseName) {
    return Promise.resolve({ error: Errors.LicenseNotFound, status: 'error' })
  }

  return httpClient.fetch(
    `beaches/${params.licenseName}/carts/${params.reservationId}/pay-with-card`,
    params.country,
    {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    },
    true
  )
}
