var WorldPrintContent_PrintModes = {
	FISCAL: 'fis',
	NON_FISCAL: 'nfis',
	REFUND: 'canc',
	LIST: 'list',
	PLAIN_TEXT: 'text'
};

var WorldPrintContent_PaymentMethods = {
	CONTANTI: 'contanti',
	BANCOMAT: 'bancomat',
	CARTA: 'carta',
	NON_RISCOSSO: 'non_riscosso',
	TICKET: 'ticket',
	BONIFICO: 'bonifico'
};

/**
 * Represents the content of a receipt
 * @constructor
 * 
 * @property {string} printMode                 - Print mode, value from WorldPrintContent_printModes
 * @property {string} paymentMethod             - Payment method of the receipt, value from WorldPrintContent_paymentMethods
 * @property {Array}  paymentMethods            - Payment methods of the receipt, value from WorldPrintContent_paymentMethods
 * @property {Array}  articles                  - Array of lines for the receipt, instances of WorldPrintArticle
 * @property {Array}  deposits                  - Acconti già versati da sottrarre
 * @property {number} discount                  - Value of discount, if applicable
 * @property {number} discountPercentage        - Value of discount in percentage, if applicable
 * @property {string} notes
 * @property {string} text
 * @property {number} timestamp
 * @property {number} payed                     - Unused?
 * @property {number} change                    - Change
 * @property {string} codeType                  - QR Code / Barcode
 * @property {string} codeText                  - Code content
 * @property {string} taxCode                   - Codice fiscale
 * @property {string} vatCode                   - Partita IVA - senza prefisso IT
 * @property {string} lotteryCode               - Codice lotteria scontrini
 * @property {Array}  deposits                  - Array di acconti già riscossi
 * @property {Array}  singleUseVouchers         - Array di buoni monouso
 */
function WorldPrintContent() {
	this.printMode;
	this.paymentMethod;
	this.paymentMethods;
	this.articles;
	this.notes;
	this.text;
	this.discount;
	this.discountPercentage;
	this.timestamp;
	this.payed;
	this.change;
	this.header2;
	this.footer;
	this.monitor;
	this.codeType;
	this.codeText;
	this.taxCode;
	this.vatCode;
	this.lotteryCode;
	this.deposits;
}

/**
 * Represents the content of a line of a receipt
 * @constructor
 * 
 * @property {string} description       - Description
 * @property {number} quantity          - Quantity
 * @property {number} price             - Unit Price
 * @property {number} vat               - Vat percentage
 */
function WorldPrintArticle() {
	this.description;
	this.quantity;
	this.price;
	this.vat;
}

/**
 * Represents the response of a print request
 * @constructor
 * 
 * @property {string} description       - Description
 * @property {number} quantity          - Quantity
 * @property {number} price             - Unit Price
 * @property {number} vat               - Vat percentage
 */
function WorldPrintResponse() {
	this.error;
	this.result;
	this.invoiceId;
}

function loadPrinterScript(id, fid, position, src, callback) {
	if (document.getElementById(id)) {
		if (callback && callback instanceof Function) {
			if (window['loaded_' + id]) {
				callback();
			} else {
				document.getElementById(id).addEventListener('load', callback, false);
			}
		}
		return;
	}
	var js, fjs = document.getElementById(fid);
	js = document.createElement('script');
	js.id = id;
	js.src = src;
	if (callback && callback instanceof Function) {
		js.addEventListener('load', callback, false);
	}
	fjs.insertAdjacentElement(position, js);
}

/* Funzione replicata poichè in Vue non è disponibile su window */
function getCookie(name) {
	if (document.cookie.length > 0) {
		var c_start = document.cookie.indexOf(name + "=");
		if (c_start != -1) {
			c_start = c_start + name.length + 1;
			var c_end = document.cookie.indexOf(";", c_start);
			if (c_end == -1) {
				c_end = document.cookie.length;
			}
			return unescape(document.cookie.substring(c_start, c_end));
		}
	}
	return "";
}

function createPrinter(printerData, domain) {
	// check if the placeholder exists, otherwise create
	if (!document.getElementById('anm22_printer_includes')) {
		var js = document.createElement('script');
		js.id = 'anm22_printer_includes';
		js.src = "";
		document.head.appendChild(js);
	}
	domain = domain || 'https://www.anm22.it';
	var printerClass = getPrinterModelClass(printerData.model);
	// Find model Class
	if (printerClass) {
		loadPrinterScript(
			'printer_script_' + printerClass,
			'anm22_printer_includes',
			'beforebegin',
			domain + '/app/world/libs/anm22_printers/js/' + printerClass + '.min.js?v=2308071149',
			function() {
				window['loaded_printer_script_' + printerClass] = true;
				new window[printerClass]().init(printerData, domain);
			}
		);
	}
}

function getPrinterModelClass(id) {
	var modelClasses = {
		31: "Printer_coristech_printf",
		41: "Printer_custom",
		42: "Printer_custom_raw",
		43: "Printer_custom_escpos",
		44: "Printer_custom_remote",
		21: "Printer_epson_fp81ii",
		22: "Printer_epson_tmp80",
		23: "Printer_epson_tmt20ii",
		24: "Printer_epson_escpos",
		51: "Printer_italretail_prx",
		11: "Printer_olivetti_nettuna7000_old_diver",
		12: "Printer_olivetti_nettuna7000_old_diver_v2",
		13: "Printer_olivetti_nettuna300",
		14: "Printer_olivetti_prt200fx_old_driver",
		15: "Printer_olivetti_prt200fx",
		16: "Printer_olivetti_nettuna7000",
		2: "Printer_rch_printf",
		3: "Printer_rch_printf_fidelity",
		61: "Printer_retex_fiscal",
		62: "Printer_retex_fiscal_eft",
		63: "Printer_retex_fiscal_wallet",
	};
	return modelClasses[id];
}

export {
	WorldPrintContent,
	WorldPrintContent_PaymentMethods,
	WorldPrintContent_PrintModes,
	WorldPrintArticle,
	WorldPrintResponse,
	getCookie,
	createPrinter,
	loadPrinterScript
}

