import { Button, Dialog } from '@spiaggeit/spit-ui'
import { Dispatch, ReactElement, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ExternalLink } from '../../../../components/ExternalLink'
import { PLAGES_HOSTNAME } from '../../../../utils/constants.ts'

interface InsuranceDialogProps {
  isDialogOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSelectInsurance(): void
  trigger: ReactElement
}

export const InsuranceDialog = (props: InsuranceDialogProps) => {
  const { t } = useTranslation()

  const isFrenchHostname = window.location.hostname === PLAGES_HOSTNAME

  const informationSetPdfPath = isFrenchHostname
    ? t('extra.informationSetPlagesPdfPath')
    : t('extra.informationSetSpiaggePdfPath')

  const privacyPdfPath = isFrenchHostname
    ? t('extra.privacyPlagesPdfPath')
    : t('extra.privacySpiaggePdfPath')

  return (
    <Dialog
      isOpen={props.isDialogOpen}
      mobileMode="bottomSheet"
      setIsOpen={props.setIsOpen}
      size="sm"
      title={t('extra.weatherInsurance.title')}
      trigger={props.trigger}
    >
      <div className="flex flex-col gap-4 border-t border-t-gray-100 p-4 text-xs text-secondary">
        <p>
          <Trans i18nKey="extra.weatherInsurance.consentText">
            <ExternalLink
              href={informationSetPdfPath}
              isInsideParagraph
              target="_blank"
            />
            <ExternalLink
              href={privacyPdfPath}
              isInsideParagraph
              target="_blank"
            />
          </Trans>
        </p>

        <Button onClick={props.onSelectInsurance}>
          {t('extra.addGoodWeatherGuarantee')}
        </Button>
      </div>
    </Dialog>
  )
}
