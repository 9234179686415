import { Service, ServiceLimitType } from '@/models/service'

export const getServiceSingleLimit = ({
  service,
  dayDifference,
  seatsAmount = 0,
}: {
  service: Service
  dayDifference: number
  seatsAmount?: number
}) => {
  if (service.maximumLimitType === ServiceLimitType.NUMBER_OF_PIECES) {
    return seatsAmount
  }

  if (service.maximumLimitType === ServiceLimitType.NUMBER_OF_DAYS) {
    return dayDifference
  }

  if (service.maximumLimitType === ServiceLimitType.FLAT && service.hasLimit) {
    return service.availableQuantity
  }

  return Infinity
}
