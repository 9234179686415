import { useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ExpandedSection } from '@/components/ExpandedSection'
import { ExpandedText } from '@/components/ExpandedText'
import { Fieldset } from '@/components/Fieldset'
import { Input } from '@/components/Input'
import { useAppSelector } from '@/hooks/store'
import { appSlice } from '@/store/appSlice.ts'
import { licenseSlice } from '@/store/licenseSlice'

export const InfoFormSection = () => {
  const { t } = useTranslation()
  const notes = useWatch({ name: 'notes' })
  const [showNotes, setShowNotes] = useState(!!notes)
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const isKiosk = useAppSelector(appSlice.selectors.isKiosk)
  const license = useAppSelector(licenseSlice.selectors.license)
  const bookingUserDataNotes = license?.bookingUserDataNotes

  const handleShowTextAreaNotes = () => {
    setShowNotes(true)
    inputRef.current?.focus()
  }

  return (
    <Fieldset
      className="flex flex-col gap-4"
      title={t('insertDataForm.info.title')}
    >
      {!isKiosk && (
        <ExpandedSection
          isVisible={showNotes}
          onShowSection={handleShowTextAreaNotes}
          sectionAriaLabel={t('insertDataForm.info.addNotesTitle')}
          text={t('insertDataForm.info.addNotesText')}
          tooltipText={t('insertDataForm.info.addNotesTitle')}
        >
          <Input
            isTextArea
            label={t('insertDataForm.info.notes')}
            name="notes"
            placeholder={t('placeholder.notes')}
            ref={(el: HTMLTextAreaElement) => (inputRef.current = el)}
            tabIndex={showNotes ? 0 : -1}
          />
        </ExpandedSection>
      )}

      {bookingUserDataNotes ? (
        <ExpandedText text={bookingUserDataNotes} />
      ) : null}
    </Fieldset>
  )
}
