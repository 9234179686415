import { SpotType } from '@spiaggeit/spit-core'
import { t } from 'i18next'
import { useState } from 'react'

import { MAP_PATH, SECTOR_PATH } from '@/app/router/paths.ts'
import { useCurrentPath } from '@/hooks/useCurrentPath.ts'
import { useMapSpotName } from '@/hooks/useMapSpotName.ts'
import { QuoteReservation } from '@/models/quote.ts'
import { Edit } from '../../../assets/icons/Edit'
import { Trash } from '../../../assets/icons/Trash'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { useCartProductMapper } from '../../../hooks/useCartSelectedProducts.ts'
import { useCartServicesMapper } from '../../../hooks/useCartSelectedServices.ts'
import { MapElementWithSectorId } from '../../../models/map'
import { cartSlice } from '../../../store/cartSlice'
import { mapSlice } from '../../../store/mapSlice'
import { isSpotWithSeats } from '../../../utils/isWithVisibleSeats.ts'
import { priceFormatter } from '../../../utils/price'
import { RemoveElementFromCartDialog } from '../../RemoveElementFromCartDialog'

interface Props {
  spot: MapElementWithSectorId
  sectorImage?: string | null
  reservation: QuoteReservation
  services?: { label: string | undefined; value: number }[]
}

export const CartPopupSpot = (props: Props) => {
  const dispatch = useAppDispatch()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const reservationPrices = useAppSelector(
    cartSlice.selectors.reservationPrices
  )

  const elementHasVisibleSeats = isSpotWithSeats()
  const reservationServices = useCartServicesMapper()(props.reservation)
  const { getMapSpotName } = useMapSpotName()
  const title = getMapSpotName(props.spot)

  const handlePopoverClose = () => {
    dispatch(cartSlice.actions.setCartPopoverIsOpen(false))
  }

  const onEditSpotClick = (spot: MapElementWithSectorId) => {
    dispatch(mapSlice.actions.selectMapElement(spot))
    handlePopoverClose()
  }

  const onDeleteItem = () => {
    setDialogIsOpen(true)
  }
  const { currentPath } = useCurrentPath()

  const buttonsAreVisibile =
    currentPath === MAP_PATH || currentPath === SECTOR_PATH

  const cartProducts = useCartProductMapper()(props.reservation)

  return (
    <div
      className="border-b-neutral-100 mb-4 flex max-w-full items-stretch justify-between border-b pb-4 last:mb-0"
      key={props.spot.id}
    >
      {props.sectorImage ? (
        <img
          className="mr-3 h-20 w-16 rounded-sm object-cover"
          src={props.sectorImage}
        />
      ) : null}
      <div className="grow-1 flex basis-full items-start">
        <div className="flex grow flex-col gap-1">
          <span className="word-break text-sm font-bold text-primary">
            {title}
          </span>
          {props.spot.subType === SpotType.UMBRELLA ? (
            <span className="text-sm text-primary">
              {t('map.elementDetail.includedUmbrella')}
            </span>
          ) : null}
          {elementHasVisibleSeats ? (
            <>
              {' '}
              {cartProducts.map((element) => {
                return (
                  <span className="text-sm text-primary" key={element.type}>
                    {element.quantity} {element.name}
                  </span>
                )
              })}
            </>
          ) : null}

          {reservationServices.map((service) => (
            <span className="text-sm text-primary" key={service.name}>
              {service.quantity} {service.name}
            </span>
          ))}
          {reservationPrices && (
            <div className="shrink-0 basis-full text-base font-bold text-primary">
              {priceFormatter.format(
                reservationPrices[`_${props.spot.subType}_${props.spot.name}`]
              )}
            </div>
          )}
        </div>
        {buttonsAreVisibile ? (
          <div className="flex h-full flex-col items-end justify-between">
            <div className="flex gap-5">
              <button
                className="text-primary"
                onClick={() => onEditSpotClick(props.spot)}
                type="button"
              >
                <Edit height={24} width={24} />
              </button>
              <button
                className="text-red-600"
                onClick={onDeleteItem}
                type="button"
              >
                <Trash height={24} width={24} />
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <RemoveElementFromCartDialog
        element={props.spot}
        handleClose={handlePopoverClose}
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
      />
    </div>
  )
}
