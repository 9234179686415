import { Button, Dialog } from '@spiaggeit/spit-ui'
import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'

export const DiscardChangesDialog = ({
  isOpen,
  setIsOpen,
  handleClose,
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="md"
      title={t('map.elementDetail.discardChangesConfirmation.title')}
    >
      <div className="flex flex-col border-t border-t-gray-200 p-4">
        <span className="text-primary">
          {t('map.elementDetail.discardChangesConfirmation.copy')}
        </span>
        <div className="flex flex-col gap-2 pt-8 lg:flex-row-reverse">
          <Button
            className="text-wrap"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t('map.elementDetail.cancelDiscardChanges')}
          </Button>
          <Button
            className="text-wrap"
            onClick={() => {
              setIsOpen(false)
              handleClose()
            }}
            variant="outline"
          >
            {t('map.elementDetail.discardChanges')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
