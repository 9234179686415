import { cn } from '@spiaggeit/spit-ui'

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <span
      className={cn(
        'animate-pulse rounded-sm bg-gray-100 dark:bg-gray-700',
        className
      )}
      {...props}
    />
  )
}

export { Skeleton }
