import { SpotType } from '@spiaggeit/spit-core'
import { FC, SVGProps } from 'react'

import { BasketBall } from '../assets/icons/BasketBall'
import { Boat } from '../assets/icons/Boat'
import { Cabin } from '../assets/icons/Cabin'
import { Gazebo } from '../assets/icons/Gazebo'
import { Parking } from '../assets/icons/Parking'
import { Sunbed } from '../assets/icons/Sunbed'
import { Sunbeds } from '../assets/icons/Sunbeds'
import { SunUmbrella } from '../assets/icons/SunUmbrella.tsx'

export const MAP_CELL_SIZE = 39

export const mappedSpotTypes: Record<
  string,
  { icon: FC<SVGProps<SVGSVGElement>>; label: string }
> = {
  [SpotType.BED]: {
    icon: Sunbed,
    label: 'spotTypes.bed',
  },
  [SpotType.BEDS]: {
    icon: Sunbeds,
    label: 'spotTypes.beds',
  },
  [SpotType.BOAT]: {
    icon: Boat,
    label: 'spotTypes.boat',
  },
  [SpotType.CABIN]: {
    icon: Cabin,
    label: 'spotTypes.cabins',
  },
  [SpotType.GAZEBO]: {
    icon: Gazebo,
    label: 'spotTypes.gazebo',
  },
  [SpotType.PARKING]: {
    icon: Parking,
    label: 'spotTypes.parking',
  },
  [SpotType.PLAYFIELD]: {
    icon: BasketBall,
    label: 'spotTypes.playfield',
  },
  [SpotType.UMBRELLA]: {
    icon: SunUmbrella,
    label: 'spotTypes.umbrella',
  },
}
export const mapSetupElements = {
  b: {
    icon: 'bed.svg',
    keyName: 'b',
    label: 'map.elementDetail.setupItems.bed',
    quoteReservationKey: 'beds',
  },
  c: {
    icon: 'chair.svg',
    keyName: 'c',
    label: 'map.elementDetail.setupItems.chair',
    quoteReservationKey: 'chairs',
  },
  d: {
    icon: 'deck-chair.svg',
    keyName: 'd',
    label: 'map.elementDetail.setupItems.deckChair',
    quoteReservationKey: 'deckChairs',
  },
  i: { icon: '', keyName: 'i', label: 'i', quoteReservationKey: '' },
  m: {
    icon: 'maxi-bed.svg',
    keyName: 'm',
    label: 'map.elementDetail.setupItems.maxiBed',
    quoteReservationKey: 'maxiBeds',
  },
}

export const PLAGES_HOSTNAME = 'plages.fr'

//If a map element has one of these spot types, we do NOT show the following:
// - any seat setup options
// - any service with a limit type 'number-of-pieces'
export const spotTypesWithoutSetupSeats = [
  SpotType.BOAT,
  SpotType.PARKING,
  SpotType.PLAYFIELD,
]

export const cartSetupElements = Object.values(mapSetupElements).filter(
  (setupElement) => setupElement.label !== 's'
)

export const FALLBACK_LICENSE_BOOKING_USER_UMBRELLAS_LIMIT = 9999

export const USER_HAS_VISITED_MAP_BEFORE_KEY = 'userHasVisitedMapBefore'

export const ONE_SECOND = 1000

export const ONE_MINUTE = ONE_SECOND * 60

export const ONE_HOUR = ONE_MINUTE * 60

export const ONE_DAY = ONE_HOUR * 24

export const ONE_WEEK = ONE_DAY * 7

export const REG_ID_QUERY_PARAM_NAME = 'regId'
