import { isSuccess } from '@devexperts/remote-data-ts'
import { useTranslation } from 'react-i18next'

import { selectCartMapSpots } from '@/store/cartSlice/selectors/selectCartMapSpots.ts'
import { extraSlice } from '@/store/extraSlice'
import { ExclamationMark } from '../../../assets/icons/ExclamationMark'
import { useAppSelector } from '../../../hooks/store'
import { cartSlice } from '../../../store/cartSlice'
import { mapSelectors } from '../../../store/mapSlice'
import { priceFormatter } from '../../../utils/price'
import { ServiceCostDialog } from '../../ServiceCostDialog'

import { CartPopupSpot } from './CartPopupSpot'

export const CartPopupContent = () => {
  const cartReservations = useAppSelector(cartSlice.selectors.cartReservations)
  const total = useAppSelector(cartSlice.selectors.total)
  const fees = useAppSelector(cartSlice.selectors.quoteFees)
  const sectors = useAppSelector(mapSelectors.sectors)
  const quote = useAppSelector(cartSlice.selectors.quote)
  const mapSpots = useAppSelector(selectCartMapSpots)

  const isWeatherInsuranceAddedToCart = useAppSelector(
    cartSlice.selectors.isWeatherInsuranceAddedToCart
  )

  const weatherInsurancePricePreview = useAppSelector(
    extraSlice.selectors.weatherInsurancePricePreview
  )

  const { t } = useTranslation()

  if (!mapSpots) return null

  return (
    <div className="">
      <div className="flex flex-col gap-4 lg:max-h-80 lg:overflow-hidden">
        <div className="lg:overflow-auto">
          {mapSpots?.map((spot) => {
            const sectorImage = sectors.find(
              (sector) => sector.header.id === spot.sectorId
            )?.header.image
            const currentSpotReservation = cartReservations.find((item) => {
              return (
                item.spotName === spot.name &&
                item.spotType === spot.subType &&
                item.sector === spot.sectorId
              )
            })

            if (!currentSpotReservation) return null
            return (
              <CartPopupSpot
                key={spot.id}
                reservation={currentSpotReservation}
                sectorImage={sectorImage}
                spot={spot}
              />
            )
          })}
        </div>

        <div className="bg-white pb-4 lg:sticky lg:bottom-0">
          <div className="flex justify-between">
            <span className="text-sm text-secondary">
              {/* Please note: this count does not reflect a real dynamic value,
              we just needed any number greater than one to obtain the translation
              in plural form */}
              {t('map.elementDetail.spot', { count: 2 })}
            </span>
            <span className="text-sm text-secondary">
              {priceFormatter.format(total - fees)}
            </span>
          </div>
          {isSuccess(weatherInsurancePricePreview) &&
          isWeatherInsuranceAddedToCart ? (
                <div className="flex justify-between">
                  <span className="text-sm text-secondary">
                    {t('extra.weatherInsurance.title')}
                  </span>
                  <span className="text-sm text-secondary">
                    {priceFormatter.format(weatherInsurancePricePreview.value)}
                  </span>
                </div>
              ) : null}
          <div className="flex justify-between">
            <ServiceCostDialog
              trigger={
                <button aria-label={t('common.open')}>
                  <span className="inline-flex items-center gap-1 text-sm text-secondary underline">
                    {t('map.elementDetail.serviceCost.title')}
                    <span className="text-base text-primary">
                      <ExclamationMark height={16} width={16} />
                    </span>
                  </span>
                </button>
              }
            />
            <span className="text-sm text-secondary">
              {priceFormatter.format(fees)}
            </span>
          </div>
          {isSuccess(quote) && quote.value.offer > 0 ? (
            <div className="flex justify-between">
              <span className="text-sm text-secondary">
                {t('map.elementDetail.offer')}
              </span>
              <span className="text-sm text-secondary">
                -{priceFormatter.format(quote.value.offer)}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
