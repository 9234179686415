export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
export const ITALIAN_FISCAL_CODE_PATTERN =
  '^[A-Z]{6}[0-9LMNPQRSTUV]{2}[A-Z][0-9LMNPQRSTUV]{2}[A-Z][0-9LMNPQRSTUV]{3}[A-Z]$'
export const ITALIAN_VAT_NUMBER_PATTERN = '^[0-9]{11}$'
export const PHONE_NUMBER_PATTERN = '^[\\d\\s]{6,15}$'
export const NUMBER_PATTERN = '^\\d+$'

export const EMAIL_REGEX = new RegExp(EMAIL_PATTERN)
export const ITALIAN_FISCAL_CODE_REGEX = new RegExp(ITALIAN_FISCAL_CODE_PATTERN)
export const ITALIAN_VAT_NUMBER_REGEX = new RegExp(ITALIAN_VAT_NUMBER_PATTERN)
export const PHONE_NUMBER_REGEX = new RegExp(PHONE_NUMBER_PATTERN)
export const NUMBER_REGEX = new RegExp(NUMBER_PATTERN)
