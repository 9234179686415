import { LicenseBookingMode } from '@/store/licenseSlice'
import { MAP_PATH, SECTOR_PATH } from '../app/router/paths'

export const getSpotsPageByBookingMode = (bookingMode: LicenseBookingMode) => {
  if (bookingMode === LicenseBookingMode.MAP) {
    return MAP_PATH
  }

  return SECTOR_PATH
}
