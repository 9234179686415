import { cn } from '@spiaggeit/spit-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Service } from '@/models/service'

import { Minus } from '../../assets/icons/Minus'
import { Plus } from '../../assets/icons/Plus'
import { ItemQuantityControllerVariant } from '../../models/components'
import { priceFormatter } from '../../utils/price'
import { IconButton } from '../IconButton'
import { TicketCard } from '../TicketCard'
import { Tooltip } from '../Tooltip/Tooltip'

interface Props {
  available: {
    global?: number
    group?: number
    ticket: number
  }
  hasLimit: boolean
  icon: string
  name: string
  minLabel?: string
  maxLabel?: string
  alwaysShowLabel?: boolean
  minimumQuantity: number
  price?: number
  initialQuantity?: number
  showSoldOutLabel?: boolean
  ticket?: Service
  onAdd: VoidFunction
  onRemove: VoidFunction
  onAddDisabled?: VoidFunction
  variant?: ItemQuantityControllerVariant
}

export const ItemQuantityController = (props: Props) => {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(props.initialQuantity || 0)

  const availableQuantity = props.hasLimit
    ? Math.min(
      props.available?.global || 0,
      props.available?.group || 0,
      props.available.ticket - quantity
    )
    : Infinity

  const isSoldOut = props.hasLimit && props?.available.ticket === 0

  useEffect(() => {
    if (props.initialQuantity) {
      setQuantity(props.initialQuantity)
    } else if (props.available.ticket < quantity) {
      setQuantity(props.available.ticket)
    }
  }, [props.initialQuantity, props.available.ticket])

  const handleRemove = () => {
    if (quantity > props.minimumQuantity) {
      setQuantity(quantity - 1)
      props.onRemove()
    }
  }

  const handleAdd = () => {
    if (availableQuantity > 0) {
      setQuantity(quantity + 1)
      props.onAdd()
    }
  }

  const isAddDisabled = availableQuantity < 1
  const isRemoveDisabled = quantity === 0 || quantity <= props.minimumQuantity

  const minLabel =
    props.minLabel || t('listItem.min', { value: props.minimumQuantity })

  const maxLabel =
    props.maxLabel || t('listItem.max', { value: props.available.ticket })

  if (props.ticket && props.variant === ItemQuantityControllerVariant.CARD) {
    return (
      <TicketCard
        isAddDisable={isAddDisabled}
        isRemoveDisabled={isRemoveDisabled}
        maxLabel={maxLabel}
        minLabel={minLabel}
        onAdd={handleAdd}
        onRemove={handleRemove}
        quantity={quantity}
        ticket={props.ticket}
      />
    )
  }

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 first:rounded-t last:rounded-b',
        {
          'cursor-not-allowed opacity-50': isSoldOut,
        }
      )}
    >
      {props.variant === ItemQuantityControllerVariant.DEFAULT ? (
        <div className="flex flex-auto items-center gap-3">
          <div className="flex-initial shrink-0">
            <img
              alt={props.name}
              className="size-8"
              src={`/icons/${props.icon}`}
            />
          </div>

          <div className="flex-auto">
            <Tooltip
              className="font-medium"
              content={props.name}
              maxLines={2}
              overflowStyle="underline"
            />

            <p className="text-sm leading-5">
              {props.price && props.price > 0 && (
                <span>{priceFormatter.format(props.price)} </span>
              )}

              {isSoldOut && props.showSoldOutLabel ? (
                <span className="text-secondary">{t('listItem.soldOut')}</span>
              ) : null}
              {!isSoldOut || props.alwaysShowLabel ? (
                <span className="text-secondary">
                  {props.minimumQuantity > 0 || props.alwaysShowLabel ? (
                    <> {minLabel}</>
                  ) : null}
                  {props.minimumQuantity > 0 ||
                  (props.alwaysShowLabel && props.hasLimit)
                    ? ' · '
                    : null}
                  {props.hasLimit ? <>{maxLabel}</> : null}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      ) : null}

      <div className="flex-0 relative flex items-center">
        <IconButton
          ariaLabel={t('itemQuantityController.remove')}
          icon={Minus}
          isDisabled={isRemoveDisabled}
          onClick={handleRemove}
        />

        <div className="flex size-10 items-center justify-center text-sm font-bold">
          {quantity}
        </div>

        <IconButton
          ariaLabel={t('itemQuantityController.add')}
          icon={Plus}
          isDisabled={isAddDisabled}
          onClick={handleAdd}
        />

        {props.onAddDisabled && isAddDisabled && (
          <button
            className="absolute right-0 top-0 size-10"
            onClick={props.onAddDisabled}
          />
        )}
      </div>
    </div>
  )
}

ItemQuantityController.defaultProps = {
  variant: ItemQuantityControllerVariant.DEFAULT,
}
