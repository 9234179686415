import { BackendErrorModel } from '@/models/backendErrorModel.ts'

export const isBackendErrorResponse = (
  error: unknown
): error is BackendErrorModel => {
  return (
    !!error &&
    typeof error === 'object' &&
    'error' in error &&
    'error_ex' in error &&
    'internal_error_code' in error
  )
}

export function isBrowserError(error: unknown): error is { code: string } {
  return !!error && typeof error === 'object' && 'code' in error
}
