import { cn } from '@spiaggeit/spit-ui'
import { useTranslation } from 'react-i18next'

import { selectActiveCartReservation } from '@/store/cartSlice/selectors/selectActiveCartReservation.ts'
import { useAppSelector } from '../../../hooks/store'
import { cartSlice } from '../../../store/cartSlice'
import {
  mapSelectedElementHasVisibleSeatsSelector,
  mapSelectedElementSetupDataSelector,
  mapSelectors,
} from '../../../store/mapSlice'
import { getSeatDefaultQuantity } from '../../../utils/getSeatDefaultQuantity'

import { SetupSeatListItem } from './SetupSeatListItem'
import { SetupServiceList } from './SetupServiceList'

export const SetupProductsList = () => {
  const { t } = useTranslation()
  const selectedElement = useAppSelector(mapSelectors.selectedElement)
  const selectedElementQuotedSeatsAmount = useAppSelector(
    cartSlice.selectors.selectedElementQuotedSeatsAmount
  )

  const setupData = useAppSelector(mapSelectedElementSetupDataSelector)

  const selectedElementSetupItems = setupData?.selectedElementSetupItems
  const selectedElementSetupLimit = setupData?.selectedElementSetupLimit

  if (!selectedElement || !selectedElementSetupItems) return null

  const isMinimumSeatsAmountSelected =
    !selectedElementSetupLimit?.min ||
    selectedElementQuotedSeatsAmount >= selectedElementSetupLimit?.min

  const activeCartReservation = useAppSelector(selectActiveCartReservation)
  const filteredSetupItems = selectedElementSetupItems
    ?.filter((item) => item.max > 0)
    .map((item) => {
      return {
        ...item,
        d: getSeatDefaultQuantity({
          selectedElementCartReservation: activeCartReservation,
          setupItem: item,
        }),
      }
    })

  const selectedElementHasVisibleSeats = useAppSelector(
    mapSelectedElementHasVisibleSeatsSelector
  )

  return (
    <div
      className={cn('flex w-full flex-col gap-2 px-3 pb-3 pt-1', {
        'py-3': selectedElementHasVisibleSeats,
      })}
    >
      {selectedElementHasVisibleSeats && filteredSetupItems.length >= 1 ? (
        <div className="border-b border-solid pb-3">
          <h3 className="font-bold text-primary">
            {t('map.elementDetail.setup')}
          </h3>
          <div className="flex flex-col gap-3 border-b border-solid py-3">
            {filteredSetupItems.map((item) => {
              const remainingGlobal = Math.max(
                (selectedElementSetupLimit?.max || 0) -
                  selectedElementQuotedSeatsAmount,
                0
              )

              return (
                <SetupSeatListItem
                  item={item}
                  key={item.keyName}
                  remainingGlobal={remainingGlobal}
                />
              )
            })}
          </div>

          <div className="mt-3">
            <div className="flex justify-between">
              <div>
                <h3 className="text-md font-bold text-primary">
                  {t('map.elementDetail.totalSeats')}
                </h3>
                <span className="text-md text-secondary">
                  {t('listItem.min', { value: selectedElementSetupLimit?.min })}{' '}
                  ·{' '}
                  {t('listItem.max', { value: selectedElementSetupLimit?.max })}
                </span>
              </div>
              <div className="ml-auto flex w-32 flex-col gap-3 text-center">
                <span className="text-sm font-bold text-primary">
                  {selectedElementQuotedSeatsAmount}
                </span>
                {!isMinimumSeatsAmountSelected && (
                  <span className="text-xs text-red-500">
                    {t('map.elementDetail.minimumSeatsRequired', {
                      count:
                        (selectedElementSetupLimit?.min || 0) -
                        selectedElementQuotedSeatsAmount,
                    })}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <SetupServiceList />
    </div>
  )
}
