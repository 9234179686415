import { cn } from '@spiaggeit/spit-ui'

import { Icon } from '../../../assets/icons'

export type InputErrorType =
  | 'api-error-with-exclamation-mark-icon'
  | 'api-error-with-triangle-exclamation-mark-icon'

export interface InputErrorConfig {
  id: string
  message: string
  type: 'error' | 'warning'
}

interface InputErrorProps {
  errorConfig: InputErrorConfig
}

export const InputError = (props: InputErrorProps) => {
  const { errorConfig } = props

  const message = errorConfig.message
  const isWarning = errorConfig.type === 'warning'
  const icon = isWarning ? 'ExclamationMark' : 'TriangleExclamationMark'

  return (
    <p
      className={cn(`flex gap-1 text-sm text-red-600`, {
        'text-yellow-600': isWarning,
      })}
      id={errorConfig.id}
      key={errorConfig.id}
      role="alert"
    >
      {icon && <Icon className="shrink-0" name={icon} />}
      <span className="inline-block">{message}</span>
    </p>
  )
}
