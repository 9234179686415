import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  CHOOSE_PRODUCT_PATH,
  INSERT_DATA_PATH,
  INSERT_INSURANCE_PATH,
  INSERT_PERIOD_PATH,
  LANDING_KIOSK_PATH,
  MAP_PATH,
  PAY_PATH,
  PAYMENT_KIOSK_PATH,
  ROOT_PATH,
  SECTOR_PATH,
  THANK_YOU_PATH,
  TICKETS_PATH,
} from '../app/router/paths'

export function useCurrentPath() {
  const location = useLocation()

  const currentPath = useMemo(() => {
    if (matchPath(LANDING_KIOSK_PATH, location.pathname)) {
      return LANDING_KIOSK_PATH
    }

    if (matchPath(INSERT_DATA_PATH, location.pathname)) {
      return INSERT_DATA_PATH
    }

    if (matchPath(INSERT_INSURANCE_PATH, location.pathname)) {
      return INSERT_INSURANCE_PATH
    }

    if (matchPath(INSERT_PERIOD_PATH, location.pathname)) {
      return INSERT_PERIOD_PATH
    }

    if (matchPath(CHOOSE_PRODUCT_PATH, location.pathname)) {
      return CHOOSE_PRODUCT_PATH
    }

    if (matchPath(TICKETS_PATH, location.pathname)) {
      return TICKETS_PATH
    }

    if (matchPath(PAYMENT_KIOSK_PATH, location.pathname)) {
      return PAYMENT_KIOSK_PATH
    }

    if (matchPath(MAP_PATH, location.pathname)) {
      return MAP_PATH
    }

    if (matchPath(SECTOR_PATH, location.pathname)) {
      return SECTOR_PATH
    }

    if (matchPath(PAY_PATH, location.pathname)) {
      return PAY_PATH
    }

    if (matchPath(THANK_YOU_PATH, location.pathname)) {
      return THANK_YOU_PATH
    }

    return ROOT_PATH
  }, [location])

  return {
    currentPath,
  }
}
