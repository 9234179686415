import { createSelector } from '@reduxjs/toolkit'

import { cartSlice } from '../store/cartSlice/index'
import { servicesSlice } from '../store/servicesSlice'
import { getServiceKeyFromId } from './serviceIdKeyUtils'

export const servicesWithCartQuantitySelector = createSelector(
  [servicesSlice.selectors.data, cartSlice.selectors.cartReservations],
  (services, cartReservations) => {
    return services?.serviceGroups
      .map((group) => ({
        ...group,
        services: group.services
          .map((service) => ({
            ...service,
            quantity:
              cartReservations[0]?.services[
                getServiceKeyFromId(service.serviceId)
              ],
          }))
          .filter(
            (service) =>
              cartReservations[0]?.services[
                getServiceKeyFromId(service.serviceId)
              ] >= 1
          ),
      }))
      .filter((group) => {
        const groupSum = Object.values(group.services).reduce(
          (acc, curr) => acc + curr.quantity,
          0
        )
        return groupSum > 0
      })
  }
)

export const ticketsInCartTotalQuantity = createSelector(
  [servicesWithCartQuantitySelector],
  (servicesWithCartQuantity) => {
    const totalQuantity = servicesWithCartQuantity?.reduce(
      (groupAcc, group) =>
        groupAcc + group.services.reduce((acc, curr) => acc + curr.quantity, 0),
      0
    )
    return totalQuantity ?? 0
  }
)
