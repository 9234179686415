import { AppIcon, Icon } from '@/assets/icons'

import styles from './style.module.css'

interface SurfaceIconProps {
  iconName: AppIcon
}

export const SurfaceIcon = (props: SurfaceIconProps) => {
  return (
    <div className="flex justify-center">
      <div className={styles.IconContainer}>
        <Icon className="size-5" name={props.iconName} />
      </div>
    </div>
  )
}
