import { isSuccess } from '@devexperts/remote-data-ts'
import chroma from 'chroma-js'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { selectIsWhiteLabelActive, widgetSlice } from '@/store/widgetSlice.ts'

/**
 * This hook is responsible for fetching the whiteLabel data when the mode set is “widget” and setting
 * the body class and the global css variables from the data returned in response
 */
export const useSetupWhiteLabel = () => {
  const dispatch = useAppDispatch()
  const whiteLabel = useAppSelector(widgetSlice.selectors.whiteLabel)
  const isActive = useAppSelector(selectIsWhiteLabelActive)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!isActive) return

    dispatch(
      widgetSlice.actions.fetchWidgetWhiteLabel({
        previewCode: searchParams.get('previewCode'),
      })
    )
  }, [isActive])

  useEffect(() => {
    if (!isSuccess(whiteLabel) || !whiteLabel.value) return

    const {
      headerTextColor,
      headerBackgroundColor,
      textColorBackground,
      primaryColor,
      pageBackgroundColor,
      dangerButtonBackgroundColor,
      dangerButtonTextColor,
      borderRadius,
      textFont,
    } = whiteLabel.value

    document.body.classList.add('whitelabel')

    if (!borderRadius) {
      document.body.classList.add('whitelabel--no-border-radius')
    }

    let variables: [string, string | number][] = [
      ['--white-label-text-font', textFont || ''],
      ['--white-label-header-background-color', headerBackgroundColor || ''],
      ['--white-label-header-text-color', headerTextColor || ''],
      ['--white-label-text-color-bg-image', textColorBackground || ''],
      ['--white-label-primary-color', primaryColor || ''],
      [
        '--white-label-danger-button-bg-color',
        dangerButtonBackgroundColor || '',
      ],
      ['--white-label-danger-button-text-color', dangerButtonTextColor || ''],
      ['--white-label-page-background-color', pageBackgroundColor || ''],

      /* Spit ui & Datepicker */
      ['--wl-font-family', textFont || ''],
    ]

    if (!borderRadius) {
      variables = [...variables, ['--wl-border-radius', 0]]
    }

    if (primaryColor) {
      const shades = chroma
        .scale(['#fff', primaryColor, '#000'])
        .mode('lab')
        .colors(10)

      const [
        [primary100Hex, primary100Rgb],
        [primary200Hex, primary200Rgb],
        [primary500Hex, primary500Rgb],
        [primary700Hex, primary700Rgb],
      ] = [
        [shades[1], chroma(shades[1]).rgb()],
        [shades[2], chroma(shades[2]).rgb()],
        [primaryColor, chroma(primaryColor).rgb()],
        [shades[7], chroma(shades[7]).rgb()],
      ]

      variables = [
        ...variables,
        ['--wl-primary-color-100', primary100Hex || ''],
        ['--wl-primary-color-200', primary200Hex || ''],
        ['--wl-primary-color-500', primary500Hex || ''],
        ['--wl-primary-color-700', primary700Hex || ''],

        ['--wl-primary-color-100-rgb', primary100Rgb.join(' ')],
        ['--wl-primary-color-200-rgb', primary200Rgb.join(' ')],
        ['--wl-primary-color-500-rgb', primary500Rgb.join(' ')],
        ['--wl-primary-color-700-rgb', primary700Rgb.join(' ')],
      ]
    }

    if (dangerButtonBackgroundColor) {
      const dangerShades = chroma
        .scale(['#fff', dangerButtonBackgroundColor, '#000'])
        .mode('lab')
        .colors(10)

      const [
        [
          dangerBackgroundColorRgb,
          dangerBackgroundColorHoverRgb,
          dangerTextColorRgb,
        ],
      ] = [
        [
          chroma(dangerButtonBackgroundColor).rgb(),
          chroma(dangerShades[7]).rgb(),
          chroma(dangerButtonTextColor).rgb(),
        ],
      ]

      variables = [
        ...variables,
        ['--wl-danger-btn-bg-color-rgb', dangerBackgroundColorRgb.join(' ')],
        [
          '--wl-danger-btn-bg-color-hover-rgb',
          dangerBackgroundColorHoverRgb.join(' '),
        ],
        ['--wl-danger-btn-text-color-rgb', dangerTextColorRgb.join(' ')],
      ]
    }

    variables.forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value.toString())
    })

    return () => {
      document.body.classList.remove('whitelabel')

      if (!borderRadius) {
        document.body.classList.remove('whitelabel--no-border-radius')
      }

      variables.forEach(([key]) => {
        document.documentElement.style.removeProperty(key)
      })
    }
  }, [whiteLabel])
}
