import { isSuccess } from '@devexperts/remote-data-ts'
import { useEffect } from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'

import { INSERT_PERIOD_PATH } from '@/app/router/paths.ts'
import { useAppDispatch, useAppSelector } from '@/hooks/store.ts'
import { useAppPath } from '@/hooks/useAppPath'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { insertPeriodSlice } from '@/store/insertPeriodSlice.ts'

export const PeriodProtectedRoute = () => {
  const period = useAppSelector(insertPeriodSlice.selectors.period)
  const quoteTotal = useAppSelector(cartSlice.selectors.quoteTotal)
  const [searchParams] = useSearchParams()
  const insertPeriodPath = useAppPath(INSERT_PERIOD_PATH)
  const quoteWithDiscount = useAppSelector(
    cartSlice.selectors.quoteWithDiscount
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    // This useEffect is useful because it allows you to remove the discount
    // code in the store whenever the total price
    // of the cart changes after the discount code has already been applied

    if (isSuccess(quoteWithDiscount)) {
      dispatch(cartSlice.actions.resetDiscount())
    }
  }, [quoteTotal])

  const isPeriodSet = period && period.end && period.start

  return isPeriodSet ? (
    <Outlet />
  ) : (
    <Navigate to={`${insertPeriodPath}?${searchParams.toString()}`} />
  )
}
