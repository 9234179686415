import { ComponentPropsWithoutRef, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonText } from '@/components/SkeletonText'
import { useAppSelector } from '@/hooks/store.ts'
import { cartSlice } from '@/store/cartSlice/index.ts'
import { Icon } from '../../../assets/icons'
import { formatPrice } from '../../../utils/price.ts'
import { ServiceCostDialog } from '../../ServiceCostDialog'

export interface SummaryCost {
  name: string
  price: number
  isDiscount?: boolean
  hasTooltip?: boolean
}

export interface ReservationCostsProps
  extends ComponentPropsWithoutRef<'section'> {
  costs: SummaryCost[]
  total: number
}

export const ReservationCosts = (props: ReservationCostsProps) => {
  const { costs, total, ...rest } = props
  const { t } = useTranslation()
  const titleId = useId()
  const isPending = useAppSelector(
    cartSlice.selectors.isQuoteOrDiscountQuotePending
  )

  return (
    <section aria-labelledby={titleId} {...rest}>
      <h3 className="sr-only" id={titleId}>
        {t('a11y.reservationCosts')}
      </h3>

      <dl className="mb-1 flex flex-col items-stretch justify-between gap-1">
        {costs.map((cost, index) => (
          <div
            className="flex items-center justify-between gap-4 text-secondary"
            key={index}
          >
            <dt>
              {cost.hasTooltip ? (
                <ServiceCostDialog
                  trigger={
                    <button className="flex items-center justify-between gap-1 underline">
                      {cost.name}
                      <Icon className="size-4" name="ExclamationMark" />
                    </button>
                  }
                />
              ) : (
                <>{cost.name}</>
              )}
            </dt>
            <dd>
              {cost.isDiscount && (
                <>
                  <span aria-hidden="true">-</span>&nbsp;
                </>
              )}

              <SkeletonText
                isPending={isPending}
                text={formatPrice(cost.price)}
              />
            </dd>
          </div>
        ))}
      </dl>

      <p className="flex items-center justify-between font-bold">
        <strong>{t('common.total')}</strong>
        <output>
          <SkeletonText isPending={isPending} text={formatPrice(total)} />
        </output>
      </p>
    </section>
  )
}
