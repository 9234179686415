import { Button, Dialog, TextField } from '@spiaggeit/spit-ui'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { licenseSlice } from '@/store/licenseSlice'
import { assertNever } from '../lib/types/utils'
import { SectorRule } from '../models/insertPeriod'
import { appSlice } from '../store/appSlice'
import { insertPeriodSlice } from '../store/insertPeriodSlice'
import { getUnavailableSectors } from '../utils/sectorRulesUtils'

import { ToggleGroup, ToggleGroupProps } from './ToggleGroup'

type SectorRulesDialogProps = {
  isOpen: boolean
  sectorRules: SectorRule[]

  onConfirm: (unavailableSectors: number[], taxCode: string) => void
  setIsOpen: (isOpen: boolean) => void
}

type Mode = 'resident' | 'non-resident'

export function SectorRulesDialog(props: SectorRulesDialogProps) {
  const { t } = useTranslation()

  const license = useSelector(licenseSlice.selectors.license)
  const period = useSelector(insertPeriodSlice.selectors.period)
  const country = useSelector(appSlice.selectors.country)

  const [mode, setMode] = React.useState<Mode>('non-resident')
  const [taxCode, setTaxCode] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const toggleGroupOptions = React.useRef<ToggleGroupProps<Mode>['options']>([
    {
      label: t('dialog.sectorRules.nonResident'),
      value: 'non-resident',
    },
    {
      label: t('dialog.sectorRules.resident'),
      value: 'resident',
    },
  ])

  React.useEffect(() => {
    if (!props.isOpen) {
      // reset when modal closes
      setMode('non-resident')
      setTaxCode('')
    }
  }, [props.isOpen])

  React.useEffect(() => {
    if (mode === 'non-resident') {
      setTaxCode('')
    }
  }, [mode])

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    switch (mode) {
      case 'non-resident':
      case 'resident': {
        setIsLoading(true)
        try {
          const unavailableSectors = await getUnavailableSectors({
            country,
            license,
            period,
            sectorRules: props.sectorRules,
            taxCode,
          })
          props.onConfirm(unavailableSectors ?? [], taxCode)
        } finally {
          setIsLoading(false)
        }
        break
      }
      default:
        assertNever(mode)
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={t('dialog.sectorRules.title', { city: license?.beach.city ?? '' })}
      trigger={null}
    >
      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <ToggleGroup
            className="mb-4"
            onValueChange={setMode}
            options={toggleGroupOptions.current}
            value={mode}
          />

          {mode === 'non-resident' ? null : (
            <div className="mb-8 space-y-1">
              <TextField
                id="tax-code"
                label={t('dialog.sectorRules.taxCode')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTaxCode(e.target.value)
                }}
                value={taxCode}
              />

              <p className="text-secondary">
                <Trans i18nKey="dialog.sectorRules.taxCodeMessage" />{' '}
                <a
                  className="text-link transition-colors hover:text-link-hover hover:underline"
                  href="https://www.codicefiscale.com/"
                >
                  {t('dialog.sectorRules.taxCodeLink')}
                </a>
              </p>
            </div>
          )}

          <Button
            disabled={
              mode === 'non-resident' ? false : taxCode.trim().length !== 16
            }
            fullWidth
            isLoading={isLoading}
            type="submit"
          >
            {t('dialog.sectorRules.submitBtn')}
          </Button>
        </form>
      </div>
    </Dialog>
  )
}
